import { combineReducers } from 'redux';
import { START_FETCHING_DASHBOARD_OVERVIEWS, SET_DASHBOARD_OVERVIEWS_DATA } from './actions';

const overviewsDataReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_DASHBOARD_OVERVIEWS_DATA:
      return payload;
    default:
      return state;
  }
};

const overviewsIsLoadingReducer = (state = false, { type }) => {
  switch (type) {
    case START_FETCHING_DASHBOARD_OVERVIEWS:
      return true;
    case SET_DASHBOARD_OVERVIEWS_DATA:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  data: overviewsDataReducer,
  isLoading: overviewsIsLoadingReducer
});

export const selectViolationsDashboardOverview = state => state.data;
