import { fetchAdvertisers } from '../../../domain/Advertisers/actions';
import { getAdvertisers, putAdvertisers } from './advertiserStorage';
import * as R from 'ramda';

export const clearInvalidAdvertisers = async () => {
  const validAdvertisers = await fetchAdvertisers();
  const validAdvertiserIds = R.map(R.prop('cid'))(validAdvertisers);
  const validAdvertiserIdsMap = R.fromPairs(
    R.map(advertiserId => [advertiserId, true])(validAdvertiserIds)
  );
  const advertisersFromStorage = getAdvertisers();
  const isValidAdvertiser = advertiserId => {
    return R.defaultTo(false)(R.prop(advertiserId)(validAdvertiserIdsMap));
  };
  const validAdvertisersForLocalStorage = R.filter(isValidAdvertiser)(advertisersFromStorage);
  putAdvertisers(validAdvertisersForLocalStorage);
};
