import * as R from 'ramda';
import { useState } from 'react';
import { normalize, normalizeComments, parseDate } from '../helpers/issuesExcelFormatHelper';
import { isEmployee, isPublisher } from '../../../../shared/userStorage';
import {useSelector} from 'react-redux';
import {selectAdvertiserNameMap, selectAdvertiserPrefixesMap} from '../../../../reducer';

export const ExportIssueButtonPure = ({ useUser, getIssues, Button, saveXLSXFile}) => {
  const user = useUser();
  const advertiserPrefixMap = useSelector(selectAdvertiserPrefixesMap);
  const advertiserNameMap = useSelector(selectAdvertiserNameMap);
  const advertiserMap = {prefix: advertiserPrefixMap, name: advertiserNameMap};
  const _isEmployee = isEmployee(user);
  const _isPublisher = isPublisher(user);
  const [isDownloading, setIsDownloading] = useState(false); 

  const handlerExport = async () => {
    setIsDownloading(true);
    const { issues } = await getIssues();
    
    if (!R.isEmpty(issues)) {
      const issuesTransformed = issues.map(issue => ({...issue, fullIssueID: `${advertiserMap.prefix[issue.advertiserId]}-${issue.id}`, advertiserName: advertiserMap.name[issue.advertiserId]}));
      const normalizedIssues = normalize(issuesTransformed, _isEmployee, _isPublisher);
      const commentsIssues = normalizeComments(issuesTransformed, _isPublisher);
      const compliance = {sheetName: 'Compliance Violation', items: normalizedIssues};
      const comments = {sheetName: 'Comments', items: commentsIssues};
      const sheets = [compliance, comments];
      const fileName = `Error Log ${parseDate(new Date())}.xlsx`;
      saveXLSXFile({ sheets, fileName });
    }
    setIsDownloading(false);
  };

  return <Button isDownloading={isDownloading} onClick={handlerExport} />;
};
