import React from 'react';
import { connect } from 'react-redux';
import R from 'ramda';
import { Modal } from '@cjdev-internal/visual-stack-x/components/Modal';
import { closeModal } from '@cjdev-internal/visual-stack-redux';
import { SimpleError } from '../../components/ErrorMessage';
import CustomButton, { CancelButton, SpinnerButton } from '../../components/Button';
import { TextInput } from '../../components/FormFields';
import {
  submitDomainAndReload,
  createDomainAndConfig,
  resetModal
} from '../../domain/Domains/actions';
import {history, withNavigate} from '../../router'
import { Row } from '@cjdev-internal/visual-stack-x/components/Row';

export class SubmitDomainModal extends React.Component {
  constructor() {
    super();
    this.state = {
      domainUrl: ''
    };
  }

  componentDidMount() {
    const { domainId, domain } = this.props;
    if (!R.isNil(domainId))
      this.setState({
        domainUrl: domain
      });
  }

  onInputChange(event) {
    this.setState({
      domainUrl: event.target.value
    });
  }
  onSubmit() {
    const { submitDomainAndReload, createDomainAndConfig, domainId, domainConfigIds } = this.props;
    const domainPath = { domain: this.state.domainUrl, domainConfigIds: [] };

    if (R.isNil(domainId)) {
      createDomainAndConfig(domainPath).then(({ domainId, configId }) => {
          history.push(`/domains/${domainId}/domain-configs/${configId}`);
          this.props.navigate(`/domains/${domainId}/domain-configs/${configId}`);
        }
      );
    } else {
      const domainBody = { ...domainPath, domainConfigIds: domainConfigIds };
      submitDomainAndReload(domainBody, domainId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const stoppedLoading = this.props.submitDomain.isLoading && !nextProps.submitDomain.isLoading;
    const error = nextProps.submitDomain.error;

    if (stoppedLoading && R.isNil(error)) {
      this.props.closeModal();
    }
  }
  render() {
    const { closeModal, submitDomain, resetModal } = this.props;
    const submitTitle = this.props.domain ? 'Edit' : 'Create';
    const submitAction = this.props.domain ? 'Editing' : 'Creating';
    const footer = (
      <Row gap="small">
        {submitDomain.error && <SimpleError error={submitDomain.error} />}
        {submitDomain.isLoading ? (
          <SpinnerButton message={`${submitAction} Domain`} />
        ) : (
          <CustomButton onClick={() => this.onSubmit()} iconType="plus">
            {submitTitle} Domain
          </CustomButton>
        )}
        <CancelButton
          onClick={() => {
            resetModal();
            closeModal();
          }}
        />
      </Row>
    )
    return (
      <Modal headerTitle={`${submitTitle} Domain`} footer={footer}>
        <TextInput
            placeholder="Enter your domain here"
            onChange={event => this.onInputChange(event)}
            value={this.state.domainUrl}
            width={'50%'}
          />
      </Modal>
    );
  }
}

export const mapStateToProps = state => ({
  submitDomain: state.CAS.domains.domains.submitDomain
});

export const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  resetModal: () => dispatch(resetModal()),
  submitDomainAndReload: (value, id) => dispatch(submitDomainAndReload(value, id)),
  createDomainAndConfig: value => dispatch(createDomainAndConfig(value))
});

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(SubmitDomainModal));
