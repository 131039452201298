import {FormPageLayout, FormPageBlock} from '@cjdev-internal/visual-stack-x/FormPageLayout';
import {Divider} from '@cjdev-internal/visual-stack-x/LabelValueGrid';
import {Text} from '@cjdev-internal/visual-stack-x/Text';
import {Stack} from '@cjdev-internal/visual-stack-x/components/Stack';
import {Box} from '@cjdev-internal/visual-stack-x/components/Box';
import {Field} from '@cjdev-internal/visual-stack-x/Field';
import {TextArea} from '@cjdev-internal/visual-stack-x/TextArea';
import {useManageRecipientContext} from '../context/ManageRecipientContext';
import {Heading} from '@cjdev-internal/visual-stack-x/components/Heading';

const ManageRecipientContent = ({complianceViolationId, issueReporter}) => {
    const {
        publisherContact,
        setPuplisherContact,
        advertiserContact,
        setAdvertiserContact,
        internalContact,
        setInternalContact
    } = useManageRecipientContext();
    const help = 'Separate email addresses with commas.';
    return (
        <FormPageLayout width="wide" verticalPad>
            <FormPageBlock>
                <Box gap="medium-large">
                    <Heading size={18}>Notification Recipients</Heading>
                    <Stack gap="medium">
                        <Text size={13} color="primary">
                            <Text weight="bold">Violation:</Text> {complianceViolationId}
                        </Text>
                        <Text size={13} color="primary">
                            <Text weight="bold">Record Creator:</Text> {issueReporter}
                        </Text>
                        <Box paddingTop="xl">
                            <Text size={13} color="secondary">
                                The following contacts will be included in all enabled email notifications during
                                the resolution process of the compliance violation.
                            </Text>
                        </Box>
                    </Stack>
                    <Heading size={16}>Publisher Contacts</Heading>
                    <Field label="Email notification recipient" help={help}>
                        <TextArea
                            data-testid="publisher-emails"
                            value={publisherContact}
                            onChange={e => setPuplisherContact(e.target.value)}
                        />
                    </Field>
                    <Divider/>
                    <Heading size={16}>Advertiser Contacts</Heading>
                    <Field label="Email notification recipient" help={help}>
                        <TextArea
                            data-testid="advertiser-emails"
                            value={advertiserContact}
                            onChange={e => setAdvertiserContact(e.target.value)}
                        />
                    </Field>
                    <Divider/>
                    <Heading size={16}>CJ Contacts</Heading>
                    <Field label="Email notification recipient" help={help}>
                        <TextArea
                            data-testid="internal-emails"
                            value={internalContact}
                            onChange={e => setInternalContact(e.target.value)}
                        />
                    </Field>
                </Box>
            </FormPageBlock>
        </FormPageLayout>
    );
};

export default ManageRecipientContent;
