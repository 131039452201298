export class S3Uploader {
    constructor(options, S3API) {
        // this must be bigger than or equal to 5MB,
        // otherwise AWS will respond with:
        // "Your proposed upload is smaller than the minimum allowed size"
        this.chunkSize = options.chunkSize || 1024 * 1024 * 5
        // number of parallel uploads
        this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15)
        this.file = options.file
        this.initializeMultipartUpload = S3API.initializeMultipartUpload;
        this.getMultipartPreSignedUrls = S3API.getMultipartPreSignedUrls;
        this.finalizeMultipartUpload = S3API.finalizeMultipartUpload;
        this.uploadedSize = 0
        this.progressCache = {}
        this.activeConnections = {}
        this.parts = []
        this.uploadedParts = []
        this.fileId = null
        this.fileKey = null
        this.onProgressFn = () => {}
    }

    async start() {
        return new Promise(async (resolve, reject)=> {
            await this.initialize(resolve, reject);
        })
    }

    async initialize(resolve, reject) {
        try {
            const videoInitializationUploadInput = {
                name: this.file.name,
            };

            const {fileId, fileKey} = await this.initializeMultipartUpload(videoInitializationUploadInput);

            this.fileId = fileId;
            this.fileKey = fileKey;

            // retrieving the pre-signed URLs
            const numberOfparts = Math.ceil(this.file.size / this.chunkSize);

            const AWSMultipartFileDataInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                parts: numberOfparts,
            };

            const {parts} = await this.getMultipartPreSignedUrls(AWSMultipartFileDataInput);
            this.parts = [...parts];
            
            
            await this.sendNext(resolve, reject);

        } catch (error) {
            reject(error);
        }
    }

    async sendNext(resolve, reject) {
        const activeConnections = Object.keys(this.activeConnections).length

        if (activeConnections >= this.threadsQuantity) {
            return
        }

        if (!this.parts.length) {
            if (!activeConnections) {
                await this.complete(resolve, reject)
            }

            return
        }

        const part = this.parts.pop()
        if (this.file && part) {
            const sentSize = (part.PartNumber - 1) * this.chunkSize
            const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

            const sendChunkStarted = async () => {
                await this.sendNext(resolve, reject);
            }

            await this.sendChunk(chunk, part, sendChunkStarted)
                .then(async () => {
                    await this.sendNext(resolve, reject);
                })
                .catch(async (error) => {
                    reject(error);
                })
        }
    }

    async complete(resolve, reject) {
        try {
            const completeValue = await this.sendCompleteRequest();
            resolve(completeValue);
        } catch (error) {
            reject(error);
        }
    }

    async sendCompleteRequest() {
        if (this.fileId && this.fileKey) {
            const videoFinalizationMultiPartInput = {
                fileId: this.fileId,
                fileKey: this.fileKey,
                parts: this.uploadedParts,
            }

            const {link, status} = await this.finalizeMultipartUpload(videoFinalizationMultiPartInput);

            return {link, status};
        }
    }

    sendChunk(chunk, part, sendChunkStarted) {
        return new Promise((resolve, reject) => {
            this.upload(chunk, part, sendChunkStarted)
                .then((status) => {
                    if (status !== 200) {
                        reject(new Error("Failed chunk upload"))
                        return
                    }

                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    handleProgress(part, event) {
        
        if (this.file) {
            if (event.type === "progress" || event.type === "error") {
                this.progressCache[part] = event.loaded
            }

            const inProgress = Object.keys(this.progressCache)
                .map(Number)
                .reduce((memo, id) => (memo += this.progressCache[id]), 0);

            const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

            const total = this.file.size

            const percentage = Math.round((sent / total) * 100);

            this.onProgressFn({
                sent,
                total,
                percentage,
            })
        }
    }

    upload(file, part, sendChunkStarted) {
        // uploading each part with its pre-signed URL
        return new Promise(async (resolve, reject) => {
            if (this.fileId && this.fileKey) {
                const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest())

                sendChunkStarted()

                const progressListener = this.handleProgress.bind(this, part.PartNumber - 1)

                xhr.upload.addEventListener("progress", progressListener)

                xhr.upload.addEventListener("error", progressListener)
                xhr.upload.addEventListener("loadend", progressListener)

                xhr.open("PUT", part.signedUrl)

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        const ETag = xhr.getResponseHeader("ETag")

                        if (ETag) {
                            const uploadedPart = {
                                partNumber: part.PartNumber,
                                eTag: ETag.replaceAll('"', ""),
                            }

                            this.uploadedParts.push(uploadedPart)

                            resolve(xhr.status)
                            delete this.activeConnections[part.PartNumber - 1]
                        }
                    }
                }

                xhr.onerror = (error) => {
                    reject(error)
                    delete this.activeConnections[part.PartNumber - 1]
                }

                xhr.send(file)
            }
        })
    }

    onProgress(onProgress) {
        this.onProgressFn = onProgress
        return this
    }
}


