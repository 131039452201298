import React, {Component} from 'react';
import {AppLayout} from '../App';
import {connect} from 'react-redux';
import {LargeSpinner} from '../../components/Spinner';
import {
    selectAdvertiserPrefixesMap,
    selectIsInternalUser,
    selectIsLoadingAdvertiser,
    selectIsLoadingIssuesPage,
    selectIssues,
    selectNumIssues,
    selectSelectedAdvertiserLabel,
    selectWebsites
} from '../../reducer';
import {
    fetchIssuesPageAction,
    finishLoadingIssuesPage,
    loadingIssuesPage,
    switchAdvertiserAction
} from '../../domain/Issues/actions';
import {clearSelectedAdvertiserAction} from '../../domain/Advertisers/actions';
import * as R from 'ramda';
import styled from 'styled-components';
import ZeroState from './ZeroState';
import ConfigureIssueButton from './ConfigureIssue';
import IssuesListView from './IssuesListView';
import {history, withLocation, withNavigate, withSearchParams} from '../../router';
import {PlatformNavContext} from '../../navContext';
import IssuesSlidingPanel from './IssuesSlidingPanel';
import {COMPLIANCE_VIOLATIONS} from '../../paths';

export const ContentWrapper = styled.div`
    padding: 10px;

    & > * {
        margin-bottom: 18px;
    }

    overflow-x: auto;
    max-height: calc(100vh - 300px);
`;

const SpinnerWrapper = styled.div`
    padding: 100px 0;
    text-align: center;
`;

export class Issues extends Component {
    static contextType = PlatformNavContext;

    constructor(props) {
        super(props);
        this.initializeMaps();
        this.state = {pendingIssue: {}};
    }

    initializeMaps = () => {
        this.websiteIdToAnnotatedWebsiteMap = R.fromPairs(
            this.props.websites.map(annotatedWebsite => [annotatedWebsite.websiteId, annotatedWebsite])
        );
    };

    isQueryIssueNumber = () => this.props.searchParams.has('issueNumber');

    getIssueNumber = () => this.props.searchParams.get('issueNumber');

    redirectOrRun = (callback) => {
        if (!this.isQueryIssueNumber()) {
            callback();
        } else {
            const issueNumber = this.getIssueNumber();
            history.replace(COMPLIANCE_VIOLATIONS);
            this.toIssueDetail(issueNumber);
        }
    }

    componentDidUpdate(previousProps) {
        this.redirectOrRun(() => {
            this.initializeMaps();
            const getQuery = R.view(R.lensPath(['searchParams']));
            const previousQuery = getQuery(previousProps).toString();
            const currentQuery = getQuery(this.props).toString();
            if (previousQuery !== currentQuery) {
                this.props.fetchIssuesPageAction();
            }
        });
    }

    componentDidMount() {
        this.redirectOrRun(() => {
            this.props.fetchIssuesPageAction();
        });
    }

    toIssueDetail = issueId => {
        this.props.toIssueDetail(issueId, this.props.navigate, history, this.context);
    };

    submitPendingIssue = async pendingIssue => {
        const {prefixesMap, loadingIssuesPage, finishLoadingIssuesPage, submitIssue} = this.props;
        const {advertiserId} = pendingIssue;

        loadingIssuesPage();
        const {response} = await submitIssue(pendingIssue);
        const id = await response;
        const complianceViolationId = `${prefixesMap[advertiserId]}-${id}`;
        finishLoadingIssuesPage();
        this.toIssueDetail(complianceViolationId);
    };

    switchAdvertiser = value => {
        this.props.switchAdvertiserAction(value);
    };

    componentWillUnmount() {
        this.props.clearSelectedAdvertiserAction();
    }

    configureIssueButtonGenerator = advertiserLabel => (existingValues, type) => {
        return (
            <ConfigureIssueButton
                user={this.props.user}
                existingValues={existingValues}
                submitPendingIssue={this.submitPendingIssue}
                advertiserLabel={advertiserLabel}
                type={type}
            />
        );
    };

    IssuesSlidingPanel = () => {
        return <IssuesSlidingPanel initialActive={false}/>;
    };

    render() {
        const {
            websites,
            issues,
            isLoadingIssuesPage,
            isLoadingAdvertiser,
            advertiserLabel
        } = this.props;

        const renderEmptyState = () => (
            <ZeroState
                configureIssueButtonGenerator={this.configureIssueButtonGenerator(advertiserLabel)}
            />
        );

        return (
            <div>
                <AppLayout
                    pageHeaderTitle="Violations"
                    slidingPanel={this.context ? null : this.IssuesSlidingPanel}
                >
                    {(isLoadingIssuesPage || isLoadingAdvertiser) && (
                        <SpinnerWrapper>
                            <LargeSpinner/>
                        </SpinnerWrapper>
                    )}
                    {!isLoadingIssuesPage && !isLoadingAdvertiser && (
                        <IssuesListView
                            toIssueDetail={this.toIssueDetail}
                            renderEmptyState={renderEmptyState}
                            websites={websites}
                            issues={issues}
                            configureIssueButtonGenerator={this.configureIssueButtonGenerator(advertiserLabel)}
                        />
                    )}
                </AppLayout>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    websites: selectWebsites(state),
    issues: selectIssues(state),
    numIssues: selectNumIssues(state),
    isLoadingIssuesPage: selectIsLoadingIssuesPage(state),
    isInternalUser: selectIsInternalUser(state),
    isLoadingAdvertiser: selectIsLoadingAdvertiser(state),
    advertiserLabel: selectSelectedAdvertiserLabel(state),
    prefixesMap: selectAdvertiserPrefixesMap(state)
});

const mapDispatchToProps = {
    switchAdvertiserAction,
    fetchIssuesPageAction,
    clearSelectedAdvertiserAction,
    loadingIssuesPage,
    finishLoadingIssuesPage
};

export default withLocation(
    withNavigate(withSearchParams(connect(mapStateToProps, mapDispatchToProps)(Issues)))
);
