import React from 'react';
import { am4core, am4charts } from '../AMCharts';
import './RiskIdentificationAMChart.css';
import * as R from 'ramda';

const colors = ['#102c7e', '#6d40da', '#30c4b4'];

class RiskIdentificationAMChart extends React.Component {
  componentDidMount() {
    const chart = am4core.create('number-of-violations-by-audit-type', am4charts.XYChart);
    chart.legend = new am4charts.Legend();
    chart.fontSize = 13;
    chart.fill = am4core.color('#333');
    chart.data = this.props.data;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    chart.cursor = new am4charts.XYCursor();
    categoryAxis.dataFields.category = 'value';
    categoryAxis.renderer.inversed = true;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.cellStartLocation = 0.3;
    categoryAxis.renderer.cellEndLocation = 0.8;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    const label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 240;
    label.textAlign = 'start';

    const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.tooltip.disabled = true;
    xAxis.maxPrecision = 0;

    function createSeries(field, name, color) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.strokeWidth = 0;
      series.dataFields.categoryY = 'value';
      series.tooltipText = '{name}: [bold]{valueX}';
      series.columns.template.fill = am4core.color(color);
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = am4core.color('#333');
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.stroke = am4core.color(color);
      series.tooltip.background.strokeWidth = 1.5;
      series.tooltip.fill = am4core.color('#000');
      series.tooltip.fillOpacity = 0.9;
      series.name = name;
      series.columns.template.height = am4core.percent(60);
      series.sequencedInterpolation = true;
    }
    this.props.labels.forEach(([field, label], index) => {
      createSeries(field, label, colors[index]);
    });

    this.chart = chart;
  }

  componentDidUpdate() {
    this.chart.data = this.props.data;
  }

  render() {
    const { data } = this.props;
    const numberOfBars = R.length(data);
    const requiredPixels = numberOfBars * 70 + (10 - numberOfBars) * 10;
    return (
      <div
        id="number-of-violations-by-audit-type"
        className="risk-identification-chart"
        style={{ height: `${requiredPixels}px` }}
      />
    );
  }
}

export const RiskIdentificationChartAdvertiser = props => {
  const labels = props.labels || [
    ['high', 'High'],
    ['medium', 'Medium'],
    ['low', 'Low']
  ];
  return <RiskIdentificationAMChart {...props} labels={labels} />;
};

export const RiskIdentificationChartPublisher = props => {
  return <RiskIdentificationAMChart {...props} labels={[['total', 'Total']]} />;
};
