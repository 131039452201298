import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import {
  TabLayout,
  Tab,
  TabLabelContent,
  TabContent
} from '@cjdev-internal/visual-stack-redux/lib/components/TabLayout';
import ProductFormDetail from './ProductFormDetail';
import { SubmitError } from '../../components/ErrorMessage';
import { Button } from '@cjdev-internal/visual-stack-x/components/Button';
import LandingUrlConfiguration from './LandingUrlConfiguration';
import InferredProhibitedText from './InferredProhibitedText';

const DivWithBorder = styled.div`
  border: 1px solid #e1e1e1;
`;

const productFormDefaultValues = { productName: '', advertiserId: '' };

const SubmissionControl = ({ productSubmissionError, handleProductSubmit, pendingValues }) => {
  return (
    <div>
      <div>{productSubmissionError && <SubmitError submitError={productSubmissionError} />}</div>
      <Button
        htmlType="submit"
        type="primary"
        onClick={() => handleProductSubmit(pendingValues)}
        className="productSubmission"
      >
        Submit
      </Button>
    </div>
  );
};

export default class ProductConfigurations extends React.Component {
  state = {
    pendingValues: {}
  };

  componentDidMount = () => {
    this.setState({ pendingValues: this.props.product });
  };

  render() {
    const { product, basicDomainInfo, handleProductSubmit, productSubmissionError } = this.props;
    return (
      <DivWithBorder>
        <TabLayout tabLayoutId="product-config" themeColor="#048BC6">
          <Tab
            label={<TabLabelContent>Product</TabLabelContent>}
            content={
              <TabContent>
                <ProductFormDetail
                  initialValues={product}
                  onChange={product =>
                    this.setState(({ pendingValues }) => {
                      const filteredProduct = R.merge(productFormDefaultValues, product);
                      return { pendingValues: R.merge(pendingValues, filteredProduct) };
                    })
                  }
                />
                <SubmissionControl
                  productSubmissionError={productSubmissionError}
                  handleProductSubmit={handleProductSubmit}
                  pendingValues={this.state.pendingValues}
                />
              </TabContent>
            }
          />
          <Tab
            label={<TabLabelContent>Inferred Prohibited Text</TabLabelContent>}
            content={
              <TabContent>
                <InferredProhibitedText />
              </TabContent>
            }
          />
          <Tab
            label={<TabLabelContent>Landing URLs</TabLabelContent>}
            content={
              <TabContent>
                <LandingUrlConfiguration
                  landingUrlConfigurations={this.state.pendingValues.landingUrlConfigurations}
                  basicDomainInfo={basicDomainInfo}
                  onChange={landingUrlConfigurations =>
                    this.setState(({ pendingValues }) => {
                      return {
                        pendingValues: R.merge(pendingValues, { landingUrlConfigurations })
                      };
                    })
                  }
                />
                <SubmissionControl
                  productSubmissionError={productSubmissionError}
                  handleProductSubmit={handleProductSubmit}
                  pendingValues={this.state.pendingValues}
                />
              </TabContent>
            }
          />
        </TabLayout>
      </DivWithBorder>
    );
  }
}
