import { getUser, removeUser } from '../../shared/userStorage';
import R from 'ramda';
import { shouldUseAuth0, getBearerToken} from '../../shared/platformAuth';
export const isAuthenticated = async () => {
  if(await shouldUseAuth0()) {
    await getBearerToken();
    return true;
  }
  return !R.isNil(getUser())
};
export const logout = () => removeUser();
