import React from 'react';
import ReactDOM from 'react-dom';
import R from 'ramda';
import * as highlightConstant from '../../domain/TestResultDetails/constant';
import * as helper from '../TestResultDetails/helper';

export const buildHighlights = (capture, context) => {
  const isInvalidCapture = R.isNil(capture) || R.isNil(capture.text);
  if (isInvalidCapture) return null;
  const makeTokenMode = (token, className = '') => ({
    begin: token,
    className: `token ${className}`
  });

  const makeDetailMode = (tokenPrefix, classNamePrefix, contains = []) => {
    const begin = makeTokenMode(`__${tokenPrefix}_BEGIN__`, `${classNamePrefix}-begin`);

    const end = makeTokenMode(`__${tokenPrefix}_END__`, `${classNamePrefix}-end`);

    const details = {
      begin: `__${tokenPrefix}_BEGIN__`,
      end: `__${tokenPrefix}_END__`,
      className: `highlight ${classNamePrefix}-mode-details`,
      returnBegin: true,
      returnEnd: true,
      contains: [begin, ...contains]
    };
    return [details, end];
  };

  const productMode = makeDetailMode(
    highlightConstant.PRODUCT_MODE_TOKEN,
    highlightConstant.PRODUCT_MODE_CLASSNAME
  );

  const requiredTextMode = makeDetailMode(
    highlightConstant.REQUIRED_TEXT_MODE_TOKEN,
    highlightConstant.REQUIRED_TEXT_MODE_CLASSNAME,
    [...productMode]
  );

  const prohibitedTextMode = makeDetailMode(
    highlightConstant.PROHIBITED_TEXT_MODE_TOKEN,
    highlightConstant.PROHIBITED_TEXT_MODE_CLASS,
    [...productMode, ...requiredTextMode]
  );

  const inferredProhibitedTextMode = makeDetailMode(
    highlightConstant.INFERRED_PROHIBITED_TEXT_MODE_TOKEN,
    highlightConstant.INFERRED_PROHIBITED_TEXT_MODE_CLASS,
    [...productMode, ...requiredTextMode, ...prohibitedTextMode]
  );

  const warningBlockTextMode = makeDetailMode(
    highlightConstant.WARNING_BLOCK_TEXT_MODE_TOKEN,
    highlightConstant.WARNING_BLOCK_TEXT_MODE_CLASS
  );

  const offerLinkMode = makeDetailMode(
    highlightConstant.OFFER_LINK_MODE_TOKEN,
    highlightConstant.OFFER_LINK_MODE_CLASSNAME,
    [...productMode, ...requiredTextMode, ...prohibitedTextMode, ...inferredProhibitedTextMode]
  );

  const tooltipMode = makeDetailMode(
    highlightConstant.TOOL_TIP_MODE_TOKEN,
    highlightConstant.TOOL_TIP_MODE_CLASSNAME,
    [
      ...prohibitedTextMode,
      ...requiredTextMode,
      ...productMode,
      ...offerLinkMode,
      ...inferredProhibitedTextMode
    ]
  );

  const ignoreMode = makeDetailMode(
    highlightConstant.IGNORE_MODE_TOKEN,
    highlightConstant.IGNORE_MODE_CLASSNAME,
    [
      ...prohibitedTextMode,
      ...requiredTextMode,
      ...productMode,
      ...tooltipMode,
      ...offerLinkMode,
      ...inferredProhibitedTextMode
    ]
  );

  const offerMode = makeDetailMode(
    highlightConstant.OFFER_MODE_TOKEN,
    highlightConstant.OFFER_MODE_CLASS,
    [
      ...prohibitedTextMode,
      ...requiredTextMode,
      ...productMode,
      ...ignoreMode,
      ...tooltipMode,
      ...offerLinkMode,
      ...inferredProhibitedTextMode
    ]
  );

  const languageDefinition = () => ({
    case_insensitive: true,
    contains: [
      ...productMode,
      ...prohibitedTextMode,
      ...inferredProhibitedTextMode,
      ...warningBlockTextMode,
      ...requiredTextMode,
      ...tooltipMode,
      ...ignoreMode,
      ...offerMode,
      ...offerLinkMode
    ]
  });

  return {
    capture: helper.handleLegacyText(capture),
    context,
    languageId: context.id,
    languageDefinition
  };
};

export const withHighlighting = WrappedComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.parseHighlights = this.parseHighlights.bind(this);
      this.getDomElementsMap = this.getDomElementsMap.bind(this);
      const { capture, prefix } = props;
      this.highlights = buildHighlights(capture, { id: prefix });
      this.state = {
        prohibitedTextDomElementsMap: {},
        inferredProhibitedTextDomElementsMap: {},
        productNameDomElementsMap: {}
      };
    }

    componentDidMount() {
      this.parseHighlights();
    }

    getDomElementsMap(className) {
      const thisDOM = ReactDOM.findDOMNode(this);
      const domElementArray = [].slice.call(thisDOM.querySelectorAll(className));
      return R.groupBy(helper.extractDomText)(domElementArray);
    }

    parseHighlights() {
      if (!this.highlights) return;
      this.setState({
        prohibitedTextDomElementsMap: this.getDomElementsMap(
          helper.getHighLightClassName(highlightConstant.PROHIBITED_TEXT_MODE_CLASS)
        ),
        inferredProhibitedTextDomElementsMap: this.getDomElementsMap(
          helper.getHighLightClassName(highlightConstant.INFERRED_PROHIBITED_TEXT_MODE_CLASS)
        ),
        productNameDomElementsMap: this.getDomElementsMap(
          helper.getHighLightClassName(highlightConstant.PRODUCT_MODE_CLASSNAME)
        )
      });
    }

    render() {
      const {
        productName: productNameFromOffer,
        productNames: productNamesFromPage,
        errors
      } = this.props.capture;
      const productNames = productNameFromOffer ? [productNameFromOffer] : productNamesFromPage;

      const highlightedCapture = {
        ...this.props.capture,
        highlights: this.highlights
      };

      const {
        productNameDomElementsMap,
        prohibitedTextDomElementsMap,
        inferredProhibitedTextDomElementsMap
      } = this.state;
      return (
        <WrappedComponent
          capture={highlightedCapture}
          productNameDomElementsMap={productNameDomElementsMap}
          prohibitedTextDomElementsMap={prohibitedTextDomElementsMap}
          inferredProhibitedTextDomElementsMap={inferredProhibitedTextDomElementsMap}
          handleHighlightLinkClick={helper.handleHighlightLinkClick}
          productNames={productNames}
          errors={errors}
          index={this.props.index}
          totalOfferCount={this.props.totalOfferCount}
        />
      );
    }
  };
};
