import React from "react";

import {
    AnalyticCard as VSAnalyticCard,
    AnalyticCardContainer,
    AnalyticCardNegativeTrendValue,
    AnalyticCardPositiveTrendValue,
    AnalyticCardTitle,
    AnalyticCardTrend,
    AnalyticCardTrendContainer,
    AnalyticCardTrendLabel,
    AnalyticCardTrendValue,
    AnalyticCardValue,
    AnalyticCardValueUnit,
    ViewDetailButton,
} from "@cjdev-internal/visual-stack-x/components/AnalyticCard";
import {parse, stringify} from "query-string";
import {useNavigate, useSearchParams} from "react-router-dom";
import {history} from "../../router";

export const Container = AnalyticCardContainer;

export const Gauge = ({
                          title,
                          value,
                          unit,
                          trendUnit,
                          trendValue,
                          previousPeriod,
                          filters = {},
                      }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const filtersFromURL = parse(searchParams.toString());

    const renderTrendValue = (trendValue) => {
        if (trendValue > 0) {
            return <AnalyticCardPositiveTrendValue>{trendValue}</AnalyticCardPositiveTrendValue>
        }

        if (trendValue < 0) {
            return <AnalyticCardNegativeTrendValue>{trendValue}</AnalyticCardNegativeTrendValue>
        }

        return <AnalyticCardTrendValue data-testid="analytic-card-trend-value">{trendValue}</AnalyticCardTrendValue>
    }

    return (
        <VSAnalyticCard>
            <AnalyticCardTitle>{title}</AnalyticCardTitle>
            <AnalyticCardValue>
                {value}
                {unit && (
                    <AnalyticCardValueUnit style={{marginLeft: '5px'}}>{unit}</AnalyticCardValueUnit>
                )}
            </AnalyticCardValue>
            <AnalyticCardTrendContainer>
                <AnalyticCardTrend>
                    {renderTrendValue(trendValue)}
                    <AnalyticCardTrendLabel>change</AnalyticCardTrendLabel>
                </AnalyticCardTrend>
                <AnalyticCardTrend>
                    <AnalyticCardTrendValue style={{alignItems: 'baseline'}}>
                        {`${previousPeriod} `}
                        {trendUnit && (
                            <AnalyticCardValueUnit style={{marginLeft: '3px'}}>
                                {trendUnit}
                            </AnalyticCardValueUnit>
                        )}
                    </AnalyticCardTrendValue>
                    <AnalyticCardTrendLabel>prev. period</AnalyticCardTrendLabel>
                </AnalyticCardTrend>
            </AnalyticCardTrendContainer>
            <ViewDetailButton
                type="secondary"
                onClick={() => {
                    const url = `/compliance-violations?${stringify({
                        ...filtersFromURL,
                        ...filters,
                    })}`;
                    history.push(url);
                    navigate(url);
                }}
            >
                View Details
            </ViewDetailButton>
        </VSAnalyticCard>
    );
};
