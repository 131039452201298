import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';

export const STORE_PRODUCT_DATA = 'Domains/advertiser/STORE_PRODUCT_DATA';

export const storeProductDataAction = createAction(STORE_PRODUCT_DATA);

export const FAIL_FETCH_PRODUCT_DATA = 'Domains/advertiser/FAIL_FETCH_PRODUCT_DATA';

export const failFetchProductDataAction = createAction(FAIL_FETCH_PRODUCT_DATA);

export const LOADING_FETCH_PRODUCT_DATA = 'Domains/advertiser/LOADING_FETCH_PRODUCT_DATA';

export const loadingFetchProductData = createAction(LOADING_FETCH_PRODUCT_DATA);

export const STORE_SELECTED_PRODUCT_ID = 'Domains/advertiser/STORE_SELECTED_PRODUCT_ID';



export const fetchProducts = async advertiserId => {
  let response;
  if (advertiserId) {
    response = await authenticatedFetch(
      `${apiServer()}/violation-products?advertiserIds=[${advertiserId}]`
    );
  } else {
    response = await authenticatedFetch(`${apiServer()}/violation-products`);
  }
  return await response.json();
};

export const fetchViolationProductAction = () => async dispatch => {
  dispatch(loadingFetchProductData());
  try {
    const responseJSON = await fetchProducts();
    dispatch(storeProductDataAction(responseJSON));
  } catch (e) {
    dispatch(failFetchProductDataAction());
  }
};
