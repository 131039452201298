import styled from 'styled-components';

export const Section = styled.div`
  border: 0px solid #ccc;
  padding: 0 5px;
  padding-bottom: 20px;
`;

export const SectionTitle = styled.div`
  font-size: 1.3em;
  font-weight: 600;
  padding: 3px 0;
  margin-bottom: 5px;
`;

export const SectionBody = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 11px 0 rgba(136, 136, 136, 0.12);
  border-radius: 3px;
`;

export const SubSection = styled.div`
  padding: 1.5em;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
`;

export const SectionRow = styled.div`
  padding: 5px 0 5px;
`;

export const SectionRowTitle = styled.div`
  color: #585858;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 5px;
`;
