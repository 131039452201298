import R from 'ramda';
import { getBase64EncodedString, validateFiles } from './fileUtil.js';
import { apiServer } from '../../env';
import { authenticatedFetch } from '../../shared/apis';

export const upload = async files => {
  const errors = validateFiles(files);
  if (R.isEmpty(validateFiles(files))) {
    const attachmentJson = await Promise.all(
      R.map(async file => {
        const base64Attachment = await getBase64EncodedString(file);
        return {
          base64Attachment: base64Attachment,
          name: file.name
        };
      }, files)
    );
    const response = await authenticatedFetch(`${apiServer()}/attachments`, {
      method: 'POST',
      body: JSON.stringify(attachmentJson)
    });

    return response.json();
  } else {
    throw new Error(errors);
  }
};
