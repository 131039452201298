import {useCallback, useEffect, useState} from 'react';

const INITIAL_STATE = {
    status: 'idle', issue: null, error: null
};

export const ERROR_TYPES = {
    ID_REQUIRED: 'param "complianceViolationId" is required', SERVER_ERROR: 'No violations fall under this id: %s'
};

const useLoadIssue = (complianceViolationId, fetchIssue) => {
    const [state, setState] = useState(INITIAL_STATE);
    const {status, issue, error} = state;

    let isMounted = true;
    const loadIssue = useCallback(async () => {
        setState({status: 'loading'});
        try {
            const issue = await fetchIssue(complianceViolationId);
            isMounted && setState({status: 'complete', issue});
            return issue;
        } catch (error) {
            isMounted && setState({
                status: 'error',
                error: ERROR_TYPES.SERVER_ERROR.replace('%s', complianceViolationId)
            });
            return error;
        }
    }, [complianceViolationId, fetchIssue, isMounted]);

    const reload = useCallback(() => {
        loadIssue();
    }, [loadIssue])

    useEffect(() => {
        if (!complianceViolationId) {
            isMounted && setState({status: 'error', error: ERROR_TYPES.ID_REQUIRED});
        } else {
            loadIssue();
        }

        return () => {
            isMounted = false;
        };
    }, [complianceViolationId]);

    return {status, issue, error, reload};
};
export default useLoadIssue;
