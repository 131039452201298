import R from 'ramda';
import { parse, compareDesc, distanceInWordsToNow } from 'date-fns';
import { format } from 'date-fns';
import urlParse from 'url-parse';

export const formatSeconds = epochMillis => formatDate(new Date(epochMillis));

export const formatDate = date => format(date, 'YYYY-MM-DD HH:mm');

const formatDateOnly = date => format(date, 'YYYY.MM.DD');
export const formatSecondsToDate = epochMillis => formatDateOnly(new Date(epochMillis));

export const formatSecondsToDateMM_DD_YYYY = epochMillis =>
  format(new Date(epochMillis), 'MMMM DD, YYYY');

export const formatDistance = distanceInWordsToNow;

export const sortTestsNewest = R.sort((a, b) =>
  compareDesc(parse(a.timeStamp), parse(b.timeStamp))
);

const regex = /[\r\n]+/;
const trimmedList = (values, regex) =>
  values ? R.reject(R.isEmpty, R.split(regex, R.trim(values))) : [];
export const getTrimmedList = values => trimmedList(values, regex);

export const prependHttpIfNotExist = url => {
  const parsedURL = urlParse(url, {});
  const reconstructedUrl = !parsedURL.slashes ? 'http://' + url : url;
  return reconstructedUrl;
};

export const addToConfigIfExists = (nullableFields, config) => {
  const newConfig = { ...config };
  const nullableFieldsPairs = R.toPairs(nullableFields);
  nullableFieldsPairs.forEach(([key, value]) => {
    newConfig[key] = R.isEmpty(value) ? undefined : value;
  });
  return newConfig;
};

export const navigateTo = (path, navigate, history, platformNav) => {
  platformNav ? navigate(path): history.push(path);
}
