import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../../shared/apis';
import { apiServer } from '../../../env';
import { parse } from 'query-string';
import * as R from 'ramda';

export const START_FETCHING_RISK_IDENTIFICATION =
  'src/domain/violationDashboard/riskIdentification/START_FETCHING_RISK_IDENTIFICATION';

export const startFetchingRiskIdentification = createAction(START_FETCHING_RISK_IDENTIFICATION);

export const SET_RISK_IDENTIFICATION =
  'src/domain/violationDashboard/riskIdentification/SET_RISK_IDENTIFICATION';

export const setRiskIdentification = createAction(SET_RISK_IDENTIFICATION);

export const SET_RISK_IDENTIFICATION_DATE_RANGE =
  'src/domain/violationDashboard/riskIdentification/SET_RISK_IDENTIFICATION_DATE_RANGE';

export const setRiskIdentificationDateRange = createAction(SET_RISK_IDENTIFICATION_DATE_RANGE);

export const fetchRiskIdentification = filterQueries => async dispatch => {
  dispatch(startFetchingRiskIdentification());
  const dateRange = R.pick(['from', 'to'])(parse(filterQueries));
  const response = await authenticatedFetch(
    `${apiServer()}/risk-identifications?${filterQueries || ''}`
  );
  const riskIdentificationJSON = await response.json();
  dispatch(setRiskIdentification(riskIdentificationJSON));
  dispatch(setRiskIdentificationDateRange(dateRange));
};

export const CLEAR_RISK_IDENTIFICATION =
  'src/domain/violationDashboard/riskIdentification/CLEAR_RISK_IDENTIFICATION';

export const clearRiskIdentificationTable = createAction(CLEAR_RISK_IDENTIFICATION);
