import React, {useEffect} from 'react';
import {Formik} from 'formik';
import Select from '../../components/Formik/FormSelect';
import {FormContainer, SlidingPanelControl} from '../../components/SlidingPanel';
import DashboardViewController from './DashboardViewController';
import * as R from 'ramda';
import {pick} from 'ramda';
import {connect} from 'react-redux';
import {
    selectErrorCategoriesDropdownOptions,
    selectIsPublisher,
    selectPublisherDropdownOptions,
    selectStatusOptions,
    selectViolationProductDropdownOptions,
    selectWebsiteDropdownOptions
} from '../../reducer';
import {AdvertisersDropdown} from '../Advertisers/AdvertisersDropdown';
import {getAdvertisers} from '../Advertisers/AdvertisersDropdown/advertiserStorage';
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useAdvertiserAndDateFilter, usePlatformNav} from "../../appRunner";
import {useUser} from "@platform/cj-platform-navigation";
import {SlidingPanelDropdown, SlidingPanelHeader} from "@cjdev-internal/visual-stack-x/SlidingPanel";
import "./styles/dashboardFilterPanel.css"

const jsonParse = value => (value ? JSON.parse(value) : undefined);

const toJSON = (values = []) => {
    const valuesExist = values.length > 0;
    return valuesExist ? JSON.stringify(values) : undefined;
};

const convertFromFormToApiValues = ({
                                        websites = [],
                                        partners = [],
                                        products = [],
                                        errorCategories = [],
                                        ...values
                                    }) => {
    return {
        ...values,
        websites: toJSON(websites),
        partners: toJSON(partners),
        products: toJSON(products),
        errorCategories: toJSON(errorCategories)
    };
};

export const DashboardFilterPanel = ({
                                         websitesOptions = [],
                                         partnersOptions = [],
                                         productsOptions = [],
                                         errorCategoriesOptions = [],
                                         statusOptions = [],
                                         isPublisher
                                     }) => {
    const {
        advertiserFilter,
        setAdvertiserFilter,
        dateFilter,
        publisherFilter,
        setPublisherFilter
    } = useAdvertiserAndDateFilter();
        const navigate = useNavigate()
        const [searchParams] = useSearchParams()
        const dashboardViewController = new DashboardViewController(navigate, searchParams.toString())
        const platformNav = usePlatformNav()

        const filters = pick(['status', 'websites', 'partners', 'products', 'errorCategories'])(
            dashboardViewController.getExisitingQueryObject()
        );
        const user = useUser();
        const initialValues = {
            ...filters,
            websites: jsonParse(filters.websites),
            partners: jsonParse(filters.partners),
            products: jsonParse(filters.products),
            errorCategories: jsonParse(filters.errorCategories),
            advertisers: getAdvertisers()
        }

    const [initialValuesWithDefaultFilter, setInitialValuesWithDefaultFilter] = React.useState(initialValues);
    if (usePlatformNav()) isPublisher = user.currentCompany.type === 'publisher';
        if (platformNav) {
            useEffect(() => {
                    if (!isPublisher) {
                        if (advertiserFilter) {
                            if (advertiserFilter.length !== 0) {
                                const newInitialValues = {
                                    ...initialValues,
                                    advertisers: advertiserFilter,
                                    partners: publisherFilter
                                };
                                setInitialValuesWithDefaultFilter(newInitialValues);
                                window.localStorage.setItem('advertisers', JSON.stringify(newInitialValues.advertisers));
                                dashboardViewController.changeAdvertiser(advertiserFilter);
                                dashboardViewController.setQuery(convertFromFormToApiValues(newInitialValues))
                            } else {
                                const newInitialValues = {...initialValues, advertisers: [], partners: publisherFilter};
                                setInitialValuesWithDefaultFilter(newInitialValues);
                                window.localStorage.setItem('advertisers', JSON.stringify(newInitialValues.advertisers));
                                dashboardViewController.changeAdvertiser([]);
                                dashboardViewController.setQuery(convertFromFormToApiValues(newInitialValues))
                            }
                        } else {
                            const newInitialValues = {
                                ...initialValues,
                                advertisers: [user.currentCompany.id],
                            };
                            setInitialValuesWithDefaultFilter(newInitialValues);
                            window.localStorage.setItem('advertisers', JSON.stringify(newInitialValues.advertisers));
                            dashboardViewController.changeAdvertiser(user?.currentCompany.id);
                        }
                    } else {
                        if (advertiserFilter) {
                            if (advertiserFilter.length !== 0) {
                                const newInitialValues = {...initialValues, advertisers: advertiserFilter};
                                setInitialValuesWithDefaultFilter(newInitialValues);
                                window.localStorage.setItem('advertisers', JSON.stringify(newInitialValues.advertisers));
                                dashboardViewController.changeAdvertiser(advertiserFilter);
                            } else {
                                const newInitialValues = {...initialValues, advertisers: []};
                                setInitialValuesWithDefaultFilter(newInitialValues);
                                window.localStorage.setItem('advertisers', JSON.stringify(newInitialValues.advertisers));
                                dashboardViewController.changeAdvertiser([]);
                            }
                        }

                        if (publisherFilter) {
                            if (publisherFilter.length !== 0) {
                                const newInitialValues = {...initialValues, partners: publisherFilter};
                                setInitialValuesWithDefaultFilter(newInitialValues);
                                dashboardViewController.setQuery(convertFromFormToApiValues(newInitialValues));
                            } else {
                                const newInitialValues = {...initialValues, partners: undefined};
                                setInitialValuesWithDefaultFilter(newInitialValues);
                                dashboardViewController.setQuery(convertFromFormToApiValues(newInitialValues));
                            }
                        } else {
                            const newInitialValues = {...initialValues, partners: [user.currentCompany.id]};
                            setInitialValuesWithDefaultFilter(newInitialValues);
                            setPublisherFilter([user?.currentCompany.id]);
                            dashboardViewController.setQuery(convertFromFormToApiValues(newInitialValues));
                        }
                    }
                    if (dateFilter) {
                        dashboardViewController.changeDateRange([dateFilter[0], dateFilter[1]])
                    }
                }
                ,
                [user.currentCompany]
            )
            ;
        }

        const clearAll = () => {
            setAdvertiserFilter([]);
            setPublisherFilter(undefined);
            dashboardViewController.resetQuery()
        };

        return (
            <div id={'filters'}>
                <SlidingPanelHeader>Filters</SlidingPanelHeader>
                <Formik
                    initialValues={initialValuesWithDefaultFilter}
                    enableReinitialize={true}
                    onSubmit={values => {
                        if (R.isEmpty(values.partners)) {
                            values = {...values, partners: undefined}
                        }
                        setAdvertiserFilter(values.advertisers)
                        setPublisherFilter(values.partners)
                        dashboardViewController.changeAdvertiser(values.advertisers)
                        dashboardViewController.setQuery(convertFromFormToApiValues(values));
                    }}
                    render={({handleSubmit, setFieldValue, values, resetForm}) => (
                        <form onSubmit={handleSubmit}>
                            <SlidingPanelControl
                                resetForm={resetForm}
                                clearAll={clearAll}
                            />
                            <hr/>
                            <SlidingPanelDropdown id="advertisers" label="Advertisers" startExpanded={true}
                            >
                                <FormContainer>
                                    <AdvertisersDropdown
                                        onChange={ids => setFieldValue('advertisers', ids)}
                                        advertisers={values.advertisers}
                                    />
                                </FormContainer>
                            </SlidingPanelDropdown>
                            <hr/>
                            <SlidingPanelDropdown id="statusFilters" label="Status" startExpanded={true}>
                                <FormContainer>
                                    <Select
                                        name="status-filter"
                                        placeholder="Status"
                                        options={statusOptions.filter(option => option.value !== 'New')}
                                        onChange={option => {
                                            if (option) {
                                                setFieldValue('status', option.value);
                                            } else {
                                                setFieldValue('status', undefined);
                                            }
                                        }}
                                        value={values.status ?
                                            statusOptions.filter(function (option) {
                                                return option.value === values.status;
                                            }) : []}
                                        isClearable={true}
                                    />
                                </FormContainer>
                            </SlidingPanelDropdown>
                            <hr/>
                            <SlidingPanelDropdown id="websiteFilters" label="Property Names" startExpanded={true}
                            >
                                <FormContainer>
                                    <Select
                                        name="website-filter"
                                        placeholder="Property Names"
                                        options={websitesOptions}
                                        isMulti
                                        onChange={e => {
                                            const websites = e ? e.map(({value}) => value) : [];
                                            setFieldValue('websites', websites);
                                        }}
                                        value={values.websites ? websitesOptions.filter(option => values.websites.includes(option.value)) : []}

                                    />
                                </FormContainer>
                            </SlidingPanelDropdown>
                            <hr/>
                            <SlidingPanelDropdown id="partnerFilters" label="Publishers" startExpanded={true}
                            >
                                <FormContainer>
                                    <Select
                                        name="partner-filter"
                                        placeholder="Publishers"
                                        options={partnersOptions}
                                        onChange={e => {
                                            const partners = e ? e.map(({value}) => value) : [];
                                            setFieldValue('partners', partners);
                                        }}
                                        value={values.partners ? partnersOptions.filter(option => values.partners.includes(option.value)) : []}
                                        isMulti
                                    />
                                </FormContainer>
                            </SlidingPanelDropdown>
                            <hr/>
                            <SlidingPanelDropdown id="productFilters" label="Product(s)" startExpanded={true}
                            >
                                <FormContainer>
                                    <Select
                                        name="product-filter"
                                        placeholder="Product(s)"
                                        options={productsOptions}
                                        onChange={e => {
                                            const products = e ? e.map(({value}) => value) : [];
                                            setFieldValue('products', products);
                                        }}
                                        value={values.products ? productsOptions.filter(option => values.products.includes(option.value)) : []}
                                        isMulti
                                    />
                                </FormContainer>
                            </SlidingPanelDropdown>
                            <hr/>
                            <SlidingPanelDropdown id="errorCategoriesFilter" label="Categories" startExpanded={true}>
                                <FormContainer>
                                    <Select
                                        name="error-categories-filter"
                                        placeholder="Categories"
                                        options={errorCategoriesOptions}
                                        onChange={e => {
                                            const errorCategories = e ? e.map(({value}) => value) : [];
                                            setFieldValue('errorCategories', errorCategories);
                                        }}
                                        value={values.errorCategories ? errorCategoriesOptions.filter(option => values.errorCategories.includes(option.value)) : []}
                                        isMulti
                                    />
                                </FormContainer>
                            </SlidingPanelDropdown>
                            <hr/>
                        </form>
                    )}
                />
            </div>
        );
    }
;

export default connect(state => ({
    websitesOptions: selectWebsiteDropdownOptions(state),
    partnersOptions: selectPublisherDropdownOptions(state),
    productsOptions: selectViolationProductDropdownOptions(state),
    errorCategoriesOptions: selectErrorCategoriesDropdownOptions(state),
    statusOptions: selectStatusOptions(state),
    isPublisher: selectIsPublisher(state),
}))(DashboardFilterPanel);
