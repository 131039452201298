import React from 'react';
import R from 'ramda';
import styled from 'styled-components';
import { PaddedLabel } from './Label';
import { ErrorDiv } from './Error';
import ErrorSpan from '../ErrorMessage';

export const FormFieldDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextInput = styled.input`
  padding: 0.4em;
  width: ${({ width }) => width || '350px'};
  min-width: ${({ minWidth }) => minWidth || '250px'};

  ${({ meta, theme }) =>
    !R.isNil(meta) &&
    meta.error &&
    meta.touched &&
    `
    border-color: ${theme.error.color};
    box-shadow: ${theme.error.boxShadow};
    &:focus {
      border-color: ${theme.error.color};
      box-shadow: ${theme.error.boxShadow};
    }
  `};
`;

const LineInput = ({ input, label, disableForEdit, width, meta }) => {
  const { touched, error, warning } = meta;
  return (
    <FormFieldDiv>
      <PaddedLabel htmlFor={input.name}>{label}</PaddedLabel>
      <TextInput {...input} width={width} disabled={disableForEdit} meta={meta} />
      <ErrorDiv>
        {' '}
        {touched &&
          ((error && <ErrorSpan>{error}</ErrorSpan>) || (warning && <span>{warning}</span>))}
      </ErrorDiv>
    </FormFieldDiv>
  );
};

export const SingleLineInput = props => <LineInput {...props} width={'50%'} />;

export const NameInput = props => <LineInput {...props} width={'20%'} />;
