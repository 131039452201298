import qs from 'query-string';
import { getDefaultDateInMillis } from '../../containers/Dashboard/dateHelper.js';
import * as R from 'ramda';

export const getQuery = (getCurrentDate = () => new Date(), search) => {
  const currentParams = qs.parse(search);
  const defaultDates = getDefaultDateInMillis(getCurrentDate);
  const filterQuery = qs.stringify({ ...defaultDates, ...currentParams });
  const filterQueries = !R.isEmpty(filterQuery) ? `&${filterQuery}` : '';
  return filterQueries;
};
