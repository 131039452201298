import { apiServer } from '../../env';
import { createAction } from 'redux-actions';
import R from 'ramda';
import { authenticatedFetch } from '../../shared/apis';

export const SET_TEST_CONFIG = 'CAS/testconfigs/SET_TEST_CONFIG';
export const setTestConfig = createAction(SET_TEST_CONFIG);
export const FETCH_TEST_CONFIG_FAILURE = 'CAS/testconfigs/FETCH_TEST_CONFIG_FAILURE';
export const fetchTestConfigFailure = createAction(FETCH_TEST_CONFIG_FAILURE);

export const fetchTestConfigs = () => async dispatch => {
  try {
    const response = await authenticatedFetch(`${apiServer()}/test-configs`);
    const json = await response.json();
    dispatch(setTestConfig(json));
  } catch (e) {
    dispatch(fetchTestConfigFailure());
  }
};

export const MODAL_RESET = 'CAS/testconfigs/MODAL_RESET';
export const resetModal = createAction(MODAL_RESET);
export const RUN_TEST_CONFIG_START = 'CAS/testconfigs/RUN_TEST_CONFIG_START';
export const startRunTestConfig = createAction(RUN_TEST_CONFIG_START);
export const RUN_TEST_CONFIG_COMPLETE = 'CAS/testconfigs/RUN_TEST_CONFIG_COMPLETE';
export const completeRunTestConfig = createAction(RUN_TEST_CONFIG_COMPLETE);
export const RUN_TEST_CONFIG_FAILURE = 'CAS/testconfigs/RUN_TEST_CONFIG_FAILURE';
export const failRunTestConfig = createAction(RUN_TEST_CONFIG_FAILURE);

export const runTestConfig = (testConfig, lookback) => async dispatch => {
  dispatch(startRunTestConfig());
  const lookbackQuery = R.isEmpty(lookback) ? '' : `?cachedScrapeLookback=${lookback}`;
  const requestUrl = `${apiServer()}/scrape-test/${testConfig.id}${lookbackQuery}`;
  try {
    await authenticatedFetch(requestUrl, {
      headers: {
        'Content-Type': 'text/plain'
      },
      method: 'POST',
      body: JSON.stringify(testConfig)
    });
    dispatch(completeRunTestConfig());
  } catch (e) {
    dispatch(failRunTestConfig(e.message));
  }
};

export const TEST_CONFIG_ARCHIVE_RESET = 'CAS/testconfigs/TEST_CONFIG_ARCHIVE_RESET';
export const resetArchiveTestConfig = createAction(TEST_CONFIG_ARCHIVE_RESET);

export const TEST_CONFIG_ARCHIVE_START = 'CAS/testconfigs/TEST_CONFIG_ARCHIVE_START';
export const startArchiveTestConfig = createAction(TEST_CONFIG_ARCHIVE_START);
export const TEST_CONFIG_ARCHIVE_SUCCESS = 'CAS/testconfigs/TEST_CONFIG_ARCHIVE_SUCCESS';
export const successArchiveTestConfig = createAction(TEST_CONFIG_ARCHIVE_SUCCESS);
export const TEST_CONFIG_ARCHIVE_FAIL = 'CAS/testconfigs/TEST_CONFIG_ARCHIVE_FAIL';
export const failArchiveTestConfig = createAction(TEST_CONFIG_ARCHIVE_FAIL);

export const archiveTestConfig = testConfigId => async dispatch => {
  dispatch(startArchiveTestConfig(testConfigId));
  try {
    const response = await authenticatedFetch(
      `${apiServer()}/archive-test-config/${testConfigId}`,
      {
        method: 'POST'
      }
    );
    if (response.status === 200) {
      dispatch(successArchiveTestConfig());
    } else {
      dispatch(failArchiveTestConfig(response.statusText));
    }
  } catch (err) {
    dispatch(failArchiveTestConfig('Failed to archive test config'));
  }
};

export const archiveAndLoad = testConfigId => {
  return async (dispatch, getState) => {
    return dispatch(archiveTestConfig(testConfigId)).then(() => {
      const archiveError = getState().CAS.testConfig.archive.error;
      if (R.isNil(archiveError)) {
        return dispatch(fetchTestConfigs());
      }
    });
  };
};
