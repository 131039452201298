import R from 'ramda';

export const handleLegacyText = capture => {
  const { text } = capture;
  const newText = text
    .replace(/__IGNORE_\d+_END__/g, '__IGNORE_END__')
    .replace(/__IGNORE_\d+_BEGIN__/g, '__IGNORE_BEGIN__')
    .replace(/__OFFER_\d+_BEGIN__/g, '__OFFER_BEGIN__')
    .replace(/__OFFER_\d+_END__/g, '__OFFER_END__');
  return R.assoc('text', newText, capture);
};

export const getHighLightClassName = className => `.hljs-highlight.${className}-mode-details`;

export const extractDomText = dom =>
  dom.textContent
    .replace(/__PROHIBITED_TEXT_BEGIN__/g, '')
    .replace(/__PROHIBITED_TEXT_END__/g, '')
    .replace(/__INFERRED_PROHIBITED_TEXT_BEGIN__/g, '')
    .replace(/__INFERRED_PROHIBITED_TEXT_END__/g, '')
    .replace(/__PRODUCT_BEGIN__/g, '')
    .replace(/__PRODUCT_END__/g, '')
    .replace(/__REQUIRED_TEXT_BEGIN__/g, '')
    .replace(/__REQUIRED_TEXT_END__/g, '');

export const handleHighlightLinkClick = classInstance => {
  if (classInstance) {
    classInstance.scrollIntoView(true);

    const scrollingContainer = document.getElementsByClassName('application-layout-content')[0];

    if (scrollingContainer) {
      const classInstanceTop = classInstance.getBoundingClientRect().top;
      if (classInstanceTop < 150) {
        scrollingContainer.scrollTop -= 150 - classInstanceTop;
      }
    }
  }
};
