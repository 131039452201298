import React from 'react';
import style from 'styled-components';

const StyledErrorCount = style.span`
  user-select: none;
  color: ${props => (props.count > 0 ? '#F65161' : 'inherit')};
  font-weight: ${props => (props.count > 0 ? 'bold' : 'inherit')};
  font-size: inherit;
`;

const StyledErrorCountPill = style.span`
  user-select: none;
  font-size: inherit;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  display: ${props => (props.count > 0 ? 'inline-block' : 'none')};
  background: #da5153;
  border-radius: 8px;
  margin-left: 8px;
  padding:0 4px;
`;

const StyledCountPill = style.span`
  user-select: none;
  font-size: inherit;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  background: #a0a0a0;
  border-radius: 8px;
  margin-left: 8px;
  padding:0 4px;
  display: inline-block;
`;

export const CountPill = ({ count }) => <StyledCountPill count={count}>{count}</StyledCountPill>;

export const ErrorCountPill = ({ count }) => (
  <StyledErrorCountPill count={count}>{count}</StyledErrorCountPill>
);

export default ({ count }) => <StyledErrorCount count={count}>{count}</StyledErrorCount>;
