import React from 'react';
import { Header as PanelHeader } from '@cjdev-internal/visual-stack-x/components/Panel';
import { PanelHeaderRow, PanelHeaderRowSection } from '../../components/PanelHeader';
import TextSearchFilter from '../../components/Filters/TextSearchFilter';

const DomainPanelHeader = ({ filters, handleFilterChange, handleTextSearchFilterSubmit }) => {
  return (
    <PanelHeader>
      <PanelHeaderRow>
        <PanelHeaderRowSection>
          <TextSearchFilter
            label="Filter by Domain"
            onChange={value => handleFilterChange(value)}
            value={filters.textFilter}
            filters={filters}
            handleSubmit={value => handleTextSearchFilterSubmit({ domainSearch: value })}
          />
        </PanelHeaderRowSection>
      </PanelHeaderRow>
    </PanelHeader>
  );
};

export default DomainPanelHeader;
