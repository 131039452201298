import React, { Fragment } from 'react';
import ArrowUpBoldIcon from 'mdi-react/ArrowUpBoldIcon';
import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import styled from 'styled-components';
import { renderNoTrending } from '@cjdev-internal/visual-stack-redux/lib/components/DataTable';

const makeTrendIcon = Icon => styled(Icon)`
  margin-top: -2px;
  margin-right: 4px;
`;

const TrendUpIcon = makeTrendIcon(ArrowUpBoldIcon);
const TrendDownIcon = makeTrendIcon(ArrowDownBoldIcon);

export const renderTrend = value => {
  if (value > 0) {
    return (
        <div id="trend-up-icon">
          <Fragment>
            <TrendUpIcon color="#f65161"/>
            {value}
          </Fragment>
        </div>
    );
  }
  if (value < 0) {
    return (
        <div id="trend-down-icon">
          <Fragment>
            <TrendDownIcon color="#0ec38f"/>
            {value}
          </Fragment>
        </div>
    );
  }
  return renderNoTrending(value);
};
