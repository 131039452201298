import {AdvertiserManagementPure} from ".";
import {usePlatformNav} from "../../appRunner";
import {navigateTo} from "../../utils";
import {history, withNavigate, withParams} from "../../router";
import {EditAdvertiser} from "./EditAdvertiser";
import {createAdvertiser, editAdvertiser, fetchAdvertiserById, fetchAdvertisers,} from "./services";
import {CreateAdvertiserPrime} from "./CreateAdvertiser";
import {ActionsAdvertiser} from "./components/Actions";
import {useUser} from "@platform/cj-platform-navigation";

const ActionsAdvertiserDefault = ({ navigate }) => {
  const user = useUser();
  return (
      <ActionsAdvertiser
          history={history}
          navigate={navigate}
          navigateTo={navigateTo}
          usePlatformNav={usePlatformNav}
          user={user}
      />
  );
};

const AdvertiserManagementPureDefault = ({ navigate }) => {
  return (
      <AdvertiserManagementPure
          history={history}
          fetchAdvertisers={fetchAdvertisers}
          navigate={navigate}
          navigateTo={navigateTo}
          usePlatformNav={usePlatformNav}
          ActionsAdvertiser={ActionsAdvertiserDefault}
      />
  );
};

const EditAdvertiserDefault = ({ navigate, params }) => {
  return (
      <EditAdvertiser
          history={history}
          navigate={navigate}
          params={params}
          fetchAdvertiserById={fetchAdvertiserById}
          navigateTo={navigateTo}
          usePlatformNav={usePlatformNav}
          submitAdvertiser={editAdvertiser}
      />
  );
};

const CreateAdvertiserDefault = ({ navigate }) => {
  return (
      <CreateAdvertiserPrime
          history={history}
          navigate={navigate}
          navigateTo={navigateTo}
          usePlatformNav={usePlatformNav}
          submitAdvertiser={createAdvertiser}
      />
  );
};

const AdvertiserManagement = withNavigate(AdvertiserManagementPureDefault);

const AdvertiserManagementCreate = withNavigate(CreateAdvertiserDefault);

const AdvertiserManagementEdit = withNavigate(
    withParams(EditAdvertiserDefault)
);

const AdvertiserManagementActions = withNavigate(ActionsAdvertiserDefault);

export {
  AdvertiserManagement,
  AdvertiserManagementCreate,
  AdvertiserManagementEdit,
  AdvertiserManagementActions,
};
