import 'babel-polyfill';
import {gitHash} from './env';
import './index.css';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {initializeAdvertisers} from './containers/Advertisers/AdvertisersDropdown/advertiserStorage';
import {run} from './appRunner';

am4core.options.commercialLicense = true;
console.log('git hash', gitHash);
am4core.useTheme(am4themes_animated);
initializeAdvertisers();
run();
