import React from 'react';
import R from 'ramda';
import styled from 'styled-components';
import Chevron from '../Chevron';

export const BreadCrumbSpan = styled.span`
  font-size: inherit;
`;

const Span = styled.span`
  padding-right: 0.5em;
  font-size: 1em;
`;

export default ({ nodes }) => (
  <span>
    {R.intersperse(<Chevron direction="right" />, nodes).map((node, i) => (
      <Span key={i}>{node}</Span>
    ))}
  </span>
);
