import React, {useEffect, useMemo} from 'react';
import {Formik} from 'formik';
import * as R from 'ramda';
import Select from '../../components/Formik/FormSelect';
import FormSelect from '../../components/Formik/FormSelect';
import {FormContainer, SlidingPanelControl} from '../../components/SlidingPanel';
import {connect, useDispatch} from 'react-redux';
import {
    selectAdvertisers,
    selectErrorCategoriesDropdownOptions,
    selectIsPublisher,
    selectPublisherDropdownOptions,
    selectSeverityOptions,
    selectStatusOptions,
    selectViolationProductDropdownOptions,
    selectWebsiteDropdownOptions
} from '../../reducer';
import {AdvertisersDropdown} from '../Advertisers/AdvertisersDropdown';
import {useUser} from '@platform/cj-platform-navigation';
import {useAdvertiserAndDateFilter, usePlatformNav} from '../../appRunner';
import {withNavigate, withSearchParams} from '../../router';
import * as qs from 'query-string';
import {
    getAdvertisers,
    putAdvertisers,
    storeAdverstiersToRedux
} from '../Advertisers/AdvertisersDropdown/advertiserStorage';
import IssueViewController from './issueViewController';
import {statusOptionsCheck} from "../../shared/userStorage";
import {fetchIssuesPageAction} from "../../domain/Issues/actions";
import {SlidingPanelDropdown, SlidingPanelHeader} from "@cjdev-internal/visual-stack-x/SlidingPanel";
import "./style.css"

const FilterSelect = props => <FormSelect width="350px" {...props} />;
export const IssuesSlidingPanel = ({
                                       isPublisher,
                                       partnerDropdownOptions,
                                       websiteDropdownOptions,
                                       violationProductDropdownOptions,
                                       errorCategoriesDropdownOptions,
                                       severityOptions,
                                       statusOptions,
                                       navigate,
                                       searchParams,
                                       initialActive,
                                       advertisersResponse
                                   }) => {
    const {
        advertiserFilter,
        setAdvertiserFilter,
        dateFilter,
        publisherFilter,
        setPublisherFilter
    } = useAdvertiserAndDateFilter();
    const user = useUser();
    const dispatch = useDispatch();
    if (usePlatformNav()) isPublisher = user.currentCompany.type === 'publisher';
    const issueViewController = useMemo(() => new IssueViewController(navigate, searchParams), []);
    const parsed = qs.parse(searchParams.toString());
    const initialValues = useMemo(
        () => ({
            advertisers: getAdvertisers(),
            status: parsed.status,
            days: parsed.days,
            partners: JSON.parse(parsed.partners || '[]'),
            websites: JSON.parse(parsed.websites || '[]'),
            products: JSON.parse(parsed.products || '[]'),
            severity: JSON.parse(parsed.severity || '[]'),
            errorCategories: JSON.parse(parsed.errorCategories || '[]')
        }),
        []
    );

    const onSubmit = filters => {
        setAdvertiserFilter(filters.advertisers);
        setPublisherFilter(filters.partners);
        issueViewController.changeFilters(filters, dateFilter);
    };
    const clearAll = () => {
        setAdvertiserFilter([]);
        setPublisherFilter(undefined);
        issueViewController.clearAll();
    };

    const cssStyle = {
        overflowY: 'auto',
        maxHeight: usePlatformNav() ? '110vh' : 'calc(100vh - 48px)'
    };

    statusOptions = statusOptionsCheck(isPublisher);
    const [initialValuesWithDefaultFilter, setInitialValuesWithDefaultFilter] = React.useState(null);

    if (usePlatformNav()) {
        useEffect(() => {
            if (!isPublisher) {
                if (advertiserFilter) {
                    let publisherFilterInitial = publisherFilter
                    if (publisherFilter === []) {
                        publisherFilterInitial = undefined;
                    }
                    if (advertiserFilter.length !== 0) {
                        const newInitialValues = {
                            ...initialValues,
                            advertisers: advertiserFilter,
                            partners: publisherFilter
                        };
                        setInitialValuesWithDefaultFilter(newInitialValues);
                        putAdvertisers(newInitialValues.advertisers);
                        issueViewController.changeFilters({partners: publisherFilterInitial}, dateFilter)
                    } else {
                        const newInitialValues = {...initialValues, advertisers: [], partners: publisherFilter};
                        setInitialValuesWithDefaultFilter(newInitialValues);
                        putAdvertisers(newInitialValues.advertisers);
                        issueViewController.changeFilters({partners: publisherFilterInitial}, dateFilter);
                    }
                } else {
                    const newInitialValues = {
                        ...initialValues,
                        advertisers: [user?.currentCompany?.id],
                    };
                    setInitialValuesWithDefaultFilter(newInitialValues);
                    putAdvertisers(newInitialValues.advertisers);
                }
                dispatch(fetchIssuesPageAction());
            } else {
                if (advertiserFilter) {
                    if (advertiserFilter.length !== 0) {
                        const newInitialValues = {...initialValues, advertisers: advertiserFilter};
                        setInitialValuesWithDefaultFilter(newInitialValues);
                        putAdvertisers(newInitialValues.advertisers);
                    } else {
                        const newInitialValues = {...initialValues, advertisers: []};
                        setInitialValuesWithDefaultFilter(newInitialValues);
                        putAdvertisers(newInitialValues.advertisers);
                    }
                } else {
                    const newInitialValues = {...initialValues, advertisers: []};
                    setInitialValuesWithDefaultFilter(newInitialValues);
                    putAdvertisers(newInitialValues.advertisers);
                }
                if (publisherFilter) {
                    if (publisherFilter.length !== 0) {
                        const newInitialValues = {...initialValues, partners: publisherFilter};
                        setInitialValuesWithDefaultFilter(newInitialValues);
                        issueViewController.changeFilters({partners: publisherFilter}, dateFilter);
                    } else {
                        const newInitialValues = {...initialValues, partners: undefined};
                        setInitialValuesWithDefaultFilter(newInitialValues);
                        issueViewController.changeFilters({partners: undefined}, dateFilter);
                    }
                } else {
                    const newInitialValues = {...initialValues, partners: [user.currentCompany.id]};
                    setInitialValuesWithDefaultFilter(newInitialValues);
                    setPublisherFilter([user?.currentCompany.id]);
                    issueViewController.changeFilters({partners: [user.currentCompany.id]}, [[], []])
                }
                dispatch(fetchIssuesPageAction());
            }
        }, [user.currentCompany])
    }

    return (
        <div id={'filters'}>
            <SlidingPanelHeader>Filters</SlidingPanelHeader>
            <Formik
                initialValues={initialValuesWithDefaultFilter}
                enableReinitialize
                onSubmit={values => {
                    const partners = R.isEmpty(values.partners) ? undefined : values.partners;
                    const products = R.isEmpty(values.products) ? undefined : values.products;
                    const websites = R.isEmpty(values.websites) ? undefined : values.websites;
                    const severity = R.isEmpty(values.severity) ? undefined : values.severity;
                    const errorCategories = R.isEmpty(values.errorCategories)
                        ? undefined
                        : values.errorCategories;
                    onSubmit({...values, partners, products, websites, errorCategories, severity});
                }}
                render={({setFieldValue, values = {}, handleSubmit, resetForm}) => (
                    <form onSubmit={handleSubmit}>
                        <SlidingPanelControl clearAll={clearAll} resetForm={resetForm}/>
                        <hr/>
                        <SlidingPanelDropdown id="advertisers" label="Advertisers" startExpanded={true}>
                            <FormContainer>
                                <AdvertisersDropdown
                                    onChange={ids => setFieldValue('advertisers', ids)}
                                    advertisers={values.advertisers}
                                />
                            </FormContainer>
                        </SlidingPanelDropdown>
                        <hr/>
                        <SlidingPanelDropdown id="statusFilter" label="Status" startExpanded={true}>
                            <FormContainer>
                                <FilterSelect
                                    id="status-filter"
                                    name="status-filter"
                                    placeholder="Status"
                                    options={statusOptions}
                                    onChange={e => {
                                        const value = R.view(R.lensPath(['value']), e);
                                        setFieldValue('status', value);
                                    }}
                                    value={values.status ?
                                        statusOptions.filter(function (option) {
                                            return option.value === values.status;
                                        }) : []}
                                    isClearable={true}
                                />
                            </FormContainer>
                        </SlidingPanelDropdown>
                        <hr/>
                        {!isPublisher && (
                            <SlidingPanelDropdown id="severityFilter" label="Severity" startExpanded={true}>
                                <FormContainer>
                                    <FilterSelect
                                        name="severity-filter"
                                        placeholder="Severity"
                                        options={severityOptions}
                                        onChange={e => {
                                            const value = e ? e.map(({value}) => value) : [];
                                            setFieldValue('severity', value);
                                        }}
                                        value={values.severity ? severityOptions.filter(option => values.severity.includes(option.value)) : []}
                                        isMulti
                                    />
                                </FormContainer>
                            </SlidingPanelDropdown>
                        )}
                        <hr/>
                        <SlidingPanelDropdown id="websitesFilter" label="Websites" startExpanded={true}>
                            <FormContainer>
                                <Select
                                    name="websites-filter"
                                    placeholder="Websites"
                                    options={websiteDropdownOptions}
                                    onChange={e => {
                                        const websites = e ? e.map(({value}) => value) : [];
                                        setFieldValue('websites', websites);
                                    }}
                                    value={values.websites ? websiteDropdownOptions.filter(option => values.websites.includes(option.value)) : []}
                                    isMulti
                                />
                            </FormContainer>
                        </SlidingPanelDropdown>
                        <hr/>
                        <SlidingPanelDropdown id="partnersFilter" label="Publishers" startExpanded={true}>
                            <FormContainer>
                                <Select
                                    name="partners-filter"
                                    placeholder="Publishers"
                                    options={partnerDropdownOptions}
                                    onChange={e => {
                                        const partners = e ? e.map(({value}) => value) : [];
                                        setFieldValue('partners', partners);
                                    }}
                                    value={values.partners ? partnerDropdownOptions.filter(option => values.partners.includes(option.value)) : []}
                                    isMulti
                                />
                            </FormContainer>
                        </SlidingPanelDropdown>
                        <hr/>
                        <SlidingPanelDropdown id="productsFilter" label="Product(s)" startExpanded={true}>
                            <FormContainer>
                                <Select
                                    name="products-filter"
                                    placeholder="Products"
                                    options={violationProductDropdownOptions}
                                    onChange={e => {
                                        const products = e ? e.map(({value}) => value) : [];
                                        setFieldValue('products', products);
                                    }}
                                    value={values.products ? violationProductDropdownOptions.filter(option => values.products.includes(option.value)) : []}
                                    isMulti
                                />
                            </FormContainer>
                        </SlidingPanelDropdown>
                        <hr/>
                        <SlidingPanelDropdown id="errorCategoriesFilter" label="Categories" startExpanded={true}>
                            <FormContainer>
                                <Select
                                    name="error-categories-filter"
                                    placeholder="Categories"
                                    options={errorCategoriesDropdownOptions}
                                    onChange={e => {
                                        const errorCategories = e ? e.map(({value}) => value) : [];
                                        setFieldValue('errorCategories', errorCategories);
                                    }}
                                    value={values.errorCategories ? errorCategoriesDropdownOptions.filter(option => values.errorCategories.includes(option.value)) : []}
                                    isMulti
                                />
                            </FormContainer>
                        </SlidingPanelDropdown>
                        <hr/>
                    </form>
                )}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    isPublisher: selectIsPublisher(state),
    websiteDropdownOptions: selectWebsiteDropdownOptions(state),
    violationProductDropdownOptions: selectViolationProductDropdownOptions(state),
    partnerDropdownOptions: selectPublisherDropdownOptions(state),
    errorCategoriesDropdownOptions: selectErrorCategoriesDropdownOptions(state),
    severityOptions: selectSeverityOptions(state),
    statusOptions: selectStatusOptions(state),
    advertisersResponse: selectAdvertisers(state)
});
export default withNavigate(withSearchParams(connect(mapStateToProps)(IssuesSlidingPanel)));
