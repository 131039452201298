import React, { Component } from 'react';
import { am4core, am4charts } from '../AMCharts';

const colors = ['#102c7e', '#6d40da', '#30c4b4'];

class ViolationChart extends Component {
  componentDidMount() {
    const data = this.props.data;
    const labels = this.props.labels;
    let chart = am4core.create('violation-chart', am4charts.XYChart);
    chart.fontSize = 13;
    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();
    chart.fill = am4core.color('#333');
    chart.data = this.props.data;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;
    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'range';
    categoryAxis.dataFields.dateRange = 'label';
    const yAxis = categoryAxis.renderer.chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.maxPrecision = 0;

    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.cellStartLocation = 0.4;
    categoryAxis.renderer.cellEndLocation = 0.6;
    categoryAxis.renderer.labels.template.adapter.add('text', function(text) {
      return '{dateRange}';
    });

    categoryAxis.adapter.add('getTooltipText', text => {
      const fromAndTo = text.split('-');
      const sameDay = fromAndTo[0].trim() === fromAndTo[1].trim();
      return sameDay ? fromAndTo[0] : text;
    });

    const createSeries = (field, name, color = '#123C84') => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'range';
      series.strokeWidth = 0;
      series.tooltipText = `{valueY}`;
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = am4core.color('#333');
      series.tooltipText = '{name}: [bold]{valueY}';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.stroke = am4core.color(color);
      series.tooltip.background.strokeWidth = 1.5;
      series.tooltip.fill = am4core.color('#000');
      series.tooltip.fillOpacity = 0.9;
      series.columns.template.column.fillOpacity = 0.9;
      series.columns.template.width = am4core.percent(50);
      series.columns.template.adapter.add('fill', () => am4core.color(color));
      series.columns.template.tooltipText =
        'Series: {name}\nCategory: {categoryX}\nValue: {valueY}';
    };
    labels.forEach(([field, label], index) => {
      createSeries(field, label, colors[index]);
    });
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id="violation-chart" style={{ width: '100%', height: '500px' }} />;
  }
}

export default ViolationChart;
