import React from 'react';
import R from 'ramda';
import { connect } from 'react-redux';
import { openModal } from '@cjdev-internal/visual-stack-redux';
import { AppLayout } from '../App';
import {
  Panel,
  Body as PanelBody,
  Header as PanelHeader
} from '@cjdev-internal/visual-stack-x/components/Panel';
import { PanelHeaderRow, PanelHeaderRowSection } from '../../components/PanelHeader';
import { Table, Thead, Tr, Td, Th } from '../../components/Table';
import { EditButton } from '../../components/Button';
import { SimpleError } from '../../components/ErrorMessage';
import TextSearchFilter from '../../components/Filters/TextSearchFilter';
import { fetchProducts } from '../../domain/Products/actions';
import { formatDate } from '../../utils';
import {
  resetArchiveProduct,
  archiveAndLoad,
  submitProductForm
} from '../../domain/Products/actions';
import { ProductModalMountPoint, createArchiveModal } from '../../components/Modal';
import { ArchiveModalButton, CreateModalButton } from '../../components/Modal/ModalButton';
import { selectProductsForAdvertiser } from '../../reducer';
import CreateModalDialog from '../../components/Modal/CreateModalDialog';
import {history, withNavigate} from '../../router'

const ArchiveModalDialog = createArchiveModal('Archive Product Configuration', [
  'products',
  'data'
]);

export const ProductTable = ({
  products,
  navigateToCreatePage,
  navigateToEditPage,
  archiveAction,
  resetArchiveProduct,
  submitInitializedProductForm
}) => {
  const body = products.map(
    ({ id, productName, alternativeProductNames = [], lastModified }, index) => (
      <Tr key={index}>
        <Td>{productName}</Td>
        <Td>{alternativeProductNames.join(', ')}</Td>
        <Td>{formatDate(lastModified)}</Td>
        <Td right nowrap>
          <EditButton onClick={() => navigateToEditPage(id)} />
          <ArchiveModalButton
            ModalDialog={ArchiveModalDialog}
            takeAction={() => archiveAction(id)}
            itemName={productName}
            onCancel={resetArchiveProduct}
          />
        </Td>
      </Tr>
    )
  );

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            <b>Product</b>
          </Th>
          <Th>
            <b>Alternative Product Names</b>
          </Th>
          <Th width="10%" nowrap>
            <b>Last Modified Date</b>
          </Th>
          <Th right width="10%" nowrap>
            <CreateModalButton
              ModalDialog={CreateModalDialog}
              takeAction={async productName => await submitInitializedProductForm(productName)}
            />
          </Th>
        </Tr>
      </Thead>
      <tbody>{body}</tbody>
    </Table>
  );
};

export const filterList = (productDetail, filterText) =>
  filterText
    ? R.filter(p => {
        if (!p.productName) return false;
        const allProductNames = R.concat([p.productName], p.alternativeProductNames || []).join(
          ' '
        );
        return R.toLower(allProductNames).includes(R.toLower(filterText));
      })(productDetail)
    : productDetail;

export class Products extends React.Component {
  constructor() {
    super();
    this.state = {
      textFilter: ''
    };
  }

  componentDidMount() {
    this.props.fetchProducts();
  }
  render() {
    const { products } = this.props;
    const { productDetail, error } = products;
    const { textFilter } = this.state;

    const filteredProducts = filterList(productDetail, textFilter);
    return (
      <AppLayout pageHeaderTitle="Product Configurations">
        <ProductModalMountPoint />
        <Panel>
          <PanelHeader>
            <PanelHeaderRow>
              <PanelHeaderRowSection>
                <TextSearchFilter
                  label="Filter by product name or alternatives"
                  onChange={value => {
                    this.setState({
                      textFilter: value
                    });
                  }}
                  value={this.state.textFilter}
                  filters={{ urlText: this.state.textFilter }}
                />
              </PanelHeaderRowSection>
            </PanelHeaderRow>
          </PanelHeader>
          <PanelBody>
            {error ? (
              <SimpleError error={error} />
            ) : (
              <ProductTable
                products={filteredProducts}
                navigateToCreatePage={() => {
                  history.push('/products/create');
                  this.props.navigate('/products/create');
                }}
                navigateToEditPage={productId => {
                  history.push(`/products/${productId}`);
                  this.props.navigate(`/products/${productId}`);
                }}
                archiveAction={this.props.archiveAndLoad}
                resetArchiveProduct={this.props.resetArchiveProduct}
                submitInitializedProductForm={this.props.submitInitializedProductForm}
              />
            )}
          </PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

export const mapStateToProps = state => ({ products: selectProductsForAdvertiser(state) });

export const mapDispatchToProps = dispatch => ({
  fetchProducts: () => dispatch(fetchProducts()),
  resetArchiveProduct: () => dispatch(resetArchiveProduct()),
  openModal: (component, props) => dispatch(openModal(component, props)),
  archiveAndLoad: async productId => {
    await dispatch(archiveAndLoad(productId));
  },
  submitInitializedProductForm: async productName => {
    const initializedProduct = {
      productName: productName,
      alternativeProductNames: [],
      adIdPidCombos: [],
      offerRequiredText: [],
      conditionalOfferRequiredText: [],
      pageRequiredText: [],
      conditionalPageRequiredText: [],
      prohibitedText: [],
      includeRatesAndFees: false,
      includeDisclosures: false,
      includeExclusions: false,
      landingUrlConfigurations: [],
      inferredProhibitedTexts: []
    };
    return await dispatch(submitProductForm(initializedProduct));
  }
});

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(Products));
