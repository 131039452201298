import R from 'ramda';
import {
  TEST_RESULTS_FILTERS_CHANGE,
  TEST_RESULTS_LOAD_START,
  TEST_RESULTS_LOAD_FAIL,
  TEST_RESULTS_STORE,
  URL_TOTAL_STORE
} from './actions';

export const defaultFilters = {
  productIdentified: false,
  productErrors: false,
  pageErrors: false,
  systemErrorsOnly: false,
  missingTestResults: false,
  urlText: '',
  page: 1,
  pageSize: 10,
  cjOffers: false
};

const initialState = {
  data: [],
  error: null,
  loading: false,
  totalPages: 1,
  filters: defaultFilters,
  testResultsStat: {
    numberOfUrls: -1,
    testResultIds: [],
    testName: '',
    totalURLNum: 0
  }
};

const calculateTotalPages = (totalItems, pageSize) => Math.max(Math.ceil(totalItems / pageSize), 1);

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TEST_RESULTS_FILTERS_CHANGE:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      };
    case TEST_RESULTS_LOAD_START:
      return {
        ...state,
        data: [],
        error: null,
        loading: true
      };
    case TEST_RESULTS_STORE:
      const { data, filters } = action.payload;
      const { testResultsStat, testResults } = data;
      const totalPages = calculateTotalPages(
        R.defaultTo(0)(testResultsStat.numberOfUrls),
        state.filters.pageSize
      );
      return {
        ...state,
        totalPages,
        data: testResults,
        error: null,
        loading: false,
        testResultsStat: { ...state.testResultsStat, ...testResultsStat },
        filters: { ...state.filters, ...filters }
      };
    case TEST_RESULTS_LOAD_FAIL:
      return {
        ...state,
        data: [],
        error: action.payload,
        loading: false
      };
    case URL_TOTAL_STORE:
      return {
        ...state,
        testResultsStat: {
          ...state.testResultsStat,
          totalURLNum: R.view(R.lensPath(['testResultsStat', 'numberOfUrls']), action.payload)
        }
      };
    default:
      return state;
  }
};
