import * as Actions from './actions';
import { combineReducers } from 'redux';
import { getAdvertiser } from '../../shared/advertiserStorage';
import R from 'ramda';
const initialState = {
  productDetail: [],
  error: null,
  isLoading: false,
  archive: {
    productId: null,
    loading: false,
    error: null
  },
  submission: {
    error: null
  }
};

const submissionReducer = (state, action) => {
  switch (action.type) {
    case Actions.STORE_PRODUCT_SUBMISSION_ERROR:
      return {
        error: action.payload
      };
    case Actions.PRODUCT_DETAIL_RESET:
    case Actions.PRODUCT_SUBMISSION_SUCESS:
      return {
        error: null
      };
    default:
      return state;
  }
};

const productReducer = (state = initialState, action) => {
  const stateWithSubmission = {
    ...state,
    submission: submissionReducer(state.submission, action)
  };
  switch (action.type) {
    case Actions.FETCH_PRODUCTS_START:
      return { ...stateWithSubmission, error: null, isLoading: true };
    case Actions.PRODUCTS_STORE:
      return {
        ...stateWithSubmission,
        productDetail: action.payload,
        error: null,
        isLoading: false
      };
    case Actions.FETCH_PRODUCTS_FAILURE:
      return {
        ...stateWithSubmission,
        productDetail: [],
        error: action.payload,
        isLoading: false
      };
    case Actions.PRODUCT_DETAIL_RESET:
      return { ...stateWithSubmission, productDetail: [], error: null, isLoading: false };
    case Actions.storeForm:
      return { ...stateWithSubmission };
    case Actions.PRODUCT_ARCHIVE_RESET:
      return {
        ...stateWithSubmission,
        archive: { productId: null, loading: false, error: null }
      };
    case Actions.PRODUCT_ARCHIVE_START:
      return {
        ...stateWithSubmission,
        archive: { productId: action.payload, loading: true, error: null }
      };
    case Actions.PRODUCT_ARCHIVE_SUCCESS:
      return {
        ...stateWithSubmission,
        archive: { productId: null, loading: false, error: null }
      };
    case Actions.PRODUCT_ARCHIVE_FAIL:
      return {
        ...stateWithSubmission,
        archive: { productId: null, loading: false, error: action.payload }
      };
    default:
      return stateWithSubmission;
  }
};

const inferredProhibitedTextDataReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Actions.FETCH_INFERRED_PROHIBITED_TEXTS_SUCCESS:
      return payload;
    case Actions.FETCH_INFERRED_PROHIBITED_TEXTS_EMPTY:
      return [];
    default:
      return state;
  }
};

const inferredProhibitedTextIsLoadingReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Actions.FETCH_INFERRED_PROHIBITED_TEXTS_START:
      return true;
    case Actions.FETCH_INFERRED_PROHIBITED_TEXTS_SUCCESS:
      return false;
    case Actions.FETCH_INFERRED_PROHIBITED_TEXTS_EMPTY:
      return false;
    default:
      return state;
  }
};

const inferredProhibitedTextReducer = combineReducers({
  data: inferredProhibitedTextDataReducer,
  isLoading: inferredProhibitedTextIsLoadingReducer
});

export const reducer = combineReducers({
  data: productReducer,
  inferredProhibitedTexts: inferredProhibitedTextReducer
});

export const selectProductsForAdvertiser = ({ data }) => {
  if (R.isNil(getAdvertiser())) {
    return data;
  } else {
    const { id } = getAdvertiser();
    const cloneData = { ...data };
    const previousProductDetail = cloneData.productDetail;
    cloneData.productDetail = previousProductDetail.filter(
      ({ advertiserId }) => advertiserId === id
    );
    return cloneData;
  }
};

export const selectInferredProhibitedTextsIsLoading = state => {
  return state.inferredProhibitedTexts.isLoading;
};

export const selectInferredProhibitedTexts = state => {
  return state.inferredProhibitedTexts.data;
};

export const selectDefaultProductId = state => {
  const { productDetail } = selectProductsForAdvertiser(state);
  return productDetail.length > 0 ? productDetail[0].id : null;
};

export const selectProductFormSubmissionError = state => state.data.submission.error;

export const selectProductDetail = state => state.data.productDetail;
