import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import IssueViewController from "./issueViewController";
import { parse } from "query-string";
import { selectNumIssues } from "../../reducer";
import { connect } from "react-redux";
import { Pagination } from "@cjdev-internal/visual-stack-x/components/Pagination";

export const IssueFooterPure = ({ numIssues }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { size, page } = parse(searchParams.toString());
  const issueViewController = new IssueViewController(navigate, searchParams);
  return (
    <Pagination
      style={{ borderTop: "0px" }}
      numberOfRows={numIssues}
      rowsPerPage={parseInt(size, 10) || 10}
      page={parseInt(page, 10) || 1}
      onChange={(paginationValue) => {
        issueViewController.changePagination({
          page: paginationValue.page,
          size: paginationValue.rowsPerPage,
        });
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  numIssues: selectNumIssues(state),
});

export default connect(mapStateToProps)(IssueFooterPure);
