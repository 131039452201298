import R from 'ramda';

export const isNumericalToken = value => {
  const matchingRegex = new RegExp(
    /^(\$|﹩|＄|C\$|C﹩|C＄|Mex\$|Mex﹩|Mex＄|€|£|￡|¥|￥)?[0-9,.]*[0-9]+[0-9,.]*(\$|﹩|＄|C\$|C﹩|C＄|Mex\$|Mex﹩|Mex＄|€|£|￡|¥|￥|%*)?$/g,
    'i'
  );
  return !R.isNil(value.match(matchingRegex));
};

const isWord = value => !R.isEmpty(R.trim(value));

export const isToken = value => {
  const token = isNumericalToken(value);
  return token && isWord(value);
};
