import React from 'react';
import styled from 'styled-components';
import R from 'ramda';
import NavArrow from '../../components/NavArrow/';
import {history, withNavigate} from '../../router'

const NavDescription = styled.span`
  font-size: inherit;
  user-select: none;
`;

export class DetailNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);

    const { id, testResultIds } = this.props;
    this.index = R.indexOf(id, testResultIds);
  }
  componentWillReceiveProps(nextProps) {
    const { id, testResultIds } = nextProps;
    this.index = R.indexOf(id, testResultIds);
  }
  handlePrevious() {
    const { testResultIds, numberOfUrls } = this.props;
    const prevIndex = this.index === 0 ? numberOfUrls - 1 : this.index - 1;
    const prevId = testResultIds[prevIndex];
    history.push(`/test-result-detail/${prevId}`);
    this.props.navigate(`/test-result-detail/${prevId}`);
  }
  handleNext() {
    const { testResultIds, numberOfUrls } = this.props;
    const nextIndex = this.index === numberOfUrls - 1 ? 0 : this.index + 1;
    const nextId = testResultIds[nextIndex];
    history.push(`/test-result-detail/${nextId}`);
    this.props.navigate(`/test-result-detail/${nextId}`);
  }
  render() {
    const { numberOfUrls, testResultIds } = this.props;
    if (!testResultIds || numberOfUrls === 0) return null;

    return (
      <div data-testid="detail-navigation-contents">
        <NavArrow direction="left" onClick={this.handlePrevious} />
        <NavDescription>
          {this.index + 1} of {numberOfUrls} URLS
        </NavDescription>
        <NavArrow direction="right" onClick={this.handleNext} />
      </div>
    );
  }
}

export default withNavigate(DetailNavigation);
