import React, {useEffect, useState} from 'react';
import {ButtonWithDropdown} from "@cjdev-internal/visual-stack-x/components/ButtonWithDropdown";
import {
    differenceInDays,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    format,
    parse,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    subDays,
    subMonths,
    subQuarters,
    subWeeks,
    subYears,
} from "date-fns";
import {DateRangePicker, useDateRangePicker, useDateRangeSidebar} from "@cjdev-internal/visual-stack-x/DateRangePicker";
import {useAdvertiserAndDateFilter} from "../../appRunner";
import "./styles/datePicker.css"

const DatePickerButton = ({
                              onSubmit,
                              getCurrentDate = () => new Date(),
                              appliedRange,
                              previousRange: previousRangeOverride
                          }) => {

    const getCustomRanges = (from, to) => {
        const fromDate = parse(from);
        const toDate = parse(to);
        const durationInDays = differenceInDays(to, from);
        if (durationInDays > 0) {
            return [
                [
                    format(subDays(fromDate, durationInDays), 'YYYY-MM-DD'),
                    format(subDays(toDate, durationInDays + 1), 'YYYY-MM-DD')
                ]
            ];
        } else {
            return [[format(subDays(fromDate, 1), 'YYYY-MM-DD'), format(subDays(toDate, 1), 'YYYY-MM-DD')]];
        }
    };
    const currentDate = getCurrentDate();
    const THIS_WEEK = 'this_week';
    const LAST_WEEK = 'last_week';
    const THIS_MONTH = 'this_month';
    const LAST_MONTH = 'last_month';
    const THIS_QUARTER = 'this_quarter';
    const LAST_QUARTER = 'last_quarter';
    const THIS_YEAR = 'this_year';
    const LAST_YEAR = 'last_year';
    const CUSTOM = 'custom';
    const ALL_TIME = 'all_time';

    const thisWeek = [startOfWeek(currentDate), endOfWeek(currentDate)];
    const lastWeek = [startOfWeek(subWeeks(currentDate, 1)), endOfWeek(subWeeks(currentDate, 1))];
    const thisMonth = [startOfMonth(currentDate), endOfMonth(currentDate)];
    const lastMonth = [startOfMonth(subMonths(currentDate, 1)), endOfMonth(subMonths(currentDate, 1))];
    const thisQuarter = [startOfQuarter(currentDate), endOfQuarter(currentDate)];
    const lastQuarter = [startOfQuarter(subQuarters(currentDate, 1)), endOfQuarter(subQuarters(currentDate, 1))];
    const thisYear = [startOfYear(currentDate), endOfYear(currentDate)];
    const lastYear = [startOfYear(subYears(currentDate, 1)), endOfYear(subYears(currentDate, 1))];

    const minDate = new Date('2015-01-01');
    const allTimeRange = [minDate, currentDate];

    const [selectedRange, setSelectedRange] = useState(appliedRange);
    const [datePickerExpanded, setDatePickerExpanded] = useState(false);
    const {setDateFilter} = useAdvertiserAndDateFilter();
    const [previousRange, setPreviousRange] = useState([previousRangeOverride]);

    const doExpand = () => {
        setDatePickerExpanded(!datePickerExpanded);
    };

    const [config, ranges, updateRange] = useDateRangePicker([{
        key: "selection",
        startingRange: selectedRange,
        color: "blue"
    },]);
    const sidebarSections = [
        {
            id: "selection", title: "Predefined Range", selectedItem: "enable",
            items: [
                {
                    id: THIS_WEEK,
                    label: "This Week",
                    onSelect: () => updateRange("selection", {
                        startDate: thisWeek[0],
                        endDate: thisWeek[1],
                        hidden: false
                    })
                },
                {
                    id: LAST_WEEK,
                    label: "Last Week",
                    onSelect: () => updateRange("selection", {
                        startDate: lastWeek[0],
                        endDate: lastWeek[1],
                        hidden: false
                    })
                },
                {
                    id: THIS_MONTH,
                    label: "This Month",
                    onSelect: () => updateRange("selection", {
                        startDate: thisMonth[0],
                        endDate: thisMonth[1],
                        hidden: false
                    })
                },
                {
                    id: LAST_MONTH,
                    label: "Last Month",
                    onSelect: () => updateRange("selection", {
                        startDate: lastMonth[0],
                        endDate: lastMonth[1],
                        hidden: false
                    })
                },
                {
                    id: THIS_QUARTER,
                    label: "This Quarter",
                    onSelect: () => updateRange("selection", {
                        startDate: thisQuarter[0],
                        endDate: thisQuarter[1],
                        hidden: false
                    })
                },
                {
                    id: LAST_QUARTER,
                    label: "Last Quarter",
                    onSelect: () => updateRange("selection", {
                        startDate: lastQuarter[0],
                        endDate: lastQuarter[1],
                        hidden: false
                    })
                },
                {
                    id: THIS_YEAR,
                    label: "This Year",
                    onSelect: () => updateRange("selection", {
                        startDate: thisYear[0],
                        endDate: thisYear[1],
                        hidden: false
                    })
                },
                {
                    id: LAST_YEAR,
                    label: "Last Year",
                    onSelect: () => updateRange("selection", {
                        startDate: lastYear[0],
                        endDate: lastYear[1],
                        hidden: false
                    })
                },
                {
                    id: ALL_TIME,
                    label: "All Time",
                    onSelect: () => updateRange("selection", {
                        startDate: allTimeRange[0],
                        endDate: allTimeRange[1],
                        hidden: false
                    })
                },
                {
                    id: CUSTOM,
                    label: "Custom",
                    onSelect: () => updateRange("selection", {startDate: null, endDate: null, hidden: false})
                },
            ],
        },
    ];

    const sidebar = useDateRangeSidebar(sidebarSections);
    const displayDate = format(selectedRange[0], 'DD MMM, YYYY') + ' - ' + format(selectedRange[1], 'DD MMM, YYYY');

    useEffect(() => {
        onSubmit([selectedRange, previousRange[0]]);
        setDateFilter([selectedRange, previousRange[0]]);
    }, [selectedRange]);


    const applyDateRange = () => {
        setSelectedRange([config.selectedRanges.selection.startDate, config.selectedRanges.selection.endDate])
        setPreviousRange(getCustomRanges(config.selectedRanges.selection.startDate, config.selectedRanges.selection.endDate));
        setDatePickerExpanded(false);
    };

    const cancelDateRange = () => {
        setDatePickerExpanded(false);
    };

    return (
        <ButtonWithDropdown
            data-testid='date-filter-button'
            className='date-filter-button'
            buttonContent={displayDate}
            expanded={datePickerExpanded}
            doExpand={doExpand}
            closeOnClickOutside={true}
            withChevron={true}
        >
            <DateRangePicker
                {...config}
                {...sidebar}
                onApply={applyDateRange}
                onCancel={cancelDateRange}
            />
        </ButtonWithDropdown>
    );
};

export default DatePickerButton;