import styled from 'styled-components';
import TestResults from 'mdi-react/FormatListChecksIcon';
import TestConfig from 'mdi-react/TuneVerticalIcon';
import DomainConfig from 'mdi-react/LanIcon';
import Help from 'mdi-react/HelpCircleOutlineIcon';
import Setting from 'mdi-react/SettingsIcon';
import UrlGroup from 'mdi-react/LinkVariantIcon';
import Issues from 'mdi-react/AlertCircleOutlineIcon';
import Home from 'mdi-react/HomeIcon';
import Trash from 'mdi-react/DeleteIcon';
import Undo from 'mdi-react/UndoVariantIcon';
import Download from 'mdi-react/DownloadIcon';
import ChevronLeft from 'mdi-react/ChevronLeftIcon';
import ChevronRight from 'mdi-react/ChevronRightIcon';
import ChevronDown from 'mdi-react/ChevronDownIcon';
import ChevronDoubleLeft from 'mdi-react/ChevronDoubleLeftIcon';
import ChevronDoubleRight from 'mdi-react/ChevronDoubleRightIcon';
import MenuUp from 'mdi-react/MenuUpIcon';
import MenuDown from 'mdi-react/MenuDownIcon';
import Account from 'mdi-react/AccountIcon';
import CheckMark from 'mdi-react/CheckIcon';
import CalendarRange from 'mdi-react/CalendarRangeIcon';
import Configuration from 'mdi-react/TuneIcon';

import {
  ProductIcon,
  LogoutIcon as Logout,
  BarGraphIcon
} from '@cjdev-internal/visual-stack-x/components/Icons';

const styleIcon = icon =>
  styled(icon)`
    fill: currentColor;
  `;

const TestResultsIcon = styleIcon(TestResults);
const TestConfigIcon = styleIcon(TestConfig);
const ProductConfigIcon = styleIcon(ProductIcon);
const DomainConfigIcon = styleIcon(DomainConfig);
const UrlGroupIcon = styleIcon(UrlGroup);
const HelpIcon = styleIcon(Help);
const LogoutIcon = styleIcon(Logout);
const IssuesIcon = styleIcon(Issues);
const HomeIcon = styleIcon(Home);
const AccountIcon = styleIcon(Account);
const DashboardIcon = styleIcon(BarGraphIcon);
const ConfigurationIcon = styleIcon(Configuration);
export const SideNavIcons = {
  TestResultsIcon,
  TestConfigIcon,
  ProductConfigIcon,
  DomainConfigIcon,
  UrlGroupIcon,
  HelpIcon,
  Setting,
  LogoutIcon,
  UrlGroup,
  IssuesIcon,
  HomeIcon,
  AccountIcon,
  DashboardIcon,
  ConfigurationIcon
};

export const TrashIcon = styleIcon(Trash);
export const UndoIcon = styleIcon(Undo);
export const DownloadIcon = Download;
export const ChevronLeftIcon = styleIcon(ChevronLeft);
export const ChevronRightIcon = styleIcon(ChevronRight);
export const ChevronDoubleLeftIcon = styleIcon(ChevronDoubleLeft);
export const ChevronDownIcon = styleIcon(ChevronDown);
export const ChevronDoubleRightIcon = styleIcon(ChevronDoubleRight);
export const MenuUpIcon = styleIcon(MenuUp);
export const MenuDownIcon = styleIcon(MenuDown);
export const CheckMarkIcon = styleIcon(CheckMark);
export const CalendarRangeIcon = styleIcon(CalendarRange);
