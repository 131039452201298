import * as Actions from './actions';
import * as R from 'ramda';
import { combineReducers } from 'redux';

export const dataInitialState = null;

export const data = (state = dataInitialState, { type, payload }) => {
  switch (type) {
    case Actions.TEST_CONFIG_DETAIL_LOAD_START:
    case Actions.INITIALIZE_TEST_CONFIG_DETAIL:
    case Actions.TEST_CONFIG_DETAIL_LOAD_FAIL:
      return dataInitialState;
    case Actions.TEST_CONFIG_DETAIL_STORE:
      return R.dissoc('urls', { ...payload, pendingURLs: payload.urls, invalidURLs: [] });
    case Actions.STORE_FORM_DATA:
      return payload;
    default:
      return state;
  }
};

export const requestInfoInitialState = {
  error: null,
  loading: false
};

export const requestInfo = (state = requestInfoInitialState, { type, payload }) => {
  switch (type) {
    case Actions.TEST_CONFIG_DETAIL_LOAD_START:
      return {
        ...state,
        error: null,
        loading: true
      };
    case Actions.TEST_CONFIG_DETAIL_STORE:
      return {
        ...state,
        error: null,
        loading: false
      };
    case Actions.TEST_CONFIG_DETAIL_LOAD_FAIL:
      return {
        error: payload,
        loading: false
      };
    case Actions.INITIALIZE_TEST_CONFIG_DETAIL:
      return requestInfoInitialState;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  data,
  requestInfo
});

export const selectTestConfigFormData = state => defaultProductId => {
  const { data } = state;
  return data
    ? {
        ...data,
        pendingURLs: R.defaultTo([])(data.pendingURLs).join('\n'),
        product: data.productConfigId,
        invalidURLs: R.defaultTo([])(data.invalidURLs).join('\n')
      }
    : {
        product: defaultProductId,
        invalidURLs: '',
        pendingURLs: ''
      };
};

export const selectTestConfigDetailFormTitle = ({ data }) =>
  data ? 'Edit Test Configuration' : 'Create Test Configuration';

export const selectRequestInfo = state => state.requestInfo;
