import { createAction } from 'redux-actions';
import R from 'ramda';
import { apiServer } from '../../env';
import { authenticatedFetch } from '../../shared/apis';
import { defaultFilters } from './reducer';

// TEST_RESULTS_FILTERS
export const TEST_RESULTS_FILTERS_CHANGE = 'CAS/TestResults/TEST_RESULTS_FILTERS_CHANGE';
export const changeTestResultsFilters = createAction(TEST_RESULTS_FILTERS_CHANGE);

// TEST_RESULTS
export const TEST_RESULTS_LOAD_START = 'CAS/TestResults/TEST_RESULTS_LOAD_START';
export const startLoadTestResults = createAction(TEST_RESULTS_LOAD_START);

export const TEST_RESULTS_STORE = 'CAS/TestResults/TEST_RESULTS_STORE';
export const storeTestResults = createAction(TEST_RESULTS_STORE);

export const TEST_RESULTS_LOAD_FAIL = 'CAS/TestResults/TEST_RESULTS_LOAD_FAIL';
export const failLoadTestResults = createAction(TEST_RESULTS_LOAD_FAIL);



export const URL_TOTAL_STORE = 'CAS/TestResults/URL_TOTAL_STORE';
export const storeURLTotal = createAction(URL_TOTAL_STORE);

export const loadTestResults = (testId, filters = {}) => {
  const pagingClause = `&page=${filters.page}&size=${filters.pageSize}`;
  const productErrorsClause = filters.productErrors ? '&productErrors=1' : '';
  const pageErrorsClause = filters.pageErrors ? '&pageErrors=1' : '';

  const systemErrorsOnlyClause = filters.systemErrorsOnly ? '&systemError=1' : '';
  const productClause = filters.productIdentified ? '&prodId=1' : '';
  const urlTextClause = filters.urlText ? `&urlText=${encodeURIComponent(filters.urlText)}` : '';
  const cjOffersClause = filters.cjOffers ? `&cjOffers=1` : '';
  const missingTestResults = filters.missingTestResults;
  const qs = `testId=${testId}${pagingClause}${productClause}${productErrorsClause}${pageErrorsClause}${systemErrorsOnlyClause}${urlTextClause}${cjOffersClause}`;
  const url = missingTestResults
    ? `missing-test-results?testId=${testId}${urlTextClause}${pagingClause}`
    : `test-result?${qs}`;

  return async dispatch => {
    dispatch(startLoadTestResults());
    try {
      const response = await authenticatedFetch(`${apiServer()}/${url}`);
      const data = await response.json();
      const payload = { data, filters };
      if (R.equals(filters, defaultFilters) || R.isEmpty(filters)) {
        dispatch(storeURLTotal(data));
      }
      dispatch(storeTestResults(payload));
    } catch (err) {
      dispatch(failLoadTestResults(err));
    }
  };
};
