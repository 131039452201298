import React from 'react';
import styled from 'styled-components';
import {getUser, isEnableToCreate} from '../../shared/userStorage';
import * as R from 'ramda';
import * as dateFns from 'date-fns';
import {NotesIcon} from './NotesIcon';
import {AttachmentsIcon} from './AttachmentsIcon';
import IssueFooter from './IssueFooter';
import {IssueHeader} from './IssueHeader';
import {selectAdvertiserPrefixesMap, selectIsPublisher} from '../../reducer';
import {connect} from 'react-redux';
import {usePlatformNav} from '../../appRunner'
import {useUser} from '@platform/cj-platform-navigation';
import IssueDetailLink from './IssueDetailLink';
import {HoverPopover} from '@cjdev-internal/visual-stack-x/components/Popover'
import {ListView} from '@cjdev-internal/visual-stack-x/components/ListView';
import {getValue} from "../IssuesDetail/helpers/dataHelpers";

export const ProgressBar = styled.div`
  width: 95%;
  height: 6px;
  border-radius: 3px;
  background-color: #dbe3ed;
`;
export const NewFiller = styled.div`
  width: 20%;
  height: 6px;
  border-radius: 3px;
  background-color: rgb(0, 99, 255);
`;
export const InProgressFiller = styled.div`
  width: 50%;
  height: 6px;
  border-radius: 3px;
  background-color: rgb(0, 99, 255);
`;
export const ResolvedFiller = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background-color: rgb(73, 197, 177);
`;
export const Tag = styled.span`
  background-color: rgb(242, 244, 244);
  padding: 4px 6px;
  margin-right: 8px;
  border-radius: 3px;
  font-size: 12px;
`;
const Tags = styled.div`
  display: flex;
`;
const TitleDiv = styled.div`
  padding-top: 2px;
  margin-bottom: 8px;
  word-break: break-all;
  font-size: 14px;
`;
const Icons = styled.div`
  width: 24px;
`;
const TimeDiv = styled.div``;
const Icon = styled.div`
  border: 1px solid grey;
  color: grey;
  text-align: center;
  border-radius: 3px;
  margin-right: 4px;
  padding: 0px 6px;
  font-size: 13px;
  display: inline-block;
  width: 22px;
  height: 22px;

  &:hover {
    color: #ffffff;
    background-color: #49c5b1;
    border: 1px solid #49c5b1;
    cursor: default;
  }
`;
const IDSpan = styled.span`
  word-break: break-word;
`;
export const AdvertorialIcon = () => (
    <HoverPopover tooltip={true} content="Advertorial">
        <Icon>A</Icon>
    </HoverPopover>
);
export const EditorialIcon = () => (
    <HoverPopover tooltip={true} content="Editorial">
        <Icon>E</Icon>
    </HoverPopover>
);
export const IssueButton = styled.div`
  display: contents;
  visibility: hidden;

  > button {
    width: 100%;
    margin: 0 0 8px;
    transition: none;
  }
`;

export const DescriptionDiv = styled.div`
  width: 100%;
`;
const DescriptionSpan = styled.span`
  display: flex;
  margin-bottom: 8px;
`;
const Label = styled.span`
  color: #757575;
`;
export const IssueListViewItemContainer = styled.div`
  justify-content: space-between;
  display: flex;

  &:hover ${IssueButton} {
    > * {
      visibility: visible;
    }
  }

  > div:nth-child(1) {
    width: 257px;
  }

  > div:nth-child(2) {
    width: calc((100% - 257px - 61px) * 0.5);
    font-size: 13px;
  }

  > div:nth-child(3) {
    width: calc((100% - 257px - 61px) * 0.5);
  }

  > div:nth-child(4) {
    width: 61px;
    padding: 0;
  }
`;

export const IssueInfoColumn = styled.div`
  display: flex;
  padding-right: 32px;
  flex-direction: column;
`;

const StatusDiv = styled.div`
  margin-bottom: 12px;
  margin-top: 4px;
  font-size: 12px;
`;
export const formatDate = date => (date ? dateFns.format(date, 'YYYY-MM-DD HH:mm') : '');

export const IssuesListView = ({
                                   issues,
                                   configureIssueButtonGenerator,
                                   websites,
                                   isPublisher,
                                   renderEmptyState,
                                   prefixesMap,
                                   toIssueDetail
                               }) => {
    const user = useUser()
    const platformNav = usePlatformNav()
    if (platformNav) isPublisher = user.currentCompany.type === "publisher"
    const isEmployee = platformNav ? user.isEmployee : getUser().user.isEmployee;
    const extraProducts = products => {
        return products.length > 1 ? ` +${products.length - 1}` : '';
    };

    const productsDisplay = products => {
        return R.isEmpty(products) ? 'No Related Products' : products[0].name + extraProducts(products);
    };

    const issueTextTitle = (issue) => {
        const {id, advertiserId} = issue;
        return `${prefixesMap[advertiserId]}-${id}`;
    }

    const HeaderListView = () => {
      const _isEnableToCreate = isEnableToCreate(user)
      return platformNav
                    ? (_isEnableToCreate ? configureIssueButtonGenerator() : null)
                    : <IssueHeader
                        isEnableToCreate={_isEnableToCreate}
                        configureIssueButtonGenerator={configureIssueButtonGenerator}
                        websites={websites}
                    />
    }

    return (
        <ListView
            renderEmptyState={renderEmptyState}
            data={issues}
            indexer={issue => issue.id}
            renderHeader={HeaderListView}
            renderFooter={() => <IssueFooter/>}
            renderContent={issue => (
                <IssueListViewItemContainer>
                    <IssueInfoColumn>
                        <IssueDetailLink title={issueTextTitle(issue)} toIssueDetail={toIssueDetail}/>
                        <ProgressBar>
                            {issue.status === 'Resolved' && <ResolvedFiller/>}
                            {issue.status === 'In Progress' && <InProgressFiller/>}
                            {issue.status === 'New' && <NewFiller/>}
                        </ProgressBar>
                        <StatusDiv data-testid="status">
                            <b>Status:</b> {issue.status}
                        </StatusDiv>
                        {!isPublisher && (
                            <Tags>
                                <Tag>
                                    <b>
                                        {R.isNil(issue.severityOverride)
                                            ? issue.violationTypeMapping.severity
                                            : issue.severityOverride}
                                    </b>{' '}
                                    Severity
                                </Tag>
                                <Tag>
                                    <b>
                                        {R.isNil(issue.errorPointsOverride)
                                            ? issue.violationTypeMapping.errorPoints
                                            : issue.errorPointsOverride}
                                    </b>{' '}
                                    Point(s)
                                </Tag>
                            </Tags>
                        )}
                    </IssueInfoColumn>
                    <IssueInfoColumn>
                        <TitleDiv data-testid="products">
                            <b>{productsDisplay(issue.products || [])}</b>
                        </TitleDiv>
                        <TimeDiv>
                            {isEmployee && (
                                <div data-testid="error-start-time">
                                    <Label>Started:</Label>{' '}
                                    {issue.errorStartTime ? getValue(formatDate(issue.errorStartTime)) : 'N/A'}
                                </div>
                            )}
                            <div data-testid="detection-time">
                                <Label>Detected:</Label>{' '}
                                {issue.detectionTime ? getValue(formatDate(issue.detectionTime)) : 'N/A'}
                            </div>
                            <div data-testid="link-cut-time">
                                <Label>Link Block:</Label> {issue.linkCutTime ? getValue(formatDate(issue.linkCutTime)) : 'N/A'}
                            </div>
                            <div data-testid="resolution-time">
                                <Label>Resolved:</Label>{' '}
                                {issue.resolutionTime ? getValue(formatDate(issue.resolutionTime)) : 'N/A'}
                            </div>
                        </TimeDiv>
                    </IssueInfoColumn>
                    <IssueInfoColumn>
                        <TitleDiv data-testid="domainName">
                            <b>
                                {`${issue.website.name} `}
                                <IDSpan>({issue.website.pid})</IDSpan>
                            </b>
                        </TitleDiv>
                        <DescriptionSpan>
                            {issue.contentType.includes('Advertorial') && <AdvertorialIcon/>}
                            {issue.contentType.includes('Editorial') && <EditorialIcon/>}
                            <DescriptionDiv>{issue.violationTypeMapping.description}</DescriptionDiv>
                        </DescriptionSpan>
                    </IssueInfoColumn>
                    <IssueInfoColumn>
                        <Icons>
                            {issue.errorNotes && (
                                <HoverPopover placement="left-start"
                                              content={issue.errorNotes}><NotesIcon/></HoverPopover>
                            )}
                            {issue.attachmentsOrEmpty && (
                                <HoverPopover placement="left-start"
                                              content={`${issue.attachmentsOrEmpty.length} Attachment(s)`}><AttachmentsIcon/></HoverPopover>
                            )}
                        </Icons>
                    </IssueInfoColumn>
                </IssueListViewItemContainer>
            )}
            itemStyle={{padding: "24px"}}
        />
    );
};

const mapStateToProps = state => ({
    isPublisher: selectIsPublisher(state),
    prefixesMap: selectAdvertiserPrefixesMap(state)
});

export default connect(mapStateToProps)(IssuesListView);
