import React from 'react';
import R from 'ramda';
import { ModalMountPoint } from '@cjdev-internal/visual-stack-redux/lib/components/Modal';
import { connect } from 'react-redux';
import ArchiveModalDialogPure from './ArchiveModalDialogPure';
import * as M from '@cjdev-internal/visual-stack/lib/components/Modal';
import { ArchiveBody, CancelBody } from './ModalBody';

export const ProductModalMountPoint = ModalMountPoint;

export const createArchiveModal = (modalTitle, key) => {
  const keyArray = R.equals(R.type(key), 'Array') ? key : [key];
  const mapStateToProps = state => {
    return { archive: R.view(R.lensPath(['CAS', ...keyArray, 'archive']))(state) };
  };
  return connect(mapStateToProps)(props => {
    return <ArchiveModalDialogPure modalTitle={modalTitle} {...props} />;
  });
};

export class CreateModalPure extends React.Component {
  componentWillReceiveProps(nextProps) {
    const stoppedLoading = this.props.submitState.loading && !nextProps.submitState.loading;
    const submitError = nextProps.submitState.error;
    if (stoppedLoading && R.isNil(submitError)) {
      this.props.closeModal();
    }
  }
  render() {
    return (
      <M.Modal>
        <M.Dialog>
          <M.Content>{this.props.children(this.props)}</M.Content>
        </M.Dialog>
      </M.Modal>
    );
  }
}

export const createModal = modalBody => (modalTitle, stateKey) => {
  const keyArray = R.equals(R.type(stateKey), 'Array') ? stateKey : [stateKey];
  const mapStateToProps = state => ({
    submitState: R.view(R.lensPath(['CAS', ...keyArray]))(state)
  });
  const modal = props => {
    return (
      <CreateModalPure modalTitle={modalTitle} {...props}>
        {modalBody}
      </CreateModalPure>
    );
  };
  return connect(mapStateToProps)(modal);
};

export const createCancelModal = createModal(CancelBody);
export const createArchModal = createModal(ArchiveBody);
export const createGroupArchiveModal = createModal(ArchiveBody);
