import {createContext, useContext, useState} from 'react';

export const ManageRecipientContext = createContext({});
export const useManageRecipientContext = () => useContext(ManageRecipientContext);

export const ManageRecipientContextProvider = ({children}) => {
    const [publisherContact, setPuplisherContact] = useState();
    const [advertiserContact, setAdvertiserContact] = useState();
    const [internalContact, setInternalContact] = useState();

    const value = {
        publisherContact,
        setPuplisherContact,
        advertiserContact,
        setAdvertiserContact,
        internalContact,
        setInternalContact
    };

    return (
        <ManageRecipientContext.Provider value={value}>{children}</ManageRecipientContext.Provider>
    );
};
