import styled from 'styled-components';

export const ChartTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const ChartContainer = styled.div`
  max-height: 900px;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
