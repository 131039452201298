import { combineReducers } from 'redux';
import { START_FETCHING_VIOLATION_TRENDS, SET_VIOLATION_TRENDS_DATA } from './actions';
import * as dateFns from 'date-fns';
import * as R from 'ramda';

const violationTrendsDataReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_VIOLATION_TRENDS_DATA:
      return payload;
    default:
      return state;
  }
};

const violationTrendsIsLoadingReducer = (state = false, { type }) => {
  switch (type) {
    case START_FETCHING_VIOLATION_TRENDS:
      return true;
    case SET_VIOLATION_TRENDS_DATA:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  data: violationTrendsDataReducer,
  isLoading: violationTrendsIsLoadingReducer
});


export const selectViolationTrendsIsLoading = state => state.isLoading;

const capitalizeFirstChar = string => {
  return !!string ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : '';
};

export const selectTrendType = state => capitalizeFirstChar(state.data.breakout);

export const selectViolationTrendsDataForChart = state => {
  if (!state.data.trends) return [];
  return state.data.trends.map(bucket => ({
    from: dateFns.format(new Date(bucket.from), 'MMMM DD'),
    range: `${dateFns.format(new Date(bucket.from), 'MMMM DD, YYYY')} - ${dateFns.format(
      new Date(bucket.to),
      'MMMM DD, YYYY'
    )}`,
    low: bucket.lowSeverityCount,
    medium: bucket.mediumSeverityCount,
    high: bucket.highSeverityCount,
    label: bucket.label,
    total: bucket.totalCount
  }));
};

export const selectShouldRenderViolationTrendsChart = state => {
  const trends = state.data.trends || [];
  const totalCount = R.sum(trends.map(R.prop('totalCount')));
  return totalCount > 0;
};
