import React from 'react';
import styled from 'styled-components';
import { FilterLabel } from '../../components/Filters/BooleanFilter';

const ToggleLine = styled.div`
  display: flex;
  align-items: center;
`;

export const Toggle = ({ input, label }) => {
  return (
    <ToggleLine>
      <input {...input} type="checkbox" checked={input.value} />
      <FilterLabel htmlFor={input.name}>{label}</FilterLabel>
    </ToggleLine>
  );
};
