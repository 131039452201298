
import {saveAs} from 'file-saver';
import {IconButton} from '@cjdev-internal/visual-stack-x/components/Button';
import {useUser} from '@platform/cj-platform-navigation';
import {ExportIssueButtonPure} from './component';
import {getIssues} from './services/issueService';
import {saveMultipleSheetXLSXFile} from './helpers/excelHelper';

const ExportIssueButtonPureDefault = () => {
  const Button = ({ isDownloading, onClick }) => (
    <IconButton icon="download" spinner={isDownloading} outline onClick={onClick} />
  );
  const saveXLSXFile = (file) => saveMultipleSheetXLSXFile(file, saveAs);
  
  return (
    <ExportIssueButtonPure 
      useUser={useUser}
      getIssues={getIssues}
      Button={Button}
      saveXLSXFile={saveXLSXFile}
    />
  );
};

export default ExportIssueButtonPureDefault;
