import qs from 'query-string';
import store from '../../store';
import {fetchDashboardOverviews} from '../../domain/ViolationDashboard/actions';
import * as R from 'ramda';
import {merge, mergeAll} from 'ramda';
import { endOfDay, getTime } from 'date-fns';
import {putAdvertisers} from '../Advertisers/AdvertisersDropdown/advertiserStorage';
import {history} from "../../router";

class DashboardViewController {
    constructor(navigate, search, dispatch = store.dispatch) {
        this.navigate = navigate
        this.search = search
        this.dispatch = dispatch;
    }

    getExisitingQueryObject = () => {
        return qs.parse(this.search);
    };

    setQueryFromPreviousQuery = transformation => {
        const existingQueryObject = qs.parse(this.search);
        const nextQueryObject = transformation(existingQueryObject);
        const queryString = qs.stringify(nextQueryObject);
        this.search = queryString
        history.push(`?${queryString}`);
        this.navigate(`?${queryString}`);
        this.dispatch(fetchDashboardOverviews(this.search));
    };

    resetQuery = () => {
        putAdvertisers([]);
        this.setQuery(() => ({}));
    };

    changeAdvertiser = id => {
        this.setQuery(prevQuery => {
            return mergeAll([
                prevQuery,
                {advertisers: `${[id]}`}
            ]);
        });
    };
    changePublisher = id => {
        this.setQuery(prevQuery => {
            return mergeAll([
                prevQuery,
                {partners: `${[id]}`}
            ]);
        });
    };

    setQuery = input => {
        if (typeof input === 'function') {
            this.setQueryFromPreviousQuery(input);
        } else if (typeof input === 'object') {
            input.advertisers && putAdvertisers(input.advertisers);
            this.setQueryFromPreviousQuery(query => merge(query, R.omit('advertisers', input)));
        }
    };

    changeDateRange = ([[from, to], [previousFrom, previousTo]]) => {
        this.setQuery(prevQuery => {
            const fromMillis = getTime(from);
            const toMillis = getTime(endOfDay(to));
            const previousFromMillis = getTime(previousFrom);
            const previousToMillis = getTime(endOfDay(previousTo));
            return mergeAll([
                prevQuery,
                {from: `${fromMillis}`},
                {to: `${toMillis}`},
                {previousFrom: `${previousFromMillis}`},
                {previousTo: `${previousToMillis}`}
            ]);
        });
    };
}

export default DashboardViewController;
