import React from 'react';

import {
  Section,
  SectionTitle,
  SectionRow,
  SectionBody,
  SubSection
} from '../../components/Section';
import TextCaptureRow from './TextCaptureRow';
import { withHighlighting } from './Highlighter';
import HighlightRows from '../../components/HighlightRows';
import Screenshot from './Screenshot';

export const ScreenCaptureRow = ({ urls }) => (
  <SubSection>
    <SectionRow>
      {urls.map(url => {
        return <Screenshot key={url} src={url} alt="screenshot" />;
      })}
    </SectionRow>
  </SubSection>
);

export const SimplePageDetail = ({ capture, ...highlightData }) => {
  const { highlights } = capture;
  return (
    <Section>
      <SectionTitle>Page Details</SectionTitle>
      <SectionBody>
        <HighlightRows {...highlightData} />
        <TextCaptureRow highlights={highlights} />
        <ScreenCaptureRow urls={capture.screenShots} />
      </SectionBody>
    </Section>
  );
};

export default withHighlighting(SimplePageDetail);
