import React from 'react';
import { connect } from 'react-redux';
import ErrorSpan from '../../components/ErrorMessage';
import R from 'ramda';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack-x/components/Panel';
import { AppLayout } from '../App';
import Spinner from '../../components/Spinner';
import TestConfigFormFormik from './TestConfigFormFormik';
import {
  loadTestConfigDetail,
  initializeTestConfigDetail
} from '../../domain/TestConfigDetails/actions';
import { fetchProducts } from '../../domain/Products/actions';
import {
  selectTestConfigFormData,
  selectUrlGroups,
  selectTestConfigDetailFormInfo,
  selectProductsForAdvertiser,
  selectTestConfigDetailFormTitle
} from '../../reducer';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';
import { fetchUrlGroups } from '../../domain/UrlGroups/actions';
import {history, withNavigate, withParams} from '../../router'

export class CreateTestConfig extends React.Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    const {
      testConfig: { error, loading },
      testConfigFormData,
      products,
      title,
      urlGroups
    } = this.props;
    const {
      pendingURLs: urls,
      configName: name,
      product,
      id,
      urlGroups: testConfigUrlGroups,
      scheduled
    } = testConfigFormData;
    const minimalTestConfigFormData = {
      urls: urls || '',
      name: name || '',
      product,
      urlGroups: testConfigUrlGroups || [],
      id
    };
    const formBody = error ? (
      <ErrorSpan>Error: {error}</ErrorSpan>
    ) : (
      <TestConfigFormFormik
        products={products}
        testConfigFormData={minimalTestConfigFormData}
        urlGroups={urlGroups}
        scheduled={scheduled}
        submitTestConfig={async testConfig => {
          await authenticatedFetch(`${apiServer()}/test-configs`, {
            method: 'POST',
            body: JSON.stringify(testConfig)
          });
          history.push('/test-configs');
          this.props.navigate('/test-configs');
        }}
      />
    );

    const spinnerOrFormBody = loading ? <Spinner /> : formBody;

    return (
      <AppLayout pageHeaderTitle={title}>
        <Panel>
          <PanelBody>{spinnerOrFormBody}</PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

export const mapStateToProps = state => ({
  title: selectTestConfigDetailFormTitle(state),
  testConfig: selectTestConfigDetailFormInfo(state),
  products: selectProductsForAdvertiser(state),
  testConfigFormData: selectTestConfigFormData(state),
  urlGroups: selectUrlGroups(state)
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  init: () => {
    const testConfigId = ownProps.params.id;
    const inEditMode = !R.isNil(testConfigId);
    dispatch(fetchProducts());
    dispatch(fetchUrlGroups());
    if (inEditMode) {
      dispatch(loadTestConfigDetail(testConfigId));
    } else {
      dispatch(initializeTestConfigDetail());
    }
  }
});

export default withParams(withNavigate(connect(mapStateToProps, mapDispatchToProps)(CreateTestConfig)));
