import React from 'react';
import { AppLayout } from '../App';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack-x/components/Panel';
import { connect } from 'react-redux';
import { selectHasUser } from '../../reducer';
import Loader from '../../components/Loader';
import PropTypes from 'prop-types';

const UrlErrorText = () => (
  <div>Check your url and try again? Or grab the url and send us a bug report!</div>
);

export class NoMatchPure extends React.Component {
  render() {
    const { hasUser } = this.props;
    return (
      <AppLayout pageHeaderTitle="Something went wrong!">
        <Panel>
          <PanelBody>{hasUser ? <UrlErrorText /> : <Loader />}</PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

NoMatchPure.propTypes = {
  hasUser: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    hasUser: selectHasUser(state)
  }),
  null
)(NoMatchPure);
