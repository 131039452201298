import {useNavigate} from 'react-router-dom';
import {cloneElement, useEffect, useState} from 'react';
import {FormModalWithOptions} from '@platform/cj-platform-navigation';
import {COMPLIANCE_VIOLATIONS_DETAIL} from '../../../paths';
import * as R from 'ramda';
import {useManageRecipientContext} from '../context/ManageRecipientContext';

const ManageRecipient = ({
                             complianceViolationId,
                             LoadingView,
                             SavingView,
                             ContentView,
                             api,
                             utils,
                             useLoadIssue
                         }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsloading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const {issue} = useLoadIssue(complianceViolationId, api.fetchIssue);
    const navigate = useNavigate();
    const {
        publisherContact,
        setPuplisherContact,
        advertiserContact,
        setAdvertiserContact,
        internalContact,
        setInternalContact
    } = useManageRecipientContext();

    const navigateToHome = path => {
        navigate(path);
    };

    const closeFormModal = () => {
        setIsOpen(false);
        navigateToHome(
            COMPLIANCE_VIOLATIONS_DETAIL.replace(':complianceViolationId', complianceViolationId)
        );
    };

    const onSubmitHandler = async () => {
        const newIssue = {
            ...issue,
            publisherContacts: publisherContact.replace(/\s/g, ''),
            advertiserContacts: advertiserContact.replace(/\s/g, ''),
            networkQualityContacts: internalContact.replace(/\s/g, '')
        };

        setIsSaving(true);
        await api.submitIssue(newIssue);
        closeFormModal();
    };

    useEffect(() => {
        if (!issue) {
            return;
        }

        if (R.isNil(issue.products)) {
            issue.products = [];
        }

        setIsloading(false);
    }, [issue]);

    useEffect(() => {
        if (!isLoading) {
            const {publisherContacts, advertiserContacts, networkQualityContacts} = issue;
            setPuplisherContact(publisherContacts);
            setAdvertiserContact(advertiserContacts);
            setInternalContact(networkQualityContacts);
        }
    }, [isLoading]);

    return (
        <FormModalWithOptions
            id={'manageRecipient'}
            isOpen={isOpen}
            onRequestClose={closeFormModal}
            onSubmit={onSubmitHandler}
            submitText="Save"
            title={'Manage Notification Recipients'}
            disableSubmit={isLoading || isSaving}
            gap="medium"
        >
            {isLoading ? (
                <LoadingView/>
            ) : isSaving ? (
                <SavingView/>
            ) : (
                cloneElement(ContentView(), {issueReporter: utils.getFullName(issue.issueReporter)})
            )}
        </FormModalWithOptions>
    );
};

export default ManageRecipient;
