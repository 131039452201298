import React from 'react';
import styled from 'styled-components';
import Select from '../../components/Select';
import NavArrow from '../../components/NavArrow';
import { connect } from 'react-redux';
import { Input } from '@cjdev-internal/visual-stack-x/components/Input';

const SmallInput = styled(Input)`
  width: 50px !important;
`;

export const PaddedSelect = styled(Select)`
  margin-left: 0.5em;
`;

const pageSizes = [10, 50, 100, 500];



const PagingControlContainer = styled.div`
  display: inline;
  > * {
    margin-right: 5px;
  }
`;

export const getAlwaysValidPageNumber = (pageNumber, totalPages) => {
  const nextPageInt = parseInt(pageNumber, 10);
  const normalizedPageInt = isNaN(nextPageInt) || nextPageInt <= 0 ? 1 : nextPageInt;
  return Math.min(normalizedPageInt, totalPages);
};

export const PageNumberControl = ({
  onLeftArrowPress,
  onRightArrowPress,
  onPageNumberChange,
  onPageNumberSubmit,
  disabled,
  pageNumberDetails: { totalPages, pageInput }
}) => {
  return (
    <PagingControlContainer>
      <NavArrow direction="left" onClick={onLeftArrowPress} disabled={disabled} />
      <SmallInput
        type="text"
        value={pageInput || ''}
        onChange={onPageNumberChange}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onPageNumberSubmit();
          }
        }}
        disabled={disabled}
      />
      <span> / {totalPages}</span>
      <NavArrow direction="right" onClick={onRightArrowPress} disabled={disabled} />
    </PagingControlContainer>
  );
};

export class PagingControls extends React.Component {
  constructor(props) {
    super(props);
    this.handlePageInputChange = this.handlePageInputChange.bind(this);
    this.handleOnPageChange = this.handleOnPageChange.bind(this);
    this.state = {
      pageInput: undefined
    };
  }

  componentWillReceiveProps() {
    this.setState({
      pageInput: this.props.currentPage
    });
  }

  handlePageInputChange(pageInput) {
    this.setState({ pageInput });
  }

  handleOnPageChange(nextPage) {
    const alwaysValidPageNumber = getAlwaysValidPageNumber(nextPage, this.props.totalPages);
    this.props.onPageChange(alwaysValidPageNumber);
    this.handlePageInputChange(alwaysValidPageNumber);
  }

  render() {
    const { currentPage, totalPages, currentPageSize, onPageSizeChange, isLoading } = this.props;
    return (
      <div>
        <PaddedSelect
          value={currentPageSize}
          onChange={event => onPageSizeChange(event.target.value)}
        >
          {pageSizes.map(size => (
            <option key={size} value={size}>
              {size} per page
            </option>
          ))}
        </PaddedSelect>
        <PageNumberControl
          onLeftArrowPress={() => {
            const nextPage = currentPage - 1;
            this.handleOnPageChange(nextPage);
          }}
          onRightArrowPress={() => {
            const nextPage = currentPage + 1;
            this.handleOnPageChange(nextPage);
          }}
          onPageNumberChange={e => {
            this.handlePageInputChange(e.target.value);
          }}
          onPageNumberSubmit={() => {
            this.handleOnPageChange(this.state.pageInput);
          }}
          disabled={isLoading}
          pageNumberDetails={{
            totalPages,
            pageInput: this.state.pageInput
          }}
        />
      </div>
    );
  }
}

export default connect(state => ({
  isLoading: state.CAS.testResults.loading
}))(PagingControls);
