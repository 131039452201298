import React from 'react';
import FormSelect from '../../components/Formik/FormSelect';
import * as R from 'ramda';
import {DatePicker} from 'antd';
import {MultiLineError, UploadErrors} from '../../components/ErrorMessage';
import moment from 'moment';
import {upload} from './attachmentUploader';
import {IssueAttachments} from '../../components/Attachment';
import {SubmitButtonSpinner} from '../../components/Spinner';
import {getUser, isEmployee} from '../../shared/userStorage';
import {formatDate} from './IssuesListView';
import {Field} from '@cjdev-internal/visual-stack-x/Field';
import {Input} from "@cjdev-internal/visual-stack-x/Input"
import {Grid} from '@cjdev-internal/visual-stack-x/components/Grid';
import {TextArea} from '@cjdev-internal/visual-stack-x/TextArea';
import {Stack} from '@cjdev-internal/visual-stack-x/components/Stack';
import {Clickable} from '@cjdev-internal/visual-stack-x/Clickable';
import {Button} from '@cjdev-internal/visual-stack-x/components/Button';
import {Modal} from '@cjdev-internal/visual-stack-x/components/Modal';
import {Row} from '@cjdev-internal/visual-stack-x/components/Row';

export const AllProducts = 'ALL_PRODUCTS';

const defaultStatus = 'New';

const convertWebsiteIdsToOptions = websites =>
    websites.map(({websiteId, websiteName}) => ({
        label: `${websiteId} - ${websiteName}`,
        value: websiteId
    }));

const convertProductsToOptions = (products, existingProducts = []) => {
    const option = {
        label: 'All Products',
        value: AllProducts
    };
    const productIds = products.map(_ => _.id);
    const disabledProducts = existingProducts.map(product => !R.contains(product.id, productIds) && product);
    const enabledOptions = R.prepend(option)(
        products.map(({id, name}) => ({
            label: `${name}`,
            value: id
        }))
    );
    const disabledOptions = disabledProducts
        .filter(product => product !== false)
        .map(({id, name}) => ({
            label: `${name}`,
            value: id
        }));
    return disabledProducts.length ? enabledOptions.concat(disabledOptions) : enabledOptions;
};

const convertViolationTypesToOptions = violationTypes =>
    violationTypes.map(violationType => ({
        label: violationType,
        value: violationType
    }));
const calculateAllProducts = (products, initialProducts) => {
    const productIds = products.map(product => product.id);
    const disabledProducts = (initialProducts || []).filter(
        product => !R.contains(product.id, productIds)
    );
    return [...products, ...disabledProducts];
};
const convertViolationTypeMappingsToOptions = (violationTypeMappings, violationType) => {
    const vtmsToOptions = vtms =>
        vtms.map(({id, description}) => ({
            label: description,
            value: id
        }));
    if (!violationType) {
        const allVtms = R.flatten(R.values(violationTypeMappings));
        return vtmsToOptions(allVtms);
    }
    const vtmsForCategory = R.prop(violationType, violationTypeMappings);
    return vtmsToOptions(vtmsForCategory);
};

export class ConfigureIssueModalContent extends React.Component {
    _isEmployee;

    constructor(props) {
        super(props);
        this.state = {
            pendingIssue: {},
            loadingAttachments: false
        }
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.closeModal();
        }
    }

    componentDidMount = () => {
        document.addEventListener('keydown', this.escFunction, false);
        const user = this.props.user ?? getUser().user;
        this._isEmployee = isEmployee(user);
        const {existingValues} = this.props;
        const newPendingIssue = existingValues || {
            issueReporter: {...user},
            status: defaultStatus,
            advertiserId: this.props.advertiserId
        };

        this.setPendingIssue({
            ...newPendingIssue,
            lastUpdatedBy: {id: user.id},
            products: existingValues && existingValues.products ? existingValues.products : [],
        });
        const {violationTypeMapping} = newPendingIssue;
        if (violationTypeMapping) {
            this.setState({
                violationTypeMapping: violationTypeMapping.id,
                violationType: violationTypeMapping.category
            });
        }
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    setPendingIssue = callback => {
        this.setState(({pendingIssue}) => {
            let newPendingIssue = callback;
            if (typeof callback === 'function') {
                newPendingIssue = callback(pendingIssue);
            }
            const mergedPendingIssue = R.merge(pendingIssue)(newPendingIssue);
            if (newPendingIssue.website && newPendingIssue.website.pid) {
                const annotatedWebsiteInfo = R.pick(['publisherId', 'publisherName', 'websiteName'])(
                    this.props.getAnnotatedWebsite(newPendingIssue.website.pid)
                );
                return {
                    pendingIssue: {
                        ...mergedPendingIssue,
                        websiteName: annotatedWebsiteInfo.websiteName,
                        publisherName: annotatedWebsiteInfo.publisherName,
                        publisher: {cid: annotatedWebsiteInfo.publisherId}
                    }
                };
            } else {
                return {pendingIssue: mergedPendingIssue};
            }
        });
    };

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({behavior: 'smooth'});
    };

    getMappingDetails = () => {
        const mappings = this.props.violationTypeMappings[this.state.violationType] || [];
        const violationMapping = R.find(
            mapping => mapping.id === this.state.violationTypeMapping,
            mappings
        );
        return violationMapping || {};
    };

    getTitle = (existingValues, advertiserLabel) => {
        if (R.isNil(existingValues)) {
            return `Add New ${advertiserLabel} Violation`;
        } else if (R.isNil(existingValues.id)) {
            return 'Copy Violation';
        } else {
            return `Edit Violation: ${existingValues.title}`;
        }
    };

    overrideStyles = (value) => {
        if (!R.isNil(value)) {
            return {
                textDecoration: 'line-through',
                color: 'rgb(226,29,29)'
            }
        }
        return null;
    }

    handleProductSelect = (products, initialProducts) => {
        return (value) => {
            const values = Array.isArray(value) ? value.map(v => v.value) : [];
            const selectedProducts = values.map(id => ({id}));
            if (R.contains(AllProducts)(values)) {
                this.setPendingIssue({
                    products: calculateAllProducts(products, initialProducts)
                });
            } else {
                this.setPendingIssue({
                    products: selectedProducts
                });
            }
        };
    };


    handleSubmit = () => {
        const {
            submitPendingIssue,
            closeModal,
        } = this.props;
        const {pendingIssue} = this.state;
        const errors = [];

        if (!pendingIssue.detectionTime) errors.push('Notification Time');
        if (!pendingIssue.website) errors.push('Property ID');
        if (!pendingIssue.contentType) errors.push('Content Type');
        if (!pendingIssue.source && this._isEmployee) errors.push('Source');
        if (!pendingIssue.auditType) errors.push('Audit Type');
        if (!pendingIssue.cpaLinkFound) errors.push('CPA Link Found');
        if (!this.state.violationType) errors.push('Category');
        if (!this.state.violationTypeMapping) errors.push('Description');
        if (errors.length > 0) {
            this.setState({errors: [errors.join(', ')]});
            return;
        }
        const pendingIssueWithAttachments = {
            ...pendingIssue,
            attachments: (pendingIssue.attachments || [])
                .filter(({deleteFlag}) => !deleteFlag)
                .map(({deleteFlag, ...rest}) => rest)
        };
        const pendingIssueWithViolationTypeMapping = {
            ...pendingIssueWithAttachments,
            violationTypeMapping: {id: this.state.violationTypeMapping}
        };
        submitPendingIssue(pendingIssueWithViolationTypeMapping);
        closeModal();
    }

    render() {
        const {
            websites,
            getPublisherId,
            getWebsiteName,
            getPublisherName,
            products,
            violationTypes,
            violationTypeMappings,
            closeModal,
            isSpecialOverride,
            advertiserLabel,
            existingValues,
        } = this.props;
        const initialProducts = existingValues && existingValues.products;
        const {pendingIssue} = this.state;

        const websiteId = pendingIssue && pendingIssue.website && pendingIssue.website.pid;
        const violationTypeMappingDetails = this.getMappingDetails();
        const pendingProductIds = pendingIssue.products && pendingIssue.products.map(product => product.id);
        const issueReporter = !R.isNil(pendingIssue.issueReporter) ? `${pendingIssue.issueReporter.lastName} ${pendingIssue.issueReporter.firstName}` : '';
        const ModalFooter = (
            <div>
                <div>{this.state.errors && <MultiLineError errors={this.state.errors}/>}</div>
                <br/>
                <Row gap='medium' justify="end">
                    <Button data-testid="cancel" type="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        data-testid="submit"
                        disabled={this.state.loadingAttachments}
                        type="primary"
                        onClick={this.handleSubmit}
                    >
                        Save
                    </Button>
                </Row>
            </div>
        );

        const modalProps = {
            onBackgroundClick: closeModal,
            onEscapeKeyUp: closeModal,
            onCloseIconClick: closeModal,
            headerTitle: this.getTitle(existingValues, advertiserLabel),
            footer: ModalFooter
        }

        const statusOptions = [
            {label: 'New', value: 'New'},
            {label: 'In Progress', value: 'In Progress'},
            {label: 'Resolved', value: 'Resolved'}
        ];

        const contentTypeOptions = [
            {label: 'Advertorial', value: 'Advertorial'},
            {label: 'Editorial', value: 'Editorial'},
            {label: 'Advertorial & Editorial', value: 'Advertorial & Editorial'}
        ];

        const severityOverrideOptions =
            isSpecialOverride
                ? [
                    {label: 'P1', value: 'P1'},
                    {label: 'P2', value: 'P2'},
                    {label: 'P3', value: 'P3'}
                ]
                : [
                    {label: 'Low', value: 'Low'},
                    {label: 'Medium', value: 'Medium'},
                    {label: 'High', value: 'High'}
                ]

        const pointsOverrideOptions = [
            {label: 0, value: 0},
            {label: 1, value: 1},
            {label: 2, value: 2},
            {label: 3, value: 3}
        ];

        const auditTypeOptions = [
            {label: 'Error Log', value: 'Error Log'},
            {label: 'Product Update', value: 'Product Update'},
            {label: 'Pre-Launch', value: 'Pre-Launch'}
        ];

        const sourceOptions = [
            {label: 'Tech: Benchtools', value: 'Tech: Benchtools'},
            {label: 'Tech: BrandVerity', value: 'Tech: BrandVerity'},
            {label: 'Tech: CJ Proprietary', value: 'Tech: CJ Proprietary'},
            {label: 'Tech: Fraudlogix', value: 'Tech: Fraudlogix'},
            {label: 'Tech: Lashback', value: 'Tech: Lashback'},
            {label: 'Tech: Performline', value: 'Tech: Performline'},
            {label: 'Tech: Rightlander', value: 'Tech: Rightlander'},
            {label: 'Tech: RiskIQ', value: 'Tech: RiskIQ'},
            {label: 'Tech: The Search Monitor', value: 'Tech: The Search Monitor'},
            {label: 'Tech: Other', value: 'Tech: Other'},
            {label: 'Internal: CCA', value: 'Internal: CCA'},
            {label: 'Internal: PCM', value: 'Internal: PCM'},
            {label: 'Internal: Other', value: 'Internal: Other'},
            {label: 'External: Advertiser', value: 'External: Advertiser'},
            {label: 'External: Publisher', value: 'External: Publisher'},
            {label: 'External: Agency', value: 'External: Agency'},
            {label: 'Other (specify in Notes)', value: 'Other (specify in Notes)'}
        ];

        return (
            <Modal {...modalProps} >
                <Stack gap="large">

                    <Grid gap="large" columns={pendingIssue.creationTime ? '2fr 1fr 1fr' : '1fr 1fr'}>
                        <Field
                            label="Issue Reporter">
                            <Input data-testid="issue-reporter" value={issueReporter} readOnly/>
                        </Field>

                        {pendingIssue.creationTime && (
                            <Field
                                label="Issue Created">
                                <Input data-testid="creation-time" value={formatDate(pendingIssue.creationTime)}
                                       readOnly/>
                            </Field>
                        )}

                        <Field label="Status">
                            <FormSelect
                                width='auto'
                                name="status"
                                isClearable={false}
                                options={statusOptions}
                                onChange={({value}) => this.setPendingIssue({status: value})}
                                value={pendingIssue.status ?
                                    statusOptions.filter((option) => {
                                        return option.value === pendingIssue.status;
                                    }) : []}
                            />

                        </Field>
                    </Grid>

                    <Field label="Location">
                        <TextArea
                            data-testid="errorLocation"
                            value={pendingIssue.errorLocation}
                            onChange={e =>
                                this.setPendingIssue({
                                    errorLocation: e.target.value
                                })
                            }
                            resize={"vertical"}
                        />
                    </Field>

                    {pendingIssue.advertiserId && (
                        <Field label="Advertiser ID">
                            <Input data-testid="advertiser-id" defaultValue={pendingIssue.advertiserId} readOnly/>
                        </Field>
                    )}

                    <Grid gap="large" columns='1fr 1fr'>
                        <Field required label="Property ID">
                            <FormSelect
                                width="auto"
                                name="website-id"
                                options={convertWebsiteIdsToOptions(websites)}
                                onChange={({value: websiteId}) =>
                                    this.setPendingIssue({website: {pid: websiteId}})
                                }
                                value={websiteId ?
                                    convertWebsiteIdsToOptions(websites).filter((option) => {
                                        return option.value === websiteId;
                                    }) : []}
                                isClearable={false}
                            />
                        </Field>

                        <Field label="Publisher CID">
                            <Input data-testid="publisherId" defaultValue={getPublisherId(websiteId)} readOnly/>
                        </Field>

                        <Field label="Property Name">
                            <Input data-testid="websiteName" defaultValue={getWebsiteName(websiteId)} readOnly/>
                        </Field>

                        <Field label="Publisher Name">
                            <Input data-testid="publisherName" defaultValue={getPublisherName(websiteId)} readOnly/>
                        </Field>
                    </Grid>

                    <Field label="Product(s)">
                        <FormSelect
                            width="auto"
                            name="product-id"
                            options={convertProductsToOptions(products, initialProducts)}
                            onChange={this.handleProductSelect(products, initialProducts)}
                            value={(pendingProductIds || []).length > 0
                                ? convertProductsToOptions(products, initialProducts).filter(option => (pendingProductIds || []).includes(option.value))
                                : []}
                            isClearable={false}
                            isMulti
                        />
                    </Field>

                    <Grid gap="large" columns='1fr 1fr'>
                        <Field required label="Content Type">
                            <FormSelect
                                width="auto"
                                name="content-type"
                                isClearable={false}
                                options={contentTypeOptions}
                                onChange={({value}) => {
                                    value ? this.setPendingIssue({contentType: value}) : [];
                                }}
                                value={pendingIssue.contentType ?
                                    contentTypeOptions.filter((option) => {
                                        return option.value === pendingIssue.contentType;
                                    }) : []}
                            />
                        </Field>

                        <Field required label="Category">
                            <FormSelect
                                width="auto"
                                name="violation-type"
                                options={convertViolationTypesToOptions(violationTypes)}
                                onChange={({value}) => {
                                    if (value !== this.state.violationType) {
                                        this.setState({violationTypeMapping: undefined});
                                    }
                                    this.setState({violationType: value});
                                }}
                                value={this.state.violationType ?
                                    convertViolationTypesToOptions(violationTypes).filter((option) => {
                                        return option.value === this.state.violationType;
                                    }) : []}
                                isClearable={false}
                            />
                        </Field>

                    </Grid>

                    <Field required label="Description">
                        <FormSelect
                            width="auto"
                            name="violation-type-mapping"
                            options={convertViolationTypeMappingsToOptions(
                                violationTypeMappings,
                                this.state.violationType
                            )}
                            onChange={({value}) => {
                                if (!this.state.violationType) {
                                    const flatVtms = R.flatten(R.values(violationTypeMappings));
                                    const vtmForDescription = flatVtms.filter(vtm => vtm.id === value);
                                    const categoryForDescription = R.head(vtmForDescription).category;
                                    this.setState({
                                        violationType: categoryForDescription,
                                        violationTypeMapping: value
                                    });
                                }
                                this.setState({violationTypeMapping: value});
                            }}
                            value={this.state.violationTypeMapping ?
                                convertViolationTypeMappingsToOptions(
                                    violationTypeMappings,
                                    this.state.violationType
                                ).filter((option) => {
                                    return option.value === this.state.violationTypeMapping;
                                }) : []}
                            isClearable={false}
                        />
                    </Field>

                    <Field label="Subcategory">
                        <Input data-testid="feature" defaultValue={violationTypeMappingDetails.feature} readOnly/>
                    </Field>

                    <Grid gap="large" columns='1fr 1fr'>
                        <Field label="Severity">
                            <Input style={this.overrideStyles(pendingIssue.severityOverride)} data-testid="severity"
                                   defaultValue={violationTypeMappingDetails.severity} readOnly/>
                        </Field>

                        <Field label="Severity Override">
                            <FormSelect
                                width="auto"
                                name="severity-override"
                                options={severityOverrideOptions}
                                onChange={e => {
                                    const value = R.view(R.lensPath(['value']), e);
                                    this.setPendingIssue({severityOverride: value});
                                }}
                                value={pendingIssue.severityOverride ?
                                    severityOverrideOptions.filter((option) => {
                                        return option.value === pendingIssue.severityOverride;
                                    }) : []}
                                isClearable={true}
                            />
                        </Field>

                        <Field label="Points">
                            <Input style={this.overrideStyles(pendingIssue.errorPointsOverride)} data-testid="points"
                                   defaultValue={violationTypeMappingDetails.errorPoints} readOnly/>
                        </Field>

                        <Field label="Points Override">
                            <FormSelect
                                name="error-points-override"
                                width="auto"
                                options={pointsOverrideOptions}
                                onChange={e => {
                                    const value = R.view(R.lensPath(['value']), e);
                                    this.setPendingIssue({errorPointsOverride: value});
                                }}
                                value={!R.isNil(pendingIssue.errorPointsOverride) ?
                                    pointsOverrideOptions.filter((option) => {
                                        return option.value === pendingIssue.errorPointsOverride;
                                    }) : []}
                                isClearable={true}
                            />
                        </Field>

                    </Grid>

                    <Grid gap="large" columns={this._isEmployee ? '1fr 1fr 1fr' : '1fr 1fr'}>

                        {this._isEmployee && <Field required label="Source">
                            <FormSelect
                                width="auto"
                                name="source"
                                options={sourceOptions}
                                isClearable={false}
                                onChange={({value}) => {
                                    this.setPendingIssue({source: value});
                                }}
                                value={pendingIssue.source ?
                                    sourceOptions.filter((option) => {
                                        return option.value === pendingIssue.source;
                                    }) : []}
                            />
                        </Field>}
                        <Field required label="Audit Type">
                            <FormSelect
                                width="auto"
                                isClearable={false}
                                name="auditType"
                                value={pendingIssue.auditType ?
                                    auditTypeOptions.filter((option) => {
                                        return option.value === pendingIssue.auditType;
                                    }) : []}
                                options={auditTypeOptions}
                                onChange={({value}) => {
                                    this.setPendingIssue({auditType: value});
                                }}
                            />
                        </Field>
                        <Field required label="CPA Link?">
                            <FormSelect
                                width="auto"
                                isClearable={false}
                                name="cpaLinkFound"
                                value={pendingIssue.cpaLinkFound ?
                                    [
                                        {label: 'Yes', value: 'Yes'},
                                        {label: 'No', value: 'No'}
                                    ].filter((option) => {
                                        return option.value === pendingIssue.cpaLinkFound;
                                    }) : []}
                                options={[
                                    {label: 'Yes', value: 'Yes'},
                                    {label: 'No', value: 'No'}
                                ]}
                                onChange={({value}) => {
                                    this.setPendingIssue({cpaLinkFound: value});
                                }}
                            />
                        </Field>

                    </Grid>

                    <Field label="Notes">
                        <TextArea
                            data-testid="errorNotes"
                            value={pendingIssue.errorNotes}
                            onChange={e =>
                                this.setPendingIssue({
                                    errorNotes: e.target.value
                                })
                            }
                            height="6.4em"
                        />
                    </Field>


                    <Grid gap="large" columns='1fr 1fr'>
                        <Field label="Start Time">
                            <DatePicker
                                name="error-start-time"
                                value={
                                    pendingIssue.errorStartTime
                                        ? moment(pendingIssue.errorStartTime)
                                        : undefined
                                }
                                showTime
                                format="YYYY-MM-DD HH:mm"
                                onChange={value =>
                                    this.setPendingIssue({errorStartTime: value && value.valueOf()})
                                }
                            />
                        </Field>

                        <Field required label="Notification Time">
                            <DatePicker
                                name="detection-time"
                                value={
                                    pendingIssue.detectionTime
                                        ? moment(pendingIssue.detectionTime)
                                        : undefined
                                }
                                showTime
                                format="YYYY-MM-DD HH:mm"
                                onChange={value =>
                                    this.setPendingIssue({detectionTime: value && value.valueOf()})
                                }
                            />
                        </Field>

                        <Field label="Link Block Time">
                            <DatePicker
                                name="link-cut-time"
                                value={
                                    pendingIssue.linkCutTime
                                        ? moment(pendingIssue.linkCutTime)
                                        : undefined
                                }
                                showTime
                                format="YYYY-MM-DD HH:mm"
                                onChange={value => this.setPendingIssue({linkCutTime: value && value.valueOf()})}
                            />
                        </Field>

                        <Field label="Resolution Time">
                            <DatePicker
                                name="resolution-time"
                                value={
                                    pendingIssue.resolutionTime
                                        ? moment(pendingIssue.resolutionTime)
                                        : undefined
                                }
                                showTime
                                format="YYYY-MM-DD HH:mm"
                                onChange={value => {
                                    this.setPendingIssue({resolutionTime: value && value.valueOf()});
                                    if (value) {
                                        this.setPendingIssue({status: 'Resolved'});
                                    }
                                }}
                            />
                        </Field>

                    </Grid>

                    <Field label="Image Attachments">
                        <input
                            name="attachments"
                            type="file"
                            onChange={async event => {
                                const files = event.target.files;
                                try {
                                    this.setState({uploadErrors: null, errors: null, loadingAttachments: true});
                                    const attachments = await upload(files);
                                    this.setPendingIssue(pendingIssue => ({
                                        attachments: R.concat(
                                            pendingIssue.attachments || [],
                                            attachments.map(attachment => ({
                                                ...attachment,
                                                deleteFlag: false
                                            }))
                                        )
                                    }));
                                    this.setState({loadingAttachments: false});
                                } catch (e) {
                                    this.setState({
                                        uploadErrors: R.split(',', e.message),
                                        loadingAttachments: false
                                    });
                                }
                            }}
                            multiple
                        />
                    </Field>

                    <div>{this.state.uploadErrors && <UploadErrors errors={this.state.uploadErrors}/>}</div>
                    {pendingIssue.attachments?.length > 0 && !this.state.loadingAttachments && (
                        <Clickable data-testid="scroll-to-images" onClick={this.scrollToBottom}>
                            View Images
                        </Clickable>
                    )}
                    {this.state.loadingAttachments && (
                        <SubmitButtonSpinner data-testid="attachments-loading-spinner"/>
                    )}
                    <IssueAttachments
                        issueAttachments={pendingIssue.attachments || []}
                        toggleDelete={attachmentIndex =>
                            this.setPendingIssue(({attachments}) => {
                                const selectedAttachment = attachments[attachmentIndex];
                                const toggledAttachment = R.assoc(
                                    'deleteFlag',
                                    !selectedAttachment.deleteFlag,
                                    selectedAttachment
                                );
                                return {
                                    attachments: R.update(attachmentIndex, toggledAttachment, attachments)
                                };
                            })
                        }
                    />
                    <div
                        data-testid="end-of-attachments"
                        style={{float: 'left', clear: 'both'}}
                        ref={el => {
                            this.messagesEnd = el;
                        }}
                    />
                </Stack>
            </Modal>
        )
    }
}
