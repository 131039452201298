import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { openModal } from '@cjdev-internal/visual-stack-redux';
import { formatDate, formatDistance, sortTestsNewest } from '../../utils';
import Loader from '../../components/Loader';
import CustomButton from '../../components/Button';
import { createArchModal } from '../../components/Modal';
import { ArchiveModalButton } from '../../components/Modal/ModalButton';
import * as T from '../../components/Table';
import TextLink from '../../components/TextLink';
import { resetArchiveTest, archiveAndLoad } from '../../domain/Tests/actions';

const Table = styled(T.Table)`
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
`;

const Td = styled(T.Td)`
  vertical-align: top;
`;

const ThName = styled(T.Th)`
  width: 60%;
`;
const ThUrls = styled(T.Th)`
  width: 15%;
`;
const ThDate = ThUrls;
const ThActions = ThUrls;

const ArchiveModalDialog = createArchModal('Archive Test', ['tests']);

export class TestsTable extends React.Component {
  render() {
    if (this.props.tests.loading) {
      return <Loader />;
    }
    const sortedTests = sortTestsNewest(this.props.tests.data);
    const { resetArchiveTest, archiveAndLoad } = this.props;

    return (
      <Table>
        <T.Thead>
          <T.Tr>
            <ThName nowrap>
              <b>Test Name</b>
            </ThName>
            <ThUrls right nowrap>
              <b>Total Urls</b>
            </ThUrls>
            <ThDate>
              <b>Date</b>
            </ThDate>
            <ThActions />
          </T.Tr>
        </T.Thead>
        <tbody>
          {sortedTests.map(test => (
            <T.Tr key={test.id}>
              <Td>
                <TextLink id={test.id} textLink={test.testName} path={'/test-results'} />
              </Td>
              <Td right>{test.totalUrls}</Td>
              <T.Td nowrap>
                <div>{formatDate(test.timeStamp)}</div>
                <div>{formatDistance(test.timeStamp)}</div>
              </T.Td>
              <T.Td nowrap>
                <CustomButton
                  iconType="file-text"
                  onClick={() => {
                    window.open(test.configS3Url, '_blank');
                  }}
                >
                  Config
                </CustomButton>
                <ArchiveModalButton
                  ModalDialog={ArchiveModalDialog}
                  itemName={test.testName}
                  takeAction={() => {
                    archiveAndLoad(test.id);
                  }}
                  onCancel={resetArchiveTest}
                />
              </T.Td>
            </T.Tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
export default connect(null, {
  openModal,
  resetArchiveTest,
  archiveAndLoad
})(TestsTable);
