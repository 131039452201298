import React from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import { AppLayout } from '../App';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack-x/components/Panel';
import { MediumTextArea, SubmitButtonWrapper } from '../../components/FormFields';
import SavedNotification from '../../components/Notification/SavedNotification';
import {
  submitSystemSettings,
  submitMasterUrls,
  getMasterUrlList
} from '../../domain/Settings/actions';
import { getTrimmedList } from '../../utils';
import { SimpleError } from '../../components/ErrorMessage';
import AdvertiserSetting from './AdvertiserSetting';
import { getAdvertiser, saveAdvertiser, removeAdvertiser } from '../../shared/advertiserStorage';
import MasterUrls from './MasterUrls';
import R from 'ramda';
import { getSystemSettings } from '../../shared/apis'

const NotificationButton = styled.div`
  display: flex;
  align-items: center;
`;

const SubmitError = styled.div`
  margin-left: 10px;
`;

export const InnerForm = ({
  values,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  status,
  errors
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <MediumTextArea
        input={{
          value: values.offerLinkAttributes,
          name: 'offerLinkAttributes'
        }}
        label="Offer Link Attributes"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <NotificationButton>
        <SubmitButtonWrapper loading={isSubmitting} disabled={isSubmitting}>
          Submit
        </SubmitButtonWrapper>
        {errors && errors.submitError ? (
          <SubmitError>
            <SimpleError error={errors.submitError} />
          </SubmitError>
        ) : (
          status && status.saved && <SavedNotification />
        )}
      </NotificationButton>
    </form>
  );
};

export const AttributeForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    return { offerLinkAttributes: props.offerLinkAttributes };
  },
  handleSubmit: async (values, { props, setSubmitting, setStatus, setFieldError }) => {
    const response = await props.submitForm({
      offerLinkAttributes: getTrimmedList(values.offerLinkAttributes)
    });
    if (response.code !== 200) {
      setFieldError('submitError', 'Submit failed');
    } else {
      setStatus({ saved: true });
      props.timeout(
        () =>
          setStatus({
            saved: false
          }),
        1000
      );
    }
    setSubmitting(false);
  }
})(InnerForm);

export default class Settings extends React.Component {
  constructor() {
    super();
    this.state = {
      offerLinkAttributes: '',
      advertiserId: null,
      masterUrls: []
    };
  }

  componentDidMount = async () => {
    const systemSettings = await getSystemSettings();
    const masterUrls = await getMasterUrlList();
    this.setState({
      offerLinkAttributes: systemSettings.offerLinkAttributes.join('\n'),
      advertiserId: R.view(R.lensPath(['id']), getAdvertiser()),
      masterUrls: masterUrls
    });
  };

  storeAdvertiser = id => {
    this.setState({ advertiserId: id });
    saveAdvertiser({ id });
  };

  onClear = () => {
    this.setState({ advertiserId: null });
    removeAdvertiser();
  };
  render() {
    return (
      <AppLayout pageHeaderTitle="Settings">
        <Panel>
          <PanelBody>
            <AttributeForm
              timeout={setTimeout.bind()}
              submitForm={submitSystemSettings}
              offerLinkAttributes={this.state.offerLinkAttributes}
            />
            <AdvertiserSetting
              advertiserId={this.state.advertiserId}
              onSave={this.storeAdvertiser}
              onClear={this.onClear}
            />
            <MasterUrls urls={this.state.masterUrls || []} submitMasterUrls={submitMasterUrls} />
          </PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}
