import React from 'react';
import {Button} from '@cjdev-internal/visual-stack-x/components/Button'
import {Row} from '@cjdev-internal/visual-stack-x/components/Row'

export const FormContainer = ({children}) => (
  <Row align='center'>{children}</Row>
)


export const SlidingPanelControl = ({ clearAll, resetForm }) => {
  return (
    <Row gap="medium" padding="medium-large">
      <Button style={{width: '50%'}} data-testid="sliding-apply" htmlType="submit" type="primary">Apply</Button>
      <Button style={{width: '50%'}}
        type="tertiary"
        data-testid="clear-button"
        onClick={() => {
          clearAll();
          resetForm({});
        }}
      >
        Clear
      </Button>
    </Row>
  );
};
