import { combineReducers } from 'redux';
import { STORE_WEBSITE_DATA, FAIL_FETCH_WEBSITE_DATA, LOADING_FETCH_WEBSITE_DATA } from './actions';
import * as R from 'ramda';

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case LOADING_FETCH_WEBSITE_DATA:
      return true;
    case FAIL_FETCH_WEBSITE_DATA:
    case STORE_WEBSITE_DATA:
      return false;
    default:
      return state;
  }
};

const dataReducer = (state = [], { type, payload }) => {
  switch (type) {
    case STORE_WEBSITE_DATA:
      return payload;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type }) => {
  switch (type) {
    case FAIL_FETCH_WEBSITE_DATA:
      return 'failed to fetch';
    case LOADING_FETCH_WEBSITE_DATA:
      return null;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  isLoading: loadingReducer,
  data: dataReducer,
  error: errorReducer
});

export const selectWebsites = state => state.data;
export const selectWebsiteMap = state => {
  let websiteMap = {};
  R.forEach(annotatedWebsite => {
    websiteMap[annotatedWebsite.websiteId] = annotatedWebsite;
  }, state.data);
  return websiteMap;
};

export const selectWebsiteDropdownOptions = ({ data }) => {
  return data.map(({ websiteId, websiteName }) => ({
    label: `${websiteName} - ${websiteId}`,
    value: websiteId
  }));
};

export const selectWebsiteDropdownOptionsWithDomainName = ({ data }) => {
  return data.map(({ websiteId, domainName }) => ({
    label: domainName,
    value: websiteId
  }));
};
