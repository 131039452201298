import styled, { keyframes } from 'styled-components';

const faded = keyframes`
  from {
    opacity: 1
  }

  to {
    opacity:0
  }
`;

export default styled.div`
  margin-left: 10px;
  color: ${props => props.color || '#4caa50'};
  font-size: 14px;
  animation: ${faded} 1s ease-out forwards;
`;
