import { combineReducers } from 'redux';
import {
  STORE_PUBLISHER_DATA,
  FAIL_FETCH_PUBLISHER_DATA,
  LOADING_FETCH_PUBLISHER_DATA
} from './actions';

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case LOADING_FETCH_PUBLISHER_DATA:
      return true;
    case FAIL_FETCH_PUBLISHER_DATA:
    case STORE_PUBLISHER_DATA:
      return false;
    default:
      return state;
  }
};

const dataReducer = (state = [], { type, payload }) => {
  switch (type) {
    case STORE_PUBLISHER_DATA:
      return payload;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type }) => {
  switch (type) {
    case FAIL_FETCH_PUBLISHER_DATA:
      return 'failed to fetch';
    case LOADING_FETCH_PUBLISHER_DATA:
      return null;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  isLoading: loadingReducer,
  data: dataReducer,
  error: errorReducer
});


export const selectPublisherDropdownOptions = ({ data }) => {
  return data.map(({ cid, name }) => ({
    label: `${name} - ${cid} `,
    value: cid
  }));
};
