import React from 'react';
import { logout } from './authController';
import { logout as platformLogout, shouldUseAuth0 } from '../../shared/platformAuth';
import {history, withNavigate} from '../../router'

class Logout extends React.Component {
  componentDidMount = async () => {
    const isUsingAuth0 = await shouldUseAuth0();
    if (isUsingAuth0) {
      await platformLogout();
    } else {
      logout();
      history.push('/login');
      this.props.navigate('/login');
    }
  };

  render = () => null;
}

export default withNavigate(Logout)
