import React from 'react';
import { AppLayout } from '../App';
import ReactMarkdown from 'react-markdown';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack-x/components/Panel';
import './index.css';
import csDocFile from './conditional-statement-doc.md';
import csExampleFile from './conditional-statement-example.md';
import styled from 'styled-components';

const SideBySideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  & > .right-panel {
    margin-left: 5px;
  }
  @media (max-width: 900px) {
    display: block;
    & > .right-panel {
      margin-left: 0px;
    }
  }
  & > div {
    flex: 1;
  }
`;

export default class Help extends React.Component {
  constructor() {
    super();
    this.state = {
      csDocText: 'Loading...',
      csExampleText: 'Loading...'
    };
  }

  componentDidMount() {
    fetch(csDocFile)
      .then(response => response.text())
      .then(text => {
        this.setState({
          csDocText: text
        });
      });
    fetch(csExampleFile)
      .then(response => response.text())
      .then(text => {
        this.setState({
          csExampleText: text
        });
      });
  }
  render() {
    const csDoc = this.state.csDocText;
    const csExampleFile = this.state.csExampleText;

    return (
      <AppLayout pageHeaderTitle="Conditional Statement Help">
        <SideBySideContainer>
          <Panel>
            <PanelBody>
              <ReactMarkdown source={csExampleFile} className="md" />
            </PanelBody>
          </Panel>
          <div className="right-panel">
            <Panel>
              <PanelBody>
                <ReactMarkdown source={csDoc} className="md" />
              </PanelBody>
            </Panel>
          </div>
        </SideBySideContainer>
      </AppLayout>
    );
  }
}
