import React from 'react';
import { getAuthRedirectUri } from './cjAuth';
import * as R from 'ramda';

export default class Login extends React.Component {
  componentDidMount = () => {
    const redirectPath = R.view(
      R.lensPath(['history', 'location', 'state', 'redirect']),
      this.props
    );
    const query = R.view(
      R.lensPath(['history', 'location', 'state', 'from', 'search']),
      this.props
    );
    window.location.href = getAuthRedirectUri(`${redirectPath || ''}${query || ''}` || '/');
  };

  render = () => {
    return null;
  };
}
