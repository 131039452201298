import styled from 'styled-components';

export const ItemTable = styled.table`
  width: 100%;
`;

export const Tr = styled.tr`
  &:not(:first-child) {
    border-top: 1px dashed #ddd;
  }
`;

export const Td = styled.td`
  text-align: left;
  padding: 5px 6px 5px 0;
  width: ${props => props.width || '1px'};
  white-space: ${props => (props.nowrap ? 'nowrap' : 'inherit')};
`;

export const CounterSpan = styled.span`
  color: #999;
`;
