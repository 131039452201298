import React from 'react';
import styled from 'styled-components';
import { Button } from '@cjdev-internal/visual-stack-x/components/Button';
import Spinner from '../../components/Spinner';
import * as R from 'ramda';

const CASButton = styled(Button)`
  border-width: 1px;
  border-radius: 2px;
`;

export const SpacedButton = styled(CASButton)`
  margin-left: 5px;
`;

export const StyledButton = styled(SpacedButton)`
  ${({ disabled }) => disabled && 'background: rgba(55, 71, 79, 0.1);'};
  border: 1px solid rgba(55, 71, 79, 0.3);
  color: rgba(55, 71, 79, 0.7);
  &:hover {
    ${({ disabled }) => disabled && 'cursor: not-allowed'};
    background: rgba(55, 71, 79, 0.1);
  }
`;

const StyledBackButton = styled.span`
  color: #c7c7c7;
  cursor: pointer;
`;

export const BorderlessCancelButton = props => (
  <StyledBackButton {...props}>Cancel</StyledBackButton>
);

export const ButtonText = styled.span`
  padding-left: 5px;
`;

export const CustomButton = ({ onClick, iconType, children, disabled }) => {
  const hasIcon = !R.isNil(iconType);
  const buttonTextWithIcon = children && <ButtonText>{children}</ButtonText>;
  const buttonTextWithoutIcon = children && <span>{children}</span>;
  const buttonText = hasIcon ? buttonTextWithIcon : buttonTextWithoutIcon;
  return (
    <StyledButton disabled={disabled} type="secondary" onClick={onClick}>
      <i className={`fa fa-${iconType}`} />
      {buttonText}
    </StyledButton>
  );
};

const CanceledButton = styled(StyledButton)`
  background: rgba(55, 71, 79, 0.1);
`;

export const ExportButton = ({ onClick }) => (
  <Button type="secondary" onClick={onClick}>
    <i className={`fa fa-file-excel-o`} /> Export
  </Button>
);

export const EditButton = ({ onClick }) => (
  <CustomButton onClick={onClick} iconType="file-text">
    Edit
  </CustomButton>
);

export const ArchiveButton = ({ onClick }) => (
  <CustomButton onClick={onClick} iconType="archive">
    Archive
  </CustomButton>
);
export const CancelTestButton = ({ onClick, text }) => (
  <CustomButton onClick={onClick} iconType="window-close">
    {text}
  </CustomButton>
);

export const CanceledTestUnclickableButton = () => (
  <CanceledButton type="info" disabled>
    Canceled
  </CanceledButton>
);

export const CreateButton = ({ onClick }) => <CustomButton onClick={onClick} iconType="plus" />;

export const RunButton = ({ onClick, disabled }) => (
  <CustomButton onClick={onClick} iconType="play" disabled={disabled}>
    Run
  </CustomButton>
);

export const SpinnerButton = ({ message }) => (
  <CustomButton>
    <Spinner />
    {message}
  </CustomButton>
);

export const CancelButton = ({ onClick }) => <CustomButton onClick={onClick}>Cancel</CustomButton>;
export const CloseButton = ({ onClick, disabled }) => (
  <CustomButton disabled={disabled} onClick={onClick}>
    Close
  </CustomButton>
);

export default CustomButton;
