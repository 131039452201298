import * as R from 'ramda';
import {archiveIssue, submitIssue} from '../services';
import {COMPLIANCE_VIOLATIONS, COMPLIANCE_VIOLATIONS_DETAIL} from '../../../paths';
import {navigateTo} from '../../../utils';
import moment from "moment-timezone";

export const getFullName = issueReporter => {
    const {firstName, lastName} = issueReporter || {};
    const fullName = firstName && lastName && `${lastName} ${firstName}`;
    return fullName;
};

export const checkIfUndefinedOrEmpty = value => {
    return !R.isNil(value) && !R.isEmpty(value)
};

export const getValue = value => {
    return checkIfUndefinedOrEmpty(value) ? formatDateInLocalTimeZone(value) : 'No Value';
};

export const getOverrideOrValue = (override, value) => {
    return R.isNil(override) ? value : override;
};

export const dispatchUpdate = () => {
    const event = new CustomEvent('UPDATE_ISSUE_VIEW');
    document.dispatchEvent(event);
}

export const submitAndUpdate = async (issue) => {
    const {status} = await submitIssue(issue);
    await dispatchUpdate();
    return status === 200;
}

export const onDuplicateIssue = async (issue) => {
    const {response} = await submitIssue(issue);
    return response;
}

export const onArchiveIssue = async (issueId) => {
    await archiveIssue(issueId);
}

export const toIssueList = (navigate, history, platformNav) => {
    navigateTo(COMPLIANCE_VIOLATIONS, navigate, history, platformNav);
}

export const toIssueDetail = (issueId, navigate, history, platformNav) => {
    const pathDetail = COMPLIANCE_VIOLATIONS_DETAIL.replace(':complianceViolationId', issueId);
    navigateTo(pathDetail, navigate, history, platformNav);
}

export const toBackList = (navigate, history, platformNav) => {
    let to = COMPLIANCE_VIOLATIONS;
    if (history.location.search !== '') {
        to += `${history.location.search}`;
    }
    navigateTo(to, navigate, history, platformNav)
}

const formatDateInLocalTimeZone = (date) => {
    const localTimeZone = moment.tz.guess();
    return moment(date).tz(localTimeZone).format('YYYY-MM-DD hh:mm A z');
}
