import * as Actions from './actions';

const initialState = {
  data: [],
  hasError: false,
  runConfig: {
    running: false,
    error: null
  },
  archive: {
    testConfigId: null,
    loading: false,
    error: null
  }
};

export const selectTestConfig = state => state;

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_TEST_CONFIG:
      return { ...state, data: action.payload };
    case Actions.FETCH_TEST_CONFIG_FAILURE:
      return { ...state, hasError: true };
    case Actions.TEST_CONFIG_ARCHIVE_RESET:
      return {
        ...state,
        archive: { testConfigId: null, loading: false, error: null }
      };
    case Actions.TEST_CONFIG_ARCHIVE_START:
      return {
        ...state,
        archive: { testConfigId: action.payload, loading: true, error: null }
      };
    case Actions.TEST_CONFIG_ARCHIVE_SUCCESS:
      return {
        ...state,
        archive: { testConfigId: null, loading: false, error: null }
      };
    case Actions.TEST_CONFIG_ARCHIVE_FAIL:
      return {
        ...state,
        archive: { testConfigId: null, loading: false, error: action.payload }
      };
    case Actions.MODAL_RESET:
      return { ...state, runConfig: { running: false, error: null } };
    case Actions.RUN_TEST_CONFIG_START:
      return { ...state, runConfig: { running: true, error: null } };
    case Actions.RUN_TEST_CONFIG_COMPLETE:
      return { ...state, runConfig: { running: false, error: null } };
    case Actions.RUN_TEST_CONFIG_FAILURE:
      return { ...state, runConfig: { running: false, error: action.payload } };
    default:
      return state;
  }
};
