import {
  TESTS_LOAD_FAIL,
  TESTS_STORE,
  TESTS_LOAD_START,
  TEST_ARCHIVE_START,
  TEST_ARCHIVE_SUCCESS,
  TEST_ARCHIVE_FAIL,
  TEST_ARCHIVE_RESET,
  TEST_CANCEL_FAIL,
  TEST_CANCEL_START,
  TEST_CANCEL_SUCCESS,
  TEST_CANCEL_RESET
} from './actions';
import { getAdvertiser } from '../../shared/advertiserStorage';
import R from 'ramda';

const initialState = {
  data: [],
  error: null,
  loading: false,
  archive: {
    testId: null,
    loading: false,
    error: null
  },
  cancel: {
    testId: null,
    loading: false,
    error: null
  }
};
export const selectTestsForAdvertiser = state => {
  const tests = state.data;
  const advertiser = getAdvertiser();
  return advertiser
    ? R.assoc(
        'data',
        tests.filter(test => test.advertiserId === advertiser.id),
        state
      )
    : state;
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TESTS_LOAD_START:
      return {
        ...state,
        data: [],
        error: null,
        loading: true
      };
    case TESTS_STORE:
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false
      };
    case TESTS_LOAD_FAIL:
      return {
        ...state,
        data: [],
        error: action.payload,
        loading: false
      };
    case TEST_ARCHIVE_START:
      return {
        ...state,
        archive: {
          testId: action.payload,
          loading: true,
          error: null
        }
      };
    case TEST_ARCHIVE_SUCCESS:
      return {
        ...state,
        archive: {
          testId: null,
          loading: false,
          error: null
        }
      };
    case TEST_ARCHIVE_FAIL:
      return {
        ...state,
        archive: {
          loading: false,
          error: action.payload
        }
      };
    case TEST_ARCHIVE_RESET:
      return {
        ...state,
        archive: {
          testId: null,
          loading: false,
          error: null
        }
      };
    case TEST_CANCEL_START:
      return {
        ...state,
        cancel: {
          testId: action.payload,
          loading: true,
          error: null
        }
      };
    case TEST_CANCEL_SUCCESS:
      return {
        ...state,
        cancel: {
          testId: null,
          loading: false,
          error: null
        }
      };
    case TEST_CANCEL_FAIL:
      return {
        ...state,
        cancel: {
          testId: null,
          loading: false,
          error: action.payload
        }
      };
    case TEST_CANCEL_RESET:
      return {
        ...state,
        cancel: {
          testId: null,
          loading: false,
          error: null
        }
      };
    default:
      return state;
  }
};
