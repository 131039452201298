import React from 'react';
import UrlGroupForm from './UrlGroupForm';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';
import { useNavigate } from 'react-router-dom'
import {history} from "../../router";

export default props => {
  const navigate = useNavigate()
  return <>
    <UrlGroupForm
      title="Create URL Group"
      submitUrlGroup={async urlGroup => {
        await authenticatedFetch(`${apiServer()}/url-groups`, {
          method: 'post',
          body: JSON.stringify(urlGroup)
        });
        history.push('/url-groups');
        navigate('/url-groups');
      }}
    />
  </>
}
