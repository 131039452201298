import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';

export const STORE_FETCHING_VIOLATION_TYPE_MAPPINGS =
  'src/actions/STORE_FETCHING_VIOLATION_TYPE_MAPPINGS';

export const storeViolationTypeMappings = createAction(STORE_FETCHING_VIOLATION_TYPE_MAPPINGS);

const START_FETCHING_VIOLATION_TYPE_MAPPINGS = 'src/actions/START_FETCHING_VIOLATION_TYPE_MAPPINGS';

export const startFetchingViolationTypeMappings = createAction(
  START_FETCHING_VIOLATION_TYPE_MAPPINGS
);

export const fetchViolationTypeMappings = async advertiserId => {
  let response;
  if (!advertiserId) {
    response = await authenticatedFetch(`${apiServer()}/violation-type-mappings`);
  } else {
    response = await authenticatedFetch(
      `${apiServer()}/violation-type-mappings?advertiserIds=[${advertiserId}]`
    );
  }
  return response.json();
};

export const initializeViolationTypeMapping = () => async (dispatch, getState) => {
  dispatch(startFetchingViolationTypeMappings());
  const violationTypeMapping = await fetchViolationTypeMappings();
  dispatch(storeViolationTypeMappings(violationTypeMapping));
};
