let am4Core = {};
let am4Charts = {};

if (process.env.NODE_ENV !== 'test') {
  am4Core = require('@amcharts/amcharts4/core');
  am4Charts = require('@amcharts/amcharts4/charts');
}

export const am4core = am4Core;
export const am4charts = am4Charts;
