import React from 'react';
import Lowlight from 'react-lowlight';
import 'highlight.js/styles/default.css';
import { SectionRow, SectionRowTitle, SubSection } from '../../components/Section';
import './Highlighter.css';

const TextCaptureRow = ({ highlights }) => {
  if (!highlights || !highlights.capture) return null;
  const { capture, languageId, languageDefinition } = highlights;
  Lowlight.registerLanguage(languageId, languageDefinition);
  return (
    <SubSection>
      <SectionRow>
        <SectionRowTitle>Text Capture</SectionRowTitle>
        {capture.text && <Lowlight language={languageId} value={capture.text} />}
      </SectionRow>
    </SubSection>
  );
};

export default TextCaptureRow;
