import React, {useEffect, useRef, useState} from 'react';
import {Row} from '@cjdev-internal/visual-stack-x/components/Row';
import {Button} from '@cjdev-internal/visual-stack-x/components/Button';
import {Modal as VSXModal} from '@cjdev-internal/visual-stack-x/components/Modal';
import {sendMail} from "../services";
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import {dispatchUpdate} from "../helpers/dataHelpers";

export const EmailNotificationModal = ({
                                           issue,
                                           vsxCloseModal,
                                           publisherContact,
                                           EmailContent,
                                           networkQualityContact,
                                           advertiserContact,
                                           additionalNotes,
                                           publisherName,
                                           issueCategory,
                                           issueDescription,
                                           show,
                                           initialRetryCount
                                       }) => {
    let publisherEmails = publisherContact;
    const [isDisabled, setIsDisabled] = useState(true);

    const [tryAgain, setTryAgain] = useState(false);
    const MAX_RETRY = 2;
    const retryCount = useRef(initialRetryCount);
    let sentMail = false;
    const showMessageSuccess = () => show({
        message: 'Violation has been updated and notification was successfully sent',
        contentStyle: {textAlign: "center", minWidth: "300px"},
        type: "success",
        duration: '15000'
    })

    const showMessageFailure = () => show({
        message: 'Violation has been updated but notification was not sent successfully',
        contentStyle: {textAlign: "center", minWidth: "300px"},
        type: "warning",
        duration: '15000'
    })

    const issueWithContacts = {
        ...issue,
        publisherContacts: publisherEmails.replace(" ",""),
        networkQualityContacts: networkQualityContact.replace(" ",""),
        advertiserContacts: advertiserContact.replace(" ",""),
        additionalNotes: additionalNotes
    }

    const [updatedIssue, setUpdatedIssue] = useState({
        issues: [issueWithContacts],
        advertiserName: "",
        issuePrefix: "",
        mailNotify: false,
        publisherName: publisherName,
        issueCategory: issueCategory,
        issueDescription: issueDescription
    });

    useEffect(() => {
        if(publisherContact.trim() !== "")
        {
            setIsDisabled(false);
        }
    },[]);

    const sendNotifyMail = async (updatedIssue) => {
        setIsDisabled(true);
        updatedIssue = {...updatedIssue, mailNotify: true}
        const response = await sendMail(updatedIssue);
        if(response.status === 200) {
            const responseBody = JSON.parse(await response.json());
            if (responseBody.statusCode === 200) {
                vsxCloseModal();
                showMessageSuccess();
                await dispatchUpdate();
                sentMail = true;
            } else {
                sentMail = false;
                setTryAgain(true);
            }
        }
        else {
            setTryAgain(true)
        }
        setIsDisabled(false);
    }

    const handleSendButtonRetryClick = async (updatedIssue) => {
        if (retryCount.current <= MAX_RETRY) {
            await sendNotifyMail(updatedIssue);
            if(retryCount.current === MAX_RETRY && sentMail === false)
            {
                vsxCloseModal();
                showMessageFailure();
                await dispatchUpdate();
            }
            retryCount.current = retryCount.current + 1;
        }
    };

    const closeModal = async (updatedIssue) => {
        updatedIssue = {...updatedIssue, mailNotify: false}
        await sendMail(updatedIssue);
        vsxCloseModal();
        await dispatchUpdate();
    }

    const handleUpdateIssue = (updatedIssueWithUpdatedContacts) => {
        updatedIssueWithUpdatedContacts.publisherContacts = updatedIssueWithUpdatedContacts.publisherContacts.replace(" ","");
        updatedIssueWithUpdatedContacts.advertiserContacts = updatedIssueWithUpdatedContacts.advertiserContacts.replace(" ","");
        updatedIssueWithUpdatedContacts.networkQualityContacts = updatedIssueWithUpdatedContacts.networkQualityContacts.replace(" ","");
        setUpdatedIssue(prevIssue => ({...prevIssue, issues: [updatedIssueWithUpdatedContacts]}));
        if (updatedIssueWithUpdatedContacts.publisherContacts.trim() !== "") {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };

    const modalEmailNotificationProps = {
        headerTitle: `Send Violation Notification`,
        headerContent: (
            <Text size={14} weight="regular" color="secondary"><br/>Review and update recipients for violation notices and comments related to this issue.
                Use a comma between email addresses in a group. Press "Send" to initiate the violation notice and resolution request.</Text>),
        body: <EmailContent publisherContact={publisherEmails} networkQualityContact={networkQualityContact}
                            issue={issueWithContacts} onUpdateIssue={handleUpdateIssue}
                            advertiserContact={advertiserContact} additionalNotes={additionalNotes} tryAgain={tryAgain}
        />,
        footer: (
            <Row gap='medium' justify='end'>
                <Button type="tertiary" onClick={() => closeModal(updatedIssue)}>
                    DO NOT SEND
                </Button>
                <Button type="primary" disabled={isDisabled} onClick={() => handleSendButtonRetryClick(updatedIssue)}>
                    SEND
                </Button>
            </Row>
        ),
    };

    const modalEmailNotificationPropsForResolve = {
        headerTitle: `Send Violation Update`,
        body: <EmailContent publisherContact={publisherEmails} networkQualityContact={networkQualityContact}
                            issue={issueWithContacts} onUpdateIssue={handleUpdateIssue}
                            advertiserContact={advertiserContact} additionalNotes={additionalNotes} tryAgain={tryAgain}/>,
        footer: (
            <div>
                <div>{isDisabled && issueWithContacts.publisherContacts.trim() === "" ? (
                    <Text size={12}>*Publisher contact is empty. Resolution email cannot be sent.*</Text>
                ) : null}
                </div>
                <Row gap='medium' justify='end'>
                    <Button type="tertiary" onClick={() => closeModal(updatedIssue)}>
                        DO NOT SEND
                    </Button>
                    <Button type="primary" disabled={isDisabled} onClick={() => handleSendButtonRetryClick(updatedIssue)}>
                        SEND
                    </Button>
                </Row>
            </div>
        ),
    };

    return (
        <>
            {issue.status === 'In Progress' ?
                (<VSXModal {...modalEmailNotificationProps} />) : (
                    <VSXModal {...modalEmailNotificationPropsForResolve} />)}
        </>
    );
};
