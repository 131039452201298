import * as R from 'ramda';
import * as dateFns from 'date-fns';
import { normalizeIssue } from '../../../../domain/Issues/reducer';
import {getFullName, getValue} from "../../../IssuesDetail/helpers/dataHelpers";

export const formatDate = dateMillis => dateFns.format(dateMillis, 'YYYY-MM-DD HH:mm');

export const parseDate = date => dateFns.format(date, 'YYYY-MM-DD');

export const normalize = (issues, isEmployee, isPublisher) => {
  const generateDataForIssue = issue => {
    const getInternalData = () =>
      isEmployee
        ? {

            [`Start Time`]:
              issue.errorStartTime && getValue(formatDate(issue.errorStartTime))

          }
        : {};
    const getCreatorAndSourceData = () =>
    isEmployee
        ? {
          [`Record Creator`]: getFullName(issue.issueReporter),
          [`Source`]: issue.source
        }:{};

    const getInternalAndAdvertiserSpecificData = () =>
      !isPublisher
        ? {
            'Severity':
              R.prop('severityOverride', issue) ||
              R.view(R.lensPath(['violationTypeMapping', 'severity']), issue),
            'Points':
              !R.isNil(R.prop('errorPointsOverride', issue)) ? R.prop('errorPointsOverride', issue) :
              R.view(R.lensPath(['violationTypeMapping', 'errorPoints']), issue)
          }
        : {};

    return {
      'Violation ID': issue.fullIssueID,
      'Record Created': issue.creationTime && getValue(formatDate(issue.creationTime)),
      'Advertiser Name': issue.advertiserName,
      'Advertiser CID': issue.advertiserId,
      'Publisher Name': R.view(R.lensPath(['publisher', 'name']), issue),
      'Publisher CID': R.view(R.lensPath(['publisher', 'cid']), issue),
      'Property Name': R.view(R.lensPath(['website', 'name']), issue),
      'Property ID': R.view(R.lensPath(['website', 'pid']), issue),
      'Product(s)': R.join('\r\n')((issue.products || []).map(R.prop('name'))),
      'Location': issue.errorLocation,
      'Content Type': issue.contentType,
      'Audit Type': issue.auditType,
      'CPA Link?': issue.cpaLinkFound,
      'Category': R.view(R.lensPath(['violationTypeMapping', 'category']), issue),
      'Description': R.view(R.lensPath(['violationTypeMapping', 'description']), issue),
      ...getInternalAndAdvertiserSpecificData(),
      'Status': issue.status,
      ...getInternalData(),
      [`Detection Time`]:
        issue.detectionTime && getValue(formatDate(issue.detectionTime)),
      [`Link Block Time (if applicable)`]:
        issue.linkCutTime && getValue(formatDate(issue.linkCutTime)),
      [`Resolution Time`]:
        issue.resolutionTime && getValue(formatDate(issue.resolutionTime)),
      Notes: issue.errorNotes,
      ...getCreatorAndSourceData()
    };
  };

  return issues.map(normalizeIssue).map(generateDataForIssue);
};

export const normalizeComments = (issues, isPublisher) => {
  const getVisibilityText = (advVisibility, pubVisibility) => {
    const advFlag = advVisibility === 'true';
    const pubFlag = pubVisibility === 'true';

    let text = 'All Users';

    if (!advFlag && !pubFlag) {
      text = 'CJ Only';
    }

    if (advFlag && !pubFlag) {
      text = 'CJ and Advertisers';
    }

    return text;
  };

  const getMappedComments = (currentIssue, comment, isPublisher) => {
    const commentHeader = {
      'Violation ID': currentIssue.fullIssueID,
      Comment: comment.comment,
      'Comment by': comment.createdBy
          .split(',')
          .slice(1, -1)
          .join(','),
      'Comment Date': getValue(formatDate(comment.createdTime))
    }

    if (!isPublisher) {
      commentHeader['Visibility to'] = getVisibilityText(comment.advertiserVisibility, comment.publisherVisibility)
    }

    return commentHeader
  }
  return issues.reduce((prevRows, currentIssue) => {
    const mappingComments = comment => getMappedComments(currentIssue, comment, isPublisher)
    const newRows = currentIssue.comments.map(mappingComments);
    return [...prevRows, ...newRows];
  }, []);
};
