import {useState} from 'react';
import {
    SlidingPanel, SlidingPanelPage, SlidingPanelPageContent
} from "@cjdev-internal/visual-stack-x/SlidingPanel";
import {PageContent} from "@cjdev-internal/visual-stack-x/PageContent";
import {Row} from '@cjdev-internal/visual-stack-x/components/Row';
import {IconButton} from '@cjdev-internal/visual-stack-x/components/Button';
import {HoverPopover} from '@cjdev-internal/visual-stack-x/components/Popover';

export const IssueDetailSlidingPanel = ({Content, SlidingContent, Actions, isActions}) => {
    const [active, setActive] = useState(true);
    const toogleActive = () => {
        setActive(!active);
    }
    return (<SlidingPanelPage>
        <SlidingPanelPageContent>
            <PageContent>
                <Row style={{marginBottom: '10px'}} justify="end">
                    {isActions ? <Actions/> : null}
                    <HoverPopover tooltip={true} content={active ? 'Hide Activity' : 'Show Activity'}>
                        <IconButton icon="comment-text" data-testid="issue-detail-sliding-toogle"
                                    onClick={toogleActive}/>
                    </HoverPopover>
                </Row>
                <Content/>
            </PageContent>
        </SlidingPanelPageContent>
           <SlidingPanel active={active}>
            <SlidingContent onClose={toogleActive}/>
        </SlidingPanel>
    </SlidingPanelPage>)
}

const IssueDetailSlidingPanelDefault = IssueDetailSlidingPanel;

export default IssueDetailSlidingPanelDefault;