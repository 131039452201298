import React from 'react';
import { connect } from 'react-redux';
import { Body } from '@cjdev-internal/visual-stack-x/Panel';
import {
  selectRiskIdentificationTableHeaders,
  selectRiskIdentificationTableData,
  selectShouldRenderRiskIdentificationTable,
  selectRiskIdentificationCaption,
  selectRiskIdentificationDateRange
} from '../../../reducer';
import { clearRiskIdentificationTable } from '../../../domain/ViolationDashboard/RiskIdentifications/actions';
import { useSearchParams } from 'react-router-dom';
import { getDimension } from './routeHelper';
import * as dimensionRedirectHelperFactory from './dimensionRedirectHelperFactory';
import { DataGrid } from "@cjdev-internal/visual-stack-x/DataGrid";
import {renderTrend} from "../../../domain/ViolationDashboard/RiskIdentifications/trend";
import  TopPanel from "./TopPanel";

export const RiskIdentificationTablePure = ({
                                              columns,
                                              data,
                                              caption,
                                              shouldRenderRiskIdentificationTable,
                                              dateRange,
                                            }) => {
  const [searchParams] = useSearchParams()
  const dimension = getDimension(searchParams.toString());
  const dimensionRedirectHelper = dimensionRedirectHelperFactory.create(dimension);

  if(data===undefined)
    data=[];
  if(columns===undefined)
    columns=[];
  let headings = columns
  const result = data.map((row, index) => {
    const obj = {'id':index+1};
    for (let i = 0; i < headings.length; i++) {
      obj[(headings[i]).label] = row[i];
    }
    return obj;
  });

  let columnDefs= []
  columns.forEach((column) => {
    if(column.renderCell!==undefined) {
      columnDefs.push({
        field: column.label,
        headerName: column.label,
        suppressAutoSize: true,
        flex: 1,
        width: 150,
        minWidth: 100,
        resizable: true,
        headerTooltip: column.label,
        cellRenderer:(props) =>{
          return renderTrend(parseInt(props.data[columns[8].label]));
        },
      });
    }else{
      columnDefs.push({
        field: column.label,
        headerName: column.label,
        suppressAutoSize: true,
        flex: 1,
        width: 150,
        minWidth: 100,
        resizable: true,
        headerTooltip: column.label,
      });
    }
  });

  const TopPanelComponent =()=> {
    return <TopPanel caption={caption} dateRange={dateRange} dimensionRedirectHelper={dimensionRedirectHelper}
                     dimension={dimension} data={data} columns={columns}/>
  }
  return shouldRenderRiskIdentificationTable ? (
      <Body data-testid={'risk-identification-table'}>
        <div style={{height: "590px"}}>
          <DataGrid
              title={caption}
              rowData={result}
              columnDefs={columnDefs}
              pagination
              topPanel={TopPanelComponent}
              paginationSizes={[10,25,50]}
              paginationPageSize={10}
              getRowId={(dat) => dat.id}
          />
        </div>
      </Body>
  ) : null;
};

const mapStateToProps = state => ({
  columns: selectRiskIdentificationTableHeaders(state),
  data: selectRiskIdentificationTableData(state),
  shouldRenderRiskIdentificationTable: selectShouldRenderRiskIdentificationTable(state),
  caption: selectRiskIdentificationCaption(state),
  dateRange: selectRiskIdentificationDateRange(state)
});

const mapDispatchToProps = {
  clearRiskIdentificationTable
};
export default
connect(mapStateToProps, mapDispatchToProps)(RiskIdentificationTablePure)
;
