import {useEffect, useRef} from 'react';

const useEventListener = (eventName, handler, target = document) => {
    
    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const listener = (event) => savedHandler.current(event);

        target.addEventListener(eventName, listener);

        return () => {
            target.removeEventListener(eventName, listener);
        };
    }, [eventName, target]);
};

export default useEventListener;
