import React from "react";
import { connect } from "react-redux";
import R from "ramda";
import { SimpleError } from "../../components/ErrorMessage";
import { Modal } from "@cjdev-internal/visual-stack-x/components/Modal";
import { closeModal } from "@cjdev-internal/visual-stack-redux";
import {
  RunButton,
  CancelButton,
  SpinnerButton,
} from "../../components/Button";
import { resetModal, runTestConfig } from "../../domain/TestConfigs/actions";
import { PaddedLabel, TextInput } from "../../components/FormFields";
import { Row } from "@cjdev-internal/visual-stack-x/components/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/components/Stack";
import { Text } from "@cjdev-internal/visual-stack-x/Text";

export class RunTestConfigModal extends React.Component {
  constructor() {
    super();
    this.state = {
      testName: "",
      lookback: "",
    };
  }

  componentDidMount() {
    this.setState({
      testName: this.props.testConfig.configName,
      lookback: "24",
    });
  }

  componentWillReceiveProps(nextProps) {
    const stoppedLoading =
      this.props.runConfig.running && !nextProps.runConfig.running;
    const error = nextProps.runConfig.error;
    if (stoppedLoading && R.isNil(error)) {
      this.props.closeModal();
    }
  }

  onInputChange(event) {
    this.setState({
      testName: event.target.value,
    });
  }

  onDropDownChange(event) {
    this.setState({
      lookback: event.target.value,
    });
  }

  onSubmit() {
    const { testConfig, runTestConfig } = this.props;
    const { testName, lookback } = this.state;
    runTestConfig({ ...testConfig, configName: testName }, lookback);
  }

  render() {
    const {
      closeModal,
      runConfig,
      resetModal,
      testConfig: { urlGroups = [], urlsCount = 0 },
    } = this.props;
    const shouldDisableRunButton = urlGroups.length + urlsCount === 0;

    const footer = (
      <Stack gap="small">
        <Text style={{textAlign: 'left'}}>
          Please note that some tests will not show up immediately in the Test
          Results page. Please contact{" "}
          <a href="mailto:CJ_AutomationSquad@conversantmedia.com">
            Automation Squad
          </a>{" "}
          if the test does not show up within 10 minutes.
        </Text>
        <Row gap="small" justify="end">
          {runConfig.error && <SimpleError error={runConfig.error} />}
          {shouldDisableRunButton && (
            <SimpleError error="No URLs or URL groups found in test configuration." />
          )}
          {runConfig.running ? (
            <SpinnerButton message={"Running..."} />
          ) : (
            <RunButton
              onClick={() => this.onSubmit()}
              disabled={shouldDisableRunButton}
            />
          )}
          <CancelButton
            onClick={() => {
              resetModal();
              closeModal();
            }}
          />
        </Row>
      </Stack>
    );

    return (
      <Modal headerTitle="Run Test Configuration" footer={footer}>
        <Stack>
          <PaddedLabel>Test Name</PaddedLabel>
          <TextInput
            onChange={(event) => this.onInputChange(event)}
            value={this.state.testName}
            width={"50%"}
          />
          <PaddedLabel>Use Existing Scrapes From</PaddedLabel>
          <select
            value={this.state.lookback}
            onChange={(event) => this.onDropDownChange(event)}
          >
            <option value="36">Last 36 hours</option>
            <option value="24">Last 24 hours (default)</option>
            <option value="18">Last 18 hours</option>
            <option value="12">Last 12 hours</option>
            <option value="8">Last 8 hours</option>
            <option value="4">Last 4 hours</option>
            <option value="2">Last 2 hours</option>
            <option value="1">Last 1 hours</option>
            <option value="">Don't reuse existing scrapes</option>
          </select>
        </Stack>
      </Modal>
    );
  }
}

export const mapStateToProps = (state) => ({
  runConfig: state.CAS.testConfig.runConfig,
});

export const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  resetModal: () => dispatch(resetModal()),
  runTestConfig: (testConfig, lookback) =>
    dispatch(runTestConfig(testConfig, lookback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RunTestConfigModal);
