import React, {useEffect} from "react";
import useEventListener from "../../shared/hooks/useEventListener";
import {setCurrentCompany} from "../../shared/userStorage";
import {useAdvertiserAndDateFilter, usePlatformNav, useUnauthorized} from "../../appRunner";
import DashboardViewController from "../Dashboard/DashboardViewController";
import {useNavigate, useSearchParams} from "react-router-dom";
import {LargeSpinner} from "../../components/Spinner";
import {Row} from "@cjdev-internal/visual-stack-x/components/Row";
import IssueViewController from "../Issues/issueViewController";
import {initializeOrUpdateUser} from "../IssuesDetail/services";

export const RouteContent = ({user, Component, Unauthorized}) => {
    const {returnValue, setReturnValue} = useUnauthorized();
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const dashboardViewController = new DashboardViewController(navigate, searchParams.toString())
    const {
        advertiserFilter,
        setAdvertiserFilter,
        dateFilter,
        setPublisherFilter,
        publisherFilter
    } = useAdvertiserAndDateFilter();
    const platformNav = usePlatformNav()
    let publisher = false;
    const issueViewController = new IssueViewController(navigate, searchParams);
    useEffect(() => {
        if (platformNav) {
            publisher = user.currentCompany.type === 'publisher';
        }
        if (user) {
            if (!publisher && advertiserFilter === null) {
                dashboardViewController.changeAdvertiser(user.currentCompany.id);
                window.localStorage.setItem('advertisers', JSON.stringify([user.currentCompany.id]));
            }
            if (publisher && (publisherFilter === undefined || publisherFilter === null)) {
                dashboardViewController.changePublisher(user.currentCompany.id);
            }
            setCurrentCompany(user);
        }
        initializeOrUpdateUser();
    }, [user.currentCompany]);

    const currentUrl = window.location.href;

    useEffect(() => {
        if (dateFilter) {
            if (currentUrl.includes("/compliance-violations")) {
                const regex = /\/compliance-violations\/([a-zA-Z0-9-]+)/;
                const match = currentUrl.match(regex);
                if (!match) {
                    issueViewController.changeDateRange([dateFilter[0], dateFilter[1]])
                }
            } else {
                dashboardViewController.changeDateRange([dateFilter[0], dateFilter[1]]);
            }
        }
    }, [dateFilter])

    const authorisedUser = () => {
        setReturnValue("authorized");
    };
    useEventListener('authorized', authorisedUser);

    const unauthorisedUser = () => {
        setAdvertiserFilter(null);
        setPublisherFilter(undefined);
        setReturnValue("unauthorized");
    };
    useEventListener('unauthorized', unauthorisedUser);

    // Render content based on unauthorized check
    if (returnValue === "authorized") {
        return Component;
    } else if (returnValue === "unauthorized") {
        return Unauthorized;
    }
    return <Row justify='center'><LargeSpinner/></Row>;

}