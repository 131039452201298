import { FAIL_GET_USER, START_GET_USER, STORE_USER_DATA } from './actions';
import { combineReducers } from 'redux';
import * as R from 'ramda';

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case START_GET_USER:
      return true;
    case STORE_USER_DATA:
      return false;
    default:
      return state;
  }
};

const dataReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STORE_USER_DATA:
      return payload;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type }) => {
  switch (type) {
    case FAIL_GET_USER:
      return 'Failed to fetch user';
    case START_GET_USER:
      return null;
    case STORE_USER_DATA:
      return null;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  isLoading: loadingReducer,
  data: dataReducer,
  error: errorReducer
});

export const selectIsInternalUser = ({ data: user }) =>
  !R.isNil(user.isEmployee) && user.isEmployee;
export const selectIsPublisher = state => {
  const { data: user } = state;
  return !R.isNil(user.isPublisher) && user.isPublisher && !selectIsInternalUser(state);
};
export const selectHasUser = ({ data: user }) => !R.equals({}, user);
export const selectIsUserLoading = ({ isLoading }) => isLoading;
export const selectStatusOptions = ({ data: user }) =>
  !R.isNil(user.isEmployee) && user.isEmployee
    ? [
        { label: 'New', value: 'New' },
        { label: 'In Progress', value: 'In Progress' },
        { label: 'Resolved', value: 'Resolved' }
      ]
    : [
        { label: 'In Progress', value: 'In Progress' },
        { label: 'Resolved', value: 'Resolved' }
      ];
