import React from 'react';
import style from 'styled-components';
import Spinner from '../Spinner';

const LoadingText = style.span`
  font-size: 1.2em;
`;

export const Submitter = () => (
  <div>
    <Spinner />
    <LoadingText>Submitting...</LoadingText>
  </div>
);

export default () => (
  <div>
    <Spinner />
    <LoadingText>Loading...</LoadingText>
  </div>
);
