import React from 'react';
import { getUser, getOauthData } from './cjAuth';
import { saveUser } from '../../shared/userStorage';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';
import { clearInvalidAdvertisers } from '../Advertisers/AdvertisersDropdown/advertiserCleaner';
import { handleOAuthCallback, shouldUseAuth0 } from '../../shared/platformAuth';
import {history, withNavigate} from '../../router'

const handleLambdaAuthCallback = async (navigate) => {
  try {
    const { token, redirectPath } = await getOauthData(window.location.href);
    const user = await getUser(token);
    switch (user.status) {
      case 403:
        history.push('/unauthorized');
        navigate('/unauthorized');
        break;
      case 401:
        history.push('/login');
        navigate('/login');
        break;
      default:
        saveUser({ user, token });
        await clearInvalidAdvertisers();
        await authenticatedFetch(`${apiServer()}/compliance-users`, {
          method: 'POST',
        });
        navigate(redirectPath, { replace: true });
        break;
    }
  } catch (e) {
    console.log('error:', e);
    history.push('/login');
    navigate('/login');
  }
};

class OauthCallback extends React.Component {
  componentDidMount = async () => {
    const usingAuth0 = await shouldUseAuth0();
    if (usingAuth0) {
      await handleOAuthCallback();
    } else {
      await handleLambdaAuthCallback(this.props.navigate);
    }
  };

  render = () => null;
}

export default withNavigate(OauthCallback)
