import React from 'react';
import { TOKEN } from './constants';
import R from 'ramda';
import { isToken } from './util';
import { Formik } from 'formik';
import ErrorMessage from '../../../components/Formik/Error';
import Input from '../../../components/Formik/Input';
import { Button } from '@cjdev-internal/visual-stack-x/components/Button';
import styled from 'styled-components';
import { Title } from './Style';
import { Row } from '@cjdev-internal/visual-stack-x/components/Row';

const StyledForm = styled.form`
  & > * {
    padding-bottom: 10px;
    padding-top: 10px;
  }
`;
const containsNumbers = input => {
  const inputs = input.split(' ');
  const numbers = inputs.filter(isToken);
  return numbers.length > 0;
};

export const validateFormInput = ({ phrase }) => {
  const noNumericValuesFoundError = { phrase: 'No Numeric Values Found' };
  if (R.isNil(phrase)) {
    return noNumericValuesFoundError;
  }
  const hasNumbers = containsNumbers(phrase);
  if (!hasNumbers) {
    return noNumericValuesFoundError;
  }
  return {};
};
const makeForm = returnToPrevious => ({ handleSubmit, handleChange, errors }) => {
  return (
    <StyledForm onSubmit={handleSubmit} onChange={handleChange}>
      <Title>Enter text phrase</Title>
      <Input type="text" name="phrase" autoComplete="off" error={R.hasIn('phrase', errors)} />
      {errors.phrase && <ErrorMessage>{errors.phrase}</ErrorMessage>}
      <Row rowGap="small" columnGap="medium">
        <Button htmlType="submit" type="primary">Parse</Button>
        <Button type="tertiary" onClick={returnToPrevious} data-testid="cancel">Cancel</Button>
      </Row>
    </StyledForm>
  );
};

const parse = phrase => {
  return phrase.split(' ').map(value => {
    return isToken(value)
      ? {
          value,
          type: TOKEN,
          selected: false
        }
      : { value, type: 'TEXT' };
  });
};

export default ({ acceptPhraseInput, configurations = [], returnToPrevious }) => {
  return (
    <div>
      <Formik
        onSubmit={({ phrase }) => {
          const tokenizedPhrase = parse(phrase);
          acceptPhraseInput(tokenizedPhrase);
        }}
        render={makeForm(returnToPrevious)}
        validate={validateFormInput}
        validateOnChange={false}
      />
    </div>
  );
};
