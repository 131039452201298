import React from 'react';
import {useLocation} from 'react-router-dom';
import {parse} from 'query-string';
import {format} from 'date-fns';
import {getDefaultDateInMillis} from './dateHelper';
import * as R from 'ramda';
import {useAdvertiserAndDateFilter} from "../../appRunner";
import DatePickerButton from "../../components/DatePicker/datePicker";

export const IssueDatePicker = ({...props}) => {
    let appliedRange, previousRange;
    const {search} = useLocation()
    const defaultDates = getDefaultDateInMillis();
    const existingQuery = parse(search);
    const {from, to, previousFrom, previousTo} = R.merge(defaultDates, existingQuery);
    const {dateFilter} = useAdvertiserAndDateFilter();
    if (dateFilter === null) {
        appliedRange = [
            format(new Date(parseInt(from, 10)), 'YYYY-MM-DD'),
            format(new Date(parseInt(to, 10)), 'YYYY-MM-DD')
        ];
        previousRange = [
            format(new Date(parseInt(previousFrom, 10)), 'YYYY-MM-DD'),
            format(new Date(parseInt(previousTo, 10)), 'YYYY-MM-DD')
        ];
    } else {
        appliedRange = dateFilter[0]
        previousRange = dateFilter[1]
    }
    return <DatePickerButton {...props} appliedRange={appliedRange} previousRange={previousRange}/>;
};
export default IssueDatePicker;
