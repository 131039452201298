import React from 'react';
import styled from 'styled-components';

const ClickableSvg = styled.svg`
  cursor: pointer;
`;

export default ({ className, onClick }) => (
  <ClickableSvg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="CAS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.439999998">
      <g
        id="Add-New-Product-Configuration"
        transform="translate(-947.000000, -328.000000)"
        fill="#000000"
      >
        <g id="Group-9" transform="translate(295.000000, 249.000000)">
          <g id="Group-8" transform="translate(0.000000, 60.000000)">
            <g id="Icons-/-Close" transform="translate(650.000000, 17.000000)">
              <g id="Close">
                <polygon
                  id="Shape"
                  fillRule="nonzero"
                  points="13.3333333 3.74095238 12.2590476 2.66666667 8 6.92571429 3.74095238 2.66666667 2.66666667 3.74095238 6.92571429 8 2.66666667 12.2590476 3.74095238 13.3333333 8 9.07428571 12.2590476 13.3333333 13.3333333 12.2590476 9.07428571 8"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </ClickableSvg>
);
