import React, {useContext, useState} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import {casTheme} from './theme';
import {apiServer, environment} from './env';
import store from './store';
import App, {AppNewNav} from './containers/App';
import auth0LoginConfiguration from './containers/Login/auth0Configuration.json';
import lambdaLoginConfiguration from './containers/Login/loginConfiguration.json';
import {getBearerToken, initAuth, shouldUseAuth0} from './shared/platformAuth';
import {saveUser} from './shared/userStorage';
import {getUser} from './containers/Login/cjAuth';
import {clearInvalidAdvertisers} from './containers/Advertisers/AdvertisersDropdown/advertiserCleaner';
import {authenticatedFetch} from './shared/apis';
import {shouldDisplay2022Nav} from '@platform/cj-platform-navigation'
import {PlatformNavContext} from "./navContext";
import {UnauthorizedContext} from "./unauthorizedContext";
import {AdvertiserAndDateFilterContext} from "./AdvertiserAndDateFilterContext";

const auth0Configuration = auth0LoginConfiguration[environment];
const lambdaConfiguration = lambdaLoginConfiguration[environment];

export const run = async () => {
    const displayNav = await shouldDisplay2022Nav();
    const usingAuth0 = await shouldUseAuth0();
    if (usingAuth0) {
        if (!displayNav) {
            await initAuth(auth0Configuration);
            const token = await getBearerToken();
            const user = await getUser(token);
            saveUser({user, token});
            await clearInvalidAdvertisers();
            await authenticatedFetch(`${apiServer()}/compliance-users`, {
                method: 'POST',
            });
        }
    } else {
        await initAuth(lambdaConfiguration);
    }

    ReactDOM.render(
        <Provider store={store}>
            <ThemeProvider theme={casTheme}>
                <UnauthorizedProvider>
                    <AdvertiserAndDateFilterProvider>
                        <PlatformNavProvider use2022Nav={displayNav}>
                            {displayNav ? <AppNewNav/> : <App/>}
                        </PlatformNavProvider>
                    </AdvertiserAndDateFilterProvider>
                </UnauthorizedProvider>
            </ThemeProvider>
        </Provider>,
        document.getElementById('root')
    );
};

function PlatformNavProvider({
                                 children,
                                 use2022Nav
                             }) {
    return (
        <PlatformNavContext.Provider value={use2022Nav}>
            {children}
        </PlatformNavContext.Provider>
    )
}

export function usePlatformNav() {
    const platformNav = useContext(PlatformNavContext)
    return platformNav
}

export const UnauthorizedProvider = ({children}) => {
    const [returnValue, setReturnValue] = useState('');

    return (
        <UnauthorizedContext.Provider value={{returnValue, setReturnValue}}>
            {children}
        </UnauthorizedContext.Provider>
    );
};

export const useUnauthorized = () => {
    const context = useContext(UnauthorizedContext);
    if (!context) {
        throw new Error('useUnauthorized must be used within an UnauthorizedProvider');
    }
    return context;
};

export const AdvertiserAndDateFilterProvider = ({children}) => {
    const [advertiserFilter, setAdvertiserFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [publisherFilter, setPublisherFilter] = useState(null);

    return (
        <AdvertiserAndDateFilterContext.Provider value={{
            advertiserFilter,
            setAdvertiserFilter,
            dateFilter,
            setDateFilter,
            publisherFilter,
            setPublisherFilter
        }}>
            {children}
        </AdvertiserAndDateFilterContext.Provider>
    );
};

export const useAdvertiserAndDateFilter = () => {
    const context = useContext(AdvertiserAndDateFilterContext);
    if (!context) {
        throw new Error('useAdvertiserAndDateFilter must be used within an AdvertiserAndDateFilterProvider');
    }
    return context;
};
