import { apiServer } from '../../env';
import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../shared/apis';
import * as R from 'ramda';
export const SET_URL_GROUPS = 'CAS/urlGroups/SET_URL_GROUPS';
export const setUrlGroups = createAction(SET_URL_GROUPS);
export const FETCH_URL_GROUPS_FAILURE = 'CAS/urlGroups/FETCH_URL_GROUPS_FAILURE';
export const fetchUrlGroupsFailure = createAction(FETCH_URL_GROUPS_FAILURE);
export const START_FETCHING_URL_GROUPS = 'CAS/urlGroups/START_FETCHING_URL_GROUPS';
export const startFetchingUrlGroups = createAction(START_FETCHING_URL_GROUPS);

export const START_ARCHIVE_URL_GROUP = 'CAS/urlGroups/START_ARCHIVE_URL_GROUP';
export const startArchiveUrlGroup = createAction(START_ARCHIVE_URL_GROUP);
export const SUCCESS_ARCHIVE_URL_GROUP = 'CAS/urlGroups/SUCCESS_ARCHIVE_URL_GROUP';
export const successArchiveUrlGroup = createAction(SUCCESS_ARCHIVE_URL_GROUP);
export const FAIL_ARCHIVE_URL_GROUP = 'CAS/urlGroups/FAIL_ARCHIVE_URL_GROUP';
export const failArchiveUrlGroup = createAction(FAIL_ARCHIVE_URL_GROUP);
export const URL_GROUP_ARCHIVE_RESET = 'CAS/urlGroups/URL_GROUP_ARCHIVE_RESET';


export const fetchUrlGroups = () => async dispatch => {
  try {
    dispatch(startFetchingUrlGroups());
    const response = await authenticatedFetch(`${apiServer()}/url-groups`);
    const json = await response.json();
    dispatch(setUrlGroups(json));
  } catch (e) {
    dispatch(fetchUrlGroupsFailure('Failed to fetch'));
  }
};

export const archiveUrlGroup = urlGroupId => async dispatch => {
  try {
    dispatch(startArchiveUrlGroup());
    const response = await authenticatedFetch(`${apiServer()}/archive-url-group/${urlGroupId}`, {
      method: 'POST'
    });
    if (response.status === 200) {
      dispatch(successArchiveUrlGroup());
    } else {
      dispatch(failArchiveUrlGroup(response.statusText));
    }
  } catch (e) {
    dispatch(failArchiveUrlGroup('Failed to archive url group'));
  }
};
export const archiveAndLoad = urlGroupId => async (dispatch, getState) => {
  console.log('archive and load');
  await dispatch(archiveUrlGroup(urlGroupId));
  const archiveError = getState().CAS.urlGroups.archive.error;
  if (R.isNil(archiveError)) {
    return dispatch(fetchUrlGroups());
  }
};
