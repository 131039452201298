import React, {Component, useEffect} from 'react';
import styled from 'styled-components';
import {Route, Routes} from 'react-router-dom';
import {PageHeader, PageTitle} from '@cjdev-internal/visual-stack-x/components/PageHeader';
import {PageContent} from '@cjdev-internal/visual-stack-x/components/PageContent';
import Layout from '@cjdev-internal/visual-stack-redux/lib/layouts/ApplicationLayout';
// import '@cjdev-internal/visual-stack/lib/global';
import {history} from '../../router';
import CreateTestConfig from '../CreateTestConfig';
import DomainForm from '../Domains/DomainForm';
import DomainList from '../Domains/DomainList';
import Domains from '../Domains';
import Login from '../Login';
import Logout from '../Login/Logout';
import OauthCallback from '../Login/OauthCallback';
import NoMatch from '../NoMatch';
import ProductForm from '../Products/ProductForm';
import Products from '../Products';
import Settings from '../Settings';
import TestConfigs from '../TestConfigs';
import TestResultDetails from '../TestResultDetails';
import TestResults from '../TestResults';
import TestsPage from '../TestsPage';
import UrlGroups from '../UrlGroups';
import CreateUrlGroupForm from '../UrlGroups/CreateUrlGroupForm';
import EditUrlGroupForm from '../UrlGroups/EditUrlGroupForm';
import Help from '../Help';
import {IssuesDefault} from '../Issues/dependency';
import {isAuthenticated} from '../Login/authController';
import {getUser, isPublisher} from '../../shared/userStorage';
import * as paths from '../../paths';
import initialize from '../Login/pendo';
import {connect} from 'react-redux';
import * as getUserActions from '../../domain/Users/actions';
import {selectIsInternalUser, selectIsSideNavCollapsed, selectIsSlidingPanelExpanded} from '../../reducer';
import Dashboard, {Dashboard_} from '../Dashboard';
import {PlatformApp} from '@platform/cj-platform-navigation';
import {ReduxRouter} from '@lagunovsky/redux-react-router';
import {usePlatformNav, useUnauthorized} from '../../appRunner';
import '@cjdev-internal/design-tokens';
import * as PropTypes from 'prop-types';
import {MDIcon} from '@cjdev-internal/visual-stack-x/MDIcon';
import {ErrorPage} from '@cjdev-internal/visual-stack-x/ErrorPage';
import {environment} from '../../env';
import {DashboardHeader} from '../Dashboard/DashboardHeader';
import {NewNavActionButtons, NewNavIssueHeader} from '../Issues/IssueHeader';
import DashboardFilterPanel from '../Dashboard/DashboardFilterPanel';
import IssuesSlidingPanel from '../Issues/IssuesSlidingPanel';
import IssuesDetail from '../IssuesDetail';
import IssuesDetailActivity from '../IssuesDetail/components/IssuesDetailActivity';
import IssueDetailActions from '../IssuesDetail/components/IssueDetailActions';
import {IconButton} from '@cjdev-internal/visual-stack-x/components/Button';
import {toBackList} from '../IssuesDetail/helpers/dataHelpers';
import ManageRecipient from '../ManageRecipient';
import {
    AdvertiserManagement,
    AdvertiserManagementActions,
    AdvertiserManagementCreate,
    AdvertiserManagementEdit
} from '../AdvertiserManagement/dependency';
import {RouteContent} from "./Content";

export const PageContentDiv = connect(state => ({
    sideNavIsCollapsed: selectIsSideNavCollapsed(state),
    slidingPanelIsActive: selectIsSlidingPanelExpanded(state)
}))(styled.div`
    flex-grow: 1;
    padding-top: '15px';
    padding-right: '15px';
    padding-bottom: '15px';
    --sideNavCompensation: ${props => (props.sideNavIsCollapsed ? '96px' : '304px')};
    --sidePanelCompensation: ${props => (props.slidingPanelIsActive ? '300px' : '0px')};
    width: calc(100vw - var(--sideNavCompensation) - var(--sidePanelCompensation));
    transition: 0.3s all ease-in-out;
`);


const PlatformLayout = ({children, pageHeader, slidingPanel, additionalHeader}) => {
    return (
        <>
            {pageHeader}
            {children}
        </>
    );
};

const OldLayout = ({
                       children,
                       pageHeaderTitle,
                       pageHeader,
                       slidingPanel,
                       additionalHeader,
                       slidingVsx,
                       onBackButtonClick
                   }) => {
    return (
        <Layout>
            {pageHeaderTitle && (
                <PageHeader>
                    {onBackButtonClick ? <IconButton icon="arrow-left" data-testid="old-layout-back-button"
                                                     onClick={onBackButtonClick}/> : ''}
                    <PageTitle>{pageHeaderTitle}</PageTitle>
                </PageHeader>
            )}
            {additionalHeader && additionalHeader()}
            {pageHeader}
            {slidingPanel ? (
                <div
                    style={{
                        display: 'flex',
                        width: 'calc(100%)',
                        marginRight: '-15px',
                        marginBottom: '-15px'
                    }}
                >
                    <PageContentDiv>
                        <PageContent>{children}</PageContent>
                    </PageContentDiv>
                    {slidingPanel()}
                </div>
            ) : (
                <>
                    {slidingVsx ? children : <PageContent>{children}</PageContent>}
                </>
            )}
        </Layout>
    );
};

export const AppLayout = props => {
    const platformNavEnabled = usePlatformNav();

    return platformNavEnabled ? <PlatformLayout {...props} /> : <OldLayout {...props} />;
};

const unauthorized = (
    <ErrorPage
        errorCode={403}
        onClickBack={() => (window.location.href = 'https://members.cj.com')}
    />
);

const initializePendo = () => {
    const userDetails = getUser();
    if (userDetails !== undefined && userDetails !== null) {
        const isEmployee = userDetails.user.isEmployee;
        const id = userDetails.user.id;
        const email = userDetails.user.emailAddress;
        initialize(id, email, isEmployee);
    }
};

export class AppPure extends Component {
    initializeUser = async () => {
        if (await isAuthenticated()) {
            this.props.getUser();
        }
        await initializePendo();
    };

    async componentDidMount() {
        await this.initializeUser();
    }

    async componentDidUpdate() {
        await this.initializeUser();
    }

    render() {
        const {isInternalUser} = this.props;
        return (
            <ReduxRouter history={history}>
                <Routes>
                    <Route path={paths.BASE} element={<Dashboard/>}/>
                    <Route path={paths.COMPLIANCE_VIOLATIONS} element={<IssuesDefault/>}/>
                    <Route path={paths.COMPLIANCE_VIOLATIONS_DETAIL} element={<IssuesDetail/>}/>
                    <Route path={paths.COMPLIANCE_VIOLATIONS_MANAGE_RECIPIENT} element={<ManageRecipient/>}/>

                    {isInternalUser && (
                        <>
                            <Route path={paths.ADVERTISERS} element={<AdvertiserManagement/>}/>
                            <Route path={paths.TESTS} element={<TestsPage/>}/>
                            <Route path={paths.TEST_RESULT_FROM_ID} element={<TestResults/>}/>
                            <Route path={paths.TEST_RESULTS} element={<TestResults/>}/>
                            <Route path={paths.TEST_RESULTS_DETAIL_FROM_ID} element={<TestResultDetails/>}/>
                            <Route path={paths.TEST_CONFIGS} element={<TestConfigs/>}/>
                            <Route path={paths.TEST_CONFIG_FROM_ID} element={<CreateTestConfig/>}/>
                            <Route path={paths.TEST_CONFIG} element={<CreateTestConfig/>}/>
                            <Route path={paths.PRODUCT_CREATION} element={<ProductForm/>}/>
                            <Route path={paths.PRODUCT_FROM_ID} element={<ProductForm/>}/>
                            <Route path={paths.PRODUCTS} element={<Products/>}/>
                            <Route path={paths.SETTINGS} element={<Settings/>}/>
                            <Route path={paths.URL_GROUPS_CREATION} element={<CreateUrlGroupForm/>}/>
                            <Route path={paths.URL_GROUP_FROM_ID} element={<EditUrlGroupForm/>}/>
                            <Route path={paths.URL_GROUPs} element={<UrlGroups/>}/>
                            <Route path={paths.DOMAIN_CONFIG_CREATION} element={<DomainForm/>}/>
                            <Route path={paths.DOMAIN_CONFIG_FROM_ID} element={<DomainForm/>}/>
                            <Route path={`${paths.DOMAIN_PATH}/:domainId`} element={<DomainList/>}/>
                            <Route path={paths.DOMAIN_PATH} element={<Domains/>}/>
                            <Route path="/help" element={<Help/>}/>
                            <Route path={paths.EDIT_ADVERTISER} element={<AdvertiserManagementEdit/>}/>
                            <Route path={paths.CREATE_ADVERTISER} element={<AdvertiserManagementCreate/>}/>
                        </>
                    )}

                    <Route path={paths.LOGIN} element={<Login/>}/>
                    <Route path={paths.OAUTH_CALLBACK} element={<OauthCallback/>}/>
                    <Route path={paths.LOG_OUT} element={<Logout/>}/>
                    <Route path={paths.UNAUTHORIZED} element={unauthorized}/>
                    <Route element={<NoMatch/>}/>
                </Routes>
            </ReduxRouter>
        );
    }
}

MDIcon.propTypes = {icon: PropTypes.string}
export const AppNewNavPure = ({injectUserIntoReduxState}) => {

    const emptyTitleMessage = {
        id: "compliance.untranslated.notitle",
        defaultMessage: "   "
    };

    const tweak = (user) => {
        useEffect(() => {
            // new nav has userId instead of id :-/
            if (user && user.userId && !user.id) {
                user.id = user.userId;
                injectUserIntoReduxState(user);
            }

        }, []);
        return user;
    }
    initializePendo();
    const {returnValue} = useUnauthorized();
    const isUnauthorized = returnValue === "unauthorized";
    const externalRoutes = [
        {
            path: paths.BASE,
            titleMessage: {
                id: 'compliance.untranslated.dashboard',
                defaultMessage: 'Compliance Overview'
            },
            defaultShowFilterPanel: false,
            Dimensions: ({user}) => {
                return isUnauthorized ? null : <DashboardHeader/>;
            },
            Content: ({user}) => {
                return <RouteContent user={tweak(user)} Component={<Dashboard_ user={tweak(user)}/>}
                                     Unauthorized={unauthorized}/>;
            },
            Filters: ({user}) => {
                return isUnauthorized ? null : <DashboardFilterPanel/>;
            }
        },
        {
            path: paths.COMPLIANCE_VIOLATIONS,
            titleMessage: {
                id: 'compliance.untranslated.violations',
                defaultMessage: 'Violations'
            },
            defaultShowFilterPanel: false,
            Dimensions: ({user}) => {
                return isUnauthorized ? null : <NewNavIssueHeader/>
            },
            Content: ({user}) => {
                return <RouteContent user={tweak(user)} Component={<IssuesDefault user={tweak(user)}/>}
                                     Unauthorized={unauthorized}/>;
            },
            Filters: ({user}) => {
                return isUnauthorized ? null : <IssuesSlidingPanel initialActive={true}/>
            },
            Buttons: ({user}) => {
                return isUnauthorized ? null : <NewNavActionButtons user={user}/>
            }
        },
        {
            path: paths.COMPLIANCE_VIOLATIONS_DETAIL,
            titleMessage: {
                id: 'compliance.untranslated.violationsDetail',
                defaultMessage: 'Compliance Violations / Violation Details'
            },
            defaultShowFilterPanel: true,
            toggleRightPanelIcon: 'activity',
            onBackButtonClick: (e, navigate) => {
                toBackList(navigate, history, true);
            },
            Buttons: ({user}) => {
                return isUnauthorized ? null : !isPublisher(user) ? <IssueDetailActions user={tweak(user)}/> : null
            },
            Content: ({user}) => {
                return <RouteContent user={tweak(user)} Component={<IssuesDetail user={tweak(user)}/>}
                                     Unauthorized={unauthorized}/>;
            },
            Filters: ({user, onClose}) => {
                return isUnauthorized ? null : <IssuesDetailActivity onClose={onClose} user={tweak(user)}/>
            }
        },
        {
            path: paths.COMPLIANCE_VIOLATIONS_MANAGE_RECIPIENT,
            titleMessage: {
                id: 'compliance.untranslated.violationsDetailManageRecipient',
                defaultMessage: 'Compliance Violations / Manage Recipient'
            },
            Content: ({user}) => {
                return isPublisher(user) ? unauthorized :
                    <RouteContent user={tweak(user)} Component={<ManageRecipient user={tweak(user)}/>}
                                  Unauthorized={unauthorized}/>;
            }
        },
        {
            path: paths.UNAUTHORIZED,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => unauthorized
        }
    ];

    // we don't have a user so these components need to return unauthorized when the user is not an employee
    const internalRoutes = [
        {
            path: paths.ADVERTISERS,
            titleMessage: {
                id: 'compliance.untranslated.advertisermanagement',
                defaultMessage: 'Advertiser Management'
            },
            Buttons: ({user}) => {
                return user.isEmployee ? <RouteContent user={tweak(user)} Component={<AdvertiserManagementActions/>}
                                                       Unauthorized={unauthorized}/> : unauthorized;
            },
            Content: ({user}) => {
                return (user.isEmployee && (user.employeeRoles.includes('compliance_integration') || user.employeeRoles.includes('network_quality') || user.employeeRoles.includes('product_management'))) ?
                    <RouteContent user={tweak(user)} Component={<AdvertiserManagement user={tweak(user)}/>}
                                  Unauthorized={unauthorized}/> : unauthorized;
            }
        },
        {
            titleMessage: {
                id: 'compliance.untranslated.advertisermanagementEdit',
                defaultMessage: 'Advertiser Management / Edit Advertiser'
            },
            path: paths.EDIT_ADVERTISER,
            Content: ({user}) => (user.isEmployee && (user.employeeRoles.includes('compliance_integration') || user.employeeRoles.includes('network_quality') || user.employeeRoles.includes('product_management'))) ?
                <AdvertiserManagementEdit user={tweak(user)}/> : unauthorized
        },
        {
            titleMessage: {
                id: 'compliance.untranslated.advertisermanagementCreate',
                defaultMessage: 'Advertiser Management / Create Advertiser'
            },
            path: paths.CREATE_ADVERTISER,
            Content: ({user}) => (user.isEmployee && (user.employeeRoles.includes('compliance_integration') || user.employeeRoles.includes('network_quality') || user.employeeRoles.includes('product_management'))) ?
                <AdvertiserManagementCreate user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.TESTS,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => (user.isEmployee ? <TestsPage user={tweak(user)}/> : unauthorized)
        },
        {
            path: paths.TEST_RESULT_FROM_ID,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => (user.isEmployee ? <TestResults user={tweak(user)}/> : unauthorized)
        },
        {
            path: paths.TEST_RESULTS,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => (user.isEmployee ? <TestResults user={tweak(user)}/> : unauthorized)
        },
        {
            path: paths.TEST_RESULTS_DETAIL_FROM_ID,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <TestResultDetails user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.TEST_CONFIGS,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <TestConfigs user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.TEST_CONFIG_FROM_ID,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <CreateTestConfig user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.TEST_CONFIG,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <CreateTestConfig user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.PRODUCT_CREATION,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <ProductForm user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.PRODUCT_FROM_ID,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <ProductForm user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.PRODUCTS,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <Products user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.SETTINGS,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <Settings user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.URL_GROUPS_CREATION,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <CreateUrlGroupForm user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.URL_GROUP_FROM_ID,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <EditUrlGroupForm user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.URL_GROUPs,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <UrlGroups user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.DOMAIN_CONFIG_CREATION,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <DomainForm user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.DOMAIN_CONFIG_FROM_ID,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <DomainForm user={tweak(user)}/> : unauthorized
        },
        {
            path: `${paths.DOMAIN_PATH}/:domainId`,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <DomainList user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.DOMAIN_PATH,
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <Domains user={tweak(user)}/> : unauthorized
        },
        {
            path: "/help",
            titleMessage: emptyTitleMessage,
            Content: ({user}) => user.isEmployee ? <Help user={tweak(user)}/> : unauthorized
        },
        {
            path: paths.EVERYTHING_ELSE__MUST_BE_LAST,
            titleMessage: emptyTitleMessage,
            Content: () => (
                <ErrorPage errorCode={404} onClickBack={() => window.location.href = "https://members.cj.com"}/>)
        }
    ]

    const routes = externalRoutes.concat(internalRoutes).map(x => ({
        ...x,
        layout: {
            filters: true,
            sidenav: true,
            dimensions: true,
            pageTitle: true
        },
        sideNavLinkGroups: isUnauthorized ? null : (user, location) => {
            const groups = [
                {
                    label: 'Compliance Overview',
                    HeaderIcon: () => <MDIcon icon="chart-bar"/>,
                    isCurrentArea: location?.pathname === paths.BASE,
                    link: paths.BASE
                },
                {
                    label: 'Violations',
                    HeaderIcon: () => <MDIcon icon="flag"/>,
                    isCurrentArea: location?.pathname.includes(paths.COMPLIANCE_VIOLATIONS),
                    link: paths.COMPLIANCE_VIOLATIONS
                }
            ];

            if (user?.isEmployee && (user?.employeeRoles.includes('compliance_integration') || user?.employeeRoles.includes( 'network_quality') || user?.employeeRoles.includes('product_management')))
                groups.push({
                    label: 'Advertiser Management',
                    HeaderIcon: () => <MDIcon icon="cog"/>,
                    isCurrentArea: location?.pathname === paths.ADVERTISERS,
                    link: paths.ADVERTISERS
                });

            return {
                areaLabel: 'Compliance',
                groups
            };
        }
    }));

    const platformDomains = environment === 'beta' ? undefined : {members: 'lab106'};

    return <PlatformApp routes={routes} translations={{}} platformDomains={platformDomains}/>;
};

export const AppNewNav = connect(
    state => ({
        isInternalUser: selectIsInternalUser(state)
    }),
    getUserActions
)(AppNewNavPure);

const App = connect(
    state => ({
        isInternalUser: selectIsInternalUser(state)
    }),
    getUserActions
)(AppPure);

export default App;
