import React from 'react';
import R from 'ramda';
import { connect } from 'react-redux';
import style from 'styled-components';
import {
  PageHeader,
  PageTitle,
  PageHeaderSection
} from '@cjdev-internal/visual-stack-x/components/PageHeader';
import { ModalMountPoint } from '@cjdev-internal/visual-stack-redux/lib/components/Modal';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack-x/components/Panel';
import { ROUTER_POP } from './constant';
import { formatDate, sortTestsNewest } from '../../utils';
import BreadCrumb, { BreadCrumbSpan } from '../../components/BreadCrumb';
import Spinner from '../../components/Spinner';
import Select from '../../components/Select';
import { AppLayout } from '../App';
import TestResultsTable from './TestResultsTable';
import TestResultsPanelHeader from './TestResultsPanelHeader';
import { loadTests } from '../../domain/Tests/actions';
import { defaultFilters } from '../../domain/TestResults/reducer';
import { loadTestResults, changeTestResultsFilters } from '../../domain/TestResults/actions';
import { makeFileExport } from './export';
import {history, withLocation, withNavigate, withNavigationType} from '../../router'

const paramId = R.view(R.lensPath(['params', 'id']));
const ignoreUrlText = R.dissoc('urlText');

const TestSelect = style(Select)`
  min-width: 400px;
`;

export const TestFilter = ({ tests, onChange, selectedTestId }) => {
  const sortedTests = sortTestsNewest(tests.data);
  return (
    <span>
      {tests.loading && <Spinner />}
      <TestSelect onChange={onChange} value={selectedTestId}>
        {sortedTests.map(test => (
          <option id={test.id} key={test.id} value={test.id}>
            {test.testName} - {formatDate(test.timeStamp)} ({test.totalUrls})
          </option>
        ))}
      </TestSelect>
    </span>
  );
};

const Em = style(BreadCrumbSpan)`
  font-style: italic;
`;

export const BreadCrumbTestName = ({ testStat }) => {
  const archived = testStat.archiveStatus
    ? ` - (Archived: ${formatDate(testStat.archiveStatus)})`
    : '';

  return (
    <BreadCrumbSpan>
      Results for{' '}
      <Em>
        {testStat.testName} {archived}
      </Em>
    </BreadCrumbSpan>
  );
};

export class TestResults extends React.Component {
  constructor(args) {
    super(args);
    this.redirectToResultsForId = this.redirectToResultsForId.bind(this);
    this.redirectToNewestTestResults = this.redirectToNewestTestResults.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  redirectToResultsForId(id) {
    history.push(`/test-results/${id}`);
    this.props.navigate(`/test-results/${id}`, { replace: true });
  }

  redirectToNewestTestResults(tests) {
    const firstId = R.view(R.lensPath([0, 'id']))(sortTestsNewest(tests));
    if (firstId) {
      this.redirectToResultsForId(firstId);
    }
  }

  handleChange(event) {
    this.redirectToResultsForId(event.target.value);
  }

  handleFilterChange(filterName, value) {
    const filterChange = { [filterName]: value };
    this.props.changeTestResultsFilters(filterChange);
  }

  componentDidMount() {
    this.props.loadTests();
    const testId = paramId(this.props);
    const fromTestResultDetail = R.equals(this.props.navigationType, ROUTER_POP);
    const { state } = this.props.location;
    const shouldPreserveFilters = state ? state.shouldPreserveFilters : false;
    if (testId && (fromTestResultDetail || shouldPreserveFilters)) {
      const {
        testResults: { filters }
      } = this.props;
      this.props.loadTestResults(testId, filters);
    } else {
      this.props.loadTestResults(testId, defaultFilters);
    }
  }

  componentWillReceiveProps(nextProps) {
    const oldId = paramId(this.props);
    const nextId = paramId(nextProps);
    const newTestId = nextId && oldId !== nextId;

    const filterChanged = !R.equals(
      ignoreUrlText(this.props.testResults.filters),
      ignoreUrlText(nextProps.testResults.filters)
    );

    const changePageFilter = !R.equals(
      this.props.testResults.filters.page,
      nextProps.testResults.filters.page
    );

    if (newTestId) {
      this.props.loadTestResults(nextId, defaultFilters);
    }

    if (changePageFilter) {
      this.props.loadTestResults(nextId, nextProps.testResults.filters);
    } else if (filterChanged) {
      this.props.loadTestResults(nextId, {
        ...nextProps.testResults.filters,
        page: 1
      });
    }

    const nextOrOldIsUndefined = !oldId || !nextId;
    const { data } = nextProps.tests;
    const nextPropsHaveTests = data && data.length > 0;

    if (nextPropsHaveTests && nextOrOldIsUndefined) {
      this.redirectToNewestTestResults(data);
    }
  }

  render() {
    const { tests, testResults } = this.props;
    const selectedTestId = paramId(this.props);
    const testStat = testResults.testResultsStat;
    const selectedTest = R.find(R.propEq('id', selectedTestId))(tests.data);
    const totalUrlCount = selectedTest ? selectedTest.totalUrls : 0;
    const loading = tests.loading || testResults.loading;
    const {
      filters,
      testResultsStat: { totalURLNum }
    } = testResults;
    const isSelectedTestDone = loading || totalURLNum === totalUrlCount;
    const pageHeader = (
      <PageHeader>
        <PageTitle>
          <BreadCrumb nodes={['Tests', <BreadCrumbTestName testStat={testStat} />]} />
        </PageTitle>
        <PageHeaderSection>
          <TestFilter tests={tests} onChange={this.handleChange} selectedTestId={selectedTestId} />
        </PageHeaderSection>
      </PageHeader>
    );

    return (
      <AppLayout pageHeader={pageHeader}>
        <Panel>
          <ModalMountPoint />
          <TestResultsPanelHeader
            handleTextSearchFilterSubmit={R.partial(this.props.loadTestResults, [selectedTestId])}
            testResults={testResults}
            handleFilterChange={this.handleFilterChange}
            filters={filters}
            makeFileExport={makeFileExport}
            test={selectedTest}
            isSelectedTestDone={isSelectedTestDone}
          />
          <PanelBody>
            <TestResultsTable testResults={testResults} totalUrlCount={totalUrlCount} />
          </PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

export const mapStateToProps = state => ({
  tests: state.CAS.tests,
  testResults: state.CAS.testResults
});

export const mapDispatchToProps = dispatch => {
  return {
    loadTests: () => dispatch(loadTests()),
    loadTestResults: (testId, testResultsFilters) =>
      dispatch(loadTestResults(testId, testResultsFilters)),
    changeTestResultsFilters: filterChange => dispatch(changeTestResultsFilters(filterChange))
  };
};

export default withNavigate(withLocation(withNavigationType(connect(mapStateToProps, mapDispatchToProps)(TestResults))));
