import { Modal } from "@cjdev-internal/visual-stack-x/components/Modal";
import PropTypes from "prop-types";
import React from "react";
import { SimpleError } from "../ErrorMessage";
import R from "ramda";
import { TextInput } from "../../components/FormFields";
import { history, withNavigate } from "../../router";
import { Row } from "@cjdev-internal/visual-stack-x/components/Row";
import { Button } from "@cjdev-internal/visual-stack-x/components/Button";

export const CreateModalDialogPure = ({
  productName,
  startLoading,
  onInputChange,
  createOnClick,
  closeModal,
  error,
  loading,
}) => {
  const footer = (
    <Row justify="end" gap="small">
      {error && <SimpleError error={error} />}
      <Button
        onClick={async () => {
          startLoading();
          await createOnClick();
        }}
        spinner={loading}
        type="primary"
        data-testid="create-dialog-button"
      >
        Create
      </Button>
      <Button type="secondary" disabled={loading} onClick={closeModal} data-testid="cancel-dialog-button">
        Cancel
      </Button>
    </Row>
  );

  return (
    <Modal headerTitle="Create New Product Configuration" footer={footer}>
      <TextInput
        placeholder="Enter your product name here"
        onChange={onInputChange}
        value={productName}
        width={"50%"}
      />
    </Modal>
  );
};

export class CreateModalDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      productName: "",
      error: null,
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const stoppedLoading =
      this.props.create.loading && !nextProps.create.loading;
    const createError = nextProps.create.error;
    if (stoppedLoading && R.isNil(createError)) {
      this.props.closeModal();
    }
  }

  startLoading = () => {
    this.setState({
      loading: true,
      error: null,
    });
  };

  stopLoading = () => {
    this.setState({
      loading: false,
    });
  };

  emptyProductNameError = () => {
    this.setState({
      loading: false,
      error: "Product Name can not be empty",
    });
  };

  submitFailedError = () => {
    this.setState({
      loading: false,
      error: "Failed to submit",
    });
  };

  onInputChange = (event) => {
    this.setState({
      productName: event.target.value,
    });
  };

  createOnClick = async () => {
    const { takeAction, closeModal } = this.props;
    this.startLoading();
    if (R.isNil(this.state.productName) || R.isEmpty(this.state.productName)) {
      this.emptyProductNameError();
    } else {
      try {
        const product = await takeAction(this.state.productName);
        const { id: productId } = product;
        this.stopLoading();
        history.push(`/products/${productId}`);
        this.props.navigate(`/products/${productId}`);
        closeModal();
      } catch (e) {
        this.submitFailedError();
      }
    }
  };

  render() {
    const { error, loading, productName } = this.state;
    return (
      <CreateModalDialogPure
        productName={productName}
        startLoading={this.startLoading}
        onInputChange={this.onInputChange}
        createOnClick={this.createOnClick}
        closeModal={this.props.closeModal}
        error={error}
        loading={loading}
      />
    );
  }
}

export default withNavigate(CreateModalDialog);

CreateModalDialog.propTypes = {
  takeAction: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
