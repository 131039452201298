import { fetchAdvertiserAction, storeSelectedAdvertiserIdAction } from '../Advertisers/actions';
import { fetchWebsiteAction } from '../Websites/actions';
import { fetchViolationProductAction } from '../ViolationProducts/actions';
import { initializeIssues } from './initializeIssuesAction';
import { fetchPublishersAction } from '../Publishers/actions';
import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';
import { initializeViolationTypeMapping } from '../ViolationTypeMappings/actions';
import * as R from 'ramda';
import * as qs from 'query-string';
import { history } from '../../router';
import * as thisModule from './actions';
import { getDefaultDateInMillis } from '../../containers/Issues/dateHelper';
import { getAdvertisers } from '../../containers/Advertisers/AdvertisersDropdown/advertiserStorage';

export const STORE_ISSUE_DATA = 'Domains/advertiser/STORE_ISSUE_DATA';

export const storeIssueDataAction = createAction(STORE_ISSUE_DATA);

export const FAIL_FETCH_ISSUE_DATA = 'Domains/advertiser/FAIL_FETCH_ISSUE_DATA';

export const failFetchIssueDataAction = createAction(FAIL_FETCH_ISSUE_DATA);

export const LOADING_FETCH_ISSUE_DATA = 'Domains/advertiser/LOADING_FETCH_ISSUE_DATA';

export const loadingFetchIssueData = createAction(LOADING_FETCH_ISSUE_DATA);

export const LOADING_ISSUES_PAGE = 'Domains/advertiser/LOADING_ISSUES_PAGE';

export const loadingIssuesPage = createAction(LOADING_ISSUES_PAGE);

export const FINISH_LOADING_ISSUES_PAGE = 'Domains/advertiser/FINISH_LOADING_ISSUES_PAGE';

export const finishLoadingIssuesPage = createAction(FINISH_LOADING_ISSUES_PAGE);

export const fetchIssuesPageAction = () => async dispatch => {
  dispatch(loadingIssuesPage());
  await dispatch(fetchAdvertiserAction());
  await Promise.all([
    dispatch(fetchWebsiteAction()),
    dispatch(fetchViolationProductAction()),
    dispatch(fetchPublishersAction()),
    dispatch(initializeIssues()),
    dispatch(initializeViolationTypeMapping())
  ]);
  dispatch(finishLoadingIssuesPage());
};

export const fetchIssuesAction = (
  advertiserIds = getAdvertisers(),
  getCurrentDate = () => new Date()
) => async dispatch => {
  const currentParams = qs.parse(history.location.search);
  const { from, to } = getDefaultDateInMillis(getCurrentDate);
  const filterQuery = qs.stringify({
    ...currentParams,
    from: currentParams.from || from,
    to: currentParams.to || to,
    page: currentParams.page || 1,
    size: currentParams.size || 10
  });
  dispatch(loadingFetchIssueData());
  try {
    const advertiserIdsQuery = !R.isEmpty(advertiserIds)
      ? `${qs.stringify({ advertiserIds: JSON.stringify(advertiserIds) })}&`
      : '';
    const queryString = `?${advertiserIdsQuery}${filterQuery}`;
    const response = await authenticatedFetch(`${apiServer()}/issues${queryString}`);
    const responseJSON = await response.json();
    dispatch(storeIssueDataAction(responseJSON));
  } catch (e) {
    dispatch(failFetchIssueDataAction());
  }
};

export const switchAdvertiserAction = id => async dispatch => {
  dispatch(loadingIssuesPage());
  dispatch(storeSelectedAdvertiserIdAction(id));
  await Promise.all([
    dispatch(fetchWebsiteAction()),
    dispatch(fetchViolationProductAction()),
    dispatch(fetchPublishersAction()),
    dispatch(initializeIssues()),
    dispatch(initializeViolationTypeMapping())
  ]);
  dispatch(finishLoadingIssuesPage());
};

export const FAILED_POSTING_ISSUE = 'Domains/advertiser/FAILED_POSTING_ISSUE';

export const failSubmitPendingIssueAction = createAction(FAILED_POSTING_ISSUE);

const getAdvertiserId = pendingIssue => {
  if (pendingIssue.advertiserId) return pendingIssue.advertiserId;
  const advertisers = getAdvertisers();
  if (R.isEmpty(advertisers) || R.length(advertisers) > 1) {
    throw new Error('Invalid advertisers when submitting violation');
  }
  return R.head(getAdvertisers());
};

export const submitPendingIssueAction = pendingIssue => async dispatch => {
  const advertiserId = getAdvertiserId(pendingIssue);
  const response = await authenticatedFetch(`${apiServer()}/issues`, {
    method: 'POST',
    body: JSON.stringify({
      ...pendingIssue,
      advertiserId: advertiserId,
      lastUpdatedBy: { id: pendingIssue.lastUpdatedBy.id },
      issueReporter: { id: pendingIssue.issueReporter.id }
    })
  });
  dispatch(loadingIssuesPage());
  if (response.ok) {
    await dispatch(initializeIssues());
  } else {
    dispatch(failSubmitPendingIssueAction());
  }
  dispatch(finishLoadingIssuesPage());
};

export const archiveIssueAction = id => async (dispatch, getState) => {
  const response = await authenticatedFetch(`${apiServer()}/archive-issues/${id}`, {
    method: 'POST'
  });
  if (response.ok) {
    dispatch(thisModule.fetchIssuesAction());
  }
};
