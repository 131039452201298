import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openModal, closeModal } from '@cjdev-internal/visual-stack-redux';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack-x/components/Panel';
import { formatDate, formatDistance } from '../../utils';
import { CreateButton, EditButton, RunButton } from '../../components/Button';
import ErrorSpan from '../../components/ErrorMessage';
import { Table, Thead, Tr, Td, Th } from '../../components/Table';
import { AppLayout } from '../App';
import RunTestConfigModal from './RunTestConfigModal';
import {
  archiveAndLoad,
  fetchTestConfigs,
  resetArchiveTestConfig
} from '../../domain/TestConfigs/actions';
import { ProductModalMountPoint, createArchiveModal } from '../../components/Modal';
import { ArchiveModalButton } from '../../components/Modal/ModalButton';
import { selectTestConfigForAdvertiser } from '../../reducer';
import { fetchProducts } from '../../domain/Products/actions';
import {history, withNavigate} from '../../router'

const ModalDialog = createArchiveModal('Archive Test Configuration', 'testConfig');

const Names = styled(Th)`
  width: 60%;
`;

const Urls = styled(Th)`
  width: 10%;
`;

const WrapDiv = styled.div`
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
`;

const LastModifiedDate = Urls;

export const TestConfigsTable = ({
  testConfigs,
  navigateToCreatePage,
  navigateToEditPage,
  openRunModal,
  archiveAction,
  resetArchiveTestConfig
}) => {
  const body = testConfigs.map((testConfig, index) => {
    const { id, configName, urlsCount, urlGroups, lastModified } = testConfig;
    return (
      <Tr key={index}>
        <Td>{configName}</Td>
        <Td right>{urlsCount}</Td>
        <Td right>{urlGroups ? urlGroups.length : 0}</Td>
        <Td nowrap>
          {formatDate(lastModified)}
          <br />
          {formatDistance(lastModified)}
        </Td>
        <Td right>
          <WrapDiv nowrap>
            <EditButton onClick={() => navigateToEditPage(id)} />
            <RunButton onClick={() => openRunModal(testConfig)} />
            <ArchiveModalButton
              ModalDialog={ModalDialog}
              takeAction={() => archiveAction(id)}
              itemName={configName}
              onCancel={resetArchiveTestConfig}
            />
          </WrapDiv>
        </Td>
      </Tr>
    );
  });
  return (
    <Table>
      <Thead>
        <Tr>
          <Names>
            <b>Name</b>
          </Names>
          <Urls nowrap right>
            <b>Urls</b>
          </Urls>
          <Urls nowrap right>
            <b>Url Groups</b>
          </Urls>
          <LastModifiedDate nowrap>
            <b>Last Modified Date</b>
          </LastModifiedDate>
          <Th right>
            <CreateButton onClick={navigateToCreatePage} />
          </Th>
        </Tr>
      </Thead>
      <tbody>{body}</tbody>
    </Table>
  );
};

export class TestConfigs extends React.Component {
  componentDidMount() {
    this.props.fetchTestConfigs();
    this.props.fetchProducts();
  }
  render() {
    const { data: testConfigs, hasError, openModal, archiveAndLoad } = this.props;
    return (
      <AppLayout pageHeaderTitle="Test Configurations">
        <ProductModalMountPoint />
        <Panel>
          <PanelBody>
            {hasError ? (
              <ErrorSpan>Failed To Fetch</ErrorSpan>
            ) : (
              <TestConfigsTable
                testConfigs={testConfigs}
                archiveAction={archiveAndLoad}
                navigateToCreatePage={() => {
                  history.push(('/test-config'));
                  this.props.navigate('/test-config');
                }}
                navigateToEditPage={configId => {
                  history.push(`/test-config/${configId}`);
                  this.props.navigate(`/test-config/${configId}`);
                }}
                openRunModal={testConfig => {
                  openModal(RunTestConfigModal, { testConfig });
                }}
                resetArchiveTestConfig={this.props.resetArchiveTestConfig}
              />
            )}
          </PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

const mapStateToProps = state => selectTestConfigForAdvertiser(state);

export default withNavigate(connect(mapStateToProps, {
  fetchTestConfigs,
  archiveAndLoad,
  openModal,
  closeModal,
  resetArchiveTestConfig,
  fetchProducts
})(TestConfigs));
