import styled from 'styled-components';

export const HorizontalSection = styled.div`
  display: flex;
  align-items: flex-start;

  & > div:not(:first-child) {
    padding-left: ${props => props.paddingLeft || '20px'};
  }
  padding-bottom: 20px;
`;

export const AlignedContainer = styled.div`
  width: 50%;
  & > div {
    margin-right: 10%;
  }
  @media (max-width: 900px) {
    width: 100%;
    & > div {
      margin-right: 0em;
    }
  }
`;

export const SideBySideFieldContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  @media (max-width: 900px) {
    display: block;
  }
  & > div {
    margin-right: 5%;
    width: 100%;
  }
`;
