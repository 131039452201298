import React from 'react';
import TokenSelection from './TokenSelection';
import { PHRASE_INPUT, TOKEN_SELECTION, STARTING_PAGE, EDIT_TOKEN_SELECTION } from './constants';
import StartingPage from './StartingPage';
import PhraseInput from './PhraseInput';
import { connect } from 'react-redux';
import R from 'ramda';
import { selectInferredProhibitedTexts } from '../../../reducer';
import {
  fetchInferredProhibitedTexts,
  saveInferredProhibitedTexts
} from '../../../domain/Products/actions';
import { withParams } from '../../../router'

export class InferredProhibitedText extends React.Component {
  state = {
    view: STARTING_PAGE,
    tokenizedPhrase: [],
    editingIndex: -1,
    tokenSelectionError: null
  };
  shouldRenderStartingPage = () => this.state.view === STARTING_PAGE;
  changeViewToStartingPage = () => this.setState({ view: STARTING_PAGE });
  shouldRenderPhraseInput = () => this.state.view === PHRASE_INPUT;
  changeViewToPhraseInput = () => this.setState({ view: PHRASE_INPUT });
  shouldRenderTokenSelection = () => this.state.view === TOKEN_SELECTION;
  changeViewToTokenSelection = () =>
    this.setState({ view: TOKEN_SELECTION, tokenSelectionError: null });
  shouldRenderTokenSelectionInEditMode = () => this.state.view === EDIT_TOKEN_SELECTION;
  changeViewToTokenSelectionInEditMode = () =>
    this.setState({ view: EDIT_TOKEN_SELECTION, tokenSelectionError: null });
  updateTokenizedPhrase = newTokenizedPhrase =>
    this.setState({ tokenizedPhrase: newTokenizedPhrase });
  acceptPhraseInput = tokenizedPhrase => {
    this.updateTokenizedPhrase(tokenizedPhrase);
    this.changeViewToTokenSelection();
  };
  editExistingConfiguration = (editingIndex, tokenizedPhrase) => {
    this.updateTokenizedPhrase(tokenizedPhrase);
    this.setState({ editingIndex });
    this.changeViewToTokenSelectionInEditMode();
  };
  setTokenSelectionError = tokenSelectionError => this.setState({ tokenSelectionError });

  saveNewPhraseInput = configuration => {
    if (R.isEmpty(configuration.selectedTokens)) {
      this.setTokenSelectionError('You need to select at least one token.');
    } else {
      this.props.saveInferredProhibitedTexts(
        this.getProductId(),
        R.prepend(configuration)(this.props.configurations)
      );
      this.changeViewToStartingPage();
    }
  };

  getProductId = () => {
    return this.props.params.id;
  };

  editPhraseInput = configuration => {
    if (R.isEmpty(configuration.selectedTokens)) {
      this.setTokenSelectionError('You need to select at least one token.');
    } else {
      this.props.saveInferredProhibitedTexts(
        this.getProductId(),
        R.update(this.state.editingIndex, configuration, this.props.configurations)
      );
      this.changeViewToStartingPage();
    }
  };

  deleteExistingConfiguration = index => {
    this.props.saveInferredProhibitedTexts(
      this.getProductId(),
      R.remove(index, 1, this.props.configurations)
    );
  };

  componentDidMount = () => {
    if (!R.isNil(this.props.params?.id)) {
      this.props.fetchInferredProhibitedTexts(this.props.params.id);
    }
  };
  render() {
    return (
      <div>
        {this.shouldRenderStartingPage() && (
          <StartingPage
            configurations={this.props.configurations}
            changeViewToPhraseInput={this.changeViewToPhraseInput}
            editExistingConfiguration={this.editExistingConfiguration}
            deleteExistingConfiguration={this.deleteExistingConfiguration}
          />
        )}
        {this.shouldRenderPhraseInput() && (
          <PhraseInput
            acceptPhraseInput={this.acceptPhraseInput}
            configurations={this.props.configurations}
            returnToPrevious={this.changeViewToStartingPage}
          />
        )}
        {this.shouldRenderTokenSelectionInEditMode() && (
          <TokenSelection
            error={this.state.tokenSelectionError}
            onSave={this.editPhraseInput}
            tokenizedPhrase={this.state.tokenizedPhrase}
            onChange={this.updateTokenizedPhrase}
            returnToPrevious={this.changeViewToStartingPage}
          />
        )}
        {this.shouldRenderTokenSelection() && (
          <TokenSelection
            error={this.state.tokenSelectionError}
            onSave={this.saveNewPhraseInput}
            tokenizedPhrase={this.state.tokenizedPhrase}
            onChange={this.updateTokenizedPhrase}
            returnToPrevious={this.changeViewToPhraseInput}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  configurations: selectInferredProhibitedTexts(state)
});

const mapDispatchToProps = { fetchInferredProhibitedTexts, saveInferredProhibitedTexts };

export default withParams(connect(mapStateToProps, mapDispatchToProps)(InferredProhibitedText));
