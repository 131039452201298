import * as R from 'ramda';
import * as Constant from '../../domain/ViolationDashboard/RiskIdentifications/constant';

const generateDimensionOption = dimension => ({ value: dimension, label: dimension });

const dimensionOptionMap = {
  [Constant.WEBSITE]: generateDimensionOption(Constant.WEBSITE),
  [Constant.PUBLISHER]: generateDimensionOption(Constant.PUBLISHER),
  [Constant.ERROR_CATEGORY]: generateDimensionOption(Constant.ERROR_CATEGORY),
  [Constant.PRODUCT]: generateDimensionOption(Constant.PRODUCT)
};

export const getDimensionOption = dimension => dimensionOptionMap[dimension];

export const dimensionOptions = R.values(dimensionOptionMap);
