import React from 'react';
import styled from 'styled-components';

const ToggleLine = styled.div`
  display: flex;
  align-items: center;
`;

export default ({ value, label, ...props }) => (
  <ToggleLine>
    <input type="checkbox" checked={value} {...props} />
    <span>{label}</span>
  </ToggleLine>
);
