import React from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import R from 'ramda';
import DomainFormDetail from './DomainFormDetail';
import {
  submitDomainConfigForm,
  fetchDomainConfigById,
  fetchDomainById,
  resetDomainConfig
} from '../../domain/Domains/actions';
import { getTrimmedList, addToConfigIfExists } from '../../utils';
import Loader from '../../components/Loader';
import HeightDiv from '../../components/HeightDiv';
import { SimpleError } from '../../components/ErrorMessage';
import PageBody from '../../components/PageBody';
import {history, withNavigate, withParams} from '../../router'

const trimList = list => getTrimmedList(list);

export class DomainForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.resetDomainConfig();

    this.props.fetchDomainConfig();
  }

  handleSubmit(values) {
    const { domainId, configId } = this.props.params;
    const config = {
      name: values.name,
      ratesAndFeesText: trimList(values.ratesAndFeesText),
      advertiserDisclosureText: trimList(values.advertiserDisclosureText),
      offerSelectors: trimList(values.offerSelectors),
      exclusionSelectors: trimList(values.exclusionSelectors),
      urls: trimList(values.urls)
    };

    const tooltip =
      values.tooltipType === 'none' ||
      R.isEmpty(values.tooltipSelector) ||
      (values.tooltipType === 'javascript' && R.isEmpty(values.tooltipAttribute))
        ? {}
        : {
            tooltipSelector: values.tooltipSelector,
            tooltipAttribute: values.tooltipAttribute,
            tooltipType: values.tooltipType
          };

    const nullableFields = {
      regex: values.regex,
      navigationScript: values.navigationScript,
      ...tooltip
    };
    const configWithNullableFields = addToConfigIfExists(nullableFields, config);

    return this.props
      .submitDomainConfigForm(configWithNullableFields, domainId, configId)
      .then(() => {
        history.push(`/domains/${domainId}`);
        this.props.navigate(`/domains/${domainId}`);
      })
      .catch(e => {
        throw new SubmissionError(e);
      });
  }

  makeFormData(config) {
    return {
      ...config,
      urls: R.defaultTo([])(config.urls).join('\n'),
      ratesAndFeesText: R.defaultTo([])(config.ratesAndFeesText).join('\n'),
      advertiserDisclosureText: R.defaultTo([])(config.advertiserDisclosureText).join('\n'),
      offerSelectors: R.defaultTo([])(config.offerSelectors).join('\n'),
      exclusionSelectors: R.defaultTo([])(config.exclusionSelectors).join('\n'),
      navigationScript: config.navigationScript || '',
      regex: config.regex || '.*',
      tooltipType: R.defaultTo('none')(config.tooltipType),
      tooltipSelector: R.defaultTo('')(config.tooltipSelector),
      tooltipAttribute: R.defaultTo('')(config.tooltipAttribute)
    };
  }

  render() {
    const { domainConfigDetail, error, isLoading } = this.props.domainConfig;
    const isEdit = R.has('configId', this.props.params);
    const addValues = R.isEmpty(domainConfigDetail)
      ? {}
      : isEdit
      ? { ...domainConfigDetail, id: this.props.params.configId }
      : { ...domainConfigDetail, name: '', id: '' };

    const initialValues = this.makeFormData(addValues);

    const domainFormAction = isEdit ? 'Edit' : 'Create';

    const domainFormDetail = error ? (
      <SimpleError error={error} />
    ) : (
      <DomainFormDetail
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        isDefaultConfig={initialValues.name === 'Default'}
      />
    );
    const loader = (
      <HeightDiv height={'1600'}>
        <Loader />
      </HeightDiv>
    );
    const pageBodyContent = isLoading ? loader : domainFormDetail;

    return <PageBody title={`${domainFormAction} Domain Configuration`} body={pageBodyContent} />;
  }
}

export const mapStateToProps = state => ({
  domainConfig: state.CAS.domains.domainConfig
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  submitDomainConfigForm: (values, domainId, configId) =>
    dispatch(submitDomainConfigForm(values, domainId, configId)),
  fetchDomainConfigById: (domainId, configId) => {
    dispatch(fetchDomainConfigById(domainId, configId));
  },
  resetDomainConfig: () => dispatch(resetDomainConfig()),
  fetchDomainConfig: async () => {
    const { domainId, configId } = ownProps.params;
    const inCreateMode = R.isNil(configId);
    if (inCreateMode) {
      const domainResponse = await dispatch(fetchDomainById(domainId));
      const { defaultConfigId, error } = domainResponse;
      if (R.isNil(error)) dispatch(fetchDomainConfigById(domainId, defaultConfigId));
    } else {
      dispatch(fetchDomainConfigById(domainId, configId));
    }
  }
});

export default withParams(withNavigate(connect(mapStateToProps, mapDispatchToProps)(DomainForm)));
