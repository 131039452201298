import React from 'react';
import UrlGroupForm from './UrlGroupForm';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';
import { joinUrls } from '../../components/Formik/UrlTextArea';
import {history, withNavigate, withParams} from '../../router'

export class EditUrlGroupForm extends React.Component {
  state = {
    initialValues: { name: '', urls: '' },
    isLoading: false,
    error: null
  };

  componentDidMount = async () => {
    try {
      const {
        params: { id }
      } = this.props;
      this.setState({ isLoading: true });
      const response = await authenticatedFetch(`${apiServer()}/url-groups/${id}`);
      const json = await response.json();
      const { groupName: name, urls } = json;
      this.setState({ initialValues: { name, urls: joinUrls(urls) }, isLoading: false });
    } catch (e) {
      this.setState({ error: 'Failed to fetch', isLoading: false });
    }
  };

  getUrlGroupId = () => this.props.params.id;

  render() {
    return (
      <React.Fragment>
        {
          <UrlGroupForm
            error={this.state.error}
            isLoading={this.state.isLoading}
            title="Edit URL Group"
            initialValues={this.state.initialValues}
            submitUrlGroup={async urlGroup => {
              await authenticatedFetch(`${apiServer()}/url-groups/${this.getUrlGroupId()}`, {
                method: 'post',
                body: JSON.stringify(urlGroup)
              });
              history.push('/url-groups');
              this.props.navigate('/url-groups');
            }}
          />
        }
      </React.Fragment>
    );
  }
}

export default withParams(withNavigate(EditUrlGroupForm));
