import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import R from 'ramda';

import { formatDate } from '../../utils';
import { Section, SectionTitle, SubSection, SectionBody } from '../../components/Section';
import ErrorSpan from '../../components/ErrorMessage';

const MetadataSectionRow = styled.div`
  line-height: 1.6em;
`;

const TestName = styled.span`
  font-weight: 600;
  font-size: 1.1em;
  color: #585858;
`;

const WrappedSpan = styled.span`
  word-wrap: break-word;
`;

const TestUrlLink = ({ url = 'URL NOT SPECIFIED' }) => {
  return (
    <WrappedSpan>
      <a href={url} rel="noopener noreferrer" target="_blank">
        {url}
      </a>
    </WrappedSpan>
  );
};

const DetailMetadata = ({ detail }) => (
  <Section>
    <SectionTitle first={true}>Summary</SectionTitle>
    <SectionBody>
      <SubSection>
        <MetadataSectionRow>
          <Link
            to={{
              pathname: `/test-results/${detail.testId}`,
              state: { shouldPreserveFilters: true }
            }}
          >
            <TestName>{detail.testName}</TestName>
          </Link>
        </MetadataSectionRow>
        <MetadataSectionRow>
          <TestUrlLink url={detail.testUrl} />
        </MetadataSectionRow>
        <MetadataSectionRow>
          Captured on{' '}
          {R.isNil(detail.existingScrapeTimestamp)
            ? formatDate(detail.startTime)
            : formatDate(detail.existingScrapeTimestamp)}
        </MetadataSectionRow>
        <MetadataSectionRow>Validated on {formatDate(detail.timestamp)}</MetadataSectionRow>
        {detail.systemError && (
          <MetadataSectionRow>
            <ErrorSpan>{detail.systemError.value}</ErrorSpan>
          </MetadataSectionRow>
        )}
      </SubSection>
    </SectionBody>
  </Section>
);

export default DetailMetadata;
