import { history } from '../../../router';
import { COMPLIANCE_VIOLATIONS } from '../../../paths';
import { parse, stringify } from 'query-string';
import * as Constant from '../../../domain/ViolationDashboard/RiskIdentifications/constant';
import { selectViolationProductNameMap } from '../../../reducer';
import store from '../../../store';

const getProductNameMap = () => {
  return selectViolationProductNameMap(store.getState());
};

const redirect = filter => {
  const existingQuery = history.location.search;
  const newQuery = stringify({
    ...parse(existingQuery),
    ...filter
  });
  history.push(`${COMPLIANCE_VIOLATIONS}?${newQuery}`);
};

const toInt = input => parseInt(input, 10);

export const create = dimension => {
  const redirectHelperMap = {
    [Constant.WEBSITE]: {
      redirect: website => {
        redirect({ websites: JSON.stringify([toInt(website)]) });
      }
    },
    [Constant.PRODUCT]: {
      redirect: product => {
        const productNameMap = getProductNameMap();
        redirect({ products: JSON.stringify([toInt(productNameMap[product])]) });
      }
    },
    [Constant.PUBLISHER]: {
      redirect: publisher => {
        redirect({ partners: JSON.stringify([toInt(publisher)]) });
      }
    },
    [Constant.ERROR_CATEGORY]: {
      redirect: errorCategory => {
        redirect({ errorCategories: JSON.stringify([errorCategory]) });
      }
    }
  };
  return redirectHelperMap[dimension];
};
