import React, {useEffect, useState} from "react";
import {AppLayout} from "../App";
import * as R from "ramda";
import {Button} from "@cjdev-internal/visual-stack-x/components/Button";
import {
    TableContainer,
    Table,
    TBody,
    Td,
    Th,
    THead,
    Tr,
} from "@cjdev-internal/visual-stack-x/Table";
import * as dateFns from "date-fns";
import {EDIT_ADVERTISER} from "../../paths";
import {Text} from "@cjdev-internal/visual-stack-x/Text";
import {MDIcon} from "@cjdev-internal/visual-stack-x/MDIcon";
import {Panel} from "@cjdev-internal/visual-stack-x/Panel";
import {Stack} from "@cjdev-internal/visual-stack-x/components/Stack";
import {LoadingAnimation} from "@cjdev-internal/visual-stack-x/LoadingAnimation";
import {Box} from "@cjdev-internal/visual-stack-x/components/Box";

const parseDate = (date) => {
    return dateFns.format(date, "YYYY/MM/DD hh:mm");
};

const Empty = () => {
    return (
        <Text italic color="subdued-light">
            N/A
        </Text>
    );
};

export const AdvertiserManagementPure = ({
                                             history,
                                             fetchAdvertisers,
                                             navigate,
                                             usePlatformNav,
                                             navigateTo,
                                             ActionsAdvertiser,
                                         }) => {
    const platformNav = usePlatformNav();
    const [advertisers, setAdvertisers] = useState([]);

    const loadAdvertisers = async () => {
        const response = await fetchAdvertisers();
        setAdvertisers(response);
    };

    useEffect(() => {
        loadAdvertisers();
    }, []);

    const Row = (advertiserManagement, index) => (
        <Tr key={index} data-testid="advertiserManagement">
            <Td data-testid="cid">{advertiserManagement.advertiser.cid}</Td>
            <Td data-testid="name">{advertiserManagement.advertiser.name}</Td>
            <Td data-testid="prefix">
                {advertiserManagement.advertiser.prefix || <Empty/>}
            </Td>
            <Td data-testid="partners">
                {R.length(advertiserManagement.websites || [])}
            </Td>
            <Td data-testid="products">
                {R.length(advertiserManagement.products || [])}
            </Td>
            <Td data-testid="creationTime">
                {parseDate(advertiserManagement.advertiser.creationTime)}
            </Td>
            <Td data-testid="lastUpdated">
                {parseDate(advertiserManagement.advertiser.lastUpdated)}
            </Td>
            <Td data-testid="enabled">
                {advertiserManagement.advertiser.enabled && <MDIcon icon="check"/>}
            </Td>
            <Td>
                <Button
                    data-testid="edit"
                    type="secondary"
                    onClick={() => editAdvertiser(advertiserManagement.advertiser.cid)}
                >
                    Edit
                </Button>
            </Td>
        </Tr>
    );

    const editAdvertiser = (id) => {
        const path = EDIT_ADVERTISER.replace(":advertiserId", id);
        navigateTo(path, navigate, history, platformNav);
    };

    return (
        <AppLayout pageHeaderTitle="Advertiser Management">
            <Stack gap="medium">
                {!platformNav && <ActionsAdvertiser/>}
                <Panel>
                    {advertisers.length ? (
                        <TableContainer style={{margin: 0}}>
                            <Table>
                                <THead>
                                    <Tr>
                                        <Th>
                                            <b>CID</b>
                                        </Th>
                                        <Th>
                                            <b>Name</b>
                                        </Th>
                                        <Th>
                                            <b>Prefix</b>
                                        </Th>
                                        <Th>
                                            <b>Partners</b>
                                        </Th>
                                        <Th>
                                            <b>Product(s)</b>
                                        </Th>
                                        <Th>
                                            <b>Creation Time</b>
                                        </Th>
                                        <Th>
                                            <b>Last Updated</b>
                                        </Th>
                                        <Th>
                                            <b>Enable</b>
                                        </Th>
                                        <Th/>
                                    </Tr>
                                </THead>
                                <TBody>{advertisers.map(Row)}</TBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Box style={{height: "100%"}} align="center" justify="center">
                            <LoadingAnimation/>
                        </Box>
                    )}
                </Panel>
            </Stack>
        </AppLayout>
    );
};
