import React from 'react';
import { IconButton } from "@cjdev-internal/visual-stack-x/components/Button";
import { format } from "../excelHelper";
import { saveSingleSheetXLSXFile } from "../../../Issues/ExportIssuesButton/helpers/excelHelper";

const TopPanel = ({ caption, dimension, dateRange, data, columns }) => {
    const handlerClick = () => {
        let transformedData = [];
        data.forEach((rowData) => {
            transformedData.push({
                row: rowData,
                selected: false,
            });
        });
        const items = format(columns, transformedData);
        saveSingleSheetXLSXFile({
            items,
            sheetName: dimension,
            fileName: `${caption} Report ${dateRange}.xlsx`,
        }, saveAs);
    };

    return (
        <div className={'Download-Excel-Panel'}>
            <IconButton className={'Download-Excel-Icon'} icon="download" outline onClick={handlerClick} data-testid="export-excel-button" />
        </div>
    );
};

export default TopPanel;
