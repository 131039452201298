import React from 'react';
import styled from 'styled-components';
import ErrorSpan from '../ErrorMessage';
import { getTrimmedList } from '../../utils';
import { ErrorDiv } from './Error';
import R from 'ramda';

const numLines = values => (values.length > 0 ? getTrimmedList(values, /[\r\n]+/).length : 0);

export const PaddedLabel = styled.label`
  padding-top: 10px;
  font-weight: 600;
`;

export const TextArea = styled.textarea`
  padding: 0.4em;
  width: 100%;
  min-height: ${props => props.height};
  max-height: 100%;
  margin-bottom: 1px;

  ${({ meta, theme }) =>
    !R.isNil(meta) &&
    meta.error &&
    meta.touched &&
    `border-color: ${theme.error.color};
    box-shadow: ${theme.error.boxShadow};
    &:focus {
      border-color: ${theme.error.color};
      box-shadow: ${theme.error.boxShadow};
    }`};
`;

export const SetWidthDiv = styled.div`
  max-width: ${props => props.width || '75%'};
`;

const TextAreaWrapper = ({ width, height, input, meta, ...rest }) => {
  return (
    <SetWidthDiv width={width}>
      <TextArea {...input} height={height} {...rest} meta={meta}>
        {' '}
      </TextArea>
      {meta && (
        <ErrorDiv>
          {' '}
          {meta.touched &&
            ((meta.error && <ErrorSpan>{meta.error}</ErrorSpan>) ||
              (meta.warning && <span>{meta.warning}</span>))}
        </ErrorDiv>
      )}
    </SetWidthDiv>
  );
};

const TypeCountText = ({ value, type }) => {
  const count = numLines(value);
  return <span>{count > 0 && `- (${count} ${type})`}</span>;
};

const SizedTextArea = ({ input, label, type = 'Entries', errorText, isHidden, ...rest }) => {
  return (
    <div hidden={isHidden}>
      <PaddedLabel htmlFor={input.name}>
        {`${label}`} <TypeCountText value={input.value} type={type} />
      </PaddedLabel>
      {errorText && <ErrorSpan>{errorText}</ErrorSpan>}
      <TextAreaWrapper input={input} {...rest} />
    </div>
  );
};

export const URLTextArea = props => (
  <SizedTextArea {...props} height="450px" width="55%" type="URLs" />
);

export const SideBySideLargeTextArea = props => (
  <SizedTextArea {...props} height="250px" width="100%" />
);

export const MediumTextArea = props => <SizedTextArea {...props} height="100px" width="400px" />;

export const MediumFlexTextArea = props => <SizedTextArea {...props} height="100px" width="100%" />;


export const AlignedTextArea = props => <SizedTextArea {...props} height="250px" width="100%" />;
