import React from 'react';
import styled from 'styled-components';
import DashboardDatePicker from './DashboardDatePicker';
import DashboardViewController from './DashboardViewController';
import { ToggleIcon as VSRToggleIcon } from '@cjdev-internal/visual-stack-redux/lib/components/SlidingPanel';
import { useNavigate, useSearchParams } from 'react-router-dom'
import {usePlatformNav} from "../../appRunner";
const DashboardHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d2;
  padding: 8px;
  width: 100%;
`;

const StyledVSRToggleIcon = styled(props => <VSRToggleIcon {...props} />)`
  > button {
    margin: 0px;
  }
`;

export const DashboardHeader = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const platformNav = usePlatformNav()
  const dashboardViewController = new DashboardViewController(navigate, searchParams.toString());
  return (
    <DashboardHeaderDiv>
      <DashboardDatePicker onSubmit={dashboardViewController.changeDateRange} />
      {platformNav ? <></> :<StyledVSRToggleIcon />}
    </DashboardHeaderDiv>
  );
};
