import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { Button } from '@cjdev-internal/visual-stack-x/components/Button';
import { TOKEN } from './constants';
import { Title } from './Style';
import ErrorMessage from '../../../components/ErrorMessage';
import { Row } from '@cjdev-internal/visual-stack-x/components/Row';
import { Text } from '@cjdev-internal/visual-stack-x/Text';

const Container = styled.div`
  width: 50%;
  & > * {
    padding-bottom: 10px;
    padding-top: 10px;
  }
`;
export const Token = styled.span`
  border-radius: 3px;
  margin: 0px 4px;
  margin-bottom: 6px;
  color: ${({ selected }) => (selected ? 'white' : '#cccccc')};
  background: ${({ selected }) => (selected ? '#00af65' : 'white')};
  border: 2px solid ${({ selected }) => (selected ? '#00af65' : '#cccccc')};
  cursor: pointer;
  user-select: none;
  padding: 10px 5px;
`;

const PhraseContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const format = tokenizedPhrase => {
  let phrase = '';
  let selectedTokens = [];
  tokenizedPhrase.forEach(({ selected, value }, index) => {
    phrase = `${phrase}${value} `;
    if (selected) selectedTokens.push(index);
  });
  return { phrase: R.trim(phrase), selectedTokens, variations: [] };
};

export default ({ tokenizedPhrase = [], onChange, returnToPrevious, onSave, error }) => {
  return (
    <Container>
      <Text weight="bold">Select Inferred Prohibited Text Values</Text>
      <PhraseContainer>
        {tokenizedPhrase.map(({ value, type, selected }, index) => {
          return type === TOKEN ? (
            <Token
              onClick={() => {
                const newTokenizedPhrase = R.adjust(
                  value => ({ ...value, selected: !value.selected }),
                  index,
                  tokenizedPhrase
                );
                onChange(newTokenizedPhrase);
              }}
              key={index}
              selected={selected}
            >
              {value}
            </Token>
          ) : (
            <Text data-testid="text-value" style={{marginBottom: '6px', userSelect: 'none', padding: '10px 1px'}} key={index}>{value}</Text>
          );
        })}
      </PhraseContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Row rowGap="small" columnGap="medium">
        <Button
          type="primary"
          data-testid="save"
          onClick={() => {
            const apiFormatData = format(tokenizedPhrase);
            onSave(apiFormatData);
          }}
        >
          Save
        </Button>
        <Button type="tertiary" data-testid="cancel" onClick={returnToPrevious} >Cancel</Button>
      </Row>
    </Container>
  );
};
