import React from 'react';
import {
  RiskIdentificationChartAdvertiser,
  RiskIdentificationChartPublisher
} from './RiskIdentificationAMChart';
import { Body, Header } from '@cjdev-internal/visual-stack-x/Panel';
import { connect } from 'react-redux';
import {
  selectRiskIdentificationChart,
  selectRiskIdentificationCaption,
  selectRiskIdentificationIsLoading,
  selectIsPublisher,
  selectViolationDashboardLabels
} from '../../../reducer';
import DimensionDropdown from './DimensionDropdown';
import ViolationZeroState, { Text } from '../../../components/ZeroState/ViolationZeroState';
import './style.css';
import * as R from 'ramda';
import { HeaderContent, ChartTitle, ChartContainer } from '../shared/style';

export const RiskIdentificationChart = ({ data, caption, isLoading, isPublisher, chartLabels }) => {
  const shouldRenderRiskIdentificationChart = R.length(data) > 0;
  const Chart = isPublisher ? (
    <RiskIdentificationChartPublisher data={data} />
  ) : (
    <RiskIdentificationChartAdvertiser data={data} labels={chartLabels} />
  );
  const ZeroState = (
    <ViolationZeroState>
      <Text>There is no data to display</Text>
    </ViolationZeroState>
  );

  return (
    <React.Fragment>
      <Header>
        <HeaderContent>
          <ChartTitle>{caption}</ChartTitle>
          <DimensionDropdown />
        </HeaderContent>
      </Header>
      <Body>
        <ChartContainer>
          {!shouldRenderRiskIdentificationChart && !isLoading && ZeroState}
          {shouldRenderRiskIdentificationChart && !isLoading && Chart}
        </ChartContainer>
      </Body>
    </React.Fragment>
  );
};

export default connect(state => ({
  isLoading: selectRiskIdentificationIsLoading(state),
  data: selectRiskIdentificationChart(state),
  caption: selectRiskIdentificationCaption(state),
  isPublisher: selectIsPublisher(state),
  chartLabels: selectViolationDashboardLabels(state)
}))(RiskIdentificationChart);
