import R from 'ramda';

export const getBase64EncodedString = file => {
  const fileReader = new FileReader();
  const filePromise = new Promise(resolve => {
    fileReader.onload = file => {
      const binary = file.target.result;
      resolve(btoa(binary));
    };
  });
  fileReader.readAsBinaryString(file);
  return filePromise;
};

export const validateFiles = files => {
  const errors = [];
  const fileLimit = 10;
  const fileSizeLimitBytes = 10000000;

  const validateFileExtensions = files => {
    R.forEach(file => {
      const i = file.name.lastIndexOf('.');
      const extension = file.name.substring(i);
      if (!validExtension(extension)) {
        errors.push(`File type ${extension} invalid.`);
      }
    }, files);
  };

  const validExtension = str => {
    const validExtensions = ['.jpg', '.jpeg', '.gif', '.png', '.bmp', '.tif', '.tiff'];
    return R.contains(str.toLowerCase(), validExtensions);
  };

  if (files.length > fileLimit) errors.push('Only 10 files can be uploaded at the same time.');
  R.forEach(file => {
    if (file.size > fileSizeLimitBytes) {
      errors.push(`${file.name} is larger than 10MB.`);
    }
  }, files);
  validateFileExtensions(files);
  return errors;
};
