import * as R from 'ramda';

export const denormalize = landingUrlConfigurations => {
  const urls = R.keys(landingUrlConfigurations);
  return urls.map(url => {
    const { querySettings, includedDomains } = landingUrlConfigurations[url];
    const configurations = R.keys(querySettings).map(queryKey => {
      const { status, value } = querySettings[queryKey];
      return { key: queryKey, status, value };
    });
    return {
      url,
      configurations,
      includedDomains: includedDomains.map(R.prop('value'))
    };
  });
};

const getDomainFromBasicDomainInfo = basicDomainInfo => id =>
  (R.find(R.propEq('id', id))(basicDomainInfo) || {}).domain;

const extractConfigurationsValuesToArray = landingUrlConfigurations =>
  landingUrlConfigurations.map(({ url, configurations, includedDomains }) => [
    url,
    configurations,
    includedDomains
  ]);

const extractQuerySettingsToArray = landingUrlConfigurations =>
  landingUrlConfigurations.map(([url, configurations, includedDomains]) => [
    url,
    configurations.map(({ key, value, status }) => [key, { value, status }]),
    includedDomains
  ]);

const putQuerySettingsBackToObject = (landingUrlConfigurations, getDomain) =>
  landingUrlConfigurations.map(([url, configurations, includedDomains = []]) => [
    url,
    {
      querySettings: R.fromPairs(configurations),
      includedDomains: includedDomains
        .map(id => ({
          value: id,
          label: getDomain(id)
        }))
        .filter(({ label }) => !R.isNil(label))
    }
  ]);

export const normalize = (landingUrlConfigurations, basicDomainInfo) => {
  const getDomain = getDomainFromBasicDomainInfo(basicDomainInfo);
  const configurationsWithExtractedUrl = extractConfigurationsValuesToArray(
    landingUrlConfigurations
  );
  const configurationsWithExtractedQueryParameters = extractQuerySettingsToArray(
    configurationsWithExtractedUrl
  );
  const configurationsWithQuerySettingObjects = putQuerySettingsBackToObject(
    configurationsWithExtractedQueryParameters,
    getDomain
  );
  const configurationsInObject = R.fromPairs(configurationsWithQuerySettingObjects);
  return configurationsInObject;
};
