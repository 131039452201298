import React from 'react';
import qs from 'query-string';
import {COMPLIANCE_VIOLATIONS} from '../../paths';
import store from '../../store';
import {fetchIssuesPageAction} from '../../domain/Issues/actions';
import * as R from 'ramda';
import {isNil, mergeAll} from 'ramda';
import styled from 'styled-components';
import {addDays, endOfDay, getTime, subMilliseconds} from 'date-fns';
import {Text} from '../../components/ZeroState/ViolationZeroState';
import {putAdvertisers} from '../Advertisers/AdvertisersDropdown/advertiserStorage';
import {filterSeverities} from '../Advertisers/AdvertisersDropdown/advertiserHelper';
import {history} from "../../router";

const ZeroStateDiv = styled.div`
  & > button {
    margin: 8px 4px;
  }
`;

class IssueViewController {
    constructor(navigate, searchParams, dispatch = store.dispatch, getState = store.getState) {
        this.navigate = navigate
        this.searchParams = searchParams
        this.dispatch = dispatch;
        this.getState = getState;
    }

    clearAll = () => {
        putAdvertisers([]);
        history.push(`${COMPLIANCE_VIOLATIONS}`);
        this.navigate(`${COMPLIANCE_VIOLATIONS}`);
        this.dispatch(fetchIssuesPageAction());
    };

    setQuery = transformation => {
        const existingQueryObject = qs.parse(this.searchParams.toString());
        const nextQueryObject = transformation(existingQueryObject);
        const queryString = qs.stringify(nextQueryObject);
        history.push(`${COMPLIANCE_VIOLATIONS}?${queryString}`);
        this.navigate(`${COMPLIANCE_VIOLATIONS}?${queryString}`);
        this.dispatch(fetchIssuesPageAction());
    };

    changePagination = pagination => {
        this.setQuery(prevQuery => {
            const isChangingSizePerPage =
                !isNil(prevQuery.size) && `${prevQuery.size}` !== `${pagination.size}`;
            const nextPage = isChangingSizePerPage && {page: 1};
            return mergeAll([prevQuery, pagination, nextPage]);
        });
    };

    changeFilters = (filters, dateFilter) => {
        this.setQuery(prevQuery => {
            const hasPageInformation = !isNil(prevQuery.page);
            const nextPage = hasPageInformation && {page: 1};
            filters.advertisers && putAdvertisers(filters.advertisers);
            const filteredSeverities = filterSeverities(filters.advertisers, filters.severity || []);
            return mergeAll([
                prevQuery,
                R.dissoc('advertisers', filters),
                nextPage,
                {
                    severity: JSON.stringify(R.isEmpty(filteredSeverities) ? undefined : filteredSeverities)
                },
                {products: JSON.stringify(filters.products)},
                {websites: JSON.stringify(filters.websites)},
                {partners: JSON.stringify(filters.partners)},
                {errorCategories: JSON.stringify(filters.errorCategories)},
                {from: `${getTime(dateFilter[0][0])}`},
                {to: `${getTime(subMilliseconds(addDays(dateFilter[0][1], 1), 1))}`},
                {previousFrom: `${getTime(dateFilter[1][0])}`},
                {previousTo: `${getTime(endOfDay(dateFilter[1][1]))}`}
            ]);
        });
    };

    changeDateRange = ([[from, to], [previousFrom, previousTo]]) => {
        this.setQuery(prevQuery => {
            const fromMillis = getTime(from);
            const toMillis = getTime(endOfDay(to));
            const previousFromMillis = getTime(previousFrom);
            const previousToMillis = getTime(endOfDay(previousTo));
            return mergeAll([prevQuery, {from: `${fromMillis}`}, {to: `${toMillis}`}, {previousFrom: `${previousFromMillis}`},
                {previousTo: `${previousToMillis}`}]);
        });
    };

    changeSorting = sorting => {
        this.setQuery(prevQuery => {
            return mergeAll([prevQuery, sorting]);
        });
    };

    getZeroStateContent = configureIssueButtonGenerator => {
        const hasFilter = R.isEmpty(this.searchParams.toString());
        return (
            <React.Fragment>
                {hasFilter && (
                    <ZeroStateDiv>
                        <Text>You do not have any errors to report at this time.</Text>
                    </ZeroStateDiv>
                )}
                {!hasFilter && (
                    <div>
                        <Text>No violations fall under this set of filters.</Text>
                    </div>
                )}
            </React.Fragment>
        );
    };
}

export default IssueViewController;
