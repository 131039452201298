import * as R from 'ramda';
import { isPStyleAdvertiser } from './advertiserStorage';
const P1 = 'P1';
const P2 = 'P2';
const P3 = 'P3';
const pStyleSeverities = [P1, P2, P3];

const HIGH = 'High';
const MEDIUM = 'Medium';
const LOW = 'Low';
const defaultSeverities = [HIGH, MEDIUM, LOW];

const isPstyleSeverity = severity => pStyleSeverities.some(R.equals(severity));
const isDefaultSeverity = severity => defaultSeverities.some(R.equals(severity));

export const filterSeverities = (advertiserIds, severities) => {
  if (R.isNil(advertiserIds) || R.isEmpty(advertiserIds)) return severities;
  const hasPStyleAdvertisers = advertiserIds.some(isPStyleAdvertiser);
  const hasDefaultAdvertisers = advertiserIds.some(id => !isPStyleAdvertiser(id));

  if (hasPStyleAdvertisers && hasDefaultAdvertisers) return severities;
  if (hasPStyleAdvertisers) return R.filter(isPstyleSeverity)(severities);
  if (hasDefaultAdvertisers) return R.filter(isDefaultSeverity)(severities);
};
