import {FlexPage} from '@cjdev-internal/visual-stack-x/FlexPage';
import {Panel} from '@cjdev-internal/visual-stack-x/Panel';
import {Box} from '@cjdev-internal/visual-stack-x/components/Box';

export const ManageRecipientLoading = ({LoadingView}) => {
    return (
        <FlexPage heightAdjust="32px">
            <Panel style={{flexGrow: 1}}>
                <Box style={{height: '100%'}} align="center" justify="center">
                    <LoadingView/>
                </Box>
            </Panel>
        </FlexPage>
    );
};

