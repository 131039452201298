import React from 'react';
import { AppLayout } from '../../containers/App';
import { Body as PanelBody, Panel } from '@cjdev-internal/visual-stack-x/Panel';


export const PageBody = ({ title, body }) => {
  return (
    <AppLayout pageHeaderTitle={title}>
      <Panel>
        <PanelBody>{body}</PanelBody>
      </Panel>
    </AppLayout>
  );
};

export default PageBody;
