import XLSX from 'xlsx';


export const saveSingleSheetXLSXFile = ({
  items = [],
  sheetName = 'sheet',
  fileName = 'data.xlsx'
}, fileSaveAs) => {
  const sheets = [{items, sheetName}];
  saveMultipleSheetXLSXFile({sheets, fileName}, fileSaveAs);
};


export const saveMultipleSheetXLSXFile = ({
  sheets = [],
  fileName = 'data.xlsx'
}, fileSaveAs) => {
  const workbook = XLSX.utils.book_new();
  sheets.forEach(({items, sheetName, header = []}) => {
    const worksheet = XLSX.utils.json_to_sheet(items, { header});
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
  const wbout = XLSX.write(workbook, wopts);
  const s2ab = s => {
    const view = new Uint8Array(s.length);
    const newbuf = view.map((i, index) => s.charCodeAt(index) & 0xff);
    return newbuf;
  };

  fileSaveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), fileName);
};
