import React from 'react';
import { Section, SectionTitle, SectionBody } from '../../components/Section';
import CompetitiveOfferScreenshotCaptureRow from '../../components/OfferRow/CompetitiveOfferScreenshotCaptureRow';

const CompetitiveOffers = ({ offers }) => (
  <Section>
    <SectionTitle>Competitive Offers ({offers.length})</SectionTitle>
    <SectionBody>
      {offers.map((offer, index) => (
        <CompetitiveOfferScreenshotCaptureRow key={index} offer={offer} />
      ))}
    </SectionBody>
  </Section>
);

export default CompetitiveOffers;
