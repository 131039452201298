import React from 'react';
import styled from 'styled-components';
import R from 'ramda';
import { TrashIcon, UndoIcon } from '../../components/Icons/index.js';
import { formatSeconds } from '../../utils/index.js';
import {getValue} from "../../containers/IssuesDetail/helpers/dataHelpers";

export const ViewIssueAttachments = ({ issueAttachments }) => (
  <IssueAttachmentsDiv>
    {issueAttachments.map((attachment, i) => (
      <NonDeletableAttachment
        key={i}
        attachmentIndex={i}
        storageLink={attachment.storageLink}
        uploadTime={attachment.uploadTime}
      />
    ))}
  </IssueAttachmentsDiv>
);
export const NonDeletableAttachment = ({ storageLink, uploadTime }) => {
  const fileName = getFileName(storageLink);
  return (
    <AttachmentDiv>
      <ThumbnailContainer href={storageLink} target="_blank" title={fileName}>
        <Thumbnail src={storageLink} />
      </ThumbnailContainer>
      {
        <div>
          <AttachmentLabelContainer>
            <AttachmentLabel href={storageLink} target="_blank" title={fileName}>
              {fileName}
            </AttachmentLabel>
            <Blender />
          </AttachmentLabelContainer>
          <AttachmentUploadTime data-testid={'attachment-upload-time'}>{getValue(formatSeconds(uploadTime))}</AttachmentUploadTime>
        </div>
      }
    </AttachmentDiv>
  );
};

export const IssueAttachments = ({ issueAttachments, toggleDelete }) => (
  <IssueAttachmentsDiv>
    {issueAttachments.map((attachment, i) => (
      <Attachment
        key={i}
        attachmentIndex={i}
        storageLink={attachment.storageLink}
        uploadTime={attachment.uploadTime}
        toggleDelete={toggleDelete}
        deleteFlag={attachment.deleteFlag}
      />
    ))}
  </IssueAttachmentsDiv>
);

const IssueAttachmentsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: white;
`;

const getFileName = storageLink => {
  return R.last(R.split('/', storageLink));
};

export const Attachment = ({
  storageLink,
  uploadTime,
  attachmentIndex,
  toggleDelete,
  deleteFlag
}) => {
  const fileName = getFileName(storageLink);
  return (
    <AttachmentDiv deleteFlag={deleteFlag}>
      <ThumbnailContainer href={storageLink} target="_blank" title={fileName}>
        <Thumbnail deleteFlag={deleteFlag} src={storageLink} />
      </ThumbnailContainer>
      {deleteFlag ? (
        <PendingDeletion>
          <Warning>Pending Deletion</Warning>
          <UndoButtonContainer onClick={() => toggleDelete(attachmentIndex)}>
            <UndoIcon />
          </UndoButtonContainer>
        </PendingDeletion>
      ) : (
        <div>
          <AttachmentLabelContainer>
            <AttachmentLabel href={storageLink} target="_blank" title={fileName}>
              {fileName}
            </AttachmentLabel>
            <Blender />
            <DeleteButtonContainer onClick={() => toggleDelete(attachmentIndex)}>
              <TrashIcon />
            </DeleteButtonContainer>
          </AttachmentLabelContainer>
          <AttachmentUploadTime>{getValue(formatSeconds(uploadTime))}</AttachmentUploadTime>
        </div>
      )}
    </AttachmentDiv>
  );
};

const AttachmentDiv = styled.div`
  opacity: ${props => (props.deleteFlag ? '.5' : '1')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 190px;
  width: 200px;
  margin: 10px 15px 10px 0px;
  border-radius: 2px;
  &:hover {
    background: #e5e2e2;
    & > div > div > span {
      background-image: linear-gradient(
        to right,
        rgba(229, 226, 226, 0) 20%,
        rgba(229, 226, 226, 1) 70%
      );
    }
    & > div > div > div {
      display: block;
    }
  }
`;

const Thumbnail = styled.img`
  filter: ${props => (props.deleteFlag ? 'grayscale(100%)' : 'none')};
  max-height: 100%;
  max-width: 100%;
  margin: auto;
`;

const ThumbnailContainer = styled.a`
  padding: 2px;
  border: 1px solid #c4c2c2;
  border-radius: 2px;
  height: 140px;
  width: 200px;
  display: flex;
  justify-content: center;
`;

const AttachmentLabelContainer = styled.div`
  display: flex;
  width: 200px;
  position: relative;
  margin-top: 5px;
`;

const AttachmentLabel = styled.a`
  white-space: nowrap;
  max-width: 200px;
  height: 20px;
  overflow: hidden;
  display: block;
  padding: 0px 10px 0px 10px;
`;

const Blender = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  width: 5em;
  background-size: 100% 100%;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 1) 70%
  );
  pointer-events: none;
`;

const DeleteButtonContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  padding-top: 5px;
  display: none;
  &:hover {
    cursor: pointer;
  }
`;

const AttachmentUploadTime = styled.div`
  color: gray;
  padding: 0px 10px 5px 10px;
  font-size: 12px;
`;

const PendingDeletion = styled.div`
  display: flex;
  width: 200px;
  margin-top: 2px;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const Warning = styled.div`
  padding: 0px 10px 0px 10px;
  color: red;
`;

const UndoButtonContainer = styled.div`
  margin-right: 2px;
  &:hover {
    cursor: pointer;
  }
`;
