import { createAction } from 'redux-actions';
import { apiServer } from '../../env';
import { archiveDomainConfig as archiveDomainConfigRequest } from '../../shared/apis';
import { selectDomain } from '../../reducer';
import { getTrimmedList as trimList } from '../../utils';
import R from 'ramda';
import * as constant from './constant';
import { authenticatedFetch } from '../../shared/apis';

export const startFetchDomainConfigs = createAction(constant.FETCH_DOMAIN_CONFIGS_START);
export const storeDomainConfigs = createAction(constant.DOMAIN_CONFIGS_STORE);
export const failFetchDomainConfigs = createAction(constant.FETCH_DOMAIN_CONFIGS_FAILURE);

export const fetchDomainConfigs = domainId => async dispatch => {
  try {
    dispatch(startFetchDomainConfigs());
    const response = await authenticatedFetch(`${apiServer()}/domains/${domainId}/domain-configs`);
    const json = await response.json();
    dispatch(storeDomainConfigs(json));
  } catch (e) {
    dispatch(failFetchDomainConfigs(e.message));
  }
};

export const fetchDomainConfigsNoReload = domainId => async dispatch => {
  try {
    const response = await authenticatedFetch(`${apiServer()}/domains/${domainId}/domain-configs`);
    const json = await response.json();
    dispatch(storeDomainConfigs(json));
  } catch (e) {
    dispatch(failFetchDomainConfigs(e.message));
  }
};

export const startFetchDomainConfigById = createAction(constant.FETCH_DOMAIN_CONFIG_BY_ID_START);
export const storeDomainConfigById = createAction(constant.DOMAIN_CONFIG_BY_ID_STORE);
export const failFetchDomainConfigById = createAction(constant.FETCH_DOMAIN_CONFIG_BY_ID_FAILURE);
export const resetDomainConfig = createAction(constant.DOMAIN_CONFIG_RESET);

export const fetchDomainConfigById = (domainId, configId) => async dispatch => {
  try {
    dispatch(startFetchDomainConfigById());
    const response = await authenticatedFetch(
      `${apiServer()}/domains/${domainId}/domain-configs/${configId}`
    );
    const json = await response.json();
    dispatch(storeDomainConfigById(json));
  } catch (e) {
    dispatch(failFetchDomainConfigById(e.message));
  }
};

export const successSubmitConfigForm = createAction(constant.SUBMIT_CONFIG_FORM_SUCCESS);

export const submitDomainConfigForm = (values, domainId, configId) => async dispatch => {
  const id = R.isNil(configId) ? '' : `/${configId}`;
  const response = await authenticatedFetch(
    `${apiServer()}/domains/${domainId}/domain-configs${id}`,
    {
      headers: {
        'Content-Type': 'text/plain'
      },
      method: 'POST',
      body: JSON.stringify(values)
    }
  );
  const json = await response.json();
  dispatch(successSubmitConfigForm(json));
  return { domainId: domainId, configId: json.id };
};

export const startSubmitDomain = createAction(constant.SUBMIT_DOMAIN_START);
export const successSubmitDomain = createAction(constant.SUBMIT_DOMAIN_SUCCESS);
export const failSubmitDomain = createAction(constant.SUBMIT_DOMAIN_FAIL);
export const resetModal = createAction(constant.SUBMIT_DOMAIN_RESET);

export const submitDomain = (value, id) => async dispatch => {
  const domainid = !R.isNil(id) ? `/${id}` : '';
  dispatch(startSubmitDomain());
  try {
    const response = await authenticatedFetch(`${apiServer()}/domains${domainid}`, {
      headers: {
        'Content-Type': 'text/plain'
      },
      method: 'POST',
      body: JSON.stringify(value)
    });
    const json = await response.json();
    dispatch(successSubmitDomain(json));
  } catch (e) {
    dispatch(failSubmitDomain(e.message));
  }
};

export const submitDomainAndReload = (value, id) => {
  return async (dispatch, getState) => {
    return dispatch(submitDomain(value, id)).then(() => {
      const submissionError = getState().CAS.domains.domains.submitDomain.error;
      if (R.isNil(submissionError)) return dispatch(fetchDomains());
    });
  };
};

export const createDomainAndConfig = value => {
  return async (dispatch, getState) => {
    return dispatch(submitDomain(value)).then(() => {
      const { error, domain } = getState().CAS.domains.domains.submitDomain;
      if (R.isNil(error)) {
        const defaultValues = {
          name: 'Default',
          domain: domain.domain,
          scalaRegex: { pattern: ',*' }
        };
        return dispatch(submitDomainConfigForm(defaultValues, domain.id));
      }
    });
  };
};

export const postDomain = (values, domainId) => async (dispatch, getState) => {
  await dispatch(submitDomain(values, domainId));
  const submissionError = getState().CAS.domains.domains.submitDomain.error;
  if (R.isNil(submissionError)) {
    return dispatch(fetchDomainConfigsNoReload(domainId));
  }
};

export const submitDomainGlobalSettings = (values, domainId) => async (dispatch, getState) => {
  const domain = selectDomain(getState());
  const submittingValue = R.merge(
    domain,
    R.pick(['publisherIds', 'websiteIds', 'offerLinkUrlPatterns', 'domain'])(values)
  );

  const normalizedValues = {
    offerLinkUrlPatterns: trimList(submittingValue.offerLinkUrlPatterns),
    websiteIds: trimList(submittingValue.websiteIds),
    publisherIds: trimList(submittingValue.publisherIds)
  };

  const domainSettings = { ...submittingValue, ...normalizedValues };

  await dispatch(postDomain(domainSettings, domainId));
};

export const submitDomainConfigsOrder = (values, domainId) => async (dispatch, getState) => {
  const domain = selectDomain(getState());
  const submittingValue = R.merge(domain, values);
  return await dispatch(postDomain(submittingValue, domainId));
};

export const startFetchDomainById = createAction(constant.FETCH_DOMAIN_BY_ID_START);
export const successFetchDomainById = createAction(constant.FETCH_DOMAIN_BY_ID_SUCCESS);
export const failFetchDomainById = createAction(constant.FETCH_DOMAIN_BY_ID_FAIL);

export const fetchDomainById = id => async dispatch => {
  dispatch(startFetchDomainById());
  try {
    const response = await authenticatedFetch(`${apiServer()}/domains/${id}`);
    const json = await response.json();
    dispatch(successFetchDomainById(json));
    return { defaultConfigId: json.defaultConfig, error: null };
  } catch (e) {
    dispatch(failFetchDomainById(e.message));
    return { defaultConfigId: null, error: e.message };
  }
};

export const startFetchDomain = createAction(constant.FETCH_DOMAIN_START);
export const successFetchDomain = createAction(constant.FETCH_DOMAIN_SUCCESS);
export const failFetchDomain = createAction(constant.FETCH_DOMAIN_FAIL);

export const fetchDomains = () => async dispatch => {
  dispatch(startFetchDomain());
  try {
    const response = await authenticatedFetch(`${apiServer()}/domains`);
    const json = await response.json();
    dispatch(successFetchDomain(json));
  } catch (e) {
    dispatch(failFetchDomain(e.message));
  }
};

export const setArchiveErrorMessage = createAction(constant.SET_ARCHIVE_ERROR_MESSAGE);

export const archiveLoadingStart = createAction(constant.ARCHIVE_LOADING_START);

export const archiveLoadingComplete = createAction(constant.ARCHIVE_LOADING_COMPLETE);

export const archiveDomainConfig = (domainId, domainConfigId) => async dispatch => {
  try {
    dispatch(archiveLoadingStart());
    await archiveDomainConfigRequest(domainId, domainConfigId);
    dispatch(archiveLoadingComplete());
  } catch (e) {
    dispatch(setArchiveErrorMessage(e.message));
    throw e;
  }
};

export const submitDomainFilters = createAction(constant.DOMAIN_FILTERS_SUBMIT);
