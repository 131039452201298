export const PRODUCT_MODE_TOKEN = 'PRODUCT';
export const OFFER_LINK_MODE_TOKEN = 'LINK';
export const PRODUCT_MODE_CLASSNAME = 'product';
export const OFFER_LINK_MODE_CLASSNAME = 'link';
export const REQUIRED_TEXT_MODE_TOKEN = 'REQUIRED_TEXT';
export const REQUIRED_TEXT_MODE_CLASSNAME = 'required';
export const PROHIBITED_TEXT_MODE_TOKEN = 'PROHIBITED_TEXT';
export const PROHIBITED_TEXT_MODE_CLASS = 'prohibited';
export const INFERRED_PROHIBITED_TEXT_MODE_TOKEN = 'INFERRED_PROHIBITED_TEXT';
export const INFERRED_PROHIBITED_TEXT_MODE_CLASS = 'inferred-prohibited';
export const WARNING_BLOCK_TEXT_MODE_TOKEN = 'WARNING_BLOCK';
export const WARNING_BLOCK_TEXT_MODE_CLASS = 'warning-block';
export const IGNORE_MODE_TOKEN = 'IGNORE';
export const IGNORE_MODE_CLASSNAME = 'ignore';
export const OFFER_MODE_TOKEN = 'OFFER';
export const OFFER_MODE_CLASS = 'offer';
export const TOOL_TIP_MODE_TOKEN = 'TOOLTIP';
export const TOOL_TIP_MODE_CLASSNAME = 'tooltip';
