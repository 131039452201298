import { combineReducers } from 'redux';
import {
  STORE_ISSUE_DATA,
  FAIL_FETCH_ISSUE_DATA,
  LOADING_FETCH_ISSUE_DATA,
  LOADING_ISSUES_PAGE,
  FINISH_LOADING_ISSUES_PAGE
} from './actions';
import * as R from 'ramda';

export const normalizeIssue = issue => {
  return {
    ...issue,
    errorNotes: R.replace(/\\n/g, '\n', issue.errorNotes || ''),
    errorLocation: R.replace(/\\n/g, '\n', issue.errorLocation || '')
  };
};

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case LOADING_FETCH_ISSUE_DATA:
      return true;
    case FAIL_FETCH_ISSUE_DATA:
    case STORE_ISSUE_DATA:
      return false;
    default:
      return state;
  }
};
const numIssuesReducer = (state = 0, { type, payload }) => {
  switch (type) {
    case STORE_ISSUE_DATA:
      return payload.numIssues;
    default:
      return state;
  }
};
const dataReducer = (state = [], { type, payload }) => {
  switch (type) {
    case STORE_ISSUE_DATA:
      return R.map(normalizeIssue)(payload.issues);
    default:
      return state;
  }
};

const errorReducer = (state = null, { type }) => {
  switch (type) {
    case FAIL_FETCH_ISSUE_DATA:
      return 'failed to fetch';
    case LOADING_FETCH_ISSUE_DATA:
      return null;
    default:
      return state;
  }
};

const loadingPageReducer = (state = false, { type }) => {
  switch (type) {
    case LOADING_ISSUES_PAGE:
      return true;
    case FINISH_LOADING_ISSUES_PAGE:
      return false;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  isLoading: loadingReducer,
  data: dataReducer,
  error: errorReducer,
  isLoadingPage: loadingPageReducer,
  numIssues: numIssuesReducer
});

export const selectIssues = state => state.data;
export const selectNumIssues = state => state.numIssues;
export const selectIsLoadingIssuesPage = state => state.isLoadingPage;
