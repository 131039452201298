import styled from 'styled-components';
import React from 'react';
import R from 'ramda';
import { SectionRow, SectionRowTitle, SubSection } from '../Section';
import { ItemTable, Tr, Td, CounterSpan } from './ItemTable';
import {
  PROHIBITED_TEXT_MODE_TOKEN,
  INFERRED_PROHIBITED_TEXT_MODE_TOKEN
} from '../../domain/TestResultDetails/constant';
import ErrorSpan from '../ErrorMessage';

const translationMap = {
  PROHIBITED_TEXT: 'Prohibited Text',
  INFERRED_PROHIBITED_TEXT: 'Inferred Prohibited Text',
  NO_MATCHING_TEMPLATE: 'No Matching Template',
  REQUIRED_TEXT: 'Required Text Missing',
  INCORRECT_WEBSITE_ID: 'Incorrect Website Id',
  INCORRECT_AD_ID_PID_COMBO: 'Incorrect Ad ID + Website ID Combination',
  INCORRECT_AD_ID: 'Incorrect Ad Id',
  MALFORMED_CJ_LINK: 'Malformed CJ Link Found',
  LANDING_URL_ERROR: 'Landing Page URL',
  LANDING_PAGE_TIMEOUT_ERROR: 'Landing Page Timed Out'
};

const translate = before => translationMap[before] || before;

const StyledLink = styled.span`
  ${({ color: { fontColor, border, backgroundColor, hoverBackgroundColor } }) => `
    display: inline-block;
    cursor: pointer;
    border-radius: 2px;
    padding-left: 8px;
    padding-right: 8px;
    margin: 2px 0 2px 10px;
    color: ${fontColor};
    border: 1px solid ${border};
    background-color: ${backgroundColor};
    &:hover {
      background-color: ${hoverBackgroundColor};
    }
`};
`;

const productHelper = {
  extractAnchorKey: R.identity,
  extractType: value => <Td nowrap>{value}</Td>,
  extractValue: (_, anchors) => <Td width="100%">{anchors}</Td>,
  color: {
    fontColor: 'inherit',
    border: 'rgba(0, 0, 255, 1)',
    backgroundColor: 'rgba(0, 0, 255, 0.1)',
    hoverBackgroundColor: 'rgba(0, 0, 255, 0.2)'
  },
  shouldIncludeHighlightLinks: () => true
};

const errorHelper = {
  extractAnchorKey: ({ value }) => value,
  extractType: ({ type }) => <Td nowrap>{translate(type)}</Td>,
  extractValue: ({ value }, anchors) => (
    <Td width="100%">
      <ErrorSpan>{value}</ErrorSpan>
      {anchors}
    </Td>
  ),
  color: {
    fontColor: 'rgba(226, 29, 29, 1)',
    border: 'rgba(226, 29, 29, 1)',
    backgroundColor: 'rgba(226, 29, 29, 0.1)',
    hoverBackgroundColor: 'rgba(226, 29, 29, 0.2)'
  },
  shouldIncludeHighlightLinks: ({ type }) =>
    type === PROHIBITED_TEXT_MODE_TOKEN || type === INFERRED_PROHIBITED_TEXT_MODE_TOKEN
};

const linkHelpers = {
  product: productHelper,
  error: errorHelper
};

export const HighlightLink = ({ num, ...rest }) => <StyledLink {...rest}>{num + 1}</StyledLink>;

const EntryRow = ({ value, index, onClick, type, domElementsMap }) => {
  const {
    extractAnchorKey,
    extractType,
    extractValue,
    color,
    shouldIncludeHighlightLinks
  } = linkHelpers[type];
  const highlightData = shouldIncludeHighlightLinks(value)
    ? domElementsMap[extractAnchorKey(value)]
    : [];
  return (
    <Tr>
      <Td nowrap>
        <CounterSpan>{index + 1}.</CounterSpan>
      </Td>
      {extractType(value)}
      {extractValue(
        value,
        highlightData &&
          highlightData.map((domElement, i) => (
            <HighlightLink key={i} num={i} onClick={() => onClick(domElement)} color={color} />
          ))
      )}
    </Tr>
  );
};

const LinkTable = ({ values, ...rest }) => {
  return (
    <ItemTable>
      <tbody>
        {values.map((value, index) => {
          return <EntryRow key={index} index={index} value={value} {...rest} />;
        })}
      </tbody>
    </ItemTable>
  );
};

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ({ title, ...rest }) => {
  const linkTable = rest.values ? <LinkTable {...rest} /> : null;
  return (
    <SubSection>
      <SectionRow>
        <TopContainer>
          <div>
            <SectionRowTitle>{title}</SectionRowTitle>
            {linkTable}
          </div>
          {rest.children}
        </TopContainer>
      </SectionRow>
    </SubSection>
  );
};
