import React from 'react';
import { Formik } from 'formik';
import UrlInput, { getUrlDetail } from '../../components/Formik/UrlsInput';
import { SubmitButtonWrapper } from '../../components/FormFields/Submit';
import SavedNotification from '../../components/Notification/SavedNotification';
import ErrorMessage from '../../components/ErrorMessage';

const onSubmit = submitMasterUrls => async (values, formikBag) => {
  const { setStatus, setFieldValue, setSubmitting } = formikBag;
  const { urls, invalidUrls } = values;
  setStatus({
    submitted: false
  });
  const {
    urlsWithHttpProtocol,
    invalidUrlsArray,
    validUrlsFormValue,
    invalidUrlsFormValue
  } = getUrlDetail(urls, invalidUrls);
  setFieldValue('urls', validUrlsFormValue);
  setFieldValue('invalidUrls', invalidUrlsFormValue);
  if (invalidUrlsArray.length === 0) {
    try {
      await submitMasterUrls(urlsWithHttpProtocol);
      setStatus({
        submitted: true
      });
    } catch (e) {
      setStatus({
        error: true
      });
    }
  }
  setSubmitting(false);
};

export const MasterUrlsForm = formikBag => {
  const { handleSubmit, values } = formikBag;
  return (
    <form onSubmit={handleSubmit}>
      <UrlInput formikBag={formikBag} label="Master URLs" />
      <div>
        <SubmitButtonWrapper loading={formikBag.isSubmitting && !values.invalidUrls}>
          Submit
        </SubmitButtonWrapper>
        {formikBag.status && formikBag.status.submitted && <SavedNotification />}
        {formikBag.status && formikBag.status.error && (
          <ErrorMessage>Submission Failed</ErrorMessage>
        )}
      </div>
    </form>
  );
};

export default props => {
  const joinedUrls = props.urls ? props.urls.join('\n') : '';
  const submitMasterUrls = props.submitMasterUrls;
  return (
    <Formik
      initialValues={{ urls: joinedUrls, invalidUrls: '' }}
      onSubmit={onSubmit(submitMasterUrls)}
      render={MasterUrlsForm}
      enableReinitialize
    />
  );
};
