import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const SelectWrapper = styled.div`
    width: ${props => (props.width ? props.width : '406px')};
    margin: ${props => (props.margin ? props.margin : '0')};
`;

const StyledSelect = styled(Select)`
    .react-select__control {
        min-height: 30px;
    }

    .react-select__input input {
        height: auto !important;
    }

    .react-select__single-value,
    .react-select__placeholder {
        line-height: 30px !important;
    }

    input {
        &:focus {
            box-shadow: none;
            border: none;
        }
    }
`;

const FormSelect = props => {
    return (
        <SelectWrapper width={props.width} margin={props.margin}>
            <StyledSelect {...props} classNamePrefix="react-select"/>
        </SelectWrapper>
    );
};

export default FormSelect;
