import React from 'react';
import { ArchiveButton, CancelButton, SpinnerButton, CancelTestButton } from '../Button';
import { SimpleError } from '../ErrorMessage';
import * as M from '@cjdev-internal/visual-stack/lib/components/Modal';
import * as R from 'ramda';

export const ArchiveBody = props => {
  const { takeAction, onCancel, modalTitle, itemName, submitState, closeModal, bodyText } = props;
  return (
    <div>
      <M.Header title={modalTitle} />
      <M.Body>
        {R.isNil(bodyText) || R.isEmpty(bodyText) ? (
          <div>
            Are you sure you want to archive: <b>{itemName}?</b>
          </div>
        ) : (
          bodyText
        )}
      </M.Body>
      <M.Footer>
        {submitState.error && <SimpleError error={submitState.error} />}
        {!submitState.loading && <ArchiveButton onClick={takeAction} />}
        {submitState.loading && <SpinnerButton message="Archiving..." />}
        <CancelButton
          type="primary"
          onClick={() => {
            onCancel();
            closeModal();
          }}
        />
      </M.Footer>
    </div>
  );
};
export const CancelBody = props => {
  const { takeAction, onCancel, modalTitle, itemName, submitState, closeModal } = props;

  return (
    <div>
      <M.Header title={modalTitle} />
      <M.Body>
        Are you sure you want to cancel: <b>{itemName}?</b>
        <br />
        Note: URLs processed at and before cancellation will be reflected in results.
      </M.Body>
      <M.Footer>
        {submitState.error && <SimpleError error={submitState.error} />}
        {!submitState.loading && <CancelTestButton onClick={takeAction} text={'Cancel Test'} />}
        {submitState.loading && <SpinnerButton message="Canceling..." />}
        <CancelButton
          type="primary"
          onClick={() => {
            onCancel();
            closeModal();
          }}
        />
      </M.Footer>
    </div>
  );
};
