import React from 'react';
import {Button} from '@cjdev-internal/visual-stack-x/components/Button';
import {Input} from '@cjdev-internal/visual-stack-x/Input';
import R from 'ramda';
import {Text} from '@cjdev-internal/visual-stack-x/Text';
import {Row} from '@cjdev-internal/visual-stack-x/components/Row';
import {Stack} from '@cjdev-internal/visual-stack-x/components/Stack';

export default class advertiserIdConfig extends React.Component {
    state = {
        currentFormInput: ''
    };

    render() {
        const onChange = e => {
            this.setState({currentFormInput: e.target.value});
        };

        return (
            <Stack gap="small">
                <Text weight="bold" size={13}>Advertiser Filtering</Text>
                {this.props.advertiserId && (
                    <Text style={{margin: '5px 0'}} data-testid={'current-advertiser'}>
                        Your current selected advertiser Id is {this.props.advertiserId}
                    </Text>
                )}
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        this.props.onSave(this.state.currentFormInput);
                        this.setState({currentFormInput: ''});
                    }}
                >
                    <Input
                        style={{width: '20%'}}
                        placeholder="Enter advertiser id"
                        type="text"
                        onChange={onChange}
                        value={this.state.currentFormInput}
                    />
                    <Row gap='small'>
                        <Button
                            htmlType="submit"
                            disabled={R.isEmpty(this.state.currentFormInput)}
                            data-testid="saveButton"
                            type="primary"
                        >
                            Save
                        </Button>
                        <Button
                            data-testid="clearButton"
                            onClick={() => {
                                this.props.onClear();
                                this.setState({currentFormInput: ''});
                            }}
                            type="secondary"
                        >
                            Clear
                        </Button>
                    </Row>
                </form>
            </Stack>
        );
    }
}
