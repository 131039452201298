import React from 'react';
import styled from 'styled-components';

const FilterWrapper = styled.div`
  & > input[type='text'] {
    margin-bottom: 0;
  }
  & > input[type='text']:focus {
    border-color: rgb(221, 221, 221);
  }
`;

const Input = styled.input`
  min-width: 300px;
  margin-bottom: 0;
  &:focus {
    border-color: red;
  }
`;

class TextSearchFilter extends React.Component {
  constructor() {
    super();
    this.handleIconClick = this.handleIconClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.props.handleSubmit(event.target.value);
    }
  }

  handleIconClick() {
    this.props.handleSubmit(this.input.value);
  }

  handleChange(event) {
    const input = event.target.value;
    this.props.onChange(input);
  }

  render() {
    return (
      <FilterWrapper>
        <Input
          innerRef={input => {
            this.input = input;
          }}
          type="text"
          placeholder={this.props.label}
          onKeyPress={this.handleKeyPress}
          value={this.props.value}
          onChange={this.handleChange.bind(this)}
        />
      </FilterWrapper>
    );
  }
}

TextSearchFilter.defaultProps = {
  handleSubmit: () => {}
};

export default TextSearchFilter;
