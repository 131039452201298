export const FETCH_DOMAIN_CONFIGS_START = 'CAS/domains/FETCH_DOMAIN_CONFIGS_START';
export const DOMAIN_CONFIGS_STORE = 'CAS/domains/DOMAIN_CONFIGS_STORE';
export const FETCH_DOMAIN_CONFIGS_FAILURE = 'CAS/domains/FETCH_DOMAIN_CONFIGS_FAILURE';
export const FETCH_DOMAIN_CONFIG_BY_ID_START = 'CAS/domains/FETCH_DOMAIN_CONFIG_BY_ID_START';
export const DOMAIN_CONFIG_BY_ID_STORE = 'CAS/domains/DOMAIN_CONFIG_BY_ID_STORE';
export const FETCH_DOMAIN_CONFIG_BY_ID_FAILURE = 'CAS/domains/FETCH_DOMAIN_CONFIG_BY_ID_FAILURE';
export const DOMAIN_CONFIG_RESET = 'CAS/domains/DOMAIN_CONFIG_RESET';
export const SUBMIT_CONFIG_FORM_SUCCESS = 'CAS/domains/SUBMIT_CONFIG_FORM_SUCCESS';
export const SUBMIT_DOMAIN_START = 'CAS/domains/SUBMIT_DOMAIN_START';
export const SUBMIT_DOMAIN_SUCCESS = 'CAS/domains/SUBMIT_DOMAIN_SUCCESS';
export const SUBMIT_DOMAIN_FAIL = 'CAS/domains/SUBMIT_DOMAIN_FAIL';
export const SUBMIT_DOMAIN_RESET = 'CAS/domains/SUBMIT_DOMAIN_RESET';
export const FETCH_DOMAIN_BY_ID_START = 'CAS/domains/FETCH_DOMAIN_BY_ID_START';
export const FETCH_DOMAIN_BY_ID_SUCCESS = 'CAS/domains/FETCH_DOMAIN_BY_ID_SUCCESS';
export const FETCH_DOMAIN_BY_ID_FAIL = 'CAS/domains/FETCH_DOMAIN_BY_ID_FAIL';
export const FETCH_DOMAIN_START = 'CAS/domains/FETCH_DOMAIN_START';
export const FETCH_DOMAIN_SUCCESS = 'CAS/domains/FETCH_DOMAIN_SUCCESS';
export const FETCH_DOMAIN_FAIL = 'CAS/domains/FETCH_DOMAIN_FAIL';
export const SET_ARCHIVE_ERROR_MESSAGE = 'CAS/domains/SET_ARCHIVE_ERROR_MESSAGE';
export const ARCHIVE_LOADING_START = 'CAS/domains/ARCHIVE_LOADING_START';
export const ARCHIVE_LOADING_COMPLETE = 'CAS/domains/ARCHIVE_LOADING_COMPLETE';
export const DOMAIN_FILTERS_SUBMIT = 'CAS/domains/DOMAIN_FILTERS_SUBMIT';
