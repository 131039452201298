import React from 'react';
import { connect } from 'react-redux';
import { ModalMountPoint } from '@cjdev-internal/visual-stack-redux/lib/components/Modal';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack-x/components/Panel';
import { loadTests } from '../../domain/Tests/actions';
import TestsTable from './TestsTable';
import { AppLayout } from '../App';
import { selectTestsForAdvertiser } from '../../reducer';

export class TestsPage extends React.Component {
  componentDidMount() {
    this.props.loadTests();
  }
  render() {
    const { tests } = this.props;
    return (
      <AppLayout pageHeaderTitle="Tests">
        <ModalMountPoint />
        <Panel>
          <PanelBody>
            <TestsTable tests={tests} />
          </PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

export const mapStateToProps = state => ({
  tests: selectTestsForAdvertiser(state)
});
export const mapDispatchToProps = dispatch => ({
  loadTests: () => dispatch(loadTests())
});

export default connect(mapStateToProps, mapDispatchToProps)(TestsPage);
