import { combineReducers } from 'redux';
import * as Actions from './actions';

const urlGroupsDataReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Actions.SET_URL_GROUPS:
      return payload;
    default:
      return state;
  }
};

const urlGroupsErrorReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Actions.SET_URL_GROUPS:
      return null;
    case Actions.FETCH_URL_GROUPS_FAILURE:
      return payload;
    default:
      return state;
  }
};

const initialArchiveState = { loading: false, error: null };
const urlGroupsArchiveReducer = (state = initialArchiveState, { type, payload }) => {
  switch (type) {
    case Actions.START_ARCHIVE_URL_GROUP:
      return { loading: true, error: null };
    case Actions.SUCCESS_ARCHIVE_URL_GROUP:
      return { loading: false, error: null };
    case Actions.FAIL_ARCHIVE_URL_GROUP:
      return { loading: false, error: payload };
    case Actions.URL_GROUP_ARCHIVE_RESET:
      return { loading: false, error: null };
    default:
      return state;
  }
};

const urlGroupsIsLoadingReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Actions.START_FETCHING_URL_GROUPS:
      return true;
    case Actions.FETCH_URL_GROUPS_FAILURE:
    case Actions.SET_URL_GROUPS:
      return false;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  data: urlGroupsDataReducer,
  error: urlGroupsErrorReducer,
  isLoading: urlGroupsIsLoadingReducer,
  archive: urlGroupsArchiveReducer
});

export const selectUrlGroups = state => state.urlGroups;
