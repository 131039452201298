import React from 'react';
import { Formik } from 'formik';
import * as R from 'ramda';
import styled from 'styled-components';
import { prependHttpIfNotExist } from '../../utils/index';
import ErrorMessage from '../../components/ErrorMessage';
import { Input, Label, FormGroup, FormSelect, Toggle } from '../../components/Formik';
import UrlsInput, { getUrlDetail, splitUrls } from '../../components/Formik/UrlsInput';
import { SubmitButtonWrapper } from '../../components/FormFields/Submit';

const ERROR_MESSAGE = 'Failed to fetch';

const StyledErrorMessage = styled(ErrorMessage)`
  margin-left: 5px;
`;

const UrlGroupFormSelect = styled(FormSelect)`
  margin-bottom: 10px;
`;

const Form = styled.form`
  & > div {
    margin-bottom: 15px;
  }
`;

const normalize = testConfigs => {
  const {
    urls,
    invalidUrls,
    urlGroups,
    name: configName,
    product: productConfigId,
    scheduled,
    id
  } = testConfigs;
  return {
    urls: R.concat(splitUrls(urls), splitUrls(invalidUrls)).map(prependHttpIfNotExist),
    urlGroups,
    configName,
    productConfigId,
    scheduled,
    id
  };
};

const validate = ({ name, urls, invalidUrls, urlGroups, product }) => {
  const errors = {};
  if (R.isEmpty(name)) {
    errors.name = 'Name is required';
  }
  if (R.isEmpty(urls) && R.isEmpty(invalidUrls) && R.isEmpty(urlGroups)) {
    errors.urls = 'URLs are required';
  }
  if (R.isNil(product)) {
    errors.product = 'Product is required';
  }
  return errors;
};

export default ({ products, testConfigFormData, submitTestConfig, urlGroups, scheduled }) => (
  <Formik
    initialValues={{
      invalidUrls: '',
      urlGroups: [],
      scheduled: scheduled || false,
      ...testConfigFormData
    }}
    enableReinitialize
    validate={validate}
    onSubmit={async (values, formikBag) => {
      const { setFieldValue, setSubmitting, setStatus } = formikBag;
      setSubmitting(true);
      setStatus({});
      const { urls, invalidUrls } = values;
      const { validUrlsFormValue, invalidUrlsFormValue } = getUrlDetail(urls, invalidUrls);
      setFieldValue('urls', validUrlsFormValue);
      setFieldValue('invalidUrls', invalidUrlsFormValue);
      if (R.isEmpty(invalidUrlsFormValue)) {
        try {
          await submitTestConfig(normalize(values));
        } catch (e) {
          setStatus({
            error: ERROR_MESSAGE
          });
          setSubmitting(false);
        }
      } else {
        setSubmitting(false);
      }
    }}
    render={formikBag => {
      const {
        handleSubmit,
        setFieldValue,
        handleChange,
        values,
        isSubmitting,
        status = {},
        errors
      } = formikBag;
      return (
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Config Name</Label>
            <Input
              width="300px"
              name="name"
              onChange={handleChange}
              autoComplete="off"
              value={values.name}
            />
            <div>{errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}</div>
          </FormGroup>
          <FormGroup>
            <Toggle
              value={values.scheduled}
              name="scheduled"
              label={'Run Nightly'}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Url Groups</Label>
            <UrlGroupFormSelect
              clearable={false}
              options={
                urlGroups
                  ? urlGroups.data.map(urlGroup => ({
                      label: urlGroup.groupName,
                      value: urlGroup.id
                    }))
                  : []
              }
              name="urlGroups"
              value={values.urlGroups}
              onChange={e => {
                if (!R.isNil(e)) {
                  setFieldValue(
                    'urlGroups',
                    e.map(event => event.value)
                  );
                }
              }}
              multi
            />

            <UrlsInput
              formikBag={formikBag}
              label="Test URLs - (Duplicate URLs will be removed upon submission)"
            />
            <div>{errors.urls && <ErrorMessage>{errors.urls}</ErrorMessage>}</div>
          </FormGroup>
          <FormGroup>
            <Label>Product</Label>
            <FormSelect
              clearable={false}
              options={
                products
                  ? products.productDetail.map(product => ({
                      label: product.productName,
                      value: product.id
                    }))
                  : []
              }
              name="product"
              value={values.product}
              onChange={e => {
                if (!R.isNil(e)) {
                  setFieldValue('product', e.value);
                }
              }}
            />
            <div>{errors.product && <ErrorMessage>{errors.product}</ErrorMessage>}</div>
          </FormGroup>
          <SubmitButtonWrapper loading={isSubmitting}>Submit</SubmitButtonWrapper>
          {status.error && <StyledErrorMessage>{status.error}</StyledErrorMessage>}
        </Form>
      );
    }}
  />
);
