import React from 'react';
import IssueViewController from './issueViewController';
import ViolationZeroState from '../../components/ZeroState/ViolationZeroState';
import { useNavigate, useSearchParams } from 'react-router-dom'

export default ({ configureIssueButtonGenerator }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const issueViewController = new IssueViewController(navigate, searchParams)

  return (
    <ViolationZeroState>
      {issueViewController.getZeroStateContent(configureIssueButtonGenerator)}
    </ViolationZeroState>
  );
};
