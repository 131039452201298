import XLSX from 'xlsx';
import R from 'ramda';
import * as dateFns from 'date-fns';
import FileSaver from 'file-saver';
import { frontEndServer } from '../../env';
import { getCurrentDate } from './dateUtil';

// eslint-disable-next-line no-unused-vars
const addLinksToTestResultDetail = (itemList, ws) => {
  itemList.map(
    (item, index) =>
      (ws[`A${index + 2}`].l = { Target: `${frontEndServer()}/test-result-detail/${item.id}` })
  );
};

export const makeFileExport = (itemList = [], fileName = 'exported urls') => {
  const wsItemList = itemList.map(item => ({
    testResultDetail: `${frontEndServer()}/test-result-detail/${item.id}`,
    URL: item.url
  }));

  const worksheet = XLSX.utils.json_to_sheet(wsItemList, { header: ['testResultDetail', 'URL'] });

  // This line is commented out because the links generated break in Excel on Windows (but not OSX).
  // This is because the way we serve the web application results in a 404 for "deep links". If we
  // are ever asked to make this functionality work such that we change our hosting, this line can be
  // uncommented to wrap the urls in links.
  //
  // addLinksToTestResultDetail(itemList, ws);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
  const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' };
  const wbout = XLSX.write(workbook, wopts);
  const s2ab = s => {
    const view = new Uint8Array(s.length);
    const newbuf = view.map((i, index) => s.charCodeAt(index) & 0xff);
    return newbuf;
  };
  const currentTime = dateFns.format(getCurrentDate(), 'YYYY-MM-DD HH꞉mm꞉ss');
  FileSaver.saveAs(
    new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
    `${R.take(50)(fileName)} ${currentTime}.xlsx`
  );
};
