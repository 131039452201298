import styled from 'styled-components';
import * as R from 'ramda';

export const splitUrls = R.pipe(R.split('\n'), R.reject(R.isEmpty));
export const joinUrls = R.join('\n');

export default styled.textarea`
  width: 55%;
  height: 450px;
`;
