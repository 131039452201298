import * as constant from './constant';
import { LOCATION_CHANGE } from 'react-router-redux/reducer';
import { DOMAIN_PATH } from '../../paths';
import R from 'ramda';

export const defaultFilters = {
  domainSearch: ''
};

export const initialState = {
  domainConfig: {
    domainConfigDetail: {},
    error: null,
    isLoading: false,
    submitted: false
  },
  domainConfigs: {
    domainConfigsDetail: [],
    error: null,
    isLoading: false,
    archive: {
      error: null,
      loading: false
    }
  },
  domain: {
    domainDetail: {},
    error: null,
    isLoading: false
  },
  domains: {
    domainsDetail: [],
    error: null,
    filters: defaultFilters,
    isLoading: false,
    submitDomain: {
      domain: '',
      isLoading: false,
      error: null
    }
  }
};

export const selectAllBasicDomainInfo = ({ domains: { domainsDetail } }) =>
  domainsDetail.map(R.pick(['id', 'domain']));

const domainConfigArchiveReducer = (
  state = {
    error: null,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case constant.SET_ARCHIVE_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    case constant.ARCHIVE_LOADING_START:
      return {
        ...state,
        loading: true
      };
    case constant.ARCHIVE_LOADING_COMPLETE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.FETCH_DOMAIN_CONFIGS_START:
      return {
        ...state,
        domainConfigs: {
          ...state.domainConfigs,
          domainConfigsDetail: [],
          error: null,
          isLoading: true,
          submitted: false
        }
      };
    case constant.DOMAIN_CONFIGS_STORE:
      const defaultDomainId = action.payload.defaultDomainConfig.id;
      const domainConfigs = action.payload.domainConfigs;
      const result = domainConfigs.map(domainConfig => {
        const { id } = domainConfig;
        return id === defaultDomainId ? { ...domainConfig, default: true } : domainConfig;
      });
      return {
        ...state,
        domainConfigs: {
          ...state.domainConfigs,
          domainConfigsDetail: result,
          error: null,
          isLoading: false,
          submitted: false
        }
      };
    case constant.FETCH_DOMAIN_CONFIGS_FAILURE:
      return {
        ...state,
        domainConfigs: {
          ...state.domainConfigs,
          domainConfigsDetail: [],
          error: action.payload,
          isLoading: false,
          submitted: false
        }
      };
    case constant.SUBMIT_CONFIG_FORM_SUCCESS:
      return {
        ...state,
        domainConfigs: {
          ...state.domainConfigs,
          domainConfigsDetail: [],
          error: null,
          isLoading: false,
          submitted: true
        }
      };
    case constant.FETCH_DOMAIN_CONFIG_BY_ID_START:
      return { ...state, domainConfig: { domainConfigDetail: {}, isLoading: true, error: null } };
    case constant.DOMAIN_CONFIG_BY_ID_STORE:
      return {
        ...state,
        domainConfig: { domainConfigDetail: action.payload, isLoading: false, error: null }
      };
    case constant.FETCH_DOMAIN_CONFIG_BY_ID_FAILURE:
      return {
        ...state,
        domainConfig: { domainConfigDetail: {}, isLoading: false, error: action.payload }
      };
    case constant.DOMAIN_CONFIG_RESET:
      return {
        ...state,
        domainConfigs: initialState.domainConfigs
      };
    case constant.SUBMIT_DOMAIN_START:
      return {
        ...state,
        domains: { ...state.domains, submitDomain: { domain: '', isLoading: true, error: null } }
      };
    case constant.SUBMIT_DOMAIN_SUCCESS:
      return {
        ...state,
        domain: {
          ...state.domain,
          domainDetail: action.payload
        },
        domains: {
          ...state.domains,
          submitDomain: { domain: action.payload, isLoading: false, error: null }
        }
      };
    case constant.SUBMIT_DOMAIN_FAIL:
      return {
        ...state,
        domains: {
          ...state.domains,
          submitDomain: { domain: '', isLoading: false, error: action.payload }
        }
      };
    case constant.SUBMIT_DOMAIN_RESET:
      return {
        ...state,
        domains: {
          ...state.domains,
          submitDomain: { ...initialState.domains.submitDomain }
        }
      };
    case constant.FETCH_DOMAIN_BY_ID_START:
      return {
        ...state,
        domain: {
          ...state.domain,
          domainDetail: {},
          isLoading: true,
          error: null
        }
      };
    case constant.FETCH_DOMAIN_BY_ID_SUCCESS:
      return {
        ...state,
        domain: {
          ...state.domain,
          domainDetail: action.payload,
          isLoading: false,
          error: null
        }
      };
    case constant.FETCH_DOMAIN_BY_ID_FAIL:
      return {
        ...state,
        domain: {
          ...state.domain,
          domainDetail: {},
          isLoading: false,
          error: action.payload
        }
      };
    case constant.FETCH_DOMAIN_START:
      return {
        ...state,
        domains: {
          ...state.domains,
          domainsDetail: [],
          isLoading: true,
          error: null
        }
      };
    case constant.FETCH_DOMAIN_SUCCESS:
      return {
        ...state,
        domains: {
          ...state.domains,
          domainsDetail: action.payload,
          isLoading: false,
          error: null
        }
      };
    case constant.FETCH_DOMAIN_FAIL:
      return {
        ...state,
        domains: {
          ...state.domains,
          domainsDetail: [],
          isLoading: false,
          error: action.payload
        }
      };
    case constant.SET_ARCHIVE_ERROR_MESSAGE:
    case constant.ARCHIVE_LOADING_START:
    case constant.ARCHIVE_LOADING_COMPLETE:
      return {
        ...state,
        domainConfigs: {
          ...state.domainConfigs,
          archive: domainConfigArchiveReducer(state.archive, action)
        }
      };
    case constant.DOMAIN_FILTERS_SUBMIT:
      return {
        ...state,
        domains: {
          ...state.domains,
          filters: {
            ...state.domains.filters,
            ...action.payload
          }
        }
      };
    case LOCATION_CHANGE: {
      const resetDomainSearch = !R.startsWith(DOMAIN_PATH, action.payload.pathname);
      return resetDomainSearch
        ? {
            ...state,
            domains: {
              ...state.domains,
              filters: {
                ...state.domains.filters,
                domainSearch: ''
              }
            }
          }
        : state;
    }
    default:
      return state;
  }
};

export const selectDomain = state => state.domain.domainDetail;
