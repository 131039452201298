import styled from 'styled-components';

export const Title = styled.div`
  display: inline-block;
  font-weight: 600;
`;


export const Buttons = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 10px;
    margin-top: 12px;
  }
`;
