import loginConfiguration from './loginConfiguration';
import ClientOAuth2 from 'client-oauth2';
import { apiServer } from '../../env';

const env = process.env.REACT_APP_CAS_ENV;
const authConfig = loginConfiguration[env];

const cjAuth = new ClientOAuth2(authConfig);

export const getAuthRedirectUri = (redirectPath) => {
  const state = { nonce: (+new Date()).toString(36), redirectPath };
  return cjAuth.token.getUri({
    state: btoa(JSON.stringify(state)),
  });
};

export const getOauthData = async (href) => {
  const token = await cjAuth.token.getToken(href);
  const { redirectPath } = JSON.parse(atob(token.data.state));
  return { token: token.accessToken, redirectPath };
};

export const getUser = async (token) => {
  try {
    const auth = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const userInfoResponse = await fetch(`${apiServer()}/users`, auth);
    if (userInfoResponse.status !== 200) {
      return userInfoResponse;
    } else {
      const userInfo = await userInfoResponse.json();
      return { ...userInfo };
    }
  } catch (e) {
    return Promise.reject(e);
  }
};
