import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import BreadCrumb, { BreadCrumbSpan } from '../../components/BreadCrumb';

const Em = styled(BreadCrumbSpan)`
  font-style: italic;
`;

const BreadCrumbTestName = ({ testId, testName }) => {
  return (
    <BreadCrumbSpan>
      Result for{' '}
      <Em>
        <Link
          to={{
            pathname: `/test-results/${testId}`,
            state: { shouldPreserveFilters: true }
          }}
          replace={false}
        >
          {testName}
        </Link>
      </Em>
    </BreadCrumbSpan>
  );
};

const TestResultBreadCrumb = props => (
  <BreadCrumb nodes={['Tests', 'Results', <BreadCrumbTestName {...props} />]} />
);

export default TestResultBreadCrumb;
