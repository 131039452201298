import styled from 'styled-components';
import { getStyle } from '../../utils/styleUtil';
import { Input } from '@cjdev-internal/visual-stack-x/components/Input';

export default styled(Input)`
  margin: 0 !important;
  ${({ width, theme, error }) => {
    const errorCss =
      error &&
      `
  border-color: ${getStyle(['error', 'color'])(theme)} !important;
  box-shadow: ${getStyle(['error', 'boxShadow'])(theme)} !important;
  `;
    return `
  width: ${width || '100%'} !important;
  ${errorCss}
  padding: 0.4em;
  `;
  }};
`;
