import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  fill: #999;
  transition: 0.1s all ease-in-out;
  &:hover {
    fill: #49c5b1;
  }
`;
const AttachmentsIcon = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
  </StyledSvg>
);

const EmptyAttachmentsIcon = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
  </StyledSvg>
);
export { AttachmentsIcon, EmptyAttachmentsIcon };
