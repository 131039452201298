import {apiServer} from "../../../env";
import {authenticatedFetch} from "../../../shared/apis";

export const fetchAdvertisers = async () => {
    const response = await authenticatedFetch(
        `${apiServer()}/advertiser-managements`
    );
    const responseJSON = await response.json();
    return responseJSON;
};

export const fetchAdvertiserById = async (advertiserId) => {
    const response = await authenticatedFetch(
        `${apiServer()}/advertiser-managements/${advertiserId}`
    );
    const responseJSON = await response.json();
    return responseJSON;
};

export const editAdvertiser = async (advertiserId, payload) => {
    const advertiserJSON = JSON.stringify(payload);
    const response = await authenticatedFetch(
        `${apiServer()}/advertiser-managements/${advertiserId}`,
        {
            method: "POST",
            body: advertiserJSON,
        }
    );
    return response;
};

export const createAdvertiser = async (payload) => {
    const advertiserJSON = JSON.stringify(payload);
    const response = await authenticatedFetch(
        `${apiServer()}/advertiser-managements`,
        {
            method: "POST",
            body: advertiserJSON,
        }
    );
    return response;
};
