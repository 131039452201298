import { createAction } from 'redux-actions';
import { apiServer } from '../../env';
import { authenticatedFetch } from '../../shared/apis';

// TEST_RESULT_DETAIL
export const TEST_RESULT_DETAIL_LOAD_START = 'CAS/TestResults/TEST_RESULT_DETAIL_LOAD_START';
export const startLoadTestResultDetail = createAction(TEST_RESULT_DETAIL_LOAD_START);

export const TEST_RESULT_DETAIL_STORE = 'CAS/TestResults/TEST_RESULT_DETAIL_STORE';
export const storeTestResultDetail = createAction(TEST_RESULT_DETAIL_STORE);

export const TEST_RESULT_DETAIL_LOAD_FAIL = 'CAS/TestResults/TEST_RESULT_DETAIL_LOAD_FAIL';
export const failLoadTestResultDetail = createAction(TEST_RESULT_DETAIL_LOAD_FAIL);


export const loadTestResultDetail = resultId => {
  return async dispatch => {
    dispatch(startLoadTestResultDetail());
    try {
      const response = await authenticatedFetch(`${apiServer()}/test-result?id=${resultId}`);
      const result = await response.json();
      dispatch(storeTestResultDetail(result));
    } catch (err) {
      dispatch(failLoadTestResultDetail(err));
    }
  };
};
