import {FlexPage} from '@cjdev-internal/visual-stack-x/FlexPage';
import {LoadingAnimation} from '@cjdev-internal/visual-stack-x/LoadingAnimation';
import {Panel} from '@cjdev-internal/visual-stack-x/Panel';
import {Box} from '@cjdev-internal/visual-stack-x/components/Box';

export const IssueDetailLoading = ({Loading}) => {
    return (
        <FlexPage heightAdjust="32px">
            <Panel style={{flexGrow: 1}}>
                <Box style={{height: '100%'}} align="center" justify="center">
                    <Loading/>
                </Box>
            </Panel>
        </FlexPage>
    );
};

const IssueDetailLoadingDefault = () => {
    return <IssueDetailLoading Loading={LoadingAnimation}/>
};

export default IssueDetailLoadingDefault;
