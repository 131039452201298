import React from 'react';
import { connect } from 'react-redux';
import { openModal, closeModal } from '@cjdev-internal/visual-stack-redux';
import { ArchiveButton, CancelTestButton, CreateButton } from '../Button';

export const ArchiveModalButtonPure = ({
  ModalDialog,
  openModal,
  closeModal,
  takeAction,
  itemName,
  onCancel,
  bodyText
}) => (
  <ArchiveButton
    onClick={() => openModal(ModalDialog, { takeAction, closeModal, itemName, onCancel, bodyText })}
  />
);

export const CreateModalButtonPure = ({ ModalDialog, openModal, closeModal, takeAction }) => (
  <CreateButton onClick={() => openModal(ModalDialog, { takeAction, closeModal })} />
);

export const CancelModalButtonPure = ({
  ModalDialog,
  openModal,
  closeModal,
  takeAction,
  itemName,
  onCancel
}) => (
  <CancelTestButton
    onClick={() => openModal(ModalDialog, { takeAction, closeModal, itemName, onCancel })}
    text={'Cancel'}
  />
);

export const ArchiveModalButton = connect(null, { openModal, closeModal })(ArchiveModalButtonPure);
export const CreateModalButton = connect(null, { openModal, closeModal })(CreateModalButtonPure);
export const SubmitModalButton = connect(null, { openModal, closeModal })(CancelModalButtonPure);
