import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';

export const STORE_PUBLISHER_DATA = 'Domains/advertiser/STORE_PUBLISHER_DATA';

export const storePublisherDataAction = createAction(STORE_PUBLISHER_DATA);

export const FAIL_FETCH_PUBLISHER_DATA = 'Domains/advertiser/FAIL_FETCH_PUBLISHER_DATA';

export const failFetchPublisherDataAction = createAction(FAIL_FETCH_PUBLISHER_DATA);

export const LOADING_FETCH_PUBLISHER_DATA = 'Domains/advertiser/LOADING_FETCH_PUBLISHER_DATA';

export const loadingFetchPublisherData = createAction(LOADING_FETCH_PUBLISHER_DATA);

export const STORE_SELECTED_PUBLISHER_ID = 'Domains/advertiser/STORE_SELECTED_PUBLISHER_ID';



export const fetchPublishersAction = () => async dispatch => {
  dispatch(loadingFetchPublisherData());
  try {
    const response = await authenticatedFetch(`${apiServer()}/publishers?`);
    const responseJSON = await response.json();
    dispatch(storePublisherDataAction(responseJSON));
  } catch (e) {
    dispatch(failFetchPublisherDataAction());
  }
};
