import { combineReducers } from 'redux';
import overviewsReducer, * as fromOverViews from './Overviews/reducer';
import riskIdentificationReducer, * as fromRiskIdentification from './RiskIdentifications/reducer';
import violationTrendsReducer, * as fromViolationTrends from './ViolationTrends/reducer';

export const reducer = combineReducers({
  overviews: overviewsReducer,
  riskIdentifications: riskIdentificationReducer,
  violationTrends: violationTrendsReducer
});

export const selectViolationsDashboardOverview = state =>
  fromOverViews.selectViolationsDashboardOverview(state.overviews);

export const selectRiskIdentificationTableHeaders = (severityHeaders, isPublisher) =>
  fromRiskIdentification.selectRiskIdentificationTableHeaders(severityHeaders, isPublisher);

export const selectRiskIdentificationTableData = (state, isPublisher, isPStyleSeverityAdvertiser) =>
  fromRiskIdentification.selectRiskIdentificationTableData(state.riskIdentifications, isPublisher);

export const selectShouldRenderRiskIdentificationTable = state =>
  fromRiskIdentification.selectShouldRenderRiskIdentificationTable(state.riskIdentifications);

export const selectRiskIdentificationCaption = state =>
  fromRiskIdentification.selectRiskIdentificationCaption(state.riskIdentifications);

export const selectSortingOption = isPublisher =>
  fromRiskIdentification.selectSortingOption(isPublisher);

export const selectRiskIdentificationDateRange = state =>
  fromRiskIdentification.selectRiskIdentificationDateRange(state.riskIdentifications);

export const selectRiskIdentificationChart = (state, payload) =>
  fromRiskIdentification.selectRiskIdentificationChart(state.riskIdentifications, payload);

export const selectRiskIdentificationIsLoading = state =>
  fromRiskIdentification.selectRiskIdentificationIsLoading(state.riskIdentifications);

export const selectTrendDateRange = state =>
  fromRiskIdentification.selectTrendDateRange(state.riskIdentifications);



export const selectViolationTrendsDataForChart = state =>
  fromViolationTrends.selectViolationTrendsDataForChart(state.violationTrends);

export const selectViolationTrendsIsLoading = state =>
  fromViolationTrends.selectViolationTrendsIsLoading(state.violationTrends);

export const selectTrendType = state => fromViolationTrends.selectTrendType(state.violationTrends);

export const selectShouldRenderViolationTrendsChart = state =>
  fromViolationTrends.selectShouldRenderViolationTrendsChart(state.violationTrends);
