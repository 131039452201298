import styled from 'styled-components';

export default styled.span`
  ${({ color }) => {
    const colorOrDefaultColor = color || 'black';
    return `
      border: 3px solid ${colorOrDefaultColor};
      border-radius: 7px;
      color: ${colorOrDefaultColor};
      padding: 10px 20px;
      text-align: center;
      font-weight: 600;
      max-width: 100px;
      max-height: 80px;
      `;
  }};
`;
