import React from 'react';
import { formatDate } from '../../utils';
import { EditButton, CreateButton } from '../../components/Button';
import { createArchiveModal } from '../../components/Modal';
import { ArchiveModalButton } from '../../components/Modal/ModalButton';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Table, Thead, Tr, Td, Th } from '../../components/Table';

const ModalDialog = createArchiveModal('Archive Domain Configuration', [
  'domains',
  'domainConfigs'
]);

const SortableDomainItem = SortableElement(
  ({
    domainConfig,
    navigateToEditPage,
    rank,
    keyNum,
    archiveDomainConfig,
    resetArchiveErrorMessage
  }) => (
    <Tr background>
      <Td>{rank + 1}</Td>
      <Td nowrap width="33%">
        {domainConfig.name}
      </Td>
      <Td nowrap width="33%">
        {domainConfig.scalaRegex.pattern}
      </Td>
      <Td nowrap width="14%">
        {formatDate(domainConfig.lastModified)}
      </Td>
      <Td right nowrap width="5%">
        <EditButton onClick={() => navigateToEditPage(domainConfig.id)} />
        {!domainConfig.default && (
          <ArchiveModalButton
            ModalDialog={ModalDialog}
            archiveItemName={domainConfig.name}
            takeAction={() => {
              archiveDomainConfig(domainConfig.id);
            }}
            onCancel={resetArchiveErrorMessage}
          />
        )}
      </Td>
    </Tr>
  )
);

export const SortableDomainTable = SortableContainer(
  ({
    domainConfigs,
    navigateToCreatePage,
    navigateToEditPage,
    archiveDomainConfig,
    resetArchiveErrorMessage
  }) => (
    <Table>
      <Thead>
        <Tr>
          <Th left width="5%" nowrap>
            <b>Order</b>
          </Th>
          <Th>
            <b>Name</b>
          </Th>
          <Th>
            <b>Regex</b>
          </Th>
          <Th width="10%" nowrap>
            <b>Last Modified Date</b>
          </Th>
          <Th right width="10%" nowrap>
            <CreateButton onClick={() => navigateToCreatePage()} />
          </Th>
        </Tr>
      </Thead>
      <tbody>
        {domainConfigs.map((domainConfig, index) => (
          <SortableDomainItem
            rank={index}
            key={`item-${index}`}
            index={index}
            domainConfig={domainConfig}
            navigateToEditPage={navigateToEditPage}
            archiveDomainConfig={archiveDomainConfig}
            resetArchiveErrorMessage={resetArchiveErrorMessage}
          />
        ))}
      </tbody>
    </Table>
  )
);
