import React from 'react';
import Row from './HighlightRow';
import PropTypes from 'prop-types';
import Stamp from '../Stamp';

const HighlightRows = props => {
  return (
    <div>
      <Row
        title="Product Name"
        type="product"
        values={props.productNames}
        domElementsMap={props.productNameDomElementsMap}
        onClick={props.handleHighlightLinkClick}
      >
        {props.isMissingLink && <Stamp color="#fe3232f2">MISSING OFFER LINK</Stamp>}
      </Row>
      <Row
        title={`${props.errors.length} Errors`}
        type="error"
        values={props.errors}
        domElementsMap={{
          ...props.prohibitedTextDomElementsMap,
          ...props.inferredProhibitedTextDomElementsMap
        }}
        onClick={props.handleHighlightLinkClick}
      />
    </div>
  );
};

HighlightRows.propTypes = {
  productNames: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.array.isRequired,
  productNameDomElementsMap: PropTypes.object.isRequired,
  prohibitedTextDomElementsMap: PropTypes.object.isRequired,
  inferredProhibitedTextDomElementsMap: PropTypes.object.isRequired,
  handleHighlightLinkClick: PropTypes.func.isRequired
};

export default HighlightRows;
