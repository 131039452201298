import { createAction } from 'redux-actions';
import { fetchTestConfig } from '../../shared/apis';

export const TEST_CONFIG_DETAIL_LOAD_START = 'CAS/TestConfigDetail/TEST_CONFIG_DETAIL_LOAD_START';
export const startLoadTestConfigDetail = createAction(TEST_CONFIG_DETAIL_LOAD_START);

export const TEST_CONFIG_DETAIL_STORE = 'CAS/TestConfigDetail/TEST_CONFIG_DETAIL_STORE';
export const storeTestConfigDetail = createAction(TEST_CONFIG_DETAIL_STORE);

export const TEST_CONFIG_DETAIL_LOAD_FAIL = 'CAS/TestResults/TEST_CONFIG_DETAIL_LOAD_FAIL';
export const failLoadTestConfigDetail = createAction(TEST_CONFIG_DETAIL_LOAD_FAIL);

export const INITIALIZE_TEST_CONFIG_DETAIL = 'CAS/TestResults/INITIALIZE_TEST_CONFIG_DETAIL';
export const initializeTestConfigDetail = createAction(INITIALIZE_TEST_CONFIG_DETAIL);

export const STORE_FORM_DATA = 'CAS/TestResults/STORE_FORM_DATA';


const fetchTestConfigDetail = async id => {
  const result = await fetchTestConfig(id);
  return storeTestConfigDetail(result);
};

export const loadTestConfigDetail = id => async dispatch => {
  dispatch(startLoadTestConfigDetail());
  try {
    dispatch(await fetchTestConfigDetail(id));
  } catch (err) {
    dispatch(failLoadTestConfigDetail(err));
  }
};
