import styled from 'styled-components';
import React from 'react';
import { getStyle } from '../../utils/styleUtil';
export const ErrorSpan = styled.span`
  color: ${({ theme }) => getStyle(['error', 'color'])(theme)};
  font-size: inherit;
`;

export const SimpleError = ({ error }) => <ErrorSpan>Required: {error}</ErrorSpan>;
export const UploadError = ({ error }) => <ErrorSpan>Error: {error}</ErrorSpan>;

export const MultiLineError = ({ errors }) => {
  return errors.map((error, index) => (
    <div key={index}>
      <SimpleError error={error} />
    </div>
  ));
};

export const UploadErrors = ({ errors }) => {
  return errors.map((error, index) => (
    <div key={index}>
      <UploadError error={error} />
    </div>
  ));
};

export const SubmitError = props => {
  const { submitError } = props;

  const labelMap = {
    offerRequiredErrors: 'Offer Level Required Text Errors',
    pageRequiredErrors: 'Page Level Required Text Errors'
  };

  return (
    <div>
      {submitError &&
        Object.keys(submitError).map(errorType => {
          const errorDetail = submitError[errorType];

          return (
            <SubmitErrorDiv key={errorType}>
              <label>{labelMap[errorType]}</label>
              {errorDetail.map((error, index) => {
                return (
                  <div key={index}>
                    <ErrorSpan>{`${error.error} => ${error.expression}`}</ErrorSpan>
                  </div>
                );
              })}
            </SubmitErrorDiv>
          );
        })}
    </div>
  );
};

const SubmitErrorDiv = styled.div`
  margin-bottom: 1em;
`;

export default ErrorSpan;
