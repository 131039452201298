import React from 'react';
import { AppLayout } from '../App';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack-x/components/Panel';
import { Formik } from 'formik';
import { Label, Input, FormGroup } from '../../components/Formik';
import styled from 'styled-components';
import UrlsInput, { getUrlDetail, splitUrls } from '../../components/Formik/UrlsInput';
import * as R from 'ramda';
import Spinner from '../../components/Spinner';
import ErrorMessage from '../../components/ErrorMessage';
import { SubmitButtonWrapper } from '../../components/FormFields/Submit';

const Form = styled.form`
  > div {
    margin-bottom: 10px;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  padding-left: 5px;
`;

const submit = onSubmit => async (
  { urls, invalidUrls, name, errors },
  { setFieldValue, setStatus, setSubmitting }
) => {
  setStatus({
    error: false
  });
  const { validUrlsFormValue, invalidUrlsFormValue } = getUrlDetail(urls, invalidUrls);
  setFieldValue('urls', validUrlsFormValue);
  setFieldValue('invalidUrls', invalidUrlsFormValue);
  const hasInvalidUrls = !R.isEmpty(invalidUrlsFormValue);
  if (!hasInvalidUrls) {
    try {
      await onSubmit({
        groupName: name,
        urls: R.uniq(splitUrls(validUrlsFormValue))
      });
    } catch (e) {
      setStatus({
        error: true
      });
      setSubmitting(false);
    }
  } else {
    setSubmitting(false);
  }
};

const validate = ({ name, urls, invalidUrls }) => {
  const errors = {};
  if (R.isEmpty(name)) {
    errors.name = 'Name is required';
  }
  if (R.isEmpty(urls) && R.isEmpty(invalidUrls)) {
    errors.urls = 'URLs are required';
  }
  return errors;
};

export default class UrlGroupForm extends React.Component {
  render() {
    return (
      <AppLayout pageHeaderTitle={this.props.title}>
        <Panel>
          <PanelBody>
            {!this.props.isLoading && (
              <Formik
                enableReinitialize
                validate={validate}
                validateOnChange={false}
                render={formikBag => {
                  const { handleSubmit, values, handleChange, errors } = formikBag;
                  return (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          name="name"
                          width="300px"
                          onChange={handleChange}
                          value={values.name}
                        />
                        <div>{errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}</div>
                      </FormGroup>
                      <FormGroup>
                        <UrlsInput formikBag={formikBag} data-testid="urlInput" />
                        {errors.urls && <ErrorMessage>{errors.urls}</ErrorMessage>}
                      </FormGroup>
                      <div>
                        <SubmitButtonWrapper
                          loading={formikBag.isSubmitting && !values.invalidUrls}
                        >
                          Submit
                        </SubmitButtonWrapper>
                        {formikBag.status && formikBag.status.error && (
                          <StyledErrorMessage>Submission Failed</StyledErrorMessage>
                        )}
                      </div>
                    </Form>
                  );
                }}
                onSubmit={submit(this.props.submitUrlGroup)}
                initialValues={{ ...this.props.initialValues, invalidUrls: '' }}
              />
            )}
            {this.props.isLoading && <Spinner />}
            {this.props.error && <ErrorMessage>{this.props.error}</ErrorMessage>}
          </PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

UrlGroupForm.defaultProps = {
  initialValues: {
    urls: '',
    name: ''
  }
};
