import cookie from 'js-cookie';

const key = 'user';

export const saveUser = ({user, token}) => {
    try {
        cookie.set('authentication_token', token);
        localStorage.setItem(key, JSON.stringify({user, token}));
    } catch (e) {
        console.log(e);
    }
};

export const getUser = () => {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (e) {
        return null;
    }
};

export const isPublisher = (user = getUser()) => {
    if (user) {
        const isEmployee = user.user ? user.user.isEmployee : user.isEmployee;
        const isPublisher = user.user ? user.user.isPublisher : user.isPublisher;
        if (isPublisher !== undefined)
            return isPublisher && !isEmployee;
        else
            return user.currentCompany.type === 'publisher' && !user.isEmployee
    }
    return false;
};

export const isEmployee = (user = getUser()) => {
    if (user) {
        return user.user ? user.user.isEmployee : user.isEmployee;
    }

    return false
}

export const isEnableToCreate = (user = getUser()) => {
    if (user) {
        return !isPublisher(user);
    }
    return false;
}

export const removeUser = () => {
    localStorage.removeItem(key);
};

export const statusOptionsCheck = (isPublisher) => {
    return isPublisher ? [
        {label: 'In Progress', value: 'In Progress'},
        {label: 'Resolved', value: 'Resolved'}
    ] : [
        {label: 'New', value: 'New'},
        {label: 'In Progress', value: 'In Progress'},
        {label: 'Resolved', value: 'Resolved'}
    ]
}

export const setCurrentCompany = (user) => {
    try {
        localStorage.setItem('userCurrentCompany', JSON.stringify(user.currentCompany));
    } catch (e) {
        console.log(e);
    }
};

export const getCurrentCompany = () => {
    try {
        return JSON.parse(localStorage.getItem('userCurrentCompany'));
    } catch (e) {
        return null;
    }
};