import React from 'react';
import styled from 'styled-components';

export const ZeroStateIcon = () => (
  <svg
    width="70px"
    height="70px"
    viewBox="0 0 35 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Stroke 5892 + Stroke 5893 + Stroke 5894</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop" transform="translate(-340.000000, -223.000000)">
        <g
          id="Stroke-5892-+-Stroke-5893-+-Stroke-5894"
          transform="translate(341.000000, 224.000000)"
        >
          <path
            d="M16.6381417,0.790875 C14.7508083,3.104125 11.9118917,4.58295833 8.72780833,4.58295833 C5.942725,4.58295833 3.41730833,3.450875 0.792141667,1.617375 L0.792141667,25.068125 C0.792141667,25.068125 3.087975,33.4122917 16.625475,37.2075417 C30.162975,33.4122917 32.4588083,25.068125 32.4588083,25.068125 L32.4588083,1.617375 C29.8320583,3.450875 27.308225,4.58295833 24.5215583,4.58295833 C21.337475,4.58295833 18.4985583,3.104125 16.6381417,0.790875 Z"
            id="Stroke-5892"
            stroke="#254A5D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle id="Oval" fill="#49C5B1" fillRule="nonzero" cx="16.5" cy="19.5" r="8.5" />
          <g
            id="Stroke-761-+-Stroke-762"
            transform="translate(12.000000, 12.000000)"
            stroke="#254A5D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <polyline id="Stroke-761" points="13.5 0.75 3.75 9.85 0.5 6.6" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Text = styled.div`
  font-size: 24px;
  min-width: 200px;
`;

const Wrapper = styled.div`
  text-align: center;
  padding: 150px 0px;
`;

const Content = styled.div`
  > * {
    margin-bottom: 35px;
  }
`;

export default ({ children }) => {
  return (
    <Wrapper>
      <Content>
        <ZeroStateIcon />
        {children}
      </Content>
    </Wrapper>
  );
};
