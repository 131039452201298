import styled, { css } from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  width: 65%;
`;

export const responsive = css`
  width: 65%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const Url = styled.span`
  width: 100% !important;
  margin-left: 7px;
  & > * {
    margin-right: 5px;
  }
`;

export const ConfigurationContainer = styled.div`
  ${responsive};
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px 0px 0px 0px;

  & > * {
    margin-bottom: 5px;
    padding: 15px 10px;
  }

  & > div:not(:last-child) {
    border-bottom: 1px #ddd solid;
  }
`;

export const Configurations = styled.div`
  & > div {
    margin-bottom: 20px;
  }
`;

export const ConfigurationHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QuerySetting = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const QueryPair = styled.div`
  align-items: center;
  display: flex;
  width: 70%;
  justify-content: space-between;
`;

export const QueryKey = styled.div`
  text-align: left;
  width: 30%;
  word-wrap: break-word;
`;

export const QueryValue = styled.div`
  text-align: left;
  width: 65%;
  word-wrap: break-word;
`;

export const StyledSelect = styled(Select)`
  width: ${props => props.width || '25%'};

  input {
    &:focus {
      box-shadow: none;
      border: none;
    }
  }
`;

export const FormContainer = styled.div`
  align-items: center;
  display: flex;
`;


export const Left = styled.div`
  width: 100%;
  word-wrap: break-word;
  & > * {
    margin-bottom: 20px;
    padding-right: 5px;
  }
`;

export const Right = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 4px;
  }
`;

export const Label = styled.span`
  font-weight: 700;
`;
