import {ActivityStream} from '@cjdev-internal/visual-stack-x/ActivityStream';
import fetchIssue, {addComments, updateCommentVisibility} from "../services";
import {withParams} from "../../../router";
import useLoadIssue from "../hooks/useLoadIssue";
import {isEmployee, isPublisher} from '../../../shared/userStorage';
import {Row} from "@cjdev-internal/visual-stack-x/components/Row";
import {MDIcon} from "@cjdev-internal/visual-stack-x/MDIcon";
import IssueDetailLoading from "./IssueDetailLoading";
import getVisibilityOptions from "./utils";
import useEventListener from "../../../shared/hooks/useEventListener";
import {S3Uploader} from '../../../shared/s3Uploader';
import {useMultiFileUpload} from '@cjdev-internal/visual-stack-x/components/MultiFileUpload';
import {finalizeMultipartUpload, getMultipartPreSignedUrls, initializeMultipartUpload} from '../../../shared/apis';
import {useEffect, useState} from 'react';
import {useToast} from "@cjdev-internal/visual-stack-x/Toast";

export const IssueDetailActivity = ({
                                        complianceViolationId,
                                        fetchIssue,
                                        useLoadIssue,
                                        useEventListener,
                                        useEffect,
                                        useState,
                                        Loading,
                                        isPublisher,
                                        isEmployee,
                                        onClose,
                                        getVisibilityOptions,
                                        addComments,
                                        updateCommentVisibility,
                                        useMultiFileUpload,
                                        S3Uploader,
                                        initializeMultipartUpload,
                                        getMultipartPreSignedUrls,
                                        finalizeMultipartUpload,
                                        show
                                    }) => {
    const multiFileUpload = useMultiFileUpload();
    const multiFileAccept = '.jpg, .jpeg, .gif, .png, .bmp, .tif, .tiff, .mp4, .mov, .pdf, .xlsx, .docx, .txt';
    const multiFileUploadProps = {maxFiles: 3, accept: multiFileAccept, maxFileSize: 15728640};
    const {status, issue, reload} = useLoadIssue(complianceViolationId, fetchIssue);
    useEventListener('UPDATE_ISSUE_VIEW', reload);
    const [sendingComment, setSendingComment] = useState(false);
    const [activities, setActivities] = useState([]);

    const S3API = {
        initializeMultipartUpload,
        getMultipartPreSignedUrls,
        finalizeMultipartUpload
    };

    const showMessageSuccess = () => show({
        message: 'Comment has been added and notification was successfully sent',
        contentStyle: {textAlign: "center", minWidth: "300px"},
        type: "success",
        duration: '15000'
    })

    const showMessageFailure = (message) => show({
        message: message,
        contentStyle: {textAlign: "center", minWidth: "300px"},
        type: "warning",
        duration: '15000'
    })

    const getVisibilityText = (advVisibility, pubVisibility) => {
        const advFlag = advVisibility === "true";
        const pubFlag = pubVisibility === "true";

        let text = "all";

        if (!advFlag && !pubFlag) {
            text = "internal";
        }

        if (advFlag && !pubFlag) {
            text = "advertisers";
        }

        return text;
    }

    const getFileNameFromLink = (link) => {
        const parts = link.split('/');
        const fileName = parts[parts.length - 1];

        return fileName.includes('%') ? decodeURIComponent(fileName.replace(/%20/g, ' ')) : fileName;
    }

    const submitComment = async (comment) => {
        const {
            advertiserId,
            id: issueId,
            version: issueVersion,
        } = issue;

        const category = issue?.violationTypeMapping?.category;
        const description = issue?.violationTypeMapping?.description;
        const location = issue?.errorLocation;
        const websiteId = issue?.website?.pid;
        const websiteName = issue?.website?.name;
        const publisherContacts = issue?.publisherContacts;
        const advertiserContacts = issue?.advertiserContacts;
        const networkQualityContacts = issue?.networkQualityContacts;

        setSendingComment(true);

        let commentsJson = {
            comment: comment["text"],
            advertiserId,
            issueId,
            issueVersion,
            commentsEmail: {
                category,
                description,
                location,
                websiteId,
                websiteName,
                publisherContacts,
                advertiserContacts,
                networkQualityContacts
            }
        };

        if (multiFileUpload.files.length) {
            const pendingActivity = {
                type: "comment",
                id: 'pending',
                heading: 'sending comment...',
                timestamp: Date.now(),
                content: <>
                    <div>{comment.text}</div>
                    <Row><MDIcon icon="attachment" rotation="270deg" size="18px"/>
                        <div>Uploading Attachments...</div>
                    </Row></>,
                visibleTo: '',
                visibilityMenu: false
            }

            setActivities([pendingActivity, ...activities]);
            const fileUploaded = await uploadFilesParallel(multiFileUpload.files);
            commentsJson.attachments = fileUploaded.map(file => ({attachmentPath: file.link}));
        }

        commentsJson.advertiserVisibility = (comment["visibleTo"] === "all" || comment["visibleTo"] === "advertisers")
        commentsJson.publisherVisibility = (comment["visibleTo"] === "all")

        const response = await addComments(commentsJson);
        if (response.status === 200) {
            const responseBody = await response.json();
            if (responseBody.statusCode === 200) {
                showMessageSuccess();
            } else {
                showMessageFailure('Comment has been added and notification was not sent successfully');
            }
        } else {
            showMessageFailure('Comment has not been added and notification was not sent successfully');
        }
        setSendingComment(false);
        reload();
    }

    const editVisibility = async (event, updatedVisibility) => {
        if (updatedVisibility === event['visibleTo']) {
            return
        }

        const {advertiserId} = issue;
        const {commentId, issueId} = issue.comments.find((comment) => comment.commentId === event.id);
        const updatedCommentJson = {
            commentId,
            issueId,
            advertiserId,
            advertiserVisibility: (updatedVisibility === "all" || updatedVisibility === "advertisers"),
            publisherVisibility: (updatedVisibility === "all")
        }

        await updateCommentVisibility(updatedCommentJson);
        reload();
    }

    const uploadFilesParallel = async (files) => {
        const filesUploaded = await Promise.all(files.map(async (file) => {
            const videoUploaderOptions = {file};
            const uploader = new S3Uploader(videoUploaderOptions, S3API)
            const response = await uploader.start();
            return response;
        }));

        return filesUploaded;
    }

    const ActivityContent = (activity) => {

        const ActivityAttachment = attachment => {
            return (
                <Row style={{fontSize: "12px"}} gap="medium" key={attachment.attachmentId}>
                    <div data-testid="attachment-icon"><MDIcon icon="attachment" rotation="270deg" size="18px"/></div>
                    <div data-testid="attachment-file">
                        <a href={attachment.attachmentPath}>
                            {getFileNameFromLink(attachment.attachmentPath)}
                        </a>
                    </div>
                </Row>
            )
        }
        return <div>
            <div>{activity.comment}</div>
            {activity.attachments ? (<div>{activity.attachments.map(ActivityAttachment)}</div>) : null}
        </div>
    }

    useEffect(() => {
        if (!issue) {
            return
        }

        const newActivities = issue.comments.map(comment => (
            {
                type: "comment",
                id: comment.commentId,
                heading: comment.createdBy.split(',')[1] + " commented",
                timestamp: new Date(comment.createdTime).getTime(),
                content: ActivityContent(comment),
                visibleTo: isPublisher ? "" : getVisibilityText(comment.advertiserVisibility, comment.publisherVisibility),
                visibilityMenu: !isPublisher
            }
        ));
        setActivities(newActivities);
    }, [issue]);

    return (
        <>
            {status === 'loading' && <Loading/>}
            {status === 'complete' && (<ActivityStream
                timestampFormat={'yyyy/MM/dd p'}
                activities={activities}
                activityFilter={false}
                multiFileUploadProps={multiFileUploadProps}
                multiFileUploadHook={multiFileUpload}
                onClose={onClose}
                onCommentSubmit={(comment) => submitComment(comment)}
                commentVisibilitySelection={!(isPublisher)}
                commentVisibilityOptions={getVisibilityOptions(isPublisher, isEmployee)}
                onClickVisibleToInternal={(event) => editVisibility(event, "internal")}
                onClickVisibleToAdvertisers={(event) => editVisibility(event, "advertisers")}
                commentSubmitSpinner={sendingComment}
                onClickVisibleToAll={(event) => editVisibility(event, "all")}
            />)}
        </>
    );
};

const IssueDetailActivityDefault = ({onClose, params, user}) => {

    const {complianceViolationId} = params;
    const [toastMount, show] = useToast();

    const Loading = () => <IssueDetailLoading/>;

    return (<div data-testid="issue-detail-activity">
            {toastMount}
            <IssueDetailActivity
                complianceViolationId={complianceViolationId}
                fetchIssue={fetchIssue}
                useLoadIssue={useLoadIssue}
                useEventListener={useEventListener}
                Loading={Loading}
                isPublisher={isPublisher(user)}
                isEmployee={isEmployee(user)}
                onClose={onClose}
                getVisibilityOptions={getVisibilityOptions}
                useMultiFileUpload={useMultiFileUpload}
                addComments={addComments}
                updateCommentVisibility={updateCommentVisibility}
                S3Uploader={S3Uploader}
                initializeMultipartUpload={initializeMultipartUpload}
                getMultipartPreSignedUrls={getMultipartPreSignedUrls}
                finalizeMultipartUpload={finalizeMultipartUpload}
                useState={useState}
                useEffect={useEffect}
                show={show}
            />
        </div>
    );
};

export default withParams(IssueDetailActivityDefault);
