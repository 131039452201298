import React from 'react';
import { connect } from 'react-redux';
import { AppLayout } from '../App';
import * as R from 'ramda';
import {
  Panel,
  Body as PanelBody,
  Header as PanelHeader
} from '@cjdev-internal/visual-stack-x/components/Panel';
import { PanelHeaderRow, PanelHeaderRowSection } from '../../components/PanelHeader';
import { Table, Thead, Tr, Th, Td } from '../../components/Table';
import * as actions from '../../domain/UrlGroups/actions';
import { selectUrlGroups } from '../../reducer';
import { EditButton, CreateButton } from '../../components/Button';
import { ArchiveModalButton } from '../../components/Modal/ModalButton';
import { ProductModalMountPoint, createGroupArchiveModal } from '../../components/Modal';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import TextSearchFilter from '../../components/Filters/TextSearchFilter';
import * as dateFns from 'date-fns';

const ModalDialog = createGroupArchiveModal('Archive Url Group', ['urlGroups', 'archive']);

export const UrlGroupsTable = ({
  urlGroups,
  applyFilter,
  filter = '123',
  archiveAndLoad,
  resetArchiveUrlGroup
}) => {
  const body = urlGroups.map(({ groupName, urlsCount, timestamp, id }, index) => {
    return (
      <Tr key={index}>
        <Td>{groupName}</Td>
        <Td>{urlsCount}</Td>
        <Td>{dateFns.format(timestamp, 'YYYY-MM-DD HH꞉mm꞉ss')}</Td>
        <Td right nowrap>
          <Link to={`/url-groups/${id}`} data-testid="edit">
            <EditButton />
          </Link>
          <ArchiveModalButton
            ModalDialog={ModalDialog}
            takeAction={() => {
              archiveAndLoad(id);
            }}
            itemName={groupName}
            onCancel={resetArchiveUrlGroup}
            bodyText={
              <div>
                Warning: Archiving "<b>{groupName}</b>" will remove this URL Group from all Test
                Configurations.
              </div>
            }
          />
        </Td>
      </Tr>
    );
  });
  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th>
              <b>Name</b>
            </Th>
            <Th>
              <b>URL Count</b>
            </Th>
            <Th width="10%" nowrap>
              <b>Last Modified</b>
            </Th>
            <Th right width="10%" nowrap>
              <Link to={`/url-groups/create`} data-testid="create">
                <CreateButton />
              </Link>
            </Th>
          </Tr>
        </Thead>
        <tbody>{body}</tbody>
      </Table>
    </div>
  );
};

export class UrlGroups extends React.Component {
  constructor() {
    super();
    this.state = {
      filter: ''
    };
  }
  componentDidMount() {
    this.props.fetchUrlGroups();
  }

  render() {
    const { data, isLoading } = this.props.urlGroups;
    return (
      <AppLayout pageHeaderTitle="URL Group Configurations">
        <ProductModalMountPoint />
        <Panel>
          <PanelHeader>
            <PanelHeaderRow>
              <PanelHeaderRowSection>
                <TextSearchFilter
                  label="Filter by URL Group Name"
                  onChange={value => {
                    this.setState({ filter: value });
                  }}
                  value={this.state.filter}
                />
              </PanelHeaderRowSection>
            </PanelHeaderRow>
          </PanelHeader>
          <PanelBody>
            {isLoading ? (
              <Spinner />
            ) : (
              <UrlGroupsTable
                urlGroups={data.filter(R.pipe(R.prop('groupName'), R.contains(this.state.filter)))}
                archiveAndLoad={this.props.archiveAndLoad}
                resetArchiveUrlGroup={this.props.resetArchiveUrlGroup}
              />
            )}
          </PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

export const mapDispatchToProps = actions;
export const mapStateToProps = state => ({ urlGroups: selectUrlGroups(state) });
export default connect(mapStateToProps, mapDispatchToProps)(UrlGroups);
