import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { parse } from 'query-string';
import IssueViewController from './issueViewController';
import { IssuesSortableDropdown } from './SortableDropdown';
import { QueryToValueMap } from './SortableDropdown/QueryValueMap';

export const IssuesSortableDropdownPure = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const queryParams = parse(searchParams.toString());
  const { sortBy, order } = queryParams;
  const issueViewController = new IssueViewController(navigate, searchParams);


  let value = { value: 'Detection Date (Newest to Oldest)', label: 'Detection Date (Newest to Oldest)' };
  if (QueryToValueMap[sortBy] && QueryToValueMap[sortBy][order]) {
    value = {
      value: `${sortBy}${order.charAt(0).toUpperCase() + order.slice(1)}`,
      label: QueryToValueMap[sortBy][order],
    };
  }

  return (
    <IssuesSortableDropdown
      value={value}
      changeSorting={issueViewController.changeSorting}
    />
  );
};

export default IssuesSortableDropdownPure;
