import React from 'react';
import ViolationTrendsChart from './ViolationTrendAMChart';
import { Header, Body } from '@cjdev-internal/visual-stack-x/Panel';
import { connect } from 'react-redux';
import {
  selectViolationTrendsDataForChart,
  selectViolationTrendsIsLoading,
  selectTrendCaption,
  selectShouldRenderViolationTrendsChart,
  selectViolationDashboardLabels,
  selectIsPublisher
} from '../../../reducer';
import ViolationZeroState, { Text } from '../../../components/ZeroState/ViolationZeroState';
import { HeaderContent, ChartTitle } from '../shared/style';
import { useUser } from '@platform/cj-platform-navigation'
import { usePlatformNav } from '../../../appRunner'

export const ViolationTrendChart = ({
  data,
  isLoading,
  caption,
  shouldRender,
  labels,
  isPublisher
}) => {
  const user = useUser()
  if (usePlatformNav()) isPublisher = user.currentCompany.type === "publisher"
  const publisherDependentLabels = isPublisher ? [['total', 'Total']] : labels;
  const Chart = (
    <ViolationTrendsChart data={data} labels={publisherDependentLabels} isPublisher={isPublisher} />
  );
  const ZeroState = (
    <ViolationZeroState>
      <Text>There is no data to display</Text>
    </ViolationZeroState>
  );
  return (
    <React.Fragment>
      <Header>
        <HeaderContent>
          <ChartTitle>{!isLoading && caption}</ChartTitle>
        </HeaderContent>
      </Header>
      <Body>
        {!shouldRender && !isLoading && ZeroState}
        {shouldRender && !isLoading && Chart}
      </Body>
    </React.Fragment>
  );
};

export default connect(state => ({
  isLoading: selectViolationTrendsIsLoading(state),
  data: selectViolationTrendsDataForChart(state),
  caption: selectTrendCaption(state),
  shouldRender: selectShouldRenderViolationTrendsChart(state),
  labels: selectViolationDashboardLabels(state),
  isPublisher: selectIsPublisher(state)
}))(ViolationTrendChart);
