import { combineReducers } from 'redux';
import * as R from 'ramda';
import {
  STORE_ADVERTISER_DATA,
  FAIL_FETCH_ADVERTISER_DATA,
  LOADING_FETCH_ADVERTISER_DATA,
  STORE_SELECTED_ADVERTISER_ID,
  CLEAR_SELECTED_ADVERTISER_DATA,
  STORE_ADVERTISER_MANAGEMENTS,
  STORE_CURRENT_ADVERTISER_MANAGEMENT,
  STORE_SELECTED_ADVERTISERS
} from './actions';
import { isPStyleAdvertiser } from '../../containers/Advertisers/AdvertisersDropdown/advertiserStorage';

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case LOADING_FETCH_ADVERTISER_DATA:
      return true;
    case FAIL_FETCH_ADVERTISER_DATA:
      return false;
    case STORE_ADVERTISER_DATA:
      return false;
    default:
      return state;
  }
};

const dataReducer = (state = [], { type, payload }) => {
  switch (type) {
    case STORE_ADVERTISER_DATA:
      return payload;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type }) => {
  switch (type) {
    case FAIL_FETCH_ADVERTISER_DATA:
      return 'failed to fetch';
    case LOADING_FETCH_ADVERTISER_DATA:
      return null;
    default:
      return state;
  }
};

const selectedAdvertiserReducer = (state = null, { type, payload }) => {
  switch (type) {
    case CLEAR_SELECTED_ADVERTISER_DATA:
      return null;
    case STORE_SELECTED_ADVERTISER_ID:
      return R.defaultTo(null)(payload);
    default:
      return state;
  }
};

const selectedAdvertisersReducer = (state = [], { type, payload }) => {
  switch (type) {
    case STORE_SELECTED_ADVERTISERS:
      return payload;
    default:
      return state;
  }
};

const advertisers = combineReducers({
  isLoading: loadingReducer,
  data: dataReducer,
  error: errorReducer,
  selectedAdvertiser: selectedAdvertiserReducer,
  selectedAdvertisers: selectedAdvertisersReducer
});

const advertiserManagementsData = (state = [], { type, payload }) => {
  switch (type) {
    case STORE_ADVERTISER_MANAGEMENTS:
      return payload;
    default:
      return state;
  }
};

const currentAdvertiserManagementsData = (state = [], { type, payload }) => {
  switch (type) {
    case STORE_CURRENT_ADVERTISER_MANAGEMENT:
      return payload;
    default:
      return state;
  }
};

const advertiserManagements = combineReducers({
  data: advertiserManagementsData,
  currentAdvertiser: currentAdvertiserManagementsData
});

export const reducer = combineReducers({ advertisers, advertiserManagements });

export const selectAdvertisers = state => state.advertisers.data;

export const selectSelectedAdvertiser = state => state.advertisers.selectedAdvertiser;

export const selectSelectedAdvertiserInformation = state => {
  const advertisers = selectAdvertisers(state);
  const selectedAdvertiserId = selectSelectedAdvertiser(state);
  return R.find(advertiser => advertiser.cid === selectedAdvertiserId)(advertisers) || {};
};

export const selectAdvertiserNameMap = ({ advertisers: { data } }) => {
  let map = {};
  R.forEach(advertiser => {
    map[advertiser.cid] = advertiser.name;
  }, data);
  return map;
};
export const selectIsLoadingAdvertiser = state => state.advertisers.isLoading;

export const selectAdvertiserPrefix = ({ advertisers: { selectedAdvertiser, data } }) =>
  (R.find(({ cid }) => cid === selectedAdvertiser)(data) || {}).prefix || 'CJ';

export const selectAdvertiserPrefixesMap = ({ advertisers: { data } }) =>
  R.fromPairs(data.map(({ cid, prefix }) => [cid, prefix || 'CJ']));

export const selectAdvertiserManagements = state => state.advertiserManagements.data;

export const selectCurrentAdvertiserManagement = state =>
  state.advertiserManagements.currentAdvertiser;

export const selectIsPStyleSeverityAdvertiser = state => {
  const currentAdvertiser = selectSelectedAdvertiser(state);
  return R.contains(currentAdvertiser)([4025547, 3836079]);
};

export const selectHighSeverityValue = state => {
  const currentAdvertiser = selectSelectedAdvertiser(state);
  if (R.isNil(currentAdvertiser)) {
    return undefined;
  }
  if (selectIsPStyleSeverityAdvertiser(state)) {
    return 'P1';
  }
  return 'High';
};

const pStyleSeverityOptions = [
  { label: 'P1', value: 'P1' },
  { label: 'P2', value: 'P2' },
  { label: 'P3', value: 'P3' }
];

const defaultSeverityOptions = [
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' }
];

const getSeverityDropdownOptions = advertiserId => {
  if (isPStyleAdvertiser(advertiserId)) {
    return pStyleSeverityOptions;
  } else {
    return defaultSeverityOptions;
  }
};

const selectAdvertiserIds = state => {
  const selectedAdvertiserIds = state.advertisers.selectedAdvertisers;
  if (R.isEmpty(selectedAdvertiserIds)) return R.map(R.prop('cid'))(selectAdvertisers(state));
  return selectedAdvertiserIds;
};

export const selectSeverityOptions = state => {
  const advertiserIds = selectAdvertiserIds(state);
  return R.uniq(R.flatten(R.map(getSeverityDropdownOptions)(advertiserIds)));
};

export const selectViolationDashboardLabels = state => {
  const defaultLabels = [
    ['high', 'High'],
    ['medium', 'Medium'],
    ['low', 'Low']
  ];
  const pStyleLabels = [
    ['high', 'P1'],
    ['medium', 'P2'],
    ['low', 'P3']
  ];
  const mixedLabels = [
    ['high', 'High/P1'],
    ['medium', 'Medium/P2'],
    ['low', 'Low/P3']
  ];

  const selectedAdvertiserIds = selectAdvertiserIds(state);
  const hasPStyleAdvertiser = !R.isEmpty(R.filter(isPStyleAdvertiser)(selectedAdvertiserIds));
  const hasDefaultAdvertiser = !R.isEmpty(R.reject(isPStyleAdvertiser)(selectedAdvertiserIds));
  if (hasPStyleAdvertiser && hasDefaultAdvertiser) return mixedLabels;
  if (hasPStyleAdvertiser) return pStyleLabels;
  return defaultLabels;
};

export const selectHighSeverityValues = state => {
  const mixedValues = ['High', 'P1'];
  const pStyleValues = ['P1'];
  const defaultValues = ['High'];
  const selectedAdvertiserIds = selectAdvertiserIds(state);
  const hasPStyleAdvertiser = !R.isEmpty(R.filter(isPStyleAdvertiser)(selectedAdvertiserIds));
  const hasDefaultAdvertiser = !R.isEmpty(R.reject(isPStyleAdvertiser)(selectedAdvertiserIds));
  if (hasPStyleAdvertiser && hasDefaultAdvertiser) return mixedValues;
  if (hasPStyleAdvertiser) return pStyleValues;
  return defaultValues;
};

export const selectRiskIdentificationTableSeverityHeaders = state => {
  const violationDashboardLabels = selectViolationDashboardLabels(state);
  const highHeader = `${violationDashboardLabels[0][1]} Severity`;
  const mediumHeader = `${violationDashboardLabels[1][1]} Severity`;
  const lowHeader = `${violationDashboardLabels[2][1]} Severity`;
  return { highHeader, mediumHeader, lowHeader };
};

export const selectSelectedAdvertiserLabel = state => {
  const selectedAdvertiserIds = state.advertisers.selectedAdvertisers;
  const advertisersFromAPI = state.advertisers.data;
  const firstSelectedAdvertiserId = R.head(selectedAdvertiserIds);
  const targetAdvertiser = R.defaultTo({})(
    R.find(R.propEq('cid', firstSelectedAdvertiserId))(advertisersFromAPI)
  );
  const isEmptyAdvertiser = R.isEmpty(R.toPairs(targetAdvertiser));
  if (isEmptyAdvertiser) return '';
  return `${targetAdvertiser.name}`;
};
