import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../../shared/apis';
import { apiServer } from '../../../env';

export const SET_VIOLATION_TRENDS_DATA = 'src/domain/ViolationDashboard/SET_VIOLATION_TRENDS_DATA';

export const setViolationTrendsDataAction = createAction(SET_VIOLATION_TRENDS_DATA);

export const START_FETCHING_VIOLATION_TRENDS =
  'src/domain/ViolationDashboard/START_FETCHING_VIOLATION_TRENDS';

export const startFetchingViolationTrendsAction = createAction(START_FETCHING_VIOLATION_TRENDS);

const getViolationTrendsData = async filterQueries => {
  const response = await authenticatedFetch(
    `${apiServer()}/violation-trends?${filterQueries}&zoneId=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`
  );
  return await response.json();
};

export const fetchViolationTrendsAction = filterQueries => async dispatch => {
  dispatch(startFetchingViolationTrendsAction());
  const violationTrends = await getViolationTrendsData(filterQueries);
  dispatch(setViolationTrendsDataAction(violationTrends));
};
