import R from 'ramda';
import { getTrimmedList } from '../../utils';

const requiredErrorString = 'Field is required';
export const required = value => {
  if (R.isNil(value)) return requiredErrorString;
  if (R.isEmpty(value)) return requiredErrorString;
  return undefined;
};
required.errorString = requiredErrorString;

const numericErrorString = 'Field must be a number';
export const numeric = value => {
  const regex = /^\d+$/;
  if (R.isNil(value) || regex.test(value)) return undefined;
  return numericErrorString;
};
numeric.errorString = numericErrorString;

const numericListErrorString = 'Values must be numeric';
export const numericList = values => {
  return R.find(numeric, getTrimmedList(values)) ? numericListErrorString : undefined;
};
numericList.errorString = numericListErrorString;
