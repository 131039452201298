import { combineReducers } from 'redux';
import { STORE_PRODUCT_DATA, FAIL_FETCH_PRODUCT_DATA, LOADING_FETCH_PRODUCT_DATA } from './actions';
import * as R from 'ramda';
const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case LOADING_FETCH_PRODUCT_DATA:
      return true;
    case FAIL_FETCH_PRODUCT_DATA:
    case STORE_PRODUCT_DATA:
      return false;
    default:
      return state;
  }
};

const dataReducer = (state = [], { type, payload }) => {
  switch (type) {
    case STORE_PRODUCT_DATA:
      return payload;
    default:
      return state;
  }
};

const errorReducer = (state = null, { type }) => {
  switch (type) {
    case FAIL_FETCH_PRODUCT_DATA:
      return 'failed to fetch';
    case LOADING_FETCH_PRODUCT_DATA:
      return null;
    default:
      return state;
  }
};

export const reducer = combineReducers({
  isLoading: loadingReducer,
  data: dataReducer,
  error: errorReducer
});

export const selectViolationProductDropdownOptions = ({ data }) => {
  return data.map(({ id, name }) => ({
    label: name,
    value: id
  }));
};

export const selectViolationProductMap = state => {
  let violationProductMap = {};
  R.forEach(product => {
    violationProductMap[product.id] = product.name;
  })(state.data);
  return violationProductMap;
};

export const selectViolationProductNameMap = state => {
  let violationProductNameMap = {};
  R.forEach(product => {
    violationProductNameMap[product.name] = product.id;
  })(state.data);
  return violationProductNameMap;
};
