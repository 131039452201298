import {ConfigureIssueModalContent} from './ConfigureIssueModalContent';
import React, {useEffect} from 'react';
import * as R from 'ramda';
import {getAdvertisers,} from '../Advertisers/AdvertisersDropdown/advertiserStorage';
import {usePlatformNav} from "../../appRunner";
import useViolationModal from '../../shared/hooks/useViolationModal';
import {LoadingAnimation} from '@cjdev-internal/visual-stack-x/LoadingAnimation';
import {Modal, useModal} from '@cjdev-internal/visual-stack-x/components/Modal';
import {Box} from '@cjdev-internal/visual-stack-x/components/Box';
import {Button} from '@cjdev-internal/visual-stack-x/components/Button';
import {Text} from '@cjdev-internal/visual-stack-x/Text';

export const ConfigureIssueModal = props => {
  const xLens = R.lensPath(['existingValues', 'advertiserId']);
  const defaultAdvertiserId = R.head(getAdvertisers());
  const defaultToAdvertiserId = R.defaultTo(defaultAdvertiserId);
  const existingAdvertiserId = R.view(xLens, props);
  const advertiserId = defaultToAdvertiserId(existingAdvertiserId);
  const {
    loadingModal,
    ...dependenciesProps
  } = useViolationModal(advertiserId);

  return (
    <React.Fragment>
      {loadingModal && (
        <Modal><Box style={{height: "240px"}} align="center" justify='center'><LoadingAnimation /></Box></Modal>
      )}

      {!loadingModal && (
        <ConfigureIssueModalContent
          {...props}
          {...dependenciesProps}
          advertiserId={advertiserId}
        />
      )}
    </React.Fragment>
  );
};

export const ModalButton = (props) => {
  const addNewViolationCaption = `Add New Violation`;
  const [mount, vsxOpenModal, vsxCloseModal] = useModal();
  const { existingValues, type} = props;

  const getTitle = () => {
    if (R.isNil(existingValues)) {
      return addNewViolationCaption;
    } else if (R.isNil(existingValues.id)) {
      return 'Copy';
    } else {
      return 'Edit';
    }
  };
  const getType = () => {
    if (R.isNil(existingValues)) {
      return 'primary';
    } else if (R.isNil(existingValues.id)) {
      return 'secondary';
    } else {
      return 'primary';
    }
  };
  const getDisabled = () => {
    if (R.isNil(existingValues) && getAdvertisers().length !== 1) {
      return true;
    } else return false;
  };

  const openConfigureIssueModal = () => {
    vsxOpenModal(<ConfigureIssueModal closeModal={vsxCloseModal} {...props} />)
  };

  useEffect(() => {

    if (R.isNil(existingValues)) {
      document.addEventListener("ADD_NEW_VIOLATION", openConfigureIssueModal);
    };

    return () => {
      document.removeEventListener("ADD_NEW_VIOLATION", openConfigureIssueModal);
    }
  }, []);

  const platformNav = usePlatformNav();
  const buttonStyle = type || getType();
  const buttonCaption = getTitle();
  const isAddNewViolationButton = buttonCaption === addNewViolationCaption;
    
  return (
    <React.Fragment>
      {mount}
      <Button
        type={buttonStyle}
        onClick={openConfigureIssueModal}
        disabled={getDisabled()}
        className={ platformNav && isAddNewViolationButton ? "hideme" : "" }
      >
        {buttonCaption}
      </Button>
      {getDisabled() && (
        <Text weight="bold">Please select only one advertiser to add new violation.</Text>
      )}
    </React.Fragment>
  );
};

const OpenModalButton = ModalButton;

export default OpenModalButton;
