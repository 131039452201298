import { getQuery } from './queryHelper';
import { fetchWebsiteAction } from '../Websites/actions';
import { fetchPublishersAction } from '../Publishers/actions';
import { fetchViolationProductAction } from '../ViolationProducts/actions';
import { initializeViolationTypeMapping } from '../ViolationTypeMappings/actions';
import { fetchRiskIdentification } from './RiskIdentifications/actions';
import { fetchDashboardOverviewAction } from './Overviews/actions';
import { fetchViolationTrendsAction } from './ViolationTrends/actions';
import { getAdvertisers } from '../../containers/Advertisers/AdvertisersDropdown/advertiserStorage';
import * as R from 'ramda';
import * as qs from 'query-string';

export const fetchDashboardOverviews = (search, getCurrentDate = () => new Date()) => async dispatch => {
  const filterQueries = getQuery(getCurrentDate, search);
  const advertisers = getAdvertisers();
  const filterQueriesWithAdvertiser = !R.isEmpty(advertisers)
    ? `${qs.stringify({ advertiserIds: JSON.stringify(advertisers) })}&${filterQueries}`
    : `${filterQueries}`;
  dispatch(fetchDashboardOverviewAction(filterQueriesWithAdvertiser));
  dispatch(fetchWebsiteAction());
  dispatch(fetchPublishersAction());
  dispatch(fetchViolationProductAction());
  dispatch(initializeViolationTypeMapping());
  dispatch(fetchViolationTrendsAction(filterQueriesWithAdvertiser));
  dispatch(fetchRiskIdentification(filterQueriesWithAdvertiser));
};
