import {LoadingAnimation} from '@cjdev-internal/visual-stack-x/LoadingAnimation';
import {withParams} from '../../router';
import {ManageRecipientLoading} from './components/ManageRecipientLoading';
import useLoadIssue from '../IssuesDetail/hooks/useLoadIssue';
import fetchIssue, {submitIssue} from '../IssuesDetail/services';
import {getFullName} from '../IssuesDetail/helpers/dataHelpers';
import ManageRecipientContent from './components/ManageRecipientContent';
import ManageRecipient from './components/ManageRecipient';
import {ManageRecipientContextProvider} from './context/ManageRecipientContext';
import {Navigate} from 'react-router-dom';
import {usePlatformNav} from '../../appRunner';
import {COMPLIANCE_VIOLATIONS_DETAIL} from '../../paths';

const ManageRecipientDefault = ({params}) => {
    const {complianceViolationId} = params;
    const api = {
        fetchIssue,
        submitIssue
    };
    const utils = {
        getFullName
    };

    const platFormNav = usePlatformNav();

    const LoadingView = () => <ManageRecipientLoading LoadingView={LoadingAnimation}/>;
    const SavingAnimation = () => <LoadingAnimation loadingMessage="Saving..."/>;
    const SavingView = () => <ManageRecipientLoading LoadingView={SavingAnimation}/>;
    const ContentView = () => (
        <ManageRecipientContent complianceViolationId={complianceViolationId}/>
    );

    return platFormNav ? (
        <ManageRecipientContextProvider>
            <ManageRecipient
                useLoadIssue={useLoadIssue}
                api={api}
                utils={utils}
                complianceViolationId={complianceViolationId}
                LoadingView={LoadingView}
                SavingView={SavingView}
                ContentView={ContentView}
            />
        </ManageRecipientContextProvider>
    ) : (
        <Navigate
            to={COMPLIANCE_VIOLATIONS_DETAIL.replace(':complianceViolationId', complianceViolationId)}
        />
    );
};

export default withParams(ManageRecipientDefault);
