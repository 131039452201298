const casAdvertiserkey = 'advertiser';
const complianceToolAdvertiserKey = 'complianceAdvertiser';

export const saveAdvertiser = advertiser => {
  try {
    localStorage.setItem(casAdvertiserkey, JSON.stringify(advertiser));
  } catch (e) {
    console.log(e);
  }
};

export const getAdvertiser = () => {
  try {
    return JSON.parse(localStorage.getItem(casAdvertiserkey));
  } catch (e) {
    return null;
  }
};

export const removeAdvertiser = () => {
  localStorage.removeItem(casAdvertiserkey);
};

export const getComplianceToolAdvertiser = () => {
  try {
    return JSON.parse(localStorage.getItem(complianceToolAdvertiserKey));
  } catch (e) {
    return null;
  }
};

export const saveComplianceToolAdvertiser = advertiser => {
  try {
    localStorage.setItem(complianceToolAdvertiserKey, JSON.stringify(advertiser));
  } catch (e) {
    console.log(e);
  }
};
