import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {history} from './router';
import {createRouterMiddleware} from '@lagunovsky/redux-react-router'
import Reducer from './reducer';

// const router = routerMiddleware(history);
const router = createRouterMiddleware(history);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middlewares = [thunk, router];

const store = createStore(
    Reducer,
    composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
