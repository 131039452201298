import * as R from 'ramda';
import {isPStyleAdvertiser} from '../../containers/Advertisers/AdvertisersDropdown/advertiserStorage';
import {useCallback, useEffect, useState} from 'react';
import {fetchWebsites} from '../../domain/Websites/actions';
import {fetchProducts} from '../../domain/ViolationProducts/actions';
import {fetchViolationTypeMappings} from '../../domain/ViolationTypeMappings/actions';
import {remapViolations} from '../../domain/ViolationTypeMappings/reducer';

const useViolationModal = advertiserId => {
    const [loadingModal, setLoadingModal] = useState(true);
    const [websites, setWebsites] = useState([]);
    const [websitesMapping, setWebsitesMapping] = useState([]);
    const [products, setProducts] = useState([]);
    const [violationTypeMappings, setViolationTypeMappings] = useState([]);
    const [violationTypes, setViolationTypes] = useState([]);
    const isSpecialOverride = isPStyleAdvertiser(advertiserId);


    const getAnnotatedWebsite = useCallback(websiteId => websitesMapping[websiteId], [websitesMapping]);

    const getPublisherName = useCallback(websiteId => websitesMapping[websiteId]?.publisherName, [websitesMapping]);

    const getWebsiteName = useCallback(websiteId => websitesMapping[websiteId]?.websiteName, [websitesMapping]);

    const getPublisherId = useCallback(websiteId => websitesMapping[websiteId]?.publisherId, [websitesMapping]);

    const websitesToAnotatedWebsites = useCallback(annotatedWebsite => [
        annotatedWebsite.websiteId,
        annotatedWebsite
    ], []);

    useEffect(() => {
        (async () => {
            const websitesFromApi = await fetchWebsites(advertiserId);
            const productsFromApi = await fetchProducts(advertiserId);
            const violationTypeMappingsFromApi = await fetchViolationTypeMappings(advertiserId);
            const viotlationTypeMap = remapViolations(violationTypeMappingsFromApi);
            const violationTypeKey = R.keys(viotlationTypeMap);
            const annotatedWebsites = R.fromPairs(websitesFromApi.map(websitesToAnotatedWebsites));
            setWebsitesMapping(annotatedWebsites);
            setProducts(productsFromApi);
            setWebsites(websitesFromApi);
            setProducts(productsFromApi);
            setViolationTypeMappings(viotlationTypeMap);
            setViolationTypes(violationTypeKey);
            setLoadingModal(false);
        })();
    }, [advertiserId]);

    return {
        loadingModal,
        websites,
        products,
        violationTypeMappings,
        isSpecialOverride,
        violationTypes,
        websitesMapping,
        getAnnotatedWebsite,
        getPublisherName,
        getWebsiteName,
        getPublisherId
    };
};

export default useViolationModal;
