import React from 'react';
import { SectionRow, SectionRowTitle, SubSection } from '../Section';
import styled from 'styled-components';
import Screenshot from '../Screenshot';

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ({ offer, ...rest }) => (
  <SubSection>
    <SectionRow>
      <TopContainer>
        <SectionRowTitle>Rank {offer.offerOrder}</SectionRowTitle>
        {rest.children}
      </TopContainer>
      {offer.screenShots.map((screenShot, index) => {
        return (
          <div key={index}>
            <Screenshot key={screenShot} src={screenShot} alt="screenshot" />
          </div>
        );
      })}
    </SectionRow>
  </SubSection>
);
