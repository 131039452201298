import React from 'react';
import R from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatDate, formatDistance } from '../../utils';
import LinkIcon from '../../components/LinkIcon';
import { FixedLayoutTable, Thead, Tr, Td, Th } from '../../components/Table';
import ErrorCount from '../../components/ErrorCount';
import Loader from '../../components/Loader';

export const TestUrlLink = ({ testResult }) => {
  const url = R.isNil(testResult.testUrl) ? 'URL NOT SPECIFIED' : testResult.testUrl;
  return <Link to={`/test-result-detail/${testResult.id}`}>{url}</Link>;
};

const WordWrapTd = styled(Td)`
  word-wrap: break-word;
`;
const RightAllignedTd = styled(Td)`
  text-align: right;
`;

const ErrorText = styled.span`
  color: red;
  font-size: 1.3em;
`;

const TestProgressSpan = styled.span`
  padding-left: 10px;
  font-weight: normal;
`;

const ThUrl = styled(Th)`
  width: 60%;
`;
const ThErrors = styled(Th)`
  width: 10%;
`;
const ThDate = styled(Th)`
  width: 10%;
`;

export const TestProgress = ({ returnedTestCount, totalUrlCount }) => {
  if (!totalUrlCount || totalUrlCount === 0) return <span />;
  const minimumTotal = Math.min(returnedTestCount, totalUrlCount);
  console.log('raw returnedTestCount', returnedTestCount, minimumTotal);
  const percentDone = Math.round((minimumTotal / totalUrlCount) * 100);
  return (
    <TestProgressSpan>
      <b>
        {minimumTotal} out of {totalUrlCount} ({percentDone}
        %)
      </b>
    </TestProgressSpan>
  );
};

class TestResultsTable extends React.Component {
  constructor() {
    super();
    this.state = {
      errorFilterChecked: false
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({
      errorFilterChecked: event.target.checked
    });
  }

  render() {
    const { testResults, totalUrlCount } = this.props;
    if (testResults.loading) {
      return <Loader />;
    }
    if (testResults.error) {
      return <ErrorText>Error: {testResults.error.message}</ErrorText>;
    }
    const pageErrorCount = result => {
      const errors = R.view(R.lensPath(['page', 'errors']))(result);
      return errors ? errors.length : 0;
    };

    const resultsWithErrors = R.filter(
      result => result.errorCount > 0 || pageErrorCount(result) > 0
    )(testResults.data);

    const results = this.state.errorFilterChecked ? resultsWithErrors : testResults.data;

    return (
      <FixedLayoutTable>
        <Thead>
          <Tr>
            <ThUrl>
              <b>URLs </b>
              <TestProgress
                returnedTestCount={testResults.testResultsStat.numberOfUrls}
                totalUrlCount={totalUrlCount}
              />
            </ThUrl>
            <ThErrors right>
              <b>Product Errors</b>
            </ThErrors>
            <ThErrors right>
              <b>Page Errors</b>
            </ThErrors>
            <ThDate>
              <b>Date</b>
            </ThDate>
            <ThErrors right nowrap />
          </Tr>
        </Thead>
        <tbody>
          {results.map((result, index) => (
            <Tr key={index}>
              <WordWrapTd>
                {testResults.filters.missingTestResults ? (
                  <div>{result.testUrl}</div>
                ) : (
                  <TestUrlLink testResult={result} />
                )}
              </WordWrapTd>
              <Td right>
                <ErrorCount count={result.errorCount >= 0 ? result.errorCount : 'N/A'} />
              </Td>
              <Td right>
                <ErrorCount count={result.pageErrorCount >= 0 ? result.pageErrorCount : 'N/A'} />
              </Td>
              <Td nowrap>
                <div>{formatDate(result.timestamp)}</div>
                <div>{formatDistance(result.timestamp)}</div>
              </Td>
              <RightAllignedTd>
                <LinkIcon url={result.testUrl} />
              </RightAllignedTd>
            </Tr>
          ))}
        </tbody>
      </FixedLayoutTable>
    );
  }
}

export default TestResultsTable;
