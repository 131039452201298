import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { selectDomain } from '../../reducer';
import { arrayMove } from 'react-sortable-hoc';
import R from 'ramda';
import {
  Panel,
  Body as PanelBody,
  Header as PanelHeader
} from '@cjdev-internal/visual-stack-x/components/Panel';
import { AppLayout } from '../App';
import { ProductModalMountPoint } from '../../components/Modal';

import {
  fetchDomainConfigs,
  submitDomainConfigsOrder,
  archiveDomainConfig,
  setArchiveErrorMessage,
  fetchDomainById,
  submitDomainGlobalSettings
} from '../../domain/Domains/actions';
import { SimpleError } from '../../components/ErrorMessage';
import { SortableDomainTable } from './SortableDomainTable';
import SavedNotification from '../../components/Notification/SavedNotification';
import { reduxForm, Field } from 'redux-form';
import {
  NameInput,
  MediumTextArea,
  SubmitButtonWrapper,
  Validators
} from '../../components/FormFields';
import {history, withNavigate, withParams} from '../../router'

const PanelHeaderContainer = styled.div`
  display: flex;
`;

const PanelHeaderTitle = styled.div`
  font-size: 14px;
  color: #585858;
`;

const getDomainConfigs = ({
  domains: {
    domainConfigs: { domainConfigsDetail }
  }
}) => domainConfigsDetail;

const DomainFormPure = props => (
  <form onSubmit={props.handleSubmit}>
    <Field name="domain" label="Domain" component={NameInput} validate={Validators.required} />
    <Field
      name="publisherIds"
      label="Publisher Ids"
      component={MediumTextArea}
      validate={Validators.numericList}
    />
    <Field
      name="websiteIds"
      label="Website Ids"
      component={MediumTextArea}
      validate={Validators.numericList}
    />
    <Field name="offerLinkUrlPatterns" label="Offer Link URL Patterns" component={MediumTextArea} />
    <SubmitButtonWrapper disabled={!props.valid}>Submit</SubmitButtonWrapper>
  </form>
);

const DomainForm = reduxForm({
  form: 'domain',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(DomainFormPure);

export class Domains extends React.Component {
  constructor() {
    super();
    this.state = {
      domainConfigs: [],
      saved: false
    };
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const oldDomainConfigs = getDomainConfigs(this.props);
    const newDomainConfigs = getDomainConfigs(nextProps);
    if (oldDomainConfigs.length !== newDomainConfigs.length) {
      this.setState({
        domainConfigs: newDomainConfigs,
        saved: false
      });
    }
  }

  componentDidMount() {
    this.props.fetchDomainConfigs(this.props.params.domainId).then(() => {
      this.setState({
        domainConfigs: this.props.domains.domainConfigs.domainConfigsDetail,
        saved: false
      });
    });
    this.props.fetchDomain(this.props.params.domainId);
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(prevState => ({
      domainConfigs: arrayMove(prevState.domainConfigs, oldIndex, newIndex),
      saved: true
    }));

    setTimeout(() => {
      this.setState({
        saved: false
      });
    }, 1000);

    if (this.state.domainConfigs.length > 1) {
      const newIdList = this.state.domainConfigs.map(domain => domain.id);
      const domainId = this.props.params.domainId;
      const domainValues = { domainConfigIds: newIdList };
      this.props.submitDomainConfigsOrder(domainValues, domainId);
    }
  }

  render() {
    const domainId = this.props.params.domainId;
    const { domain } = this.props;
    const clonedDomain = { ...domain };
    const { offerLinkUrlPatterns, publisherIds, websiteIds } = clonedDomain;
    clonedDomain.offerLinkUrlPatterns = R.defaultTo([])(offerLinkUrlPatterns).join('\n');
    clonedDomain.publisherIds = R.defaultTo([])(publisherIds).join('\n');
    clonedDomain.websiteIds = R.defaultTo([])(websiteIds).join('\n');
    return (
      <AppLayout
        pageHeaderTitle={`Domain Configurations - ${R.defaultTo('')(
          R.view(R.lensPath(['domain']))(domain)
        )}`}
      >
        <Panel>
          <PanelHeader>
            <PanelHeaderContainer>
              <PanelHeaderTitle>Domain</PanelHeaderTitle>
            </PanelHeaderContainer>
          </PanelHeader>
          <PanelBody>
            {this.props.error && <SimpleError error={this.props.error} />}
            {!this.props.error && (
              <DomainForm
                onSubmit={async values => {
                  await this.props.submitDomainGlobalSettings(values, domainId);
                  if (!this.props.error) {
                    history.push('/domains');
                    this.props.navigate('/domains');
                  }
                }}
                initialValues={clonedDomain}
              />
            )}
          </PanelBody>
        </Panel>
        <Panel>
          <ProductModalMountPoint />
          <PanelHeader>
            <PanelHeaderContainer>
              <PanelHeaderTitle>Domain Configurations</PanelHeaderTitle>
              {this.state.saved && <SavedNotification />}
            </PanelHeaderContainer>
          </PanelHeader>
          <PanelBody>
            {this.props.error ? (
              <SimpleError error={this.props.error} />
            ) : (
              <SortableDomainTable
                domainConfigs={this.state.domainConfigs}
                navigateToCreatePage={() => {
                  const url = `/domains/${domainId}/domain-configs/create`;
                  history.push(url);
                  this.props.navigate(url);
                }}
                navigateToEditPage={configId => {
                  const url = `/domains/${domainId}/domain-configs/${configId}`;
                  history.push(url);
                  this.props.navigate(url);
                }}
                lockAxis={'y'}
                distance={5}
                helperClass="helper"
                onSortEnd={this.onSortEnd}
                archiveDomainConfig={this.props.archiveDomainConfig}
                resetArchiveErrorMessage={this.props.resetArchiveErrorMessage}
              />
            )}
          </PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

export const mapStateToProps = state => ({
  domains: state.CAS.domains,
  error: state.CAS.domains.domainConfigs.error || state.CAS.domains.domains.submitDomain.error,
  domain: selectDomain(state)
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDomainConfigs: id => dispatch(fetchDomainConfigs(id)),
  archiveDomainConfig: async domainConfigId => {
    const { domainId } = ownProps.params;
    await dispatch(archiveDomainConfig(domainId, domainConfigId));
    dispatch(fetchDomainConfigs(domainId));
  },
  submitDomainConfigsOrder: (value, id) => dispatch(submitDomainConfigsOrder(value, id)),
  resetArchiveErrorMessage: () => {
    dispatch(setArchiveErrorMessage(null));
  },
  submitDomainGlobalSettings: async (values, domainId) => {
    await dispatch(submitDomainGlobalSettings(values, domainId));
  },
  fetchDomain: domainId => {
    dispatch(fetchDomainById(domainId));
  }
});

export default withParams(withNavigate(connect(mapStateToProps, mapDispatchToProps)(Domains)));
