import React from 'react';
import styled from 'styled-components';

const A = styled.a`
  color: #585858;
  cursor: pointer;
  &:focus {
    color: #585858;
  }
`;
export default ({ url }) => (
  <A
    onClick={() => {
      window.open(url, 'some name', 'width=1200, height=900');
      window.opener = null;
    }}
  >
    <i className="fa fa-lg fa-external-link" />
  </A>
);
