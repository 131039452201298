import React from 'react';
import { BlankSlate, SubTitle } from '@cjdev-internal/visual-stack-x/components/BlankSlate';
import { Button } from '@cjdev-internal/visual-stack-x/components/Button';
import ExistingConfigurations from './ExistingConfigurations';
import R from 'ramda';
import { isToken } from './util';
import { TOKEN, TEXT } from './constants';
import { Title } from './Style';
import styled from 'styled-components';
import Spinner from '../../../components/Spinner';
import { selectInferredProhibitedTextsIsLoading } from '../../../reducer';
import { connect } from 'react-redux';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
`;

const ExistingConfigurationContainer = styled.div`
  & > * {
    margin-top: 12px;
  }
`;

const tokenize = ({ phrase, selectedTokens }) => {
  return phrase.split(' ').map((value, index) => {
    const valueIsToken = isToken(value);
    const type = valueIsToken ? TOKEN : TEXT;
    const selected = valueIsToken ? R.contains(index, selectedTokens) : undefined;
    return {
      value,
      type,
      selected
    };
  });
};

export const StartingPage = ({
  configurations = [],
  changeViewToPhraseInput,
  editExistingConfiguration,
  deleteExistingConfiguration,
  inferredProhibitedTextIsLoading
}) => {
  const tokenizedPhrases = configurations.map(tokenize);
  const startingPageContent = R.isEmpty(configurations) ? (
    <BlankSlate
      title="Inferred Prohibited Text"
    >
      <SubTitle>Add contextual variations for validation</SubTitle>
      <Button type="primary" data-reactid="addNewConfiguration" onClick={changeViewToPhraseInput}>
        Add New Configuration
      </Button>
    </BlankSlate>
  ) : (
    <ExistingConfigurationContainer>
      <Header>
        <Title>Inferred Prohibited Text Configurations</Title>
        <Button type="primary" data-reactid="addNewConfiguration" onClick={changeViewToPhraseInput}>
          Add New Configuration
        </Button>
      </Header>
      <ExistingConfigurations
        tokenizedPhrases={tokenizedPhrases}
        editExistingConfiguration={editExistingConfiguration}
        deleteExistingConfiguration={deleteExistingConfiguration}
      />
    </ExistingConfigurationContainer>
  );
  return inferredProhibitedTextIsLoading ? <Spinner /> : startingPageContent;
};

export default connect(state => ({
  inferredProhibitedTextIsLoading: selectInferredProhibitedTextsIsLoading(state)
}))(StartingPage);
