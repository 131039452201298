import * as R from 'ramda';
import * as qs from 'query-string';
import { authenticatedFetch } from '../../../../shared/apis';
import { apiServer } from '../../../../env';
import { history } from '../../../../router';
import { getAdvertisers } from '../../../Advertisers/AdvertisersDropdown/advertiserStorage';
import { getDefaultDateInMillis } from '../../dateHelper';

export const getQuery = () => {
  const advertiserIds = getAdvertisers();
  const defaultDateRange = getDefaultDateInMillis();
  const existingQuery = R.merge(defaultDateRange)(qs.parse(history.location.search));
  const filterQuery = qs.stringify(R.omit(['size', 'page'])(existingQuery));
  const advertisersExist = !R.isEmpty(advertiserIds);

  let query = `?${filterQuery}`;
  if (advertisersExist) {
    query = `${query}&advertiserIds=${JSON.stringify(advertiserIds)}`;
  }
  return query;
};

export const getIssues = async () => {
  const queryString = getQuery();
  const response = await authenticatedFetch(`${apiServer()}/issues${queryString}`);
  return response.json();
};
