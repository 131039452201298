import React from 'react'
import { useNavigate, useSearchParams, useLocation, useNavigationType, useParams } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history'

export const history = createHistory();

export function withNavigate(Component) {
  return (props) => {
    const navigate = useNavigate()
    return <Component {...props} navigate={navigate} />
  }
}

export function withSearchParams(Component) {
  return (props) => {
    const [searchParams] = useSearchParams()
    return <Component {...props} searchParams={searchParams} />
  }
}

export function withLocation(Component) {
  return (props) => {
    const location = useLocation()
    return <Component {...props} location={location} />
  }
}

export function withNavigationType(Component) {
  return (props) => {
    const navigationType = useNavigationType()
    return <Component {...props} navigationType={navigationType} />
  }
}

export function withParams(Component) {
  return (props) => {
    const params = useParams()
    return <Component {...props} params={params} />
  }
}
