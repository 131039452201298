import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import {ValueToQueryMap} from './QueryValueMap';

const DropDownSelect = styled(Select).attrs({
    classNamePrefix: 'react-select',
})`
    width: 259px;

    & .react-select__indicator-separator {
        display: none;
    }

    & .react-select__control {
        height: 32px;
        min-height: 32px;
    }
`;

export const IssuesSortableDropdown = ({ value, changeSorting }) => (
    <DropDownSelect
        name="filter"
        onChange={(newSort) => {
            changeSorting(ValueToQueryMap[newSort.value]);
        }}
        options={[
            {value: 'detectionTimeDesc', label: 'Detection Date (Newest to Oldest)'},
            {value: 'detectionTimeAsc', label: 'Detection Date (Oldest to Newest)'},
            {value: 'idDesc', label: 'Creation Date (Newest to Oldest)'},
            {value: 'idAsc', label: 'Creation Date (Oldest to Newest)'},
            {value: 'status', label: 'Status'},
        ]}
        value={value || {value: 'detectionTimeDesc', label: 'Detection Date (Newest to Oldest)'}}
        isSearchable={false}
        isClearable={false}
    />
);
