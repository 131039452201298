import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';

export const STORE_WEBSITE_DATA = 'Domains/advertiser/STORE_WEBSITE_DATA';

export const storeWebsiteDataAction = createAction(STORE_WEBSITE_DATA);

export const FAIL_FETCH_WEBSITE_DATA = 'Domains/advertiser/FAIL_FETCH_WEBSITE_DATA';

export const failFetchWebsiteDataAction = createAction(FAIL_FETCH_WEBSITE_DATA);

export const LOADING_FETCH_WEBSITE_DATA = 'Domains/advertiser/LOADING_FETCH_WEBSITE_DATA';

export const loadingFetchWebsiteData = createAction(LOADING_FETCH_WEBSITE_DATA);

export const STORE_SELECTED_WEBSITE_ID = 'Domains/advertiser/STORE_SELECTED_WEBSITE_ID';



export const fetchWebsites = async advertiserId => {
  let response;
  if (advertiserId) {
    response = await authenticatedFetch(`${apiServer()}/websites?advertiserIds=[${advertiserId}]`);
  } else {
    response = await authenticatedFetch(`${apiServer()}/websites`);
  }
  return await response.json();
};

export const fetchWebsiteAction = () => async dispatch => {
  dispatch(loadingFetchWebsiteData());
  try {
    const responseJSON = await fetchWebsites();
    dispatch(storeWebsiteDataAction(responseJSON));
  } catch (e) {
    dispatch(failFetchWebsiteDataAction());
  }
};
