import { apiServer } from '../../env';
import { authenticatedFetch } from '../../shared/apis';

export const submitSystemSettings = async values => {
  const response = await authenticatedFetch(`${apiServer()}/system-settings`, {
    headers: {
      'Content-Type': 'text/plain'
    },
    method: 'POST',
    body: JSON.stringify(values)
  });
  const json = await response.json();
  return {
    responseBody: json,
    code: response.status
  };
};
export const submitMasterUrls = async values => {
  try {
    const response = await authenticatedFetch(`${apiServer()}/master-urls`, {
      headers: {
        'Content-Type': 'text/plain'
      },
      method: 'POST',
      body: JSON.stringify(values)
    });
    const json = await response.json();
    return {
      responseBody: json,
      code: response.status
    };
  } catch (e) {
    throw e;
  }
};

export const getMasterUrlList = async () => {
  const response = await authenticatedFetch(`${apiServer()}/master-urls`);
  const json = await response.json();
  return json;
};
