import {endOfDay, getTime, startOfDay, subDays} from 'date-fns';

export const getDefaultDateInMillis = (getCurrentDate = () => new Date()) => {
  const currentDate = getCurrentDate();
  return {
    from: getTime(startOfDay(subDays(currentDate, 30))),
    to: getTime(currentDate),
    previousFrom: getTime(startOfDay(subDays(currentDate, 60))),
    previousTo: getTime(endOfDay(subDays(currentDate, 31)))
  };
};
