import React, {useEffect} from 'react';
import Select from '../../../components/Formik/FormSelect';
import {connect} from 'react-redux';
import {fetchAdvertiserAction} from '../../../domain/Advertisers/actions';
import {selectAdvertisers} from '../../../reducer';
import * as R from 'ramda';

export const AdvertiserDropdownPure = ({
                                           fetchAdvertiser,
                                           advertisersResponse,
                                           onChange,
                                           advertisers = []
                                       }) => {
  useEffect(() => {
    fetchAdvertiser();
  }, [fetchAdvertiser]);

  const advertiserOptions = advertisersResponse.map(({ cid, name }) => ({
    label: `${name} - ${cid}`,
    value: cid
  }));

    const selectedAdvertisers = advertiserOptions.filter(option => advertisers.includes(option.value));

  return (
      <Select
          value={selectedAdvertisers}
          options={advertiserOptions}
          isMulti
          placeholder="Advertiser"
          onChange={options => {
              const advertiserIds = options ? options.map(R.prop('value')) : [];
              onChange(advertiserIds);
          }}
      />
  );
};

const mapStateToProps = state => ({
  advertisersResponse: selectAdvertisers(state)
});

const mapDispatchToProps = {
  fetchAdvertiser: fetchAdvertiserAction
};

export const AdvertisersDropdown = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvertiserDropdownPure);