import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  PageHeader,
  PageTitle,
  PageHeaderSection
} from '@cjdev-internal/visual-stack-x/components/PageHeader';
import { ErrorCountPill, CountPill } from '../../components/ErrorCount';

import {
  TabLayout,
  Tab,
  TabContent,
  TabLabelContent
} from '@cjdev-internal/visual-stack-redux/lib/components/TabLayout';

import { AppLayout } from '../App';
import Loader from '../../components/Loader';
import { loadTestResults } from '../../domain/TestResults/actions';

import { loadTestResultDetail } from '../../domain/TestResultDetails/actions';
import DetailNavigation from './DetailNavigation';
import DetailMetadata from './DetailMetadata';
import ProductOffers from './ProductOffers';
import PageDetail from './PageDetail';
import CompetitiveOffers from './CompetitiveOffers';
import TestResultBreadCrumb from './TestResultBreadCrumb';
import LinkIcon from '../../components/LinkIcon';
import { withParams } from '../../router'

export const WhitePageHeader = styled.div`
  & > div.page-heading {
    background-color: white !important;
    box-shadow: none;
    border-bottom: 1px solid #e1e1e1;
  }
`;

export const FullPageWrapper = styled.div`
  margin: -15px -1em -1em;
`;

const Right = styled.div`
  right: 25px;
  top: 60px;
  z-index: 100;
  position: fixed;
  & > a {
    color: #585858;
  }
`;

export const TabPanel = ({ detail, onTabClick }) => {
  return (
    <TabLayout
      tabLayoutId={'testResultDetails'}
      themeColor={'#048BC6'}
      floatingHeader={true}
      headerHeight={'49px'}
      onTabClick={onTabClick}
    >
      <Tab
        disabled={detail.productOffers.length === 0}
        label={
          <TabLabelContent>
            Product Offers
            {detail.productOffers.length > 0 && <ErrorCountPill count={detail.errorCount} />}
          </TabLabelContent>
        }
        content={
          <TabContent>
            {detail.productOffers.length > 0 && <DetailMetadata detail={detail} />}
            <ProductOffers offers={detail.productOffers} />
          </TabContent>
        }
      />
      <Tab
        label={
          <TabLabelContent>
            Page Details
            <ErrorCountPill count={detail.page.errors.length} />
          </TabLabelContent>
        }
        content={
          <TabContent>
            {detail.productOffers.length === 0 && <DetailMetadata detail={detail} />}
            <PageDetail capture={detail.page} prefix="page" />
          </TabContent>
        }
      />
      <Tab
        disabled={detail.competitiveOffers.length === 0}
        label={
          <TabLabelContent>
            Competitive Offers
            <CountPill count={detail.competitiveOffers.length} />
          </TabLabelContent>
        }
        content={
          <TabContent>
            <CompetitiveOffers offers={detail.competitiveOffers} />
          </TabContent>
        }
      />
    </TabLayout>
  );
};

export class TestResultDetails extends React.Component {
  componentDidMount = async () => {
    await this.props.loadTestResultDetail(this.props.params.id);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.id !== this.props.params.id) {
      this.props.loadTestResultDetail(nextProps.params.id);
    }

    const transitionFromNullToDetail =
      this.props.testResults.data.length === 0 && nextProps.testResultDetail.data;
    const resultsAreLoading = this.props.testResults.loading;

    if (transitionFromNullToDetail && !resultsAreLoading) {
      this.props.loadTestResults(nextProps.testResultDetail.data.testId);
    }
  }

  onTabClick = () => {
    const scrollingContainer = document.getElementsByClassName('vs-application-layout-content')[0];

    if (scrollingContainer) {
      scrollingContainer.scrollTop = 0;
    }
  };

  render() {
    const {
      testResults: { testResultsStat },
      testResultDetail: { data: detail, loading }
    } = this.props;
    const testResultIds = testResultsStat.testResultIds.map(resultDetail => resultDetail.id);
    const pageHeader = (
      <WhitePageHeader>
        <PageHeader>
          <PageTitle>
            {detail && (
              <TestResultBreadCrumb testId={detail.testId} testName={testResultsStat.testName} />
            )}
            <br />
          </PageTitle>
          <PageHeaderSection>
            {detail && (
              <DetailNavigation
                id={detail.id}
                numberOfUrls={testResultsStat.numberOfUrls}
                testResultIds={testResultIds}
              />
            )}
          </PageHeaderSection>
        </PageHeader>
      </WhitePageHeader>
    );
    return (
      <AppLayout pageHeader={pageHeader}>
        {loading && <Loader />}
        {detail &&
          (detail.systemError ? (
            <DetailMetadata detail={detail} />
          ) : (
            <FullPageWrapper>
              <Right>
                <LinkIcon url={detail.testUrl} />
              </Right>
              <TabPanel detail={detail} onTabClick={() => this.onTabClick()} />
            </FullPageWrapper>
          ))}
      </AppLayout>
    );
  }
}

export const mapStateToProps = state => ({
  testResults: state.CAS.testResults,
  testResultDetail: state.CAS.testResultDetail
});

export default withParams(connect(mapStateToProps, {
  loadTestResultDetail,
  loadTestResults
})(TestResultDetails));
