import React from 'react';
import {
  Section,
  SectionTitle,
  SectionBody,
  SubSection,
  SectionRowTitle
} from '../../components/Section';
import TextCaptureRow from './TextCaptureRow';
import { withHighlighting } from './Highlighter';
import HighlightRows from '../../components/HighlightRows';
import ProductOfferScreenshotCaptureRow from '../../components/OfferRow/ProductOfferScreenshotCaptureRow';
import R from 'ramda';
import styled from 'styled-components';
import CJLogo from '../../components/Logo/CJLogo';
import Stamp from '../../components/Stamp';
import Screenshot from './Screenshot';

const Links = styled.div`
  & > div:not(:first-child) {
    border-top: 1px dashed #ddd;
  }
`;

const LinkContainer = styled.div`
  padding: 7px 0px 7px 0px;
  max-width: 1500px;
  word-break: break-all;
`;

export const LinkIndex = styled.span`
  color: #999;
  margin-right: 2px;
`;

const ExpandLink = styled.a`
  cursor: pointer;
  user-select: none;
`;

export const LinkLabel = styled.span`
  font-weight: 600;
  font-size: 1.05em;
`;

const RedirectChainContainer = styled.div`
  border-top: 0.5px dotted #ddd;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
`;

const LeftPaddedSpan = styled.span`
  padding-left: 30px;
`;

const OfferLinkDetails = ({ offerLinks }) => {
  return (
    !R.isEmpty(offerLinks || []) && (
      <SubSection>
        <SectionRowTitle>Offer Link Details</SectionRowTitle>
        {offerLinks.map((offerLink, index) => (
          <RedirectChain key={index} offerLink={offerLink} />
        ))}
      </SubSection>
    )
  );
};

class RedirectChain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showLandingPage: false
    };
  }

  render() {
    const {
      isCJOffer,
      offerLinksChain,
      landingPageUrl,
      cjLink,
      landingPageScreenshot
    } = this.props.offerLink;
    const { expanded, showLandingPage } = this.state;
    return (
      <RedirectChainContainer>
        <div>
          <ExpandLink onClick={() => this.setState(({ expanded }) => ({ expanded: !expanded }))}>
            {expanded ? 'Collapse' : 'Expand'}
          </ExpandLink>
          {landingPageScreenshot && (
            <LeftPaddedSpan>
              <ExpandLink
                onClick={() =>
                  this.setState(({ showLandingPage }) => ({ showLandingPage: !showLandingPage }))
                }
              >
                {showLandingPage ? 'Collapse Screenshot' : 'Landing Page Screenshot'}
              </ExpandLink>
            </LeftPaddedSpan>
          )}
          {expanded ? (
            <div>
              <Links>
                {offerLinksChain.map((link, index) => (
                  <LinkContainer key={index}>
                    <LinkIndex>{index + 1}:</LinkIndex> {link}
                  </LinkContainer>
                ))}
              </Links>
            </div>
          ) : (
            <div>
              {isCJOffer && (
                <LinkContainer>
                  <LinkLabel>CJ Link:</LinkLabel> {cjLink}
                </LinkContainer>
              )}
              {!R.isEmpty(offerLinksChain) && (
                <LinkContainer>
                  <LinkLabel>Landing Page:</LinkLabel> {landingPageUrl}
                </LinkContainer>
              )}
            </div>
          )}
          {showLandingPage && <Screenshot src={landingPageScreenshot} alt="screenshot" />}
        </div>
        {isCJOffer ? <CJLogo /> : <Stamp color="#585858">NON-CJ PRODUCT FOUND</Stamp>}
      </RedirectChainContainer>
    );
  }
}

export const SimpleOffer = ({ capture: offer, index, totalOfferCount, ...highlightData }) => {
  const { highlights, hasLink, hasOfferLinkUrlPatterns: shouldRenderLogo, offerLinks } = offer;
  const isMissingLink = shouldRenderLogo && !hasLink;
  return (
    <Section>
      <SectionTitle>
        Product Offer {index + 1} of {totalOfferCount}
      </SectionTitle>
      <SectionBody>
        <HighlightRows {...highlightData} isMissingLink={isMissingLink} />
        <OfferLinkDetails offerLinks={offerLinks} />
        <TextCaptureRow highlights={highlights} />
        <ProductOfferScreenshotCaptureRow offer={offer} />
      </SectionBody>
    </Section>
  );
};

export const HighlightingOffer = withHighlighting(SimpleOffer);

const ProductOffers = ({ offers }) => {
  if (offers.length === 0) {
    return (
      <Section>
        <SectionTitle>Product Offers (0)</SectionTitle>
      </Section>
    );
  }
  return (
    <div>
      {offers.map((offer, index) => (
        <HighlightingOffer
          capture={offer}
          prefix={`offer-${index}`}
          key={index}
          index={index}
          totalOfferCount={offers.length}
        />
      ))}
    </div>
  );
};

export default ProductOffers;
