import React from 'react';
import { reduxForm, Field } from 'redux-form';
import {
  PaddedLabel,
  HorizontalSection,
  NameInput,
  SideBySideLargeTextArea,
  SideBySideFieldContainer,
  AlignedTextArea,
  AlignedContainer,
  MediumFlexTextArea,
  Toggle
} from '../../components/FormFields';

export const ProductFormDetail = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <SideBySideFieldContainer>
        <div>
          <Field label="Product Name" name="productName" component={NameInput} />
          <Field
            label="Alternate Product Names"
            name="alternativeProductNames"
            component={MediumFlexTextArea}
            value={''}
          />
        </div>
        <div>
          <div>
            <Field label="Advertiser CId" name="advertiserId" component={NameInput} />
            <Field
              label="Ad Id (enable Pid validation by entering values in the format AdId:Pid)"
              name="adIdPidCombos"
              component={MediumFlexTextArea}
            />
          </div>
        </div>
      </SideBySideFieldContainer>
      <SideBySideFieldContainer>
        <Field
          label="Offer Level Required Text"
          name="offerRequiredText"
          component={SideBySideLargeTextArea}
        />
        <Field
          label="Conditional Offer Level Required Text"
          name="conditionalOfferRequiredText"
          component={SideBySideLargeTextArea}
        />
      </SideBySideFieldContainer>
      <SideBySideFieldContainer>
        <Field
          label="Page Level Required Text"
          name="pageRequiredText"
          component={SideBySideLargeTextArea}
        />
        <Field
          label="Conditional Page Level Required Text"
          name="conditionalPageRequiredText"
          component={SideBySideLargeTextArea}
        />
      </SideBySideFieldContainer>
      <AlignedContainer>
        <Field label="Prohibited Text" name="prohibitedText" component={AlignedTextArea} />
      </AlignedContainer>
      <PaddedLabel>Selector Toggles</PaddedLabel>
      <HorizontalSection>
        <Field label="Rates and Fees" name="includeRatesAndFees" component={Toggle} />
        <Field label="Disclosures" name="includeDisclosures" component={Toggle} />
        <Field label="Exclusions" name="includeExclusions" component={Toggle} />
      </HorizontalSection>
      <Field name="id" component="input" type="hidden" />
    </form>
  );
};

export default reduxForm({
  form: 'create',
  enableReinitialize: true
})(ProductFormDetail);
