import React from 'react';
import { connect } from 'react-redux';
import { Panel, Body as PanelBody } from '@cjdev-internal/visual-stack-x/components/Panel';
import { ModalMountPoint } from '@cjdev-internal/visual-stack-redux/lib/components/Modal';
import { openModal } from '@cjdev-internal/visual-stack-redux';
import { AppLayout } from '../App';
import { Table, Thead, Tr, Td, Th } from '../../components/Table';
import { EditButton, CreateButton } from '../../components/Button';
import TextLink from '../../components/TextLink';
import { fetchDomains, submitDomainFilters } from '../../domain/Domains/actions';
import { SimpleError } from '../../components/ErrorMessage';
import SubmitDomainModal from './SubmitDomainModal';
import DomainPanelHeader from './DomainPanelHeader';
import R from 'ramda';

import { DOMAIN_PATH } from '../../paths';

export const DomainTable = ({ domains, openCreateModal, openEditModal }) => {
  const body = domains.map(({ id, domain, domainConfigIds }, index) => (
    <Tr key={index}>
      <Td>
        <TextLink id={id} textLink={domain} path={DOMAIN_PATH} />
      </Td>
      <Td right nowrap>
        {false && (
          <EditButton
            onClick={() => {
              openEditModal(id, domain, domainConfigIds);
            }}
          />
        )}
      </Td>
    </Tr>
  ));
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            <b>Domains</b>
          </Th>
          <Th right width="10%" nowrap>
            <CreateButton
              onClick={() => {
                openCreateModal();
              }}
            />
          </Th>
        </Tr>
      </Thead>
      <tbody>{body}</tbody>
    </Table>
  );
};

export class Domains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textFilter: props.domains.filters.domainSearch
    };
  }

  componentDidMount() {
    this.props.fetchDomains();
  }

  render() {
    const { openModal, domains, filteredDomains } = this.props;
    return (
      <AppLayout pageHeaderTitle="Domain Configurations">
        <ModalMountPoint />
        <Panel>
          <DomainPanelHeader
            filters={this.state}
            handleFilterChange={value =>
              this.setState({
                textFilter: value
              })
            }
            handleTextSearchFilterSubmit={this.props.submitDomainFilters}
          />
          <PanelBody>
            {domains.error ? (
              <SimpleError error={domains.error} />
            ) : (
              <DomainTable
                domains={filteredDomains}
                openCreateModal={() => {
                  openModal(SubmitDomainModal);
                }}
                openEditModal={(domainId, domain, domainConfigIds) => {
                  openModal(SubmitDomainModal, {
                    domainId: domainId,
                    domain: domain,
                    domainConfigIds: domainConfigIds
                  });
                }}
              />
            )}
          </PanelBody>
        </Panel>
      </AppLayout>
    );
  }
}

export const mapStateToProps = state => {
  const { domains } = state.CAS.domains;
  const { filters } = domains;
  const filteredDomains = filters.domainSearch
    ? R.filter(domainConfig => domainConfig.domain.includes(filters.domainSearch))(
        domains.domainsDetail
      )
    : domains.domainsDetail;
  return {
    domains,
    filteredDomains
  };
};

export const mapDispatchToProps = dispatch => ({
  fetchDomains: () => dispatch(fetchDomains()),
  openModal: (component, props) => dispatch(openModal(component, props))
});

export default connect(mapStateToProps, {
  fetchDomains,
  openModal,
  submitDomainFilters
})(Domains);
