import React from 'react';
import {dimensionOptions, getDimensionOption} from '../dimensionHelper';
import DashboardViewController from '../DashboardViewController';
import {getDimension} from '../RiskIdentificationTable/routeHelper';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Select} from "@cjdev-internal/visual-stack-x/Select";
import "./dimensionDropdown.css"

export const DimensionDropdown = ({
                                      dashboardViewController
                                  }) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const search = searchParams.toString()
    dashboardViewController ??= new DashboardViewController(navigate, search)

    const dimension = getDimension(search);
    return (
        <Select
            id="dimension"
            name="dimension"
            options={dimensionOptions}
            onChange={({value}) => {
                dashboardViewController.setQuery(query => {
                    return {...query, dimension: value};
                });
            }}
            value={getDimensionOption(dimension)}
            defaultValue={getDimensionOption(dimension)}
            focusStyles={true}
        />
    );
};

export default DimensionDropdown;
