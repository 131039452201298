import { createAction } from 'redux-actions';

import { apiServer } from '../../env';
import { authenticatedFetch } from '../../shared/apis';

export const START_GET_USER = 'CAS/users/START_GET_USER';
export const STORE_USER_DATA = 'CAS/users/STORE_USER_DATA';
export const FAIL_GET_USER = 'CAS/users/FAIL_GET_USER';

export const startGetUser = createAction(START_GET_USER);
export const storeUserData = createAction(STORE_USER_DATA);
export const failGetUser = createAction(FAIL_GET_USER);

export const getUser = () => async dispatch => {
  try {
    dispatch(startGetUser());
    const response = await authenticatedFetch(`${apiServer()}/users`);
    if (response.ok) {
      const json = await response.json();
      dispatch(storeUserData(json));
    } else {
      dispatch(failGetUser());
    }
  } catch (e) {
    dispatch(failGetUser());
  }
};

export const injectUserIntoReduxState = (user) => async dispatch => {
  dispatch(storeUserData(user));
};
