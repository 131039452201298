import React from 'react';
import styled from 'styled-components';
import NoSelectSpan from '../../NoSelectSpan';

const FilterWrapper = styled.div``;

export const FilterLabel = styled.span`
  padding-left: 0.5em;
`;

const Label = styled.label`
  margin-bottom: 0;
`;

const BooleanFilter = ({ label, value, onChange, disabled }) => (
  <FilterWrapper>
    <Label>
      <input
        type="checkbox"
        onChange={event => {
          onChange(event.target.checked);
        }}
        checked={!disabled && value}
        disabled={disabled}
      />
      <NoSelectSpan>
        <FilterLabel>{label}</FilterLabel>
      </NoSelectSpan>
    </Label>
  </FilterWrapper>
);

export default BooleanFilter;
