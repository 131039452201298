export const ValueToQueryMap = {
  status: { sortBy: 'status', order: 'desc' },
  detectionTimeAsc: { sortBy: 'detection_time', order: 'asc' },
  detectionTimeDesc: { sortBy: 'detection_time', order: 'desc' },
  idAsc: { sortBy: 'id', order: 'asc' },
  idDesc: { sortBy: 'id', order: 'desc' }
};
export const QueryToValueMap = {
  detection_time: {
    asc: 'Detection Date (Oldest to Newest)',
    desc: 'Detection Date (Newest to Oldest)'
  },
  id: {
    asc: 'Creation Date (Oldest to Newest)',
    desc: 'Creation Date (Newest to Oldest)'
  },
  status: {desc: 'Status'}
};