import { createAction } from 'redux-actions';
import R from 'ramda';
import { apiServer } from '../../env';
import { authenticatedFetch, getInferredProhibitedTexts, postInferredProhibitedTexts } from '../../shared/apis';

export const FORM_STORE = 'CAS/products/FORM_STORE';
export const storeForm = createAction(FORM_STORE);

export const FETCH_PRODUCTS_START = 'CAS/products/FETCH_PRODUCTS_START';
export const startFetchProducts = createAction(FETCH_PRODUCTS_START);
export const PRODUCTS_STORE = 'CAS/products/PRODUCTS_STORE';
export const storeProducts = createAction(PRODUCTS_STORE);
export const FETCH_PRODUCTS_FAILURE = 'CAS/products/FETCH_PRODUCTS_FAILURE';
export const failFetchProducts = createAction(FETCH_PRODUCTS_FAILURE);
export const STORE_PRODUCT_SUBMISSION_ERROR = 'CAS/products/STORE_PRODUCT_SUBMISSION_ERROR';
export const storeProductSubmissionError = createAction(STORE_PRODUCT_SUBMISSION_ERROR);
export const PRODUCT_SUBMISSION_SUCESS = 'CAS/products/PRODUCT_SUBMISSION_SUCESS';
export const productSubmissionSuccess = createAction(PRODUCT_SUBMISSION_SUCESS);
export const submitProductForm = values => async dispatch => {
  const response = await authenticatedFetch(`${apiServer()}/products`, {
    headers: {
      'Content-Type': 'text/plain'
    },
    method: 'POST',
    body: JSON.stringify(values)
  });
  const json = await response.json();
  if (response.status === 400) {
    dispatch(storeProductSubmissionError(json));
    return Promise.reject();
  } else {
    dispatch(productSubmissionSuccess());
    return Promise.resolve(json);
  }
};

export const fetchProducts = (id = 0) => async dispatch => {
  try {
    dispatch(startFetchProducts());
    const idString = id === 0 ? '' : `/${id}`;
    const response = await authenticatedFetch(`${apiServer()}/products${idString}`);
    const json = await response.json();
    dispatch(storeProducts(json));
  } catch (e) {
    dispatch(failFetchProducts(e.message));
  }
};

export const PRODUCT_DETAIL_RESET = 'CAS/products/PRODUCT_DETAIL_RESET';
export const resetProductDetail = createAction(PRODUCT_DETAIL_RESET);


export const PRODUCT_ARCHIVE_RESET = 'CAS/products/PRODUCT_ARCHIVE_RESET';
export const resetArchiveProduct = createAction(PRODUCT_ARCHIVE_RESET);

export const PRODUCT_ARCHIVE_START = 'CAS/products/PRODUCT_ARCHIVE_START';
export const startArchiveProduct = createAction(PRODUCT_ARCHIVE_START);

export const PRODUCT_ARCHIVE_SUCCESS = 'CAS/products/PRODUCT_ARCHIVE_SUCCESS';
export const successArchiveProduct = createAction(PRODUCT_ARCHIVE_SUCCESS);

export const PRODUCT_ARCHIVE_FAIL = 'CAS/products/PRODUCT_ARCHIVE_FAIL';
export const failArchiveProduct = createAction(PRODUCT_ARCHIVE_FAIL);

export const archiveProduct = productId => async dispatch => {
  dispatch(startArchiveProduct(productId));
  try {
    const response = await authenticatedFetch(`${apiServer()}/archive-product/${productId}`, {
      method: 'POST'
    });
    if (response.status === 200) {
      dispatch(successArchiveProduct());
    } else {
      dispatch(failArchiveProduct(response.statusText));
    }
  } catch (err) {
    dispatch(failArchiveProduct('Failed to archive product'));
  }
};

export const archiveAndLoad = productId => async (dispatch, getState) => {
  await dispatch(archiveProduct(productId));
  const archiveError = getState().CAS.products.data.archive.error;
  if (R.isNil(archiveError)) {
    return dispatch(fetchProducts());
  }
};

export const FETCH_INFERRED_PROHIBITED_TEXTS_START =
  'CAS/products/FETCH_INFERRED_PROHIBITED_TEXTS_START';
export const fetchInferredProhibitedTextsStart = createAction(
  FETCH_INFERRED_PROHIBITED_TEXTS_START
);
export const FETCH_INFERRED_PROHIBITED_TEXTS_SUCCESS =
  'CAS/products/FETCH_INFERRED_PROHIBITED_TEXTS_SUCCESS';
export const fetchInferredProhibitedTextsSuccess = createAction(
  FETCH_INFERRED_PROHIBITED_TEXTS_SUCCESS
);
export const FETCH_INFERRED_PROHIBITED_TEXTS_EMPTY =
  'CAS/products/FETCH_INFERRED_PROHIBITED_TEXTS_EMPTY';
export const fetchInferredProhibitedTextsEmpty = createAction(
  FETCH_INFERRED_PROHIBITED_TEXTS_EMPTY
);

export const fetchInferredProhibitedTexts = productId => async dispatch => {
  dispatch(fetchInferredProhibitedTextsStart());
  const response = await getInferredProhibitedTexts()
  if (response?.status === 404) {
    dispatch(fetchInferredProhibitedTextsEmpty());
  } else {
    const json = await response?.json();
    dispatch(fetchInferredProhibitedTextsSuccess(json));
  }
};

export const saveInferredProhibitedTexts = (productId, data) => async dispatch => {
  dispatch(fetchInferredProhibitedTextsStart());
  const json = await postInferredProhibitedTexts(productId, data)
  dispatch(fetchInferredProhibitedTextsSuccess(json));
};
