import * as qs from 'query-string';
import {authenticatedFetch, fetchJSON} from '../../../shared/apis';
import {apiServer, membersApiServer} from '../../../env';

const fetchIssue = async issueId => {
    const filterQuery = qs.stringify({
        issueNumber: issueId
    });
    const queryString = `?${filterQuery}`;
    try {
        const response = await fetchJSON(`${apiServer()}/issues${queryString}`);
        if (response.numIssues === 1) {
            return response.issues[0];
        } else {
            throw new Error(`'${issueId}' invalid compliance ID.`);
        }
    } catch (error) {
        throw error;
    }
};

export const submitIssue = async (issue) => {
    const body = {
        ...issue, issueReporter: {id: issue.issueReporter.id}
    }
    try {
        const response = await authenticatedFetch(`${apiServer()}/issues`, {
            method: 'POST', body: JSON.stringify(body)
        });
        return {response: response.json(),status: response.status};
    } catch (error) {
        throw error
    }
};

export const archiveIssue = async (issueId) => {
    try {
        const response = await authenticatedFetch(`${apiServer()}/archive-issues/${issueId}`, {
            method: 'POST'
          });
          return response.ok;
    } catch (error) {
        throw error;
    }

}

export const addComments = async (commentJson) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(commentJson)
    };

    try {
        const response = await authenticatedFetch(`${apiServer()}/add-comments`, requestOptions);
        return response;
    } catch (error) {
        return { status: 500, error: error.message };
    }
}

export const updateCommentVisibility = async (commentJson) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(commentJson)
    };

    try {
        const response = await authenticatedFetch(`${apiServer()}/update-comments`, requestOptions);
        return response.ok
    } catch (error) {
        throw error;
    }
};

export const sendMail = async (issue) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(issue)
    };

    try {
        const response = await authenticatedFetch(`${apiServer()}/mail`, requestOptions);
        return response;
    } catch (error) {
        return { status: 500, error: error.message };
    }
}

export const initializeOrUpdateUser = async () => {
    await authenticatedFetch(`${apiServer()}/compliance-users`, {
        method: 'POST',
    });
}

export default fetchIssue;
