import * as R from 'ramda';
import store from '../../../store';
import { storeSelectedAdvertisersAction } from '../../../domain/Advertisers/actions';

const storeAdverstiersToRedux = advertiserIds => {
  store.dispatch(storeSelectedAdvertisersAction(advertiserIds));
};

export const getAdvertisers = () => {
  const advertiserString = window.localStorage.getItem('advertisers') || '[]';
  return JSON.parse(advertiserString);
};

export const initializeAdvertisers = () => {
  storeAdverstiersToRedux(getAdvertisers());
};

export const putAdvertisers = ids => {
  const advertiserIds = ids;
  storeAdverstiersToRedux(advertiserIds);
  window.localStorage.setItem('advertisers', JSON.stringify(ids));
};

export const isPStyleAdvertiser = advertiserId => {
  return R.contains(advertiserId)([4025547, 3836079]);
};
