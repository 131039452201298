import React from 'react';
import styled from 'styled-components';
import { Header as PanelHeader } from '@cjdev-internal/visual-stack-x/components/Panel';
import { ExportButton } from '../../components/Button';
import { SubmitModalButton } from '../../components/Modal/ModalButton';
import { createCancelModal } from '../../components/Modal';
import { PanelHeaderRow, PanelHeaderRowSection } from '../../components/PanelHeader';
import BooleanFilter from '../../components/Filters/BooleanFilter';
import TextSearchFilter from '../../components/Filters/TextSearchFilter';
import PagingControls from './PagingControls';
import { resetCancelTest, cancelAndLoad } from '../../domain/Tests/actions';

import { connect } from 'react-redux';
import { Row } from '@cjdev-internal/visual-stack-x/components/Row';
import { Text } from '@cjdev-internal/visual-stack-x/Text';


const StatusTitle = styled.span`
  font-weight: 600;
  margin-right: 5px;
`;

const StatusTranslationMap = {
  submitting: 'IN PROGRESS',
  canceled: 'CANCELED'
};

export const CancelTestModalDialog = createCancelModal('Cancel Test', ['tests', 'cancel']);

export const TestStatusPure = ({ test, isSelectedTestDone, cancelAndLoad, resetCancelTest }) => {
  return isSelectedTestDone ? (
    <div />
  ) : (
    <div>
      <span className="status">
        <Text weight="bold">Status:</Text> {StatusTranslationMap[test.status]}
      </span>
      {test.status !== 'canceled' && (
        <SubmitModalButton
          ModalDialog={CancelTestModalDialog}
          itemName={test.testName}
          takeAction={() => {
            cancelAndLoad(test.id);
          }}
          onCancel={resetCancelTest}
        />
      )}
    </div>
  );
};

export const TestStatus = connect(null, { cancelAndLoad, resetCancelTest })(TestStatusPure);

export const TestResultsPanelHeader = ({
  testResults,
  handleFilterChange,
  handleTextSearchFilterSubmit,
  makeFileExport,
  isSelectedTestDone,
  test = {}
}) => {
  const handleTextSubmit = value => {
    handleTextSearchFilterSubmit({ ...testResults.filters, urlText: value });
  };

  const shouldDisableIrrelevantFilters = testResults.filters.missingTestResults;
  const urlList = testResults.testResultsStat.testResultIds;
  const { testName } = testResults.testResultsStat;

  return (
    <PanelHeader>
      <PanelHeaderRow>
        <PanelHeaderRowSection>
          <TextSearchFilter
            label="Filter by URL"
            onChange={value => handleFilterChange('urlText', value)}
            value={testResults.filters.urlText}
            filters={testResults.filters}
            handleSubmit={handleTextSubmit}
          />
        </PanelHeaderRowSection>
        <PanelHeaderRowSection>
          <PagingControls
            currentPage={testResults.filters.page}
            currentPageSize={testResults.filters.pageSize}
            onPageChange={value => handleFilterChange('page', value)}
            onPageSizeChange={value => handleFilterChange('pageSize', value)}
            totalPages={testResults.totalPages}
          />
          <ExportButton onClick={() => makeFileExport(urlList, testName)} />
        </PanelHeaderRowSection>
      </PanelHeaderRow>
      <Row justify="space-between">
        <TestStatus isSelectedTestDone={isSelectedTestDone} test={test} />
        <PanelHeaderRow justify="flex-end">
          <BooleanFilter
            label="Missing Test Results"
            onChange={value => handleFilterChange('missingTestResults', value)}
            value={testResults.filters.missingTestResults}
          />
          <BooleanFilter
            label="System Errors Only"
            onChange={value => handleFilterChange('systemErrorsOnly', value)}
            value={testResults.filters.systemErrorsOnly}
            disabled={shouldDisableIrrelevantFilters}
          />
          <BooleanFilter
            label="Product Errors"
            onChange={value => handleFilterChange('productErrors', value)}
            value={testResults.filters.productErrors}
            disabled={shouldDisableIrrelevantFilters}
          />
          <BooleanFilter
            label="Page Errors"
            onChange={value => handleFilterChange('pageErrors', value)}
            value={testResults.filters.pageErrors}
            disabled={shouldDisableIrrelevantFilters}
          />
          <BooleanFilter
            label="Product Identified"
            onChange={value => handleFilterChange('productIdentified', value)}
            value={testResults.filters.productIdentified}
            disabled={shouldDisableIrrelevantFilters}
          />
          <BooleanFilter
            label="CJ Offers Only"
            onChange={value => handleFilterChange('cjOffers', value)}
            value={testResults.filters.cjOffers}
            disabled={shouldDisableIrrelevantFilters}
          />
        </PanelHeaderRow>
      </Row>
    </PanelHeader>
  );
};

export default TestResultsPanelHeader;
