import { Modal } from '@cjdev-internal/visual-stack-x/components/Modal';
import PropTypes from 'prop-types';
import { ArchiveButton, CancelButton, SpinnerButton } from '../Button';
import React from 'react';
import { SimpleError } from '../ErrorMessage';
import R from 'ramda';

export default class ArchiveModalDialogPure extends React.Component {
  componentWillReceiveProps(nextProps) {
    const stoppedLoading = this.props.archive.loading && !nextProps.archive.loading;
    const archiveError = nextProps.archive.error;
    if (stoppedLoading && R.isNil(archiveError)) {
      this.props.closeModal();
    }
  }

  render() {
    const { takeAction, onCancel, closeModal, modalTitle, itemName, archive } = this.props;
    const footer = (<>
        {archive.error && <SimpleError error={archive.error} />}
            {!archive.loading && <ArchiveButton onClick={takeAction} />}
            {archive.loading && <SpinnerButton message="Archiving..." />}
            <CancelButton
              onClick={() => {
                onCancel();
                closeModal();
              }}
            />
    </>);
    return (
      <Modal headerTitle={modalTitle} footer={footer}>
        Are you sure you want to archive: <b>{itemName}?</b>
      </Modal>
    );
  }
}

ArchiveModalDialogPure.propTypes = {
  takeAction: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  archive: PropTypes.shape({
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired
  })
};
