import React from 'react';
import * as R from 'ramda';
import { isURL } from 'validator';
import { prependHttpIfNotExist } from '../../../src/utils';
import Label from './Label';
import UrlTextArea from './UrlTextArea';
import ErrorMessage from '../ErrorMessage';

const isValidUrl = url => isURL(url) && R.startsWith('http')(url);
export const SUBMITTED = 'SUBMITTED';

export const splitUrls = R.pipe(R.split('\n'), R.reject(R.isEmpty));
export const joinUrls = R.join('\n');
const prependHttpIfNotExistToUrls = R.map(prependHttpIfNotExist);

export const getUrlDetail = (urls, invalidUrls) => {
  const allUrlsArray = R.concat(splitUrls(urls), splitUrls(invalidUrls));
  const urlsWithHttpProtocol = R.uniq(prependHttpIfNotExistToUrls(allUrlsArray));
  const validUrlsArray = R.filter(isValidUrl)(urlsWithHttpProtocol);
  const invalidUrlsArray = R.reject(isValidUrl)(urlsWithHttpProtocol);
  const validUrlsFormValue = joinUrls(prependHttpIfNotExistToUrls(validUrlsArray));
  const invalidUrlsFormValue = joinUrls(prependHttpIfNotExistToUrls(invalidUrlsArray));
  return {
    urlsWithHttpProtocol,
    validUrlsArray,
    invalidUrlsArray,
    validUrlsFormValue,
    invalidUrlsFormValue
  };
};

export default ({ formikBag, label = 'URLs' }) => {
  const { urls = '', invalidUrls = '' } = formikBag.values;
  return (
    <React.Fragment>
      <Label>{`${label} (${R.length(splitUrls(urls))} URLs)`}</Label>
      <div>
        <UrlTextArea name="urls" onChange={formikBag.handleChange} value={urls} />
      </div>
      <div>
        {invalidUrls && (
          <React.Fragment>
            <div>
              <ErrorMessage>
                Submission will not occur until the following URLs have been edited or removed:
              </ErrorMessage>
            </div>
            <UrlTextArea name="invalidUrls" onChange={formikBag.handleChange} value={invalidUrls} />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
