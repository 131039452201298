import React from "react";
import styled from "styled-components";
import { TOKEN } from "./constants";
import { connect } from "react-redux";
import { Button } from "@cjdev-internal/visual-stack-x/components/Button";
import { openModal, closeModal } from "@cjdev-internal/visual-stack-redux";
import { ModalMountPoint } from "@cjdev-internal/visual-stack-redux/lib/components/Modal";
import { Row } from "@cjdev-internal/visual-stack-x/components/Row";
import { Modal } from "@cjdev-internal/visual-stack-x/components/Modal";

const ModalDialog = ({ takeAction, closeModal }) => {
  const footer = (
    <Row justify="end">
      <Button
        type="primary"
        onClick={() => {
          takeAction();
          closeModal();
        }}
      >
        Yes
      </Button>
      <Button type="secondary" onClick={() => closeModal()}>
        No
      </Button>
    </Row>
  );

  return (
    <Modal headerTitle="Delete Configuration" footer={footer}>
      Are you sure you want to delete this configuration?
    </Modal>
  );
};

const ModalButton = ({ openModal, closeModal, takeAction }) => (
  <Button
    type="danger"
    onClick={() => openModal(ModalDialog, { takeAction, closeModal })}
  >
    Delete
  </Button>
);

const Buttons = styled.div`
  & > * {
    margin-right: 5px;
  }
`;

const DeleteButton = connect(null, { openModal, closeModal })(ModalButton);

const ExistingConfigs = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 50%;
  border: 1px solid #cccccc;
  padding: 17px;
`;

const TextContainer = styled.div`
  line-height: 2.5;
  display: flex;
  flex-wrap: wrap;
  white-space: normal;
  width: 70%;
  align-items: center;
`;

const EditButton = styled(Button)`
  color: #bbbbbb;
  border-color: #bbbbbb;

  &:hover {
    background: #eeeeee;
  }
`;

const ExistingConfigurationsContainer = styled.div`
  & > *:not(:last-child) {
    border-bottom: none;
  }
`;

export const Token = styled.span`
  ${({ selected }) => selected && "color: #00af65"};
  font-weight: 600;
  margin-right: 3px;
  font-size: 1.3em;
  text-decoration: underline;
  cursor: default;
`;
export const Text = styled.span`
  color: #636363;
  margin-right: 3px;
`;

export default ({
  tokenizedPhrases,
  editExistingConfiguration,
  deleteExistingConfiguration,
}) => {
  return (
    <ExistingConfigurationsContainer>
      <ModalMountPoint />
      {tokenizedPhrases.map((phrase, index) => {
        return (
          <ExistingConfigs data-testid="existingConfiguration" key={index}>
            <TextContainer>
              {phrase.map((value, index) => {
                return value.type === TOKEN ? (
                  <Token selected={value.selected} key={index}>
                    {value.value}
                  </Token>
                ) : (
                  <Text key={index}>{value.value}</Text>
                );
              })}
            </TextContainer>
            <Buttons>
              <DeleteButton
                data-testid="delete"
                takeAction={() => {
                  deleteExistingConfiguration(index);
                }}
              />
              <EditButton
                type="primary"
                data-testid="edit"
                onClick={() =>
                  editExistingConfiguration(index, tokenizedPhrases[index])
                }
              >
                Edit
              </EditButton>
            </Buttons>
          </ExistingConfigs>
        );
      })}
    </ExistingConfigurationsContainer>
  );
};
