import {CREATE_ADVERTISER} from "../../../paths";
import {Button} from "@cjdev-internal/visual-stack-x/components/Button";
import {Row} from "@cjdev-internal/visual-stack-x/components/Row";

export const ActionsAdvertiser = ({navigate, usePlatformNav, navigateTo, history, user}) => {
    const platformNav = usePlatformNav();

    const createAdvertiser = () => {
        navigateTo(CREATE_ADVERTISER, navigate, history, platformNav);
    }

    return (user.isEmployee && (user.employeeRoles.includes('compliance_integration') || user.employeeRoles.includes('network_quality') || user.employeeRoles.includes('product_management'))) ?
        <Row>
            <Button type="primary" htmlType="button" onClick={createAdvertiser}>Add Advertiser</Button>
        </Row> : null
}