import React from 'react';
import { connect } from 'react-redux';
import R from 'ramda';
import {
  submitProductForm,
  resetProductDetail,
  fetchProducts,
  fetchInferredProhibitedTexts
} from '../../domain/Products/actions';
import { fetchDomains } from '../../domain/Domains/actions';
import { getTrimmedList, addToConfigIfExists } from '../../utils';
import { SimpleError } from '../../components/ErrorMessage';
import Loader from '../../components/Loader';
import HeightDiv from '../../components/HeightDiv';
import PageBody from '../../components/PageBody';
import { selectAllBasicDomainInfo, selectProductFormSubmissionError } from '../../reducer';
import ProductConfigurations from './ProductConfigurations';
import {history, withNavigate, withParams} from '../../router'

const trimList = list => getTrimmedList(list);

const adIdPidToPair = adIdPidCombos => {
  const adIdPidList = trimList(adIdPidCombos);
  return adIdPidList.map(idPair => {
    const idPairArray = idPair.split(':');
    return { adId: idPairArray[0], pid: idPairArray[1] };
  });
};

const adIdPidFromPair = adIdPidCombos =>
  adIdPidCombos.map(({ adId, pid }) => (pid ? `${adId}:${pid}` : `${adId}`)).join('\n');

export class ProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleProductSubmit = this.handleProductSubmit.bind(this);
  }

  componentDidMount() {
    this.props.resetProductDetail();
    this.props.fetchDomains();
    if (this.props.params?.id) {
      this.props.fetchProducts(this.props.params.id);
    }
  }

  handleProductSubmit(values) {
    const productDetail = {
      id: values.id,
      productName: values.productName,
      alternativeProductNames: trimList(values.alternativeProductNames),
      adIdPidCombos: adIdPidToPair(values.adIdPidCombos),
      offerRequiredText: trimList(values.offerRequiredText),
      conditionalOfferRequiredText: trimList(values.conditionalOfferRequiredText),
      pageRequiredText: trimList(values.pageRequiredText),
      conditionalPageRequiredText: trimList(values.conditionalPageRequiredText),
      prohibitedText: trimList(values.prohibitedText),
      includeRatesAndFees: Boolean(values.includeRatesAndFees),
      includeDisclosures: Boolean(values.includeDisclosures),
      includeExclusions: Boolean(values.includeExclusions),
      landingUrlConfigurations: values.landingUrlConfigurations
    };

    const nullableFields = {
      advertiserId: values.advertiserId
    };

    const mergedProductDetail = addToConfigIfExists(nullableFields, productDetail);

    return this.props
      .submitProductForm(mergedProductDetail)
      .then(() => {
        history.push('/products');
        this.props.navigate('/products');
      })
      .catch(() => {});
  }

  makeFormData(product) {
    return (
      product && {
        ...product,
        alternativeProductNames: product.alternativeProductNames.join('\n'),
        offerRequiredText: product.offerRequiredText.join('\n'),
        conditionalOfferRequiredText: R.isNil(product.conditionalOfferRequiredText)
          ? ''
          : product.conditionalOfferRequiredText.join('\n'),
        pageRequiredText: product.pageRequiredText.join('\n'),
        conditionalPageRequiredText: R.isNil(product.conditionalPageRequiredText)
          ? ''
          : product.conditionalPageRequiredText.join('\n'),
        prohibitedText: product.prohibitedText.join('\n'),
        adIdPidCombos: R.isNil(product.adIdPidCombos) ? '' : adIdPidFromPair(product.adIdPidCombos)
      }
    );
  }

  render() {
    const productId = this.props.params?.id
    const {
      products: { productDetail, error, isLoading },
      basicDomainInfo,
      productSubmissionError
    } = this.props;
    const product =
      productId && productDetail.length > 0
        ? this.makeFormData(R.filter(R.propEq('id', productId), productDetail)[0])
        : {};
    const productConfigurations = (
      <ProductConfigurations
        basicDomainInfo={basicDomainInfo}
        product={product}
        handleProductSubmit={this.handleProductSubmit}
        productSubmissionError={productSubmissionError}
      />
    );
    const title =
      productDetail.length === 0 || R.isEmpty(product)
        ? 'Create New Product Configuration'
        : 'Edit Product Configuration';
    const loader = (
      <HeightDiv height="1600">
        <Loader />
      </HeightDiv>
    );

    const pageBody = isLoading ? (
      <PageBody title="Loading" body={loader} />
    ) : error ? (
      <PageBody title={title} body={<SimpleError error={error} />} />
    ) : (
      <PageBody title={title} body={productConfigurations} />
    );

    return pageBody;
  }
}

export const mapStateToProps = state => ({
  products: state.CAS.products.data,
  basicDomainInfo: selectAllBasicDomainInfo(state),
  productSubmissionError: selectProductFormSubmissionError(state)
});

export const mapDispatchToProps = {
  submitProductForm,
  resetProductDetail,
  fetchProducts,
  fetchDomains,
  fetchInferredProhibitedTexts
};

export default withNavigate(withParams(connect(mapStateToProps, mapDispatchToProps)(ProductForm)));
