import React from 'react';
import styled from 'styled-components';
import ExportIssuesButton from './ExportIssuesButton';
import { ToggleIcon as VSRToggleIcon } from '@cjdev-internal/visual-stack-redux/lib/components/SlidingPanel';
import { isEmpty } from 'ramda';
import DropdownFilter from './IssuesSortableDropdown';
import IssueViewController from './issueViewController';
import IssueDatePicker from './IssueDatePicker';
import { useNavigate, useSearchParams } from 'react-router-dom'
import {connect} from "react-redux";
import {withSearchParams} from "../../router";
import { getAdvertisers} from '../Advertisers/AdvertisersDropdown/advertiserStorage';
import {
  selectWebsites
} from "../../reducer";
import { isEnableToCreate } from '../../shared/userStorage';
import { Button } from '@cjdev-internal/visual-stack-x/components/Button';
import { Row } from '@cjdev-internal/visual-stack-x/components/Row';

const StyledVSRToggleIcon = styled(props => <VSRToggleIcon {...props} />)`
  > button {
    margin: 0px;
  }
`;

const IssueHeaderSpan = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > * {
    margin-right: 5px;
  }
`;

const StyledExportButton = styled(props => <ExportIssuesButton {...props} />)`
  margin: 0px 12px 0px 0px;
`;

export const IssueHeader = ({ isEnableToCreate, configureIssueButtonGenerator, websites }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const issueViewController = new IssueViewController(navigate, searchParams);
  return (
    <IssueHeaderSpan>
      <div style={{ display: 'flex' }}>
        {isEnableToCreate && !isEmpty(websites) && configureIssueButtonGenerator()}
      </div>
      <div style={{ display: 'flex' }}>
        <IssueDatePicker onSubmit={issueViewController.changeDateRange} />
        <DropdownFilter />
        <StyledExportButton />
        <StyledVSRToggleIcon hoverText={'Toggle Filters'} />
      </div>
    </IssueHeaderSpan>
  );
};

const NewNavIssueHeaderPure = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const issueViewController = new IssueViewController(navigate, searchParams);
  return (
    <IssueHeaderSpan >
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <IssueDatePicker onSubmit={issueViewController.changeDateRange} />
        <DropdownFilter />
      </div>
    </IssueHeaderSpan>
  );
};

export const NewNavActionButtonsPure = ({ user, websites }) => {
  const displayButton = isEnableToCreate(user) && !isEmpty(websites)
  const disableButton = getAdvertisers().length !== 1;
  return (
  <Row gap="small">
    {displayButton &&
      <Button
        type='primary'
        disabled={disableButton}
        onClick={()=>{
          const event = new CustomEvent('ADD_NEW_VIOLATION');
          document.dispatchEvent(event);
        }}
      >Add New Violation</Button>
    }
    <ExportIssuesButton />
  </Row>)
};

const mapStateToProps = state => ({
  websites: selectWebsites(state)
});

export const NewNavIssueHeader = withSearchParams(connect()(NewNavIssueHeaderPure));
export const NewNavActionButtons = connect(mapStateToProps)(NewNavActionButtonsPure);
