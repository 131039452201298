import {ViewIssueAttachments} from '../../../components/Attachment';
import {checkIfUndefinedOrEmpty} from '../helpers/dataHelpers';

export const IssueDetailAttachment = ({attachments, AttachmentsView}) => {
    return checkIfUndefinedOrEmpty(attachments) ? (<AttachmentsView attachments={attachments}/>) : (
        <div>No Attachments</div>);
};

const IssueDetailAttachmentDefault = ({attachments}) => {
    const AttachmentsView = ({attachments}) => <ViewIssueAttachments name="image-attachments"
                                                                     issueAttachments={attachments}/>;
    return <IssueDetailAttachment attachments={attachments} AttachmentsView={AttachmentsView}/>;
};

export default IssueDetailAttachmentDefault;
