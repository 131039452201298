import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { selectDomainCreateFormTooltipTypes } from '../../reducer';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@cjdev-internal/visual-stack-x/components/Button';
import {
  PaddedLabel,
  TextInput,
  FormFieldDiv,
  MediumTextArea,
  URLTextArea,
  SingleLineInput,
  NameInput
} from '../../components/FormFields';
import { SimpleError } from '../../components/ErrorMessage';
import { Submitter } from '../../components/Loader';

const TooltipRadioGroup = styled.div`
  display: block;
  & > * {
    margin-right: 5px;
  }
`;

const RegexSingleLineInput = ({ input, label, domainName, disableForEdit }) => {
  const domain = domainName === '' ? <div /> : <span>{domainName}/</span>;
  return (
    <FormFieldDiv>
      <PaddedLabel htmlFor={input.name}>{label}</PaddedLabel>
      {domain}
      <TextInput {...input} width={'30%'} disabled={disableForEdit} />
    </FormFieldDiv>
  );
};

export const DomainFormDetail = ({
  submitting,
  handleSubmit,
  submitFailed,
  isDefaultConfig,
  initialValues,
  isSimpleTooltip,
  isJavascriptTooltip
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field label="Name" name="name" component={NameInput} disableForEdit={isDefaultConfig} />
      <Field
        label="Regex"
        name="regex"
        domainName={initialValues.domain}
        component={RegexSingleLineInput}
        disableForEdit={isDefaultConfig}
      />
      <Field label="URLs" name="urls" component={URLTextArea} />
      <Field label="Navigation Script" name="navigationScript" component={SingleLineInput} />
      <Field
        label="Advertiser Disclosure"
        name="advertiserDisclosureText"
        component={SingleLineInput}
      />
      <Field label="Rates and Fees" name="ratesAndFeesText" component={MediumTextArea} />
      <Field label="Offer Selectors" name="offerSelectors" component={MediumTextArea} />
      <Field label="Exclusion Selectors" name="exclusionSelectors" component={MediumTextArea} />
      <PaddedLabel>Tooltip</PaddedLabel>
      <div>
        <TooltipRadioGroup>
          <div>
            <Field type="radio" name="tooltipType" component="input" value="simple" />
            Simple
          </div>
          <div>
            <Field type="radio" name="tooltipType" component="input" value="javascript" />
            Javascript
          </div>
          <div>
            <Field type="radio" name="tooltipType" component="input" value="none" />
            None
          </div>
        </TooltipRadioGroup>
        {isSimpleTooltip && (
          <Field label="Selector" name="tooltipSelector" component={SingleLineInput} />
        )}
        {isJavascriptTooltip && (
          <div>
            <Field label="Selector" name="tooltipSelector" component={SingleLineInput} />
            <Field label="Attribute" name="tooltipAttribute" component={SingleLineInput} />
          </div>
        )}
      </div>
      {submitFailed && (
        <div>
          <SimpleError error={'Failed to submit form'} />
        </div>
      )}
      {submitting && <Submitter />}
      <Button htmlType="submit" type="primary" data-testid="domain-submit" disabled={submitting}>
        Submit
      </Button>
    </form>
  );
};

export default connect(state => ({ ...selectDomainCreateFormTooltipTypes(state) }))(
  reduxForm({
    form: 'create',
    enableReinitialize: true
  })(DomainFormDetail)
);
