// GENERAL
export const BASE = '/';
export const HOME = '/home';
export const LOGIN = '/login';
export const OAUTH_CALLBACK = '/oauth-callback';
export const LOG_OUT = '/log-out';
export const UNAUTHORIZED = '/unauthorized';
export const EVERYTHING_ELSE__MUST_BE_LAST = '/*';
export const SETTINGS = '/settings'; 
export const HELP = '/help'; // NOT WORK

// COMPLIANCE MODULE
export const COMPLIANCE_VIOLATIONS = '/compliance-violations';
export const COMPLIANCE_VIOLATIONS_DETAIL = '/compliance-violations/:complianceViolationId';
export const COMPLIANCE_VIOLATIONS_MANAGE_RECIPIENT = '/compliance-violations/:complianceViolationId/manage-recipients';

// ADVERTISER MODULE
export const ADVERTISERS = '/advertisers';
export const EDIT_ADVERTISER = '/edit-advertiser/:advertiserId';
export const CREATE_ADVERTISER = '/create-advertiser';

// DOMAINS MODULE
export const DOMAIN_PATH = '/domains';
export const DOMAIN_CONFIG_CREATION = `${DOMAIN_PATH}/:domainId/domain-configs/create`;
export const DOMAIN_CONFIG_FROM_ID = `${DOMAIN_PATH}/:domainId/domain-configs/:configId`;
export const DOMAIN_FROM_ID = `${DOMAIN_PATH}/:domainId`;

// TEST MODULE
export const TESTS = '/tests';
export const TEST_RESULT_FROM_ID = '/test-results/:id';
export const TEST_RESULTS = '/test-results';
export const TEST_RESULTS_DETAIL_FROM_ID = '/test-result-detail/:id';
export const TEST_CONFIGS = '/test-configs';
export const TEST_CONFIG_FROM_ID = '/test-config/:id';
export const TEST_CONFIG = '/test-config';

// PRODUCTS MODULE
export const PRODUCT_CREATION = '/products/create';
export const PRODUCT_FROM_ID = '/products/:id';
export const PRODUCTS = '/products';

// URL-GROUPS MODULE
export const URL_GROUPS_CREATION = '/url-groups/create';
export const URL_GROUP_FROM_ID = '/url-groups/:id';
export const URL_GROUPs = '/url-groups';