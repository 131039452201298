import { apiServer } from '../env';
import R from 'ramda';
import {getCurrentCompany, removeUser} from './userStorage';
import { history as browserHistory } from '../router';
import { getBearerToken } from './platformAuth';

export const authenticatedFetch = async (input, init) => {
  const bearerToken = await getBearerToken();
  const currentCompany = getCurrentCompany();
  const company = currentCompany?.id;
  const companyId = {companyId: `${company}`}
  const tokenHeader = { Authorization: `Bearer ${bearerToken}` }
  const headerLens = R.lensPath(['headers']);
  const authenticatedInit = R.set(
    headerLens,
    {
      ...R.view(headerLens, init),
      ...tokenHeader,
      ...companyId
    },
    init
  );
  const response = await fetch(input, authenticatedInit);
  if (response?.status === 401) {
    removeUser();
    browserHistory.push('/login', {
      redirect: window.location.pathname,
    });
  } else if (response?.status === 403) {
      const event = new CustomEvent('unauthorized');
      document.dispatchEvent(event);
      return response;
  }
  else {
      const event = new CustomEvent('authorized');
      document.dispatchEvent(event);
      return response;
  }
};

export const fetchJSON = async (url) => {
  const response = await authenticatedFetch(url);
  return response.json();
};

export const fetchProducts = async () => {
  return fetchJSON(`${apiServer()}/products`);
};

export const fetchTestConfig = async (id) => {
  return fetchJSON(`${apiServer()}/test-configs/${id}`);
};

export const archiveDomainConfig = async (domainId, domainConfigId) => {
  const response = await authenticatedFetch(
    `${apiServer()}/archive-domain-configs/domains/${domainId}/domain-configs/${domainConfigId}`,
    {
      method: 'POST',
    }
  );
  return response.ok
    ? Promise.resolve()
    : Promise.reject({ message: 'Failed to archive domain config' });
};

export const postInferredProhibitedTexts = async (productId, data) => {
  const response =  await authenticatedFetch(
    `${apiServer()}/products/${productId}/inferredProhibitedText`,
    {
      method: 'POST',
      body: JSON.stringify(data)
    }
  )
  return response.json()
}

export async function getInferredProhibitedTexts(productId) {
  return authenticatedFetch(`${apiServer()}/products/${productId}/inferredProhibitedText`)
}

export async function getSystemSettings() {
  const response = await authenticatedFetch(`${apiServer()}/system-settings`);
  const json = await response.json();
  return json;
}

/**
 * 
 * @param {Object} body
 * @param {string} body.name
 * @returns {Promise<{fileId: string, fileKey:string}>}
 */
export const initializeMultipartUpload = async (body) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    };

    const response = await authenticatedFetch(`${apiServer()}/uploads/initializeMultipartUpload`, requestOptions)
    return response.json();
}

/**
 * 
 * @param {Object} body 
 * @param {string} body.fileId
 * @param {string} body.fileKey
 * @param {number} body.parts
 * @returns {Promise<{parts: Array<{PartNumber: number,signedUrl: string}>}>}
 */
export const getMultipartPreSignedUrls = async (body) => {
  const multiPartOptions = {
    method: 'POST',
    body: JSON.stringify(body),
  }

  const response = await authenticatedFetch(`${apiServer()}/uploads/getMultipartPreSignedUrls`,multiPartOptions)

  return response.json();
}

/**
 * 
 * @param {Object} body 
 * @param {string} body.fileId
 * @param {string} body.fileKey
 * @param {Array} body.parts
 * @returns {Promise<{link:string, status:string}>}
 */
export const finalizeMultipartUpload = async (body) => {
  const finalizeMultipartOptions = {
      method: 'POST',
      body: JSON.stringify(body),
  }
  const response = await authenticatedFetch(`${apiServer()}/uploads/finalizeMultipartUpload`, finalizeMultipartOptions);
  return response.json();

}
