import {FlexPage} from '@cjdev-internal/visual-stack-x/FlexPage';
import {ZeroStateIcon} from '../../../components/ZeroState/ViolationZeroState';
import {Panel} from '@cjdev-internal/visual-stack-x/Panel';
import {Box} from '@cjdev-internal/visual-stack-x/components/Box';
import {Text} from '@cjdev-internal/visual-stack-x/Text';

const IssueDetailError = ({text}) => {
    return (
        <FlexPage heightAdjust="32px">
            <Panel style={{flexGrow: 1}}>
                <Box style={{height: '100%'}} align="center" justify="center">
                    <ZeroStateIcon/>
                    <Text color="primary" size={24}>{text}</Text>
                </Box>
            </Panel>
        </FlexPage>
    );
};

export default IssueDetailError;
