import { combineReducers } from 'redux';
import {
  SET_RISK_IDENTIFICATION,
  CLEAR_RISK_IDENTIFICATION,
  SET_RISK_IDENTIFICATION_DATE_RANGE,
  START_FETCHING_RISK_IDENTIFICATION
} from './actions';
import * as R from 'ramda';
import { DESCENDING } from '@cjdev-internal/visual-stack-redux/lib/components/DataTable';
import { renderTrend } from './trend';
import { getDimension } from '../../../containers/Dashboard/RiskIdentificationTable/routeHelper';
import { history as browserHistory } from '../../../router';
import * as Constant from './constant';
import { formatSecondsToDate, formatSecondsToDateMM_DD_YYYY } from '../../../utils';

const nameCentricDimensions = [Constant.PRODUCT, Constant.ERROR_CATEGORY];

const isNameCentricDimension = dimension => R.contains(dimension)(nameCentricDimensions);

const renderTrendIcons = {
  renderCell: renderTrend
};

const dataReducer = (state = [], { type, payload }) => {
  switch (type) {
    case SET_RISK_IDENTIFICATION:
      return payload;
    case CLEAR_RISK_IDENTIFICATION:
      return [];
    default:
      return state;
  }
};

const dateRangeReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_RISK_IDENTIFICATION_DATE_RANGE:
      return payload;
    default:
      return state;
  }
};

const isLoadingReducer = (state = false, { type }) => {
  switch (type) {
    case START_FETCHING_RISK_IDENTIFICATION:
      return true;
    case SET_RISK_IDENTIFICATION:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  isLoading: isLoadingReducer,
  data: dataReducer,
  dateRange: dateRangeReducer
});

const getDataIfNotPublisher = (isPublisher, data) => {
  return isPublisher ? [] : data;
};

const getIDColumn = dimension => {
  if (isNameCentricDimension(dimension)) return [];
  return [{ label: `${dimension} ID`, width: '9%', clickable: true }];
};
const getNameColumn = dimension => {
  const nameColumn = { label: `${dimension} Name`, width: '9%' };
  if (isNameCentricDimension(dimension)) return [{ ...nameColumn, clickable: true }];
  return [nameColumn];
};

export const selectRiskIdentificationTableHeaders = (
  severityHeaders,
  isPublisher = false,
  history = browserHistory
) => {
  const { highHeader, mediumHeader, lowHeader } = severityHeaders;
  const dimension = getDimension(history.location.search);
  return [
    ...getIDColumn(dimension),
    ...getNameColumn(dimension),
    ...getDataIfNotPublisher(isPublisher, [
      { label: highHeader, width: '9%' },
      { label: mediumHeader, width: '13%' },
      { label: lowHeader, width: '9%' }
    ]),
    { label: 'Average Resolution Time (hrs)', width: '15%' },
    { label: 'Total Violations', width: '9%' },
    { label: 'Total Violations Change', width: '9%', ...renderTrendIcons },
    ...getDataIfNotPublisher(isPublisher, [
      { label: 'Total Points', width: '9%' },
      { label: 'Total Points Change', width: '15%', ...renderTrendIcons }
    ])
  ];
};

export const selectSortingOption = isPublisher => {
  return {
    label: isPublisher ? 'Total Violations' : 'Total Points',
    order: DESCENDING
  };
};
const getIDData = (dimension, item) => {
  if (isNameCentricDimension(dimension)) return [];
  return [item.id];
};

export const selectRiskIdentificationTableData = (
  { data },
  isPublisher,
  history = browserHistory
) => {
  const dimension = getDimension(history.location.search);
  return R.map(item => [
    ...getIDData(dimension, item),
    item.name,
    ...getDataIfNotPublisher(isPublisher, [
      item.highSeverity,
      item.mediumSeverity,
      item.lowSeverity
    ]),
    item.averageResolutionTime,
    item.totalViolations,
    item.totalViolations - item.previousTotalViolations,
    ...getDataIfNotPublisher(isPublisher, [
      item.errorPoints,
      item.errorPoints - item.previousTotalErrorPoints
    ])
  ])(data);
};

export const selectShouldRenderRiskIdentificationTable = ({ data, isLoading }) => {
  return !R.isEmpty(data) && !isLoading;
};

export const selectRiskIdentificationCaption = (state, history = browserHistory) => {
  const dimension = getDimension(history.location.search);
  return `Top Violations by ${dimension}`;
};

export const selectRiskIdentificationDateRange = state => {
  const {
    dateRange: { from, to }
  } = state;
  return `${formatSecondsToDate(parseInt(from, 10))} - ${formatSecondsToDate(parseInt(to, 10))}`;
};

export const selectTrendDateRange = state => {
  const {
    dateRange: { from, to }
  } = state;
  if (!from || !to) return '';
  return `${formatSecondsToDateMM_DD_YYYY(parseInt(from, 10))} - ${formatSecondsToDateMM_DD_YYYY(
    parseInt(to, 10)
  )}`;
};

const selectRiskIdentificationChartForPublisher = data => {
  const riskIdentificationChartData = R.reject(
    riskIdentification => riskIdentification.total === 0
  )(
    R.map(riskIdentification => ({
      value: riskIdentification.name,
      total: riskIdentification.totalViolations
    }))(data)
  );
  return R.take(10)(riskIdentificationChartData);
};

const selectRiskIdentificationChartForAdvertiser = data => {
  const riskIdentificationChartData = R.reject(
    riskIdentification =>
      riskIdentification.low + riskIdentification.medium + riskIdentification.high === 0
  )(
    R.map(riskIdentification => ({
      value: riskIdentification.name,
      low: riskIdentification.lowSeverity,
      medium: riskIdentification.mediumSeverity,
      high: riskIdentification.highSeverity
    }))(data)
  );
  return R.take(10)(riskIdentificationChartData);
};

export const selectRiskIdentificationChart = ({ data }, { isPublisher }) => {
  if (isPublisher) {
    return selectRiskIdentificationChartForPublisher(data);
  }
  return selectRiskIdentificationChartForAdvertiser(data);
};

export const selectRiskIdentificationIsLoading = state => {
  return state.isLoading;
};
