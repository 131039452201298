import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../shared/apis';
import { apiServer } from '../../env';
import * as R from 'ramda';
import { COMPLIANCE_VIOLATIONS, BASE } from '../../paths';
import { saveComplianceToolAdvertiser } from '../../shared/advertiserStorage';
import { history } from '../../router';
import { fetchDashboardOverviews } from '../ViolationDashboard/actions';

import { switchAdvertiserAction } from '../Issues/actions';

export const CLEAR_SELECTED_ADVERTISER_DATA = 'Domains/advertiser/CLEAR_SELECTED_ADVERTISER_DATA';

export const clearSelectedAdvertiserAction = createAction(CLEAR_SELECTED_ADVERTISER_DATA);

export const STORE_ADVERTISER_DATA = 'Domains/advertiser/STORE_ADVERTISER_DATA';

export const storeAdvertiserDataAction = createAction(STORE_ADVERTISER_DATA);

export const FAIL_FETCH_ADVERTISER_DATA = 'Domains/advertiser/FAIL_FETCH_ADVERTISER_DATA';

export const failFetchAdvertiserDataAction = createAction(FAIL_FETCH_ADVERTISER_DATA);

export const LOADING_FETCH_ADVERTISER_DATA = 'Domains/advertiser/LOADING_FETCH_ADVERTISER_DATA';

export const loadingFetchAdvertiserData = createAction(LOADING_FETCH_ADVERTISER_DATA);

export const STORE_SELECTED_ADVERTISER_ID = 'Domains/advertiser/STORE_SELECTED_ADVERTISER_ID';

export const storeSelectedAdvertiserIdAction = createAction(STORE_SELECTED_ADVERTISER_ID);

export const STORE_SELECTED_ADVERTISERS = 'Domains/advertiser/STORE_SELECTED_ADVERTISERS';

export const storeSelectedAdvertisersAction = createAction(STORE_SELECTED_ADVERTISERS);

export const fetchAdvertisers = async () => {
  const response = await authenticatedFetch(`${apiServer()}/advertisers`);
  return response.json();
};

export const fetchAdvertiserAction = () => async dispatch => {
  dispatch(loadingFetchAdvertiserData());
  try {
    const responseJSON = await fetchAdvertisers();
    dispatch(storeAdvertiserDataAction(responseJSON));
  } catch (e) {
    dispatch(failFetchAdvertiserDataAction());
  }
};

const fetchIssueActionAction = (advertiserId, search) => dispatch => {
  const currentUrl = window.location.href;
  if (R.contains(COMPLIANCE_VIOLATIONS)(currentUrl)) {
    dispatch(switchAdvertiserAction(advertiserId));
  }
  if (window.location.pathname === BASE) {
    dispatch(fetchDashboardOverviews(search));
  }
};

const clearFilters = () => {
  const {
    location: { pathname }
  } = history;
  if (R.contains(COMPLIANCE_VIOLATIONS)(pathname)) {
    history.push(COMPLIANCE_VIOLATIONS);
  }
};

export const storeComplianceToolAdvertiser = (advertiserId, search) => dispatch => {
  clearFilters();
  saveComplianceToolAdvertiser({ id: advertiserId });
  dispatch(storeSelectedAdvertiserIdAction(advertiserId));
  dispatch(fetchIssueActionAction(advertiserId, search));
};

export const STORE_ADVERTISER_MANAGEMENTS = 'Domains/advertiser/STORE_ADVERTISER_MANAGEMENTS';

export const storeAdvertiserManagements = createAction(STORE_ADVERTISER_MANAGEMENTS);

// TODO: rebase for remove
export const fetchAdvertiserManagements = () => async dispatch => {
  const response = await authenticatedFetch(`${apiServer()}/advertiser-managements`);
  const responseJSON = await response.json();
  dispatch(storeAdvertiserManagements(responseJSON));
};
export const STORE_CURRENT_ADVERTISER_MANAGEMENT =
  'Domains/advertiser/STORE_CURRENT_ADVERTISER_MANAGEMENT';

export const storeCurrentAdvertiserManagement = createAction(STORE_CURRENT_ADVERTISER_MANAGEMENT);

// TODO: rebase for remove
export const fetchAdvertiserManagement = () => async dispatch => {
  const {
    location: { pathname }
  } = history;
  const advertiser = pathname.split('/')[2];
  const response = await authenticatedFetch(`${apiServer()}/advertiser-managements/${advertiser}`);
  const responseJSON = await response.json();
  dispatch(storeCurrentAdvertiserManagement(responseJSON));
};
