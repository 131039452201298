import {Row} from '@cjdev-internal/visual-stack-x/components/Row';
import {history, withParams} from '../../../router';
import {Button} from '@cjdev-internal/visual-stack-x/components/Button';
import {Modal as VSXModal, useModal} from '@cjdev-internal/visual-stack-x/components/Modal';
import {ListView} from '@cjdev-internal/visual-stack-x/components/ListView';
import {IconButtonWithDropdown} from '@cjdev-internal/visual-stack-x/components/ButtonWithDropdown';
import {ConfigureIssueModal} from '../../Issues/ConfigureIssue';
import {useCallback, useEffect, useRef, useState} from 'react';
import {onArchiveIssue, onDuplicateIssue, submitAndUpdate, toIssueDetail, toIssueList,} from '../helpers/dataHelpers';
import useEventListener from '../../../shared/hooks/useEventListener';
import {useNavigate} from 'react-router-dom';
import {usePlatformNav} from '../../../appRunner';
import {MDIcon} from '@cjdev-internal/visual-stack-x/MDIcon';

export const IssueDetailActions = ({
                                       user,
                                       useRef,
                                       useState,
                                       useCallback,
                                       useNavigate,
                                       usePlatformNav,
                                       useEventListener,
                                       onSubmit,
                                       onDuplicateIssue,
                                       onArchiveIssue,
                                       complianceViolationId,
                                       Modal,
                                       toIssueDetail,
                                       toIssueList,
                                       useModal,
                                       VSXModal,
                                       history,
                                   }) => {
    const navigate = useNavigate();
    const [mount, vsxOpenModal, vsxCloseModal] = useModal();
    const prefix = complianceViolationId.split('-')[0];
    const platformNav = usePlatformNav();
    const oldStatus = useRef();
    const publisherContact = useRef([]);
    let [issue, setIssue] = useState(null);
    const [iconExpanded, setIconExpanded] = useState(false);
    const isArchiving = useRef(false);
    
    const onIssueLoaded = ({detail}) => setIssue(detail);
    useEventListener('ISSUE_LOADED', onIssueLoaded);

    const submitAndNotify = async (issue) => {
        const response = await onSubmit(issue);

        if (response && (oldStatus.current === 'New' && issue.status === 'In Progress')) {
            publisherContact.current = issue.publisherContacts;
            const details = {
                publisherContact: publisherContact.current,
                networkQualityContact: issue.networkQualityContacts,
                advertiserContact: issue.advertiserContacts,
                additionalNotes: issue.additionalNotes
            }
            const event = new CustomEvent('OPEN_CONTACT_NOTIFICATION', {
                detail: details,
            });
            document.dispatchEvent(event);
        }

        if (response && (oldStatus.current === 'In Progress' && issue.status === 'Resolved')) {
            const details = {
                publisherContact: issue.publisherContacts,
                networkQualityContact: issue.networkQualityContacts,
                advertiserContact: issue.advertiserContacts,
                additionalNotes: issue.additionalNotes
            }
            const event = new CustomEvent('OPEN_CONTACT_NOTIFICATION', {
                detail: details,
            });
            document.dispatchEvent(event);
        }
    }

    const onEdit = useCallback(() => {
        const props = {
            closeModal: vsxCloseModal,
            user,
            submitPendingIssue: submitAndNotify,
            existingValues: {...issue, title: complianceViolationId},
        };
        vsxOpenModal(<Modal {...props}></Modal>);
    }, [issue, complianceViolationId]);

    const showNewIssue = (id) => {
        const complianceViolationId = `${prefix}-${id}`;
        toIssueDetail(complianceViolationId, navigate, history, platformNav)
    }

    const handlerDuplicate = async (newIssue) => {
        const response = await onDuplicateIssue(newIssue);
        showNewIssue(response);
    }

    const onCopy = useCallback(() => {
        const props = {
            closeModal: vsxCloseModal,
            user,
            submitPendingIssue: handlerDuplicate,
            existingValues: {
                ...issue,
                issueReporter: user,
                id: undefined,
                status: 'New',
                publisherContacts: '',
                advertiserContacts: '',
                networkQualityContacts: '',
                additionalNotes: '',
                detectionTime: '',
                resolutionTime: ''
            }
        };
        vsxOpenModal(<Modal {...props}></Modal>);
    }, [issue]);

    const handlerArchive = async () => {
        if (isArchiving.current) {
            return;
        }
        isArchiving.current = true;
        await onArchiveIssue(issue.id);
        isArchiving.current = false;
        vsxCloseModal();
        toIssueList(navigate, history, platformNav);
    };

    const modalArchiveProps = {
        onBackgroundClick: vsxCloseModal,
        onEscapeKeyUp: vsxCloseModal,
        onCloseIconClick: vsxCloseModal,
        headerTitle: `Archiving ${complianceViolationId}`,
        body: `Do you want to archive ${complianceViolationId}?`,
        footer: (
            <Row gap='medium' justify='end'>
                <Button type="tertiary" onClick={vsxCloseModal}>
                    Cancel
                </Button>
                <Button type="primary" onClick={handlerArchive}>
                    <MDIcon inline icon="archive"/> Archive
                </Button>
            </Row>
        ),
    }

    const onArchive = () => {
        vsxOpenModal(<VSXModal {...modalArchiveProps} />);
    };

    const list = <ListView
        data={[
            {id: 'copy', text: 'Duplicate', onClick: onCopy, testId: 'issue-detail-action-copy'},
            {id: 'archive', text: 'Archive', onClick: onArchive, testId: 'issue-detail-action-archive'}
        ]}
        indexer={(item) => item.id}
        onClick={(item) => {
            item.onClick();
            setIconExpanded(false)
        }}
        renderContent={(item) => <span data-testid={item.testId}>{item.text}</span>}
    />

    useEffect(() => {
        if (!issue) {
            return;
        }
        oldStatus.current = issue.status
    }, [issue]);

    return (
        <Row gap='medium'>
            {mount}
            <Button type="primary" onClick={onEdit} data-testid="issue-detail-action-edit">
                Edit
            </Button>
            <IconButtonWithDropdown
                data-testid="issue-detail-action-dropdown"
                icon="dots-vertical"
                expanded={iconExpanded}
                doExpand={setIconExpanded}
                placement="bottom-end"
                closeOnClickOutside
            >
                {list}
            </IconButtonWithDropdown>
        </Row>
    );
};

const IssueDetailActionsDefault = ({params, user}) => {
    const {complianceViolationId} = params;
    const onSubmit = submitAndUpdate;

    return (
        <IssueDetailActions
            VSXModal={VSXModal}
            Modal={ConfigureIssueModal}
            useModal={useModal}
            useRef={useRef}
            user={user}
            complianceViolationId={complianceViolationId}
            useCallback={useCallback}
            useState={useState}
            useEventListener={useEventListener}
            onSubmit={onSubmit}
            onDuplicateIssue={onDuplicateIssue}
            onArchiveIssue={onArchiveIssue}
            toIssueDetail={toIssueDetail}
            toIssueList={toIssueList}
            useNavigate={useNavigate}
            usePlatformNav={usePlatformNav}
            history={history}
        />

    );
};

export default withParams(IssueDetailActionsDefault);