import { combineReducers } from 'redux';
import { reducer as vs } from '@cjdev-internal/visual-stack-redux';
import { reducer as form, formValueSelector } from 'redux-form';
import { reducer as tests } from './domain/Tests/reducer';
import { reducer as testResults } from './domain/TestResults/reducer';
import { reducer as testResultDetail } from './domain/TestResultDetails/reducer';
import { reducer as testConfig } from './domain/TestConfigs/reducer';
import { history } from './router'
import * as fromDomain from './domain/Domains/reducer';
import * as fromTestConfigDetails from './domain/TestConfigDetails/reducer';
import * as fromProducts from './domain/Products/reducer';
import * as fromTestConfig from './domain/TestConfigs/reducer';
import * as fromTests from './domain/Tests/reducer';
import * as fromUrlGroups from './domain/UrlGroups/reducer';
import * as fromUser from './domain/Users/reducer';
import * as fromAdvertisers from './domain/Advertisers/reducer';
import * as fromWebsites from './domain/Websites/reducer';
import * as fromViolationProducts from './domain/ViolationProducts/reducer';
import * as fromIssues from './domain/Issues/reducer';
import * as fromViolationTypeMappings from './domain/ViolationTypeMappings/reducer';
import * as fromViolationDashboard from './domain/ViolationDashboard/reducer';
import * as fromPublishers from './domain/Publishers/reducer';
import R from 'ramda';
import { createRouterReducer } from '@lagunovsky/redux-react-router'

const cas = combineReducers({
  tests: tests,
  testResults: testResults,
  testResultDetail: testResultDetail,
  testConfig: testConfig,
  testConfigDetail: fromTestConfigDetails.reducer,
  products: fromProducts.reducer,
  domains: fromDomain.reducer,
  urlGroups: fromUrlGroups.reducer,
  user: fromUser.reducer,
  advertisers: fromAdvertisers.reducer,
  websites: fromWebsites.reducer,
  violationProducts: fromViolationProducts.reducer,
  issues: fromIssues.reducer,
  violationTypeMappings: fromViolationTypeMappings.reducer,
  publishers: fromPublishers.reducer,
  violationDashboard: fromViolationDashboard.reducer
});

const reducer = combineReducers({
  visualStack: vs,
  form: form,
  CAS: cas,
  router: createRouterReducer(history)
});

export default reducer;

//products
export const selectProductFormSubmissionError = state =>
  fromProducts.selectProductFormSubmissionError(state.CAS.products);

export const selectDefaultProductId = state =>
  fromProducts.selectDefaultProductId(state.CAS.products);

export const selectProductDetail = state => fromProducts.selectProductDetail(state.CAS.products);

export const selectInferredProhibitedTexts = state =>
  fromProducts.selectInferredProhibitedTexts(state.CAS.products);

export const selectInferredProhibitedTextsIsLoading = state =>
  fromProducts.selectInferredProhibitedTextsIsLoading(state.CAS.products);

export const selectProductsForAdvertiser = state =>
  fromProducts.selectProductsForAdvertiser(state.CAS.products);

//testConfig
export const selectTestConfigForAdvertiser = state => {
  const productForAdvertiser = selectProductsForAdvertiser(state);
  const testConfig = fromTestConfig.selectTestConfig(state.CAS.testConfig);
  const productForAdvertiserIds = productForAdvertiser.productDetail.map(R.prop('id'));
  const data = testConfig.data.filter(({ productConfigId }) =>
    R.contains(productConfigId, productForAdvertiserIds)
  );
  return R.assoc('data', data, testConfig);
};
//urlGroups
export const selectUrlGroups = state => {
  return fromUrlGroups.selectUrlGroups(state.CAS);
};

//tests
export const selectTestsForAdvertiser = state =>
  fromTests.selectTestsForAdvertiser(state.CAS.tests);
//testConfigDetail
export const selectTestConfigFormData = state => {
  const defaultProductId = selectDefaultProductId(state);
  return fromTestConfigDetails.selectTestConfigFormData(state.CAS.testConfigDetail)(
    defaultProductId
  );
};

export const selectTestConfigDetailFormTitle = state =>
  fromTestConfigDetails.selectTestConfigDetailFormTitle(state.CAS.testConfigDetail);

export const selectTestConfigDetailFormInfo = state =>
  fromTestConfigDetails.selectRequestInfo(state.CAS.testConfigDetail);

//domain
const selector = formValueSelector('create');
export const selectDomainCreateFormTooltipTypes = state => ({
  isSimpleTooltip: selector(state, 'tooltipType') === 'simple',
  isJavascriptTooltip: selector(state, 'tooltipType') === 'javascript'
});
export const selectDomain = state => fromDomain.selectDomain(state.CAS.domains);
export const selectAllBasicDomainInfo = state =>
  fromDomain.selectAllBasicDomainInfo(state.CAS.domains);

//user
export const selectIsInternalUser = state => fromUser.selectIsInternalUser(state.CAS.user);
export const selectIsPublisher = state => fromUser.selectIsPublisher(state.CAS.user);
export const selectHasUser = state => fromUser.selectHasUser(state.CAS.user);
export const selectIsUserLoading = state => fromUser.selectIsUserLoading(state.CAS.user);
export const selectStatusOptions = state => fromUser.selectStatusOptions(state.CAS.user);

//advertisers
export const selectAdvertisers = state => fromAdvertisers.selectAdvertisers(state.CAS.advertisers);
export const selectAdvertiserNameMap = state =>
  fromAdvertisers.selectAdvertiserNameMap(state.CAS.advertisers);
export const selectIsLoadingAdvertiser = state =>
  fromAdvertisers.selectIsLoadingAdvertiser(state.CAS.advertisers);
export const selectAdvertiserPrefix = state =>
  fromAdvertisers.selectAdvertiserPrefix(state.CAS.advertisers);
export const selectAdvertiserManagements = state =>
  fromAdvertisers.selectAdvertiserManagements(state.CAS.advertisers);
export const selectCurrentAdvertiserManagement = state =>
  fromAdvertisers.selectCurrentAdvertiserManagement(state.CAS.advertisers);
export const selectIsPStyleSeverityAdvertiser = state =>
  fromAdvertisers.selectIsPStyleSeverityAdvertiser(state.CAS.advertisers);
export const selectSelectedAdvertiserInformation = state =>
  fromAdvertisers.selectSelectedAdvertiserInformation(state.CAS.advertisers);
export const selectAdvertiserPrefixesMap = state =>
  fromAdvertisers.selectAdvertiserPrefixesMap(state.CAS.advertisers);
export const selectSeverityOptions = state =>
  fromAdvertisers.selectSeverityOptions(state.CAS.advertisers);
export const selectSelectedAdvertiserLabel = state =>
  fromAdvertisers.selectSelectedAdvertiserLabel(state.CAS.advertisers);
export const selectHighSeverityValues = state =>
  fromAdvertisers.selectHighSeverityValues(state.CAS.advertisers);

//websites
export const selectWebsites = state => fromWebsites.selectWebsites(state.CAS.websites);
export const selectWebsiteMap = state => fromWebsites.selectWebsiteMap(state.CAS.websites);
export const selectWebsiteDropdownOptions = state =>
  fromWebsites.selectWebsiteDropdownOptions(state.CAS.websites);
export const selectWebsiteDropdownOptionsWithDomainName = state =>
  fromWebsites.selectWebsiteDropdownOptionsWithDomainName(state.CAS.websites);

//violationProducts


export const selectViolationProductNameMap = state =>
  fromViolationProducts.selectViolationProductNameMap(state.CAS.violationProducts);
export const selectViolationProductDropdownOptions = state =>
  fromViolationProducts.selectViolationProductDropdownOptions(state.CAS.violationProducts);
//issues
export const selectIssues = state => fromIssues.selectIssues(state.CAS.issues);
export const selectIsLoadingIssuesPage = state =>
  fromIssues.selectIsLoadingIssuesPage(state.CAS.issues);
export const selectNumIssues = state => fromIssues.selectNumIssues(state.CAS.issues);

//violationTypeMappings


export const selectViolationTypes = state =>
  fromViolationTypeMappings.selectViolationTypes(state.CAS.violationTypeMappings);

export const selectErrorCategoriesDropdownOptions = state =>
  fromViolationTypeMappings.selectErrorCategoriesDropdownOptions(state.CAS.violationTypeMappings);

//sideNav
export const selectIsSideNavCollapsed = state => state.visualStack.sideNav.collapsed;

//slidingPanel
export const selectIsSlidingPanelExpanded = state => state.visualStack.slidingPanel.active;

//violation dashboard
export const selectViolationDashboardLabels = state =>
  fromAdvertisers.selectViolationDashboardLabels(state.CAS.advertisers);
export const selectViolationsDashboardOverview = state =>
  fromViolationDashboard.selectViolationsDashboardOverview(state.CAS.violationDashboard);
export const selectRiskIdentificationTableHeaders = state => {
  const isPublisher = selectIsPublisher(state);
  const severityHeaders = fromAdvertisers.selectRiskIdentificationTableSeverityHeaders(
    state.CAS.advertisers
  );
  return fromViolationDashboard.selectRiskIdentificationTableHeaders(severityHeaders, isPublisher);
};
export const selectRiskIdentificationDateRange = state =>
  fromViolationDashboard.selectRiskIdentificationDateRange(state.CAS.violationDashboard);
export const selectTrendDateRange = state =>
  fromViolationDashboard.selectTrendDateRange(state.CAS.violationDashboard);
export const selectRiskIdentificationCaption = state =>
  fromViolationDashboard.selectRiskIdentificationCaption(state.CAS.violationDashboard);
export const selectSortingOption = state => {
  const isPublisher = selectIsPublisher(state);
  return fromViolationDashboard.selectSortingOption(isPublisher);
};

export const selectRiskIdentificationTableData = state => {
  const isPublisher = selectIsPublisher(state);
  const isPStyleSeverityAdvertiser = selectIsPStyleSeverityAdvertiser(state);
  return fromViolationDashboard.selectRiskIdentificationTableData(
    state.CAS.violationDashboard,
    isPublisher,
    isPStyleSeverityAdvertiser
  );
};

export const selectRiskIdentificationChart = state => {
  const payload = { isPublisher: selectIsPublisher(state) };
  return fromViolationDashboard.selectRiskIdentificationChart(
    state.CAS.violationDashboard,
    payload
  );
};

export const selectShouldRenderRiskIdentificationTable = state =>
  fromViolationDashboard.selectShouldRenderRiskIdentificationTable(state.CAS.violationDashboard);

export const selectRiskIdentificationIsLoading = state =>
  fromViolationDashboard.selectRiskIdentificationIsLoading(state.CAS.violationDashboard);

//trend chart
export const selectViolationTrendsDataForChart = state =>
  fromViolationDashboard.selectViolationTrendsDataForChart(state.CAS.violationDashboard);
export const selectViolationTrendsIsLoading = state =>
  fromViolationDashboard.selectViolationTrendsIsLoading(state.CAS.violationDashboard);
export const selectTrendCaption = state => {
  const trendType = fromViolationDashboard.selectTrendType(state.CAS.violationDashboard);
  const trendDate = fromViolationDashboard.selectTrendDateRange(state.CAS.violationDashboard);
  if (!trendType || !trendDate) return 'Violation Breakout Chart';
  return `${trendType} Breakout ${trendDate}`;
};

export const selectShouldRenderViolationTrendsChart = state =>
  fromViolationDashboard.selectShouldRenderViolationTrendsChart(state.CAS.violationDashboard);

//publishers
export const selectPublisherDropdownOptions = state =>
  fromPublishers.selectPublisherDropdownOptions(state.CAS.publishers);
