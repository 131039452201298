import { createAction } from 'redux-actions';
import { authenticatedFetch } from '../../../shared/apis';
import { apiServer } from '../../../env';

export const SET_DASHBOARD_OVERVIEWS_DATA =
  'src/domain/ViolationDashboard/SET_DASHBOARD_OVERVIEWS_DATA';

export const setDashboardOverviewsDataAction = createAction(SET_DASHBOARD_OVERVIEWS_DATA);

export const START_FETCHING_DASHBOARD_OVERVIEWS =
  'src/domain/ViolationDashboard/START_FETCHING_DASHBOARD_OVERVIEWS';

export const startFetchingDashboardOverviewsAction = createAction(
  START_FETCHING_DASHBOARD_OVERVIEWS
);

const getDashboardOverviewData = async filterQueries => {
  const response = await authenticatedFetch(`${apiServer()}/violation-overviews?${filterQueries}`);
  return await response.json();
};

export const fetchDashboardOverviewAction = filterQueries => async dispatch => {
  dispatch(startFetchingDashboardOverviewsAction());
  const dashboardOverview = await getDashboardOverviewData(filterQueries);
  dispatch(setDashboardOverviewsDataAction(dashboardOverview));
};
