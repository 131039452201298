import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  fill: #999;
  transition: 0.1s all ease-in-out;
  &:hover {
    fill: #49c5b1;
  }
`;

const NotesIcon = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M16,9H21.5L16,3.5V9M7,2H17L23,8V18A2,2 0 0,1 21,20H7C5.89,20 5,19.1 5,18V4A2,2 0 0,1 7,2M3,6V22H21V24H3A2,2 0 0,1 1,22V6H3Z" />
  </StyledSvg>
);

const EmptyNotesIcon = () => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M16,9H21.5L16,3.5V9M7,2H17L23,8V18A2,2 0 0,1 21,20H7C5.89,20 5,19.1 5,18V4A2,2 0 0,1 7,2M3,6V22H21V24H3A2,2 0 0,1 1,22V6H3Z" />
  </StyledSvg>
);
export { NotesIcon, EmptyNotesIcon };
