import styled from 'styled-components';

export const PanelHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.justify ? props.justify : 'space-between')};
  & > * {
    padding-right: 10px;
  }
`;

export const PanelHeaderRowSection = styled.div`
  display: flex;
  align-items: center;
  & > div:not(:first-child) {
    padding-left: 15px;
  }
`;
