import {LabelValue, LabelValueGrid} from '@cjdev-internal/visual-stack-x/LabelValueGrid';
import {Body, Panel} from '@cjdev-internal/visual-stack-x/Panel';
import {formatDate} from '../helpers/dateHelpers';
import {getFullName, getOverrideOrValue, getValue} from '../helpers/dataHelpers';
import IssueDetailProducts from './IssueDetailProducts';
import IssueDetailAttachment from './IssueDetailAttachment';

export const IssueDetailView = ({
                                    issue,
                                    isPublisher,
                                    isEmployee,
                                    title,
                                    AttachmentsView,
                                    ProductsView,
                                    ManageButton,
                                    getFullName
                                }) => {
    const {
        products,
        website,
        attachments,
        issueReporter,
        creationTime,
        advertiserId,
        publisher,
        contentType,
        violationTypeMapping,
        severityOverride,
        errorPointsOverride,
        source,
        auditType,
        cpaLinkFound,
        errorNotes,
        errorLocation,
        status
    } = issue;

    const resolutionTime = formatDate(issue.resolutionTime);
    const linkCutTime = formatDate(issue.linkCutTime);
    const errorStartTime = formatDate(issue.errorStartTime);
    const detectionTime = formatDate(issue.detectionTime);

    const preStyle = {
        margin: 0,
        padding: 0,
        fontFamily: 'inherit',
        fontSize: 'inherit',
        border: 'none',
        backgroundColor: 'transparent'
    };

    return (<Panel>
        <Body>
            <LabelValueGrid title={title} actions={<ManageButton/>}>
                {isEmployee && <LabelValue label="Record Created" value={getValue(formatDate(creationTime))}/>}
                {!isPublisher && <LabelValue label="Record Creator" value={getFullName(issueReporter)}/>}
                <LabelValue label="Location" value={<pre style={preStyle}>{errorLocation || ''}</pre>}/>
                <LabelValue label="Advertiser CID" value={advertiserId}/>
                <LabelValue label="Property ID" value={`${website.pid} - ${website.domainName}`}/>
                <LabelValue label="Publisher CID" value={publisher.cid}/>
                <LabelValue label="Property Name" value={website.name}/>
                <LabelValue label="Publisher Name" value={publisher.name}/>
                <LabelValue label="Product(s)" value={<ProductsView products={products || []}/>}/>
                <LabelValue label="Content Type" value={contentType || ''}/>
                <LabelValue label="Category" value={violationTypeMapping.category || ''}/>
                <LabelValue label="Description" value={violationTypeMapping.description || ''}/>
                <LabelValue label="Subcategory" value={violationTypeMapping.feature || ''}/>
                {!isPublisher && (<LabelValue
                    label="Severity"
                    value={getOverrideOrValue(severityOverride, violationTypeMapping.severity)}
                />)}
                {!isPublisher && (<LabelValue
                    label="Points"
                    value={getOverrideOrValue(errorPointsOverride, violationTypeMapping.errorPoints)}
                />)}
                <LabelValue label="Status" value={status}/>
                {isEmployee && <LabelValue label="Source" value={source}/>}
                <LabelValue label="Audit Type" value={auditType}/>
                <LabelValue label="CPA Link?" value={cpaLinkFound}/>
                <LabelValue label="Notes" value={<pre style={preStyle}>{errorNotes || 'No Value'}</pre>}/>
                {isEmployee && <LabelValue label="Start Time" value={getValue(errorStartTime)}/>}
                <LabelValue label="Detection Time" value={getValue(detectionTime)}/>
                <LabelValue label="Link Block Time" value={getValue(linkCutTime)}/>
                <LabelValue label="Resolution Time" value={getValue(resolutionTime)}/>

                <LabelValue label="Attachments" value={<AttachmentsView attachments={attachments}/>}/>
            </LabelValueGrid>
        </Body>
    </Panel>);
};

const IssueDetailViewDefault = ({issue, isPublisher, isEmployee, title, ManageButton}) => {

    const AttachmentsView = ({attachments}) => <IssueDetailAttachment attachments={attachments}/>;
    const ProductsView = ({products}) => <IssueDetailProducts products={products}/>;

    return (<IssueDetailView
        getFullName={getFullName}
        issue={issue}
        isPublisher={isPublisher}
        isEmployee={isEmployee}
        title={title}
        AttachmentsView={AttachmentsView}
        ProductsView={ProductsView}
        ManageButton={ManageButton}
    />);
};

export default IssueDetailViewDefault;
