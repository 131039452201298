import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
`;

export const FixedLayoutTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;

const StyledThead = styled.thead`
  border-bottom: 1px #ccc solid;
`;

const StyledTr = styled.tr`
  &:hover {
    background: ${props => (props.disableHover ? '' : '#f9f9f9')};
  }
  ${StyledThead} &:hover {
    background: inherit;
  }
  border-bottom: ${props => (props.disableBorder ? '' : '1px dashed #e0e0e0')};
  background: ${props => (props.background ? '#fff' : 'inherit')};
`;

const StyledTd = styled.td`
  &:first-of-type {
    padding-left: 5px;
  }
  padding: ${props => (props.padding ? props.padding : '1em 2em 1em 0')};
  vertical-align: top;
  text-align: ${props => (props.right ? 'right' : 'inherit')};
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
  width: ${props => (props.width ? props.width : 'inherit')};
  word-wrap: ${props => (props.wordWrap ? props.wordWrap : '')};
`;

const StyledTh = styled.th`
  padding: 1em 2em 1em 0;
  &:first-of-type {
    padding-left: 5px;
  }
  text-align: ${props => (props.right ? 'right' : 'inherit')};
  vertical-align: bottom;
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
  width: ${props => (props.width ? props.width : 'auto')};
  ${StyledThead} & {
    padding-bottom: 0.7em;
    padding-top: 0;
  }
  min-width: ${props => (props.minWidth ? props.minWidth : 'auto')};
`;

export const Table = ({ children }) => <StyledTable>{children}</StyledTable>;
export const Thead = props => <StyledThead {...props}>{props.children}</StyledThead>;
export const Tr = props => <StyledTr {...props}>{props.children}</StyledTr>;
export const Td = props => <StyledTd {...props}>{props.children}</StyledTd>;
export const Th = props => <StyledTh {...props}>{props.children}</StyledTh>;
