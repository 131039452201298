import R from 'ramda';
import { STORE_FETCHING_VIOLATION_TYPE_MAPPINGS } from './actions';

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_FETCHING_VIOLATION_TYPE_MAPPINGS:
      const mappedViolations = remapViolations(action.payload);
      return mappedViolations;
    default:
      return state;
  }
};

export const remapViolations = payload => {
  const byCategory = mapping => mapping.category;
  return R.groupBy(byCategory)(payload);
};


export const selectViolationTypes = state => R.keys(state);

export const selectErrorCategoriesDropdownOptions = state => {
  const categories = R.keys(state);
  return categories.map(category => ({
    label: category,
    value: category
  }));
};
