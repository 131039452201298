import {Button} from '@cjdev-internal/visual-stack-x/components/Button';
import {Row} from '@cjdev-internal/visual-stack-x/components/Row';
import {useNavigate} from 'react-router-dom';
import {COMPLIANCE_VIOLATIONS_MANAGE_RECIPIENT} from '../../../paths';

const ActionsDetailView = ({complianceViolationId}) => {
    const navigate = useNavigate();
    const onClickManageRecipient = () => {
        const path = COMPLIANCE_VIOLATIONS_MANAGE_RECIPIENT.replace(
            ':complianceViolationId',
            complianceViolationId
        );
        navigate(path);
    };
    return (
        <Row gap="small">
            <Button type="secondary" htmlType="button" data-testid='manage-recepients' uppercase
                    onClick={onClickManageRecipient}>
                Manage Recipients
            </Button>
        </Row>
    );
};

export default ActionsDetailView;
