import {Clickable} from '@cjdev-internal/visual-stack-x/Clickable';
import {Text} from '@cjdev-internal/visual-stack-x/Text';

const IssueDetailLink = ({title, toIssueDetail}) => (
    <Clickable
        style={{marginBottom: '18px'}}
        type="solid-primary"
        data-testid="issue-detail-link-clickable"
        onClick={() => toIssueDetail(title)}
    >
        <Text color="primary" size={18}>
            {title}
        </Text>
    </Clickable>
);

export default IssueDetailLink;
