import {
  TEST_RESULT_DETAIL_LOAD_START,
  TEST_RESULT_DETAIL_LOAD_FAIL,
  TEST_RESULT_DETAIL_STORE
} from './actions';

const initialState = {
  data: null,
  error: null,
  loading: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TEST_RESULT_DETAIL_LOAD_START:
      return {
        ...state,
        data: null,
        error: null,
        loading: true
      };
    case TEST_RESULT_DETAIL_STORE:
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false
      };
    case TEST_RESULT_DETAIL_LOAD_FAIL:
      return {
        ...state,
        data: null,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
