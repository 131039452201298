import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { isURL } from 'validator';
import { Formik } from 'formik';
import RemoveButton from '../../../src/components/Button/RemoveButton';
import URL from 'url-parse';
import LinkIcon from '../../components/LinkIcon';
import * as S from './Style';
import ErrorMessage from '../../components/Formik/Error';
import { normalize, denormalize } from './landingUrlConfigurationNormalizer';
import { Button } from '@cjdev-internal/visual-stack-x/components/Button';
import Input from '../../components/Formik/Input';

const defaultStatus = { status: 'match' };

const includeDefaults = query =>
  R.fromPairs(R.toPairs(query).map(([key, value]) => [key, { value, ...defaultStatus }]));

const validateFormInput = ({ url }) => {
  let errors = {};
  const isValidUrl = isURL(url) && R.startsWith('http', url);
  if (!isValidUrl) {
    errors.url = 'Invalid URL';
  }
  return errors;
};

const initialUrlValue = { url: '' };

const Configuration = ({
  url,
  querySettings,
  changeQueryStatus,
  deleteConfig,
  basicDomainInfo,
  changeIncludedDomains,
  includedDomains
}) => {
  const urlObject = new URL(url);
  return (
    <S.ConfigurationContainer className="configuration-box">
      <S.ConfigurationHeader>
        <S.Left>
          <S.Header>
            <S.Label>URL:</S.Label>
            <S.Url>
              <span>{`${urlObject.protocol}//${urlObject.host}${urlObject.pathname}`}</span>
              <LinkIcon url={url} />
            </S.Url>
          </S.Header>
          <S.Header>
            <S.Label>Domains:</S.Label>
            <S.StyledSelect
              placeholder="Apply to all publisher domains"
              width="`10`0%"
              name="includedDomains"
              value={includedDomains}
              multi
              closeOnSelect={false}
              options={basicDomainInfo.map(({ id: value, domain: label }) => ({ value, label }))}
              onChange={includedDomains => {
                changeIncludedDomains(url, includedDomains);
              }}
            />
          </S.Header>
        </S.Left>
        <RemoveButton
          onClick={() => {
            deleteConfig(url);
          }}
          className="deleteButton"
        >
          delete
        </RemoveButton>
      </S.ConfigurationHeader>
      {R.toPairs(querySettings).map(([key, { value, status }], index) => (
        <S.QuerySetting key={index}>
          <S.QueryPair>
            <S.QueryKey className="queryKey">{key}</S.QueryKey>
            <S.QueryValue className="queryValue">{value}</S.QueryValue>
          </S.QueryPair>
          <S.StyledSelect
            name="statusDropdown"
            value={status}
            searchable={false}
            clearable={false}
            options={[
              { value: 'match', label: 'Exact Match' },
              { value: 'populated', label: 'Populated' },
              { value: 'ignore', label: 'Ignore' }
            ]}
            onChange={e => {
              const status = e.value;
              changeQueryStatus(url, key, status);
            }}
          />
        </S.QuerySetting>
      ))}
    </S.ConfigurationContainer>
  );
};

const UrlInput = ({ values, handleChange, handleBlur, handleSubmit, errors }) => {
  return (
    <form onSubmit={handleSubmit}>
      <S.FormContainer>
        <Input
          placeholder="Enter an URL"
          autoComplete="off"
          name="url"
          type="text"
          value={values.url}
          onBlur={handleBlur}
          onChange={handleChange}
          error={R.hasIn('url', errors)}
          width="65%"
        />
        <Button htmlType="submit" type="primary">Parse</Button>
      </S.FormContainer>
      <ErrorMessage data-testid="error-message" className="error">{errors.url}</ErrorMessage>
    </form>
  );
};

export default class LandingUrlConfiguration extends React.Component {
  state = {
    ...initialUrlValue,
    configurations: {}
  };

  callOnChange = newState => {
    this.props.onChange(denormalize(newState));
  };

  handleFormSubmission = ({ url }, { resetForm }) => {
    resetForm();
    const { query } = queryString.parseUrl(url);
    const queryWithDefaultStatus = includeDefaults(query);
    const newState = {
      [url]: { querySettings: queryWithDefaultStatus, includedDomains: [] },
      ...this.getConfigurations()
    };
    this.callOnChange(newState);
  };

  getConfigurations = () =>
    normalize(this.props.landingUrlConfigurations, this.props.basicDomainInfo);

  getConfigurationsList = () => R.toPairs(this.getConfigurations());

  changeQueryStatus = (url, key, status) => {
    const newState = R.assocPath(
      [url, 'querySettings', key, 'status'],
      status,
      this.getConfigurations()
    );
    this.callOnChange(newState);
  };

  deleteConfig = url => {
    const newState = R.dissocPath([url], this.getConfigurations());
    this.callOnChange(newState);
  };
  changeIncludedDomains = (url, includedDomains) => {
    const newState = R.assocPath(
      [url, 'includedDomains'],
      includedDomains,
      this.getConfigurations()
    );
    this.callOnChange(newState);
  };

  render() {
    return (
      <S.Container>
        Enter one or more Landing URLs and select which query string parameters you would like to
        validate in addition to the base URL.
        <Formik
          initialValues={initialUrlValue}
          onSubmit={this.handleFormSubmission}
          validateOnChange={false}
          validateOnBlur={false}
          validate={validateFormInput}
          render={UrlInput}
        />
        <S.Configurations>
          {this.getConfigurationsList().map(([url, { querySettings, includedDomains }], index) => (
            <Configuration
              key={index}
              url={url}
              querySettings={querySettings}
              changeQueryStatus={this.changeQueryStatus}
              deleteConfig={this.deleteConfig}
              basicDomainInfo={this.props.basicDomainInfo}
              changeIncludedDomains={this.changeIncludedDomains}
              includedDomains={includedDomains}
            />
          ))}
        </S.Configurations>
      </S.Container>
    );
  }
}

LandingUrlConfiguration.propTypes = {
  landingUrlConfigurations: PropTypes.array.isRequired,
  basicDomainInfo: PropTypes.array.isRequired,
  includedDomains: PropTypes.array.isRequired
};

LandingUrlConfiguration.defaultProps = {
  landingUrlConfigurations: [],
  basicDomainInfo: [],
  includedDomains: []
};
