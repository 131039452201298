import React from 'react';
import styled from 'styled-components';

const SpinnerWrapper = styled.span`
  font-size: 1.1em;
  padding-right: 0.3em;
`;

const Spinner = ({ className, ...rest }) => (
  <SpinnerWrapper className={className} {...rest}>
    <i className="fa fa-circle-o-notch fa-spin" />
  </SpinnerWrapper>
);

export const SubmitButtonSpinner = styled(Spinner)`
  margin-left: 5px;
`;

export const LargeSpinner = styled(Spinner)`
  font-size: 5.1em;
`;

export default Spinner;
