import {TextArea} from "@cjdev-internal/visual-stack-x/TextArea";
import React, {useState} from "react";
import {Field} from "@cjdev-internal/visual-stack-x/Field"
import {Banner} from "@cjdev-internal/visual-stack-x/Banner"

const EmailContent = ({
                          publisherContact,
                          networkQualityContact,
                          advertiserContact,
                          issue,
                          onUpdateIssue,
                          additionalNotes,
                          tryAgain
                      }) => {
    const [publisherValue, setPublisherValue] = useState(publisherContact)
    const [advertiserValue, setAdvertiserValue] = useState(advertiserContact)
    const [networkContactValue, setNetworkContactValue] = useState(networkQualityContact)
    const [noteValue, setNoteValue] = useState(additionalNotes)
    const [maxChars, setMaxChars] = useState(additionalNotes.length);

    const validateMaxCharacter = (value) => setMaxChars(Object.entries(value).length);

    const handleChange = (id, value, setValue) => {
        setValue(value);
        const updatedIssue = {
            ...issue,
            publisherContacts: publisherValue,
            advertiserContacts: advertiserValue,
            networkQualityContacts: networkContactValue,
            additionalNotes: noteValue,
            [id]: value,
        };
        onUpdateIssue(updatedIssue);
    };

    return (
        <>
            {
                issue.status === "In Progress" ? <div>
                    {
                        tryAgain ?
                            <div data-testid="try-again">
                                <Banner type="warning">Email failed to send. Please Try Again</Banner><br/></div>
                            : null
                    }
                    <div data-testid="publisher-contact">
                        <Field label="Publisher Contacts" required>
                            <TextArea
                                data-testid="publisher-emails"
                                value={publisherValue}
                                onChange={e => {
                                    handleChange('publisherContacts', e.currentTarget.value, setPublisherValue)
                                }}
                                error={publisherValue.trim() === ""}
                            />
                        </Field>
                    </div>
                    <br/>
                    <div data-testid="advertiser-contact">
                        <Field label="Advertiser Contacts">
                            <TextArea
                                value={advertiserValue}
                                onChange={e => {
                                    handleChange('advertiserContacts', e.currentTarget.value, setAdvertiserValue)
                                }}
                                data-testid="advertiser-emails"
                            />
                        </Field>
                    </div>
                    <br/>
                    <div data-testid="network-quality-contact">
                        <Field label="CJ Contacts">
                            <TextArea
                                value={networkContactValue}
                                onChange={e => {
                                    handleChange('networkQualityContacts', e.currentTarget.value, setNetworkContactValue)
                                }}
                                data-testid="network-contact-emails"
                            />
                        </Field>
                    </div>
                    <br/>
                    <div data-testid="add-note">
                        <Field label="Add Note" help="Add a custom comment to the violation notice"
                               secondaryHelp={`${maxChars}/2000`}>
                            <TextArea
                                value={noteValue}
                                onChange={e => {
                                    handleChange('additionalNotes', e.currentTarget.value, setNoteValue)
                                    validateMaxCharacter(e.currentTarget.value)
                                }}
                                data-testid="add-notes"
                                placeholder="Enter text"
                                maxLength='2000'
                                resize="vertical"
                            />
                        </Field>
                    </div>
                </div> : <div>
                    {
                        tryAgain ?
                            <div data-testid="try-again">
                                <Banner type="warning">Email failed to send. Please Try Again</Banner><br/></div>
                            : null
                    }
                    <p>Press "Send" to notify this violation's communication recipients that it has been resolved.</p>
                </div>
            }
        </>
    );
};

export default EmailContent;
