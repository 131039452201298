import R from 'ramda';
import { createAction } from 'redux-actions';
import { apiServer } from '../../env';
import { authenticatedFetch } from '../../shared/apis';

// TESTS
export const TESTS_LOAD_START = 'CAS/TestResults/TESTS_LOAD_START';
export const startLoadTests = createAction(TESTS_LOAD_START);

export const TESTS_STORE = 'CAS/TestResults/TESTS_STORE';
export const storeTests = createAction(TESTS_STORE);

export const TESTS_LOAD_FAIL = 'CAS/TestResults/TESTS_LOAD_FAIL';
export const failLoadTests = createAction(TESTS_LOAD_FAIL);


export const loadTests = () => {
  return async dispatch => {
    dispatch(startLoadTests());
    try {
      const response = await authenticatedFetch(`${apiServer()}/tests`);
      const tests = await response.json();
      dispatch(storeTests(tests));
    } catch (err) {
      dispatch(failLoadTests(err));
    }
  };
};

// ARCHIVE TEST
export const TEST_ARCHIVE_RESET = 'CAS/Tests/TEST_ARCHIVE_RESET';
export const resetArchiveTest = createAction(TEST_ARCHIVE_RESET);

export const TEST_ARCHIVE_START = 'CAS/Tests/TEST_ARCHIVE_START';
export const startArchiveTest = createAction(TEST_ARCHIVE_START);

export const TEST_ARCHIVE_SUCCESS = 'CAS/Tests/TEST_ARCHIVE_SUCCESS';
export const successArchiveTest = createAction(TEST_ARCHIVE_SUCCESS);

export const TEST_ARCHIVE_FAIL = 'CAS/Tests/TEST_ARCHIVE_FAIL';
export const failArchiveTest = createAction(TEST_ARCHIVE_FAIL);


export const archiveTest = testId => {
  return async dispatch => {
    dispatch(startArchiveTest(testId));
    try {
      const response = await authenticatedFetch(`${apiServer()}/archive-test/${testId}`, {
        method: 'POST'
      });
      if (response.status === 200) {
        dispatch(successArchiveTest());
      } else {
        dispatch(failArchiveTest(response.statusText));
      }
    } catch (err) {
      dispatch(failArchiveTest(err.message));
    }
  };
};

export const archiveAndLoad = testId => {
  return async (dispatch, getState) => {
    return dispatch(archiveTest(testId)).then(() => {
      const archiveError = getState().CAS.tests.archive.error;
      if (R.isNil(archiveError)) {
        return dispatch(loadTests());
      }
    });
  };
};

//CANCEL TEST
export const TEST_CANCEL_START = 'CAS/Tests/TEST_CANCEL_START';
export const startCancelTest = createAction(TEST_CANCEL_START);

export const TEST_CANCEL_SUCCESS = 'CAS/Tests/TEST_CANCEL_SUCCESS';
export const successCancelTest = createAction(TEST_CANCEL_SUCCESS);

export const TEST_CANCEL_FAIL = 'CAS/Tests/TEST_CANCEL_FAIL';
export const failCancelTest = createAction(TEST_CANCEL_FAIL);

export const TEST_CANCEL_RESET = 'CAS/Tests/TEST_CANCEL_RESET';
export const resetCancelTest = createAction(TEST_CANCEL_RESET);

export const cancelTest = testId => {
  return async dispatch => {
    dispatch(startCancelTest(testId));
    try {
      const response = await authenticatedFetch(`${apiServer()}/cancel-test/${testId}`, {
        method: 'POST'
      });
      if (response.status === 200) {
        dispatch(successCancelTest());
      } else {
        const responseBody = await response.json();
        dispatch(failCancelTest(responseBody));
      }
    } catch (err) {
      dispatch(failCancelTest(err.message));
    }
  };
};

export const cancelAndLoad = testId => {
  return async (dispatch, getState) => {
    return dispatch(cancelTest(testId)).then(() => {
      const cancelError = getState().CAS.tests.cancel.error;
      if (R.isNil(cancelError)) {
        return dispatch(loadTests());
      }
    });
  };
};
